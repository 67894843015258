import react,{ useState, useEffect, useRef } from 'react';
import { getData,postData } from '../../../../ApiServices/ApiService';
import { ORG_GET_CLIENT_INFO, ORG_GET_CLIENT_CONTACT_LIST, ORG_DELETE_CLIENT_CONTACT } from '../../../../ApiServices/BaseURL';
import { useLocation } from 'react-router-dom';

const PrimaryInformationController = () => {
    const [basicInformation, setBasicInformation] = useState({
        client_name: "",
        client_type_id: null,
        group_id: null,
        client_email: "",
        client_status: null,
        client_file_id: "",
        client_mobile_no: null,
        client_mobile_code: null,
        client_pan_no: null,
        client_gstin: null,
        client_aadhar_no: null,
        client_enrolled_on: null,
        client_address: null,
        client_state: null,
        client_city: null,
        client_pin: null,
        emp_id_incharge: null,
        emp_id_fixed: null,
        is_india_client: null,
        client_type_name: "",
        group_name: "",
        emp_id_fixed_name: "",
      });
      const location = useLocation();
      const {clientId} = location.state || '';
      console.log('clientId:----------',clientId)
      const [clientContactList, setClientContactList] = useState([]);
      const [clientContactListLoading, setClientContactListLoading] =  useState(false);
      const [clientContactListError, setClientContactListError] = useState('');
      const contactInfoRef = useRef(null);
      const [deleteLoading, setDeleteLoading] = useState({});
      const [deleteError, setDeleteError] = useState({});

  
      const fieldConfigs = [
        { label: "Client Name", key: "client_name", type: "text" },
        { label: "Client Type", key: "client_type_name", type: "dropdown", options: ["Company", "Individual"] },
        { label: "Date of Enrollment", key: "date_of_enrollment", type: "date" },
        { label: "Group Name", key: "group_name", type: "dropdown", options: ["Group A", "Group B"] },
        { label: "Address", key: "client_address", type: "textarea" },
        { label: "City", key: "client_city", type: "text" },
        { label: "State", key: "client_state", type: "dropdown", options: ["Telangana", "Andhra Pradesh"] },
        { label: "Pin Code", key: "client_pin", type: "text" },
        // { label: "Email ID", key: "client_email", type: "text" },
        { label: "Mobile No.", key: "client_mobile_no", type: "text" },
        { label: "GSTIN", key: "client_gstin", type: "text" },
        { label: "Aadhaar Number (UIDAI)", key: "client_aadhar_no", type: "text" },
        { label: "Fix Assigned User", key: "fix_assigned_user", type: "text" },
        { label: "Client Incharge", key: "emp_id_fixed_name", type: "text" },
        { label: "Out Of India Client", key: "out_of_india_client", type: "dropdown", options: ["Yes", "No"] },
      ];
      

      const [clientInformation,setclientInformation]=useState({})

      const dropdownFields = {
        ClientType: ["Company", "Individual", "Partnership", "LLP"],
        GroupName: ["Group A", "Group B", "Group C"],
        City: ["Hyderabad", "Bangalore", "Chennai", "Delhi"],
        State: ["Telangana", "Andhra Pradesh", "Karnataka", "Tamil Nadu"],
        OutOfIndiaClient: ["Yes", "No"],
      };
  
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('');

    useEffect(()=>{
        fetchClientInfo();
        fetchClientContacts();
    },[])

    const fetchClientInfo =async ()=>{
        try {
          clearData();
            setLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_CLIENT_INFO}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    // setclientInformation(responseData?.dataJ[0]);
                    setBasicInformation(responseData?.dataJ[0])
                    
                  } else {
                    setError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch Task Categorie data: ${String(error)}`);
            }
          } finally {
            setLoading(false);
          }
      }

  const fetchClientContacts = async () => {
      try {
        setClientContactList([]);
        setClientContactListLoading(true);
        setClientContactListError('');
          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      
          // Make the API call
          const response = await getData(`${ORG_GET_CLIENT_CONTACT_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}`);
          const responseData = await response.json();
      
          // Check for success (200) and internal status code
          if (response?.status === 200) {
            if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                console.log('responseData @@@', responseData)
                if (responseData?.dataJ?.length > 0) {
                  setClientContactList(responseData?.dataJ);
                  
                } else {
                  setClientContactListError(responseData?.info || 'Data Not Found');
                }
              } else {
                setClientContactListError(responseData?.info || 'Failed to Get Client Contact List. Please try again.');
              }
            } else {
              setClientContactListError(responseData?.info || 'Failed to Get Client Contact List. Status code error.');
            }
          } 
          // Token-related errors: 400, 401, 403
          else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
            if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
              setClientContactListError('Unauthorized access. Your session may have expired. Please log in again.');
              // Optionally, redirect to the login page or refresh the token
              // Example: redirectToLogin();
            } else {
              setClientContactListError(responseData?.info || 'Bad request. Please check the request parameters.');
            }
          } 
          // Internal Server Error: 500
          else if (response?.status === 500) {
            setClientContactListError(responseData?.info || 'Internal Server Error. Please try again later.');
          } 
          // Unexpected or network-related errors
          else {
            setClientContactListError('Unexpected error occurred. Please try again later.');
          }
        } catch (error) {
          // Handle various error scenarios
          if (error.response) {
            // Server responded with a status outside the 2xx range
            setClientContactListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to Get Client Contact List. Please try again.'}`);
          } else if (error.request) {
            // Request was made but no response was received
            setClientContactListError('No response received from the server. Please check your network connection and try again.');
          } else {
            // An error occurred in setting up the request
            setClientContactListError(`Failed to Get Client Contact List: ${String(error)}`);
          }
        } finally {
          setClientContactListLoading(false);
        }
  }

  const handleAddContactClick = () => {
    contactInfoRef.current = null;
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFieldChange = (key, value) => {
    setBasicInformation((prev) => ({ ...prev, [key]: value }));
  };

  const clearData = ()=>{
    setBasicInformation({
      client_name: "",
      client_type_id: null,
      group_id: null,
      client_email: "",
      client_status: null,
      client_file_id: "",
      client_mobile_no: null,
      client_mobile_code: null,
      client_pan_no: null,
      client_gstin: null,
      client_aadhar_no: null,
      client_enrolled_on: null,
      client_address: null,
      client_state: null,
      client_city: null,
      client_pin: null,
      emp_id_incharge: null,
      emp_id_fixed: null,
      is_india_client: null,
      client_type_name: "",
      group_name: "",
      emp_id_fixed_name: "",
    })
  }

  const handleEditClientContact = (contact) => {
    contactInfoRef.current = contact;
    setIsModalOpen(true);
  }

  const onAddUpdateSuccess = () => {
    console.log('onAddUpdateSuccess')
    fetchClientContacts();
  }

  const handleDeleteClientContact = (contact) => {
    // console.log('handleDeleteTaskCategory service @', service);
    showConfirmation(contact);
  }

  const showConfirmation = (contact) => {
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      callDeleteClientContact(contact);
    } else {
      console.log("Delete canceled");
    }
  }

  const callDeleteClientContact = async (contact) => {
    try {
      console.log('callDeleteClientContact')
      setDeleteError((prev) => ({ ...prev, [contact?.contact_id]: ''}));
      setDeleteLoading((prev) => ({ ...prev, [contact?.contact_id]: true }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "contact_id" : contact?.contact_id
      }
  
      console.log('callDeleteClientContact payload',payLoad);
  
      // Make the API call
      const response = await postData(ORG_DELETE_CLIENT_CONTACT,payLoad);
      const responseData = await response.json();
      console.log('callDeleteClientContact response Data',responseData);
  
      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            // setError(responseData?.info);
            setDeleteError((prev) => ({ ...prev, [contact?.contact_id]: responseData?.info}));
          } else {
            setDeleteError((prev) => ({ ...prev, [contact?.contact_id]: responseData?.info || 'Failed to delete client contact. Please try again.'}));
            // setError(responseData?.info || 'Failed to delete service. Please try again.');
          }
        } else {
          setDeleteError((prev) => ({ ...prev, [contact?.contact_id]: responseData?.info || 'Failed to delete client contact. Please try again...'}));
          // setError(responseData?.info || 'Failed to delete service. Please try again...');
        }
      } 
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          // setError('Unauthorized access. Your session may have expired. Please log in again.');
          setDeleteError((prev) => ({ ...prev, [contact?.contact_id]: 'Unauthorized access. Your session may have expired. Please log in again.'}));
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          // setError(responseData?.info || 'Bad request. Please check the request parameters.');
          setDeleteError((prev) => ({ ...prev, [contact?.contact_id]: responseData?.info || 'Bad request. Please check the request parameters.'}));
        }
      } 
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setDeleteError((prev) => ({ ...prev, [contact?.contact_id]: responseData?.info || 'Internal Server Error. Please try again later.'}));
        // setError(responseData?.info || 'Internal Server Error. Please try again later.');
      } 
      // Unexpected or network-related errors
      else {
        setDeleteError((prev) => ({ ...prev, [contact?.contact_id]: 'Unexpected error occurred. Please try again later.'}));
        // setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        // setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete service. Please try again.'}`);
        setDeleteError((prev) => ({ ...prev, [contact?.contact_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete client contact. Please try again.'}`}));
      } else if (error.request) {
        // Request was made but no response was received
        // setError('No response received from the server. Please check your network connection and try again.');
        setDeleteError((prev) => ({ ...prev, [contact?.contact_id]: 'No response received from the server. Please check your network connection and try again.'}));
      } else {
        // An error occurred in setting up the request
        // setError(`Failed to delete service data: ${String(error)}`);
        setDeleteError((prev) => ({ ...prev, [contact?.contact_id]: `Failed to delete client contact: ${String(error)}` }));
      }
    } finally {
      // setTimeout(() => {
      //   setDeleteLoading((prev) => ({ ...prev, [contact?.contact_id]: false }));
      // }, 3000);
        setDeleteLoading((prev) => ({ ...prev, [contact?.contact_id]: false })); 
    }
  }



    return {
        basicInformation,
        clientInformation,
        isModalOpen,
        clientContactList,
        dropdownFields,
        handleFieldChange,
        handleCloseModal,
        handleAddContactClick,
        fieldConfigs,
        loading,
        error,
        clientContactListLoading,
        clientContactListError,
        handleEditClientContact,
        handleDeleteClientContact,
        contactInfoRef,
        onAddUpdateSuccess,
        clientId,
        deleteLoading,
        deleteError
    };
};

export default PrimaryInformationController;
