import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClientBulkUpdateListController from '../../../../Controllers/AdminScreens/Services/Settings/ClientBulkUpdateListController';
import Dropdown from '../../../../../Components/Dropdown';
import TextInput from '../../../../../Components/TextInput';
import CheckBox from '../../../../../Components/CheckBox';
import search from '../../../../../Assets/Icons/search.png';
import editIcon from '../../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../../Assets/Icons/delete.png';
import downArrow from '../../../../../Assets/Icons/chevron-down.png';

const ClientBulkUpdateListScreen = () => {
    const navigate = useNavigate();

    const [selectAll, setSelectAll] = useState(false);
    const [checkedStates, setCheckedStates] = useState([]);

    const [clientName, setClientName] = useState("");
    const [groupName, setGroupName] = useState("");
    const [assignedUser, setAssignedUser] = useState("");
    const [filters, setFilters] = useState({
        location: '',
        taskLeader: '',
        services: '',
    });

    const {
        tasks,
        currentPage,
        totalPages,
        handlePageChange,
        toggleDetails
    } = ClientBulkUpdateListController();

    const handleFilterChange = (name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const applyFilters = () => {
        console.log('Applying filters:', filters);
    };

    const resetFilters = () => {
        setFilters({
            location: '',
            taskLeader: '',
            services: '',
        });
    };

    const toggleFilters = () => {
        setFilters({
            location: '',
            taskLeader: '',
            services: '',
        });
        console.log('Cancel filters:', filters);
    };

    const handleNav = (pageName, task) => {
        console.log('page', pageName, 'task', task);
        navigate(`/${pageName}`, { state: { task } }); // Pass task in state
    };

    const handleSelectAll = (isChecked) => {
        setSelectAll(isChecked);
        setCheckedStates(tasks.map(() => isChecked));
    };

    const handleCheckboxChange = (index, isChecked) => {
        const updatedStates = [...checkedStates];
        updatedStates[index] = isChecked;
        setCheckedStates(updatedStates);
    };

    return (
        <>
            <div className='flexdisplaybetween' style={{ paddingBottom: '10px' }}>
            <h2 >Search Clients</h2>
            <div className='add-popup-buttons' style={{fontSize:12}}>Bulk Import</div>
            </div>
            <div className="basic-info-outer-card" style={{ borderBottom: '1px solid #EFF0F4' }}>
                <div className="tasksScreen-open-filters">
                    <div className="tasksScreen-filters">
                        <div className="filter-field" style={{ paddingBottom: '10px' }}>
                            <label>Search Client</label>
                            <TextInput
                                type="text"
                                placeholder="Enter client name"
                                value={clientName}
                                onChange={(e) => setClientName(e.target.value)}
                                right={<img src={search} alt="Search" className="search-icon" />}
                            />
                        </div>
                        <div className="filter-field" style={{ paddingBottom: '10px' }}>
                            <label>Group Name</label>
                            <TextInput
                                type="text"
                                placeholder="Enter group name"
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                                right={<img src={search} alt="Search" className="search-icon" />}
                            />
                        </div>
                        <div className="filter-field" style={{ paddingBottom: '10px' }}>
                            <label>Assigned User</label>
                            <TextInput
                                type="text"
                                placeholder="Enter assigned user"
                                value={assignedUser}
                                onChange={(e) => setAssignedUser(e.target.value)}
                                right={<img src={search} alt="Search" className="search-icon" />}
                            />
                        </div>
                        <Dropdown
                            label="Location"
                            options={['Barchasi', 'Hyderabad']}
                            value={filters.location}
                            onChange={(value) => handleFilterChange('location', value)}
                        />

                        <Dropdown
                            label="Task Leader"
                            options={['Barchasi', 'Jack']}
                            value={filters.taskLeader}
                            onChange={(value) => handleFilterChange('taskLeader', value)}
                        />

                        <Dropdown
                            label="Services"
                            options={['GST', 'Tax Filing']}
                            value={filters.services}
                            onChange={(value) => handleFilterChange('services', value)}
                        />

                    </div>

                    <div className="tasksScreen-filter-buttons">
                        <button onClick={applyFilters} className="tasksScreen-apply-button">Apply</button>
                        <button onClick={toggleFilters} className="tasksScreen-cancel-button">Cancel</button>
                        <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
                    </div>
                </div>
            </div>
            <div style={{ paddingTop: '50px' }}>
                <div className="basic-info-outer-card" style={{ paddingTop: '10px ' }}>
                    <table className="tasksScreen-task-table" >
                        <thead>
                            <tr >
                                <th style={{ width: "5%" }}>
                                    {/* <input type="checkbox" /> */}
                                    <CheckBox
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                    />
                                </th>
                                <th style={{ width: "25%", }}>Client Name</th>
                                <th style={{ width: "10%", }}>Group Name</th>
                                <th style={{ width: "10%", }}>Assigned to</th>
                                <th style={{ width: "10%", }}>Task Leader</th>
                                <th style={{ width: "10%", }}>Location</th>
                                <th style={{ width: "15%", }}>Services</th>
                                <th style={{ width: "7%", }}></th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: 'white' }}>
                            {tasks?.map((task, index) => (
                                <tr
                                    key={index}
                                    style={{
                                        borderBottom: "3px solid #f8f9fa",
                                    }}
                                >
                                    <td >
                                        {/* <input type="checkbox" /> */}
                                        <CheckBox
                                            checked={checkedStates[index] || false}
                                            onChange={(isChecked) => handleCheckboxChange(index, isChecked)}
                                        />
                                    </td>
                                    <td>{task.clientName}</td>
                                    <td>{task.groupName}</td>
                                    <td>{task.assignedTo}</td>
                                    <td>{task.taskLeader}</td>
                                    <td>{task.location}</td>
                                    <td>{task.services}</td>
                                    <td
                                    >
                                        <button className="tasksScreen-edit-button" >
                                            <img src={editIcon} alt="Edit" onClick={() => handleNav('manage-clients', 'Tasks')} />
                                        </button>
                                        <button className="tasksScreen-delete-button">
                                            <img src={deleteIcon} alt="Delete" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div className="button-container">
                    <button className="add-client-button">Bulk Update</button>
                    <button className="cancel-button">Cancel</button>
                </div>
                <div className="tasksScreen-table-footer">
                    <span className="tasksScreen-pagination-info">
                        {`${currentPage}-${totalPages} of items`}
                    </span>
                    <div className="tasksScreen-pagination">
                        <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
                        </button>
                        {[...Array(totalPages)].map((_, index) => (
                            <button key={index} className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                        ))}
                        <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                            <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};


export default ClientBulkUpdateListScreen;
