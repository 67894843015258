import React, { useState, useEffect,useRef } from 'react';
import Input from '../Input';
import Dropdown from '../Dropdown';
import closeIcon from '../../Assets/Icons/close.png';
import pluseIcon from '../../Assets/Icons/Pluse.png';
import uploadIcon from '../../Assets/Icons/upload.png';
import TextInput from '../TextInput';
import search from '../../Assets/Icons/search.png'

const ConfigurationEmailConfigurationModal = ({ isOpen, onClose, onValueChange }) => {

    const [userName, setUserName] = useState('');
    const [channel, setChannel] = useState('');
    const [api, setApi] = useState('');
    const [password, setPassword] = useState('');
   
    const [showUpload, setShowUpload] = useState(false);
  
    const fileInputRef = useRef(null);
  
    // useEffect(() => {
    //   if (clientName || clientType || identificationType || identificationNumber || groupClient || mobileNumber) {
    //     setShowUpload(true);
    //   } else {
    //     setShowUpload(false);
    //   }
    // }, [clientName, clientType, identificationType, identificationNumber, groupClient, mobileNumber]);
  
    // Reset form fields to initial values
    const resetForm = () => {
      setUserName('');
      setChannel('');
      setApi('');
      setPassword('');
    };
  
    // Run resetForm when modal closes
    useEffect(() => {
      if (!isOpen) {
        resetForm();
      }
    }, [isOpen]);
  
    const handleAddClient = () => {
      return
      const clientData = {
        userName,
        channel,
        api,
        password,
      };
  
      onValueChange(clientData); // Send data to Dashboard
      onClose(); // Close modal
    };
  
    // Handle file input change
    const handleFileChange = (event) => {
      const files = event.target.files;
      if (files && files.length > 0) {
        console.log("Uploaded file:", files[0]);
        // Handle file upload here (e.g., upload to server or process locally)
      }
    };
  
    // Trigger file input click
    const handleUploadClick = () => {
      fileInputRef.current.click();
    };

    const groupClientOptions = ["Active", "In Active"];
    const [groupClient, setGroupClient] = useState('');
  
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
          </button>
          <h2 className="modal-title">Configuration</h2>
          <hr className="title-divider" />
        <div className="form-grid">
          {/* <div style={styles.formGrid}>
            <div style={styles.row}> */}
          <Dropdown
              label="Channel"
              options={groupClientOptions}
              value={channel}
              onChange={setChannel}
            />
            <Input
                label="User Name"
                placeholder="Name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                />
            {/* </div>
            </div> */}
            {/* <div style={styles.row}> */}
            <Input
                label="API"
                placeholder="Name"
                value={api}
                onChange={(e) => setApi(e.target.value)}
                />
                <Input
                label="Password"
                placeholder="Name"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                />
            
          {/* </div> */}
          {/* <div style={styles.row}> */}
          
           
          {/* </div> */}
          </div>
          {/* {showUpload && ( */}
          {/* <div style={styles.uploadContainer} onClick={handleUploadClick} onDrop={(e) => e.preventDefault()}>
            <div style={styles.uploadBox} onDragOver={(e) => e.preventDefault()}>
              <span style={styles.uploadCircle}>
              <img src={pluseIcon} alt="Plus Icon" style={styles.iconImage} />
              </span>
              <p style={styles.uploadText}>Upload Form 16 for faster onboarding, Drop here or click on upload</p>
              <img src={uploadIcon} alt="Upload Icon" style={styles.uploadIconImage} />
  
             
              <input
                type="file"
                ref={fileInputRef}
                style={styles.fileInput}
                onChange={handleFileChange}
              />
            </div>
          </div> */}
        {/* )} */}
          {/* <div className="existing-client-checkbox">
              <label style={styles.exisitingHeading}>Primary Contact?</label>
              <input type="checkbox" checked={primaryContact} onChange={(e) => setPrimartContact(e.target.checked)} />
            </div> */}
          <hr className="footer-divider" />
  
          <div className="button-container">
            <button className="add-client-button" onClick={handleAddClient} style={{paddingLeft:30, paddingRight:30}}>Add</button>
            <button className="cancel-button" onClick={() => { resetForm(); onClose(); }}>Cancel</button>
          </div>
        </div>
      </div>
    );
  };
  
  const styles = {
      uploadContainer: {
          marginTop: '20px',
          width: '97%',
        },
        uploadBox: {
          width: '100%',
          border: '1px dashed #609966',
          borderRadius: '10px',
          padding: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          cursor: 'pointer',
          boxSizing: 'border-box',
          position: 'relative', // Position relative to contain the hidden input
        },
        uploadCircle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          backgroundColor: '#f0f0f0',
        },
        plusIcon: {
          fontSize: '16px',
          color: '#333',
        },
        uploadText: {
          flex: 1,
          color: '#262626',
          fontSize: '12px',
          fontFamily:'Roboto',
          fontWeight:400
        },
        uploadArrow: {
          fontSize: '18px',
          color: '#555',
        },
        fileInput: {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0,
          cursor: 'pointer',
        },
        iconImage: {
          width: '11px',
          height: '11px',
      },
      uploadIconImage: {
        width: '25px',
        height: '25px',
        marginRight:'5px'
    },
    exisitingHeading:{
      color:'#343C44',
      fontSize:'10px',
      fontWeight:500,
      fontFamily:'Roboto'
    },
    formGrid: {
        display: "grid",
        gridTemplateColumns: "1fr", // Single-column layout by default
        gap: "20px", // Space between rows
        marginBottom: "15px",
      },
      halfWidth: {
        gridColumn: "span 1",
        width: "48%", // Half width
        marginRight: "auto", // Aligns left
        marginBottom:'17px'
      },
      fullWidth: {
        gridColumn: "span 2",
        width: "97.5%", // Full width
      },
      row: {
        display: "flex",
        // justifyContent: "space-between",
        columnGap: "80px", // Space between dropdowns
        marginBottom: "15px",
        marginRight: "auto",
        width: "100%"
      },
      labaleStyle: {
        fontSize: '12px',
        // marginBottom: '5px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        color: 'rgb(52, 60, 68)'
      },
  }
  
  export default ConfigurationEmailConfigurationModal;