
import React from "react";

const InvoiceReceiptBooksController = ()=>{

    const invoiceReceiptBookData = [
        {
          receiptBook:'FY 2023-24',
          organization: "PLC CA Services",
          address: "Address Line 1, Address Line 2, City, State - PIN",
          gstNo: "--",
          phone: "98900 98900",
          signatory: "John Doe",
          status: "Active",
          seqNo:'PLC CA Services'
        },
        {
            receiptBook:'FY 2023-24',
            organization: "India Tax Pvt Ltd",
          address: "Address Line 1, Address Line 2, City, State - PIN",
          gstNo: "--",
          phone: "98900 98900",
          signatory: "John Doe",
          status: "Active",
          seqNo:'PLC CA Services'
        },
        {
            receiptBook:'FY 2023-24',
            organization: "Free Tax Filers",
          address: "Address Line 1, Address Line 2, City, State - PIN",
          gstNo: "--",
          phone: "98900 98900",
          signatory: "John Doe",
          status: "Disabled",
          seqNo:'PLC CA Services'
        }
      ]
      const getStatusStyle = (status) => {
        switch (status) {
          case "":
            return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" }; // yellow
          case "Disabled":
            return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" }; // red
          case "Active":
            return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" }; // Green
          default:
            return {};
        }
      };

      return{
        invoiceReceiptBookData,
        getStatusStyle
      }
      
}

export default InvoiceReceiptBooksController