import React, { useState } from "react";
import Dropdown from "../../../../Components/Dropdown";
import Input from "../../../../Components/Input";
import DateInput from "../../../../Components/DateInput";
import TextInput from '../../../../Components/TextInput'
import search from '../../../../Assets/Icons/search.png';
import uploadIcon from '../../../../Assets/Icons/upload.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import ReminderRegisterController from "../../../Controllers/AdminScreens/Configuration/ReminderRegisterController";


const ReminderRegisterScreen = () => {
    const{
        clientsList,
        selectList,
        setSelectList,
        selectClient,
        setSelectClient,
        channel,
        setChannel,
        selectTemplate,
        setSelectTemplate,
        messageContent,
        setMessageContent,
        scheduleFor,
        setScheduleFor,
        date,
        setDate
    }= ReminderRegisterController();
  
    return (
      <div style={styles.container}>
        <h3 style={styles.categoryHeader}>Send Reminder</h3>
  
        <div style={styles.row}>
          {/* Recipient Information */}
          <div style={styles.recipientCard}>
            <h4 style={styles.cardHeader}>Recipient Information</h4>
            <div className="basic-info-card" style={{border:'none'}}>
              <Dropdown label="Select List" options={["Barchasi"]} value={selectList} onChange={setSelectList}/>
              <div className="filter-field" style={{ marginTop: "15px" }}>
                <label style={{ ...styles.labelStyle, display: "block" }}>
                  Select Client
                </label>
                <TextInput
                  type="text"
                  placeholder="Search"
                  right={<img src={search} alt="Search" className="search-icon" />}
                  style={{ padding: "20px 10px" }}
                  value={selectClient}
                  onChange={(e)=> setSelectClient(e.target.value)}
                />
              </div>
              <div style={styles.buttonContainer}>
                <button
                  style={{
                    ...styles.addServiceButton,
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={uploadIcon}
                    style={{ ...styles.icon, cursor: "pointer" }}
                    alt="Upload Icon"
                  />
                  <span style={{ ...styles.buttonText, cursor: "pointer" }}>
                    Upload
                  </span>
                </button>
                <button style={styles.addServiceButton}>
                  <span style={{ marginRight: "10px" }}>+</span> Add Client
                </button>
              </div>
              <div style={{ marginTop: "10px" }}>
                <table className="tasksScreen-task-table">
                  <thead>
                    <tr>
                      <th style={{ width: "90%" }}>Clients</th>
                      <th style={{ width: "10%" }}></th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "white" }}>
                    {clientsList?.map((category, index) => (
                      <tr key={index}>
                        <td style={styles.tableLink}>{category.clientName}</td>
                        <td>
                          <button
                            className="tasksScreen-delete-button"
                            style={{
                              border: "none",
                              background: "transparent",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={deleteIcon}
                              alt="Delete"
                              style={{ width: "16px", height: "16px" }}
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
  
          {/* Select Template */}
          <div style={styles.templateCard}>
            <h4 style={styles.cardHeader}>Select Template</h4>
            <div className="basic-info-card" style={{border:'none'}}>
                <div style={styles.display}>
              <Dropdown label="Channel" options={["WhatsApp"]} value={channel} onChange={setChannel}/>
              <div className="filter-field" >
                <label style={{ ...styles.labelStyle, display: "block" }}>
                  Select Template
                </label>
                <TextInput
                  type="text"
                  placeholder="Search"
                  right={<img src={search} alt="Search" className="search-icon" />}
                  style={{ padding: "20px 10px" }}
                  value={selectTemplate}
                  onChange={(e) => setSelectTemplate(e.target.value)}
                />
              </div>
              </div>
              <div style={{marginTop:'28px'}}>
              <textarea
                placeholder="Message Content"
                style={{ ...styles.textarea, width: "95%" }}
                rows={22}
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
              />
              </div>
            </div>
          </div>
  
          {/* Scheduling Details */}
          <div style={styles.schedulingCard}>
            <h4 style={styles.cardHeader}>Scheduling Details</h4>
            <div
              className="basic-info-card"
              style={{ height: "auto", paddingBottom: "20px",border:'none' }}
            >
              <Dropdown label="Schedule for" options={["Now", "Later"]} value={scheduleFor} onChange={setScheduleFor}/>
              <div className="filter-field" style={{ marginTop: "15px" }}>
                <label style={{ ...styles.labelStyle, display: "block" }}>
                  Date
                </label>
                <DateInput style={{ padding: "20px 10px" }} value={date} onChange={(e)=>setDate(e.target.value)}/>
              </div>
            </div>
        <div className="button-container" style={{ marginTop: '25px' }}>
          <button className="add-client-button" style={{ paddingLeft: '30px', paddingRight: '30px',marginRight:'10px' }}>Submit Reminder</button>
          <button className="cancel-button" >Cancel</button>
        </div>
          </div>
        </div>
  
      </div>
    );
  };
  
  export default ReminderRegisterScreen;
  
  const styles = {
    container: {
      padding: "20px",
      maxWidth: "90%",
      margin: "auto",
      backgroundColor: "#fff",
    //   border: "1px solid #ccc",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      marginBottom:'50px',
      borderLeft:'1px solid #609966'
    },
    categoryHeader: {
      color: "#1F384C",
      fontSize: 18,
      fontWeight: 500,
      paddingBottom: 20,
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      gap: "20px",
    },
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    cardHeader: {
      fontSize: "16px",
      fontWeight: "500",
      color: "#609966",
      marginBottom: "10px",
    },
    recipientCard: {
      flex: "1 1 20%", // Reduce width
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    templateCard: {
      flex: "1 1 40%", // Keep the center card wider
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    schedulingCard: {
      flex: "1 1 20%", // Reduce width
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    labelStyle: {
      fontSize: "12px",
      fontWeight: "400",
      fontFamily: "Roboto",
      color: "rgb(52, 60, 68)",
    },
    buttonContainer: {
      display: "flex",
      columnGap: "35px",
      marginTop: "10px",
    },
    addServiceButton: {
      backgroundColor: "transparent",
      border: "1px #858585 solid",
      borderRadius: "28px",
      padding: "8px 12px",
      color: "#40513B",
      cursor: "pointer",
      fontSize: "12px",
      fontFamily: "Roboto",
      fontWeight: "400",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    textarea: {
      border: "1px solid #d1e3ed",
      borderRadius: "5px",
      fontSize: "14px",
      padding: "10px",
      resize: "none",
    },
    buttonGroup: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "20px",
    },
    submitButton: {
      backgroundColor: "#4CAF50",
      color: "#fff",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    cancelButton: {
      backgroundColor: "#E0E0E0",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    icon: {
        width: "12px",
        height: "12px",
        marginRight: "10px",
    },
    display:{
        display:'flex',
        columnGap:'40px',
        rowGap: "20px",
        flexWrap:'wrap'
    }
  };
  
  