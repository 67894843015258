
import React,{useState} from 'react';

const InvoiceSettingsConfigurationsController = ()=>{
    const [serviceEstimatePrefix, setServiceEstimatePrefix] = useState("DLS24-");
  const [invoicePrefix, setInvoicePrefix] = useState("DLS24-");
  const [receiptPrefix, setReceiptPrefix] = useState("DLS24-");
  const [booksOpeningBalance, setBooksOpeningBalance] = useState("0.00");

  return {
    setServiceEstimatePrefix,
    serviceEstimatePrefix,
    setInvoicePrefix,
    invoicePrefix,
    setReceiptPrefix,
    receiptPrefix,
    setBooksOpeningBalance,
    booksOpeningBalance
  }
}

export default InvoiceSettingsConfigurationsController