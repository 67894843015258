import React, { useState } from 'react';
import ClientManageServicesModal from '../../../../Components/Modals/ClientManageServicesModal';
import editIcon from '../../../../Assets/Icons/edit.png';
import Toggle from '../../../../Components/Toggle';

const ClientAvailedServicesScreen = () => {
    const clientServices = [
        { category: "GST", type: "GSTR 3B, GSTR 1" },
        { category: "Income Tax", type: "ITR Filing" },
        { category: "TDS", type: "TDS Returns" },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    // State to track the selected service details
    const [selectedService, setSelectedService] = useState(null);

    const handleEditClick = (service) => {
        setSelectedService(service); // Set the selected service to show in the modal
        setIsModalOpen(true); // Open the modal
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedService(null); // Clear the selected service
    };

    const handleToggleChange = (newState) => {
        console.log('Toggle is now:', newState);
    };

    return (
        <div className="tasksScreen-task-table-wrapper">
            <h3 className='basic-info-header'>Services Availed</h3>
            <table className="tasksScreen-task-table">
                <thead>
                    <tr>
                        <th style={{ width: "25%" }}>Service Category</th>
                        <th style={{ width: "65%" }}>Service Type</th>
                        <th style={{ width: "10%" }}></th>
                    </tr>
                </thead>
                <tbody style={{ backgroundColor: 'white' }}>
                    {clientServices.map((service, index) => (
                        <tr
                            key={index}
                            className="tasksScreen-main-task-row"
                            style={{ borderBottom: "1px solid #dee2e6" }}
                        >
                            <td style={{ cursor: 'pointer', textDecoration: 'underline', color: '#5a55d2' }}>{service.category}</td>
                            <td>{service.type}</td>
                            <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <button
                                    className="tasksScreen-edit-button"
                                    onClick={() => handleEditClick(service)}
                                >
                                    <img
                                        src={editIcon}
                                        alt="Edit"
                                        style={{ width: "16px", height: "16px" }}
                                    />
                                </button>
                                {/* <button className="tasksScreen-delete-button">
                                    <img
                                        src={deleteIcon}
                                        alt="Delete"
                                        style={{ width: "16px", height: "16px" }}
                                    />
                                </button> */}
                                <Toggle initialStatus={false} onToggle={handleToggleChange}/>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Manage Services Modal */}
            {isModalOpen && (
                <ClientManageServicesModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    selectedService={selectedService} // Pass the selected service
                />
            )}
        </div>
    );
};

export default ClientAvailedServicesScreen;

