import { useState, useEffect } from 'react';

const BillingPaymentListController = () => {
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        // Sample data matching the table in the provided image
        const data = [
            {
                clientName: 'HPCL Corp',
                groupName: 'Company Name/Group Name',
                accountManager: 'John Doe',
                noOfBills: 12,
                lastBillDate: '11/11/2024',
                outstanding: 45000.0,
            },
            {
                clientName: 'Pranay R',
                groupName: 'Company Name/Group Name',
                accountManager: 'John Doe',
                noOfBills: 23,
                lastBillDate: '11/11/2024',
                outstanding: 5000.0,
            },
            {
                clientName: 'Satish R',
                groupName: 'Company Name/Group Name',
                accountManager: 'John Doe',
                noOfBills: 0,
                lastBillDate: '11/11/2024',
                outstanding: 200.0,
            },
            {
                clientName: 'Ashok',
                groupName: 'Company Name/Group Name',
                accountManager: 'John Doe',
                noOfBills: 11,
                lastBillDate: '11/11/2024',
                outstanding: 5000.0,
            },
            {
                clientName: 'Peter Parker',
                groupName: 'Company Name/Group Name',
                accountManager: 'John Doe',
                noOfBills: 32,
                lastBillDate: '11/11/2024',
                outstanding: 5000.0,
            },
            {
                clientName: 'Steve Boardman',
                groupName: 'Company Name/Group Name',
                accountManager: 'John Doe',
                noOfBills: 4,
                lastBillDate: '11/11/2024',
                outstanding: 45000.0,
            },
            {
                clientName: 'Allison Shaw',
                groupName: 'Company Name/Group Name',
                accountManager: 'John Doe',
                noOfBills: 43,
                lastBillDate: '11/11/2024',
                outstanding: 5000.0,
            },
            {
                clientName: 'Slavka Bateman',
                groupName: 'Company Name/Group Name',
                accountManager: 'John Doe',
                noOfBills: 21,
                lastBillDate: '11/11/2024',
                outstanding: 15000.0,
            },
        ];
        setTasks(data);
        setFilteredTasks(data);
    }, []);

    const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return {
        tasks: currentTasks,
        currentPage,
        totalPages,
        handlePageChange,
    };
};

export default BillingPaymentListController;
