import React, { useState, useEffect } from "react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import closeIcon from "../../Assets/Icons/close.png";

const AddCallTypeModal = ({ isOpen, onClose }) => {
    const [natureOfCall, setNatureOfCall] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("");

    const statusOptions = ["Active", "Inactive", "Disabled"];

    useEffect(() => {
        if (!isOpen) {
            resetForm();
        }
    }, [isOpen]);

    const resetForm = () => {
        setNatureOfCall("");
        setDescription("");
        setStatus("");
    };

    const handleAddCallType = () => {
        const newCallType = {
            natureOfCall,
            description,
            status,
        };
        console.log("New Call Type:", newCallType);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" style={styles.closeIcon} />
                </button>
                <h2 className="modal-title">Add New Call Type</h2>
                <hr className="title-divider" />
                <div className="form-grid" style={{ gridTemplateColumns: '1fr' }}>
                    <Input
                        label="Nature of Call"
                        placeholder="Enter nature of call"
                        value={natureOfCall}
                        onChange={(e) => setNatureOfCall(e.target.value)}
                        width={575}
                    />
                    <Input
                        label="Description"
                        placeholder="Enter description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        width={575}
                    />
                    <Dropdown
                        label="Status"
                        options={statusOptions}
                        value={status}
                        onChange={setStatus}
                    />
                </div>
                <hr className="footer-divider" />
                <div className="button-container">
                    <button className="add-client-button" onClick={handleAddCallType}>
                        Add
                    </button>
                    <button className="cancel-button" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    closeIcon: {
        width: "16px",
        height: "16px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "10px",
        marginTop: "20px",
    },
};

export default AddCallTypeModal;
