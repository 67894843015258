import React, { useState } from 'react';
import BillingPaymentListController from '../../Controllers/AdminScreens/BillingPaymentListController';
import AddBillingModal from '../../../Components/AddBillingModal';
import filter from '../../../Assets/Icons/filter.png';
import downArrow from '../../../Assets/Icons/chevron-down.png';
import search from '../../../Assets/Icons/search.png';
import calendar from '../../../Assets/Icons/calendar.png';
import TextInput from '../../../Components/TextInput';
import DateInput from "../../../Components/DateInput";
import { useNavigate } from 'react-router-dom';

const BillingPaymentListScreen = () => {
    const navigate = useNavigate();
    const {
        tasks,
        currentPage,
        totalPages,
        handlePageChange,
    } = BillingPaymentListController();

    const [showFilters, setShowFilters] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({
        clientName: '',
        groupName: '',
        mobile: '',
        billDateFrom: '',
        billDateTo: '',
    });

    const handleFilterChange = (name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const applyFilters = () => {
        console.log('Applying filters:', filters);
    };

    const resetFilters = () => {
        setFilters({
            clientName: '',
            groupName: '',
            mobile: '',
            billDateFrom: '',
            billDateTo: '',
        });
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleAddBillClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleNav = (pageName, task) => {
        console.log('page', pageName, 'task', task);
        navigate(`/${pageName}`, { state: { task } });
    };
    return (
        <>
            <div style={{ width: '94%', margin: 'auto' }}>
                <h3 style={styles.header}>
                    <div style={styles.headerContainer}>
                        <span>Billings & Payments</span>
                        <button
                            className="add-client-button"
                            style={{ marginBottom: 10, marginLeft: 'auto' }}
                            onClick={handleAddBillClick}
                        >
                            Add Bills
                        </button>
                    </div>
                </h3>
            </div>
            <div className="tasksScreen-main-content">

                {/* Add Bill Button */}

                {/* Filters Section */}
                <div className="tasksScreen-tasks-container">
                    <div className="tasksScreen-tasks-header">
                        <h2 className="tasksScreen-tasks-title">Search Bills</h2>
                    </div>
                    <div className="tasksScreen-filters-row">
                        <div className="tasksScreen-filters-button-wrapper">
                            <button
                                className="tasksScreen-filters-button"
                                onClick={toggleFilters}
                            >
                                <img src={filter} alt="Filter" />
                                {showFilters ? 'Filters' : 'Filters'}
                            </button>
                        </div>
                    </div>

                    {showFilters && (
                        <div className={`tasksScreen-filters-container ${showFilters ? 'tasksScreen-open-filters' : ''}`}>
                            <div className="tasksScreen-filters-flex">
                                {/* Client Name Input */}
                                <div className="filter-field">
                                    <label>Client Name</label>
                                    <TextInput
                                        type="text"
                                        placeholder="Search"
                                        value={filters.clientName || ''}
                                        onChange={(e) => handleFilterChange('clientName', e.target.value)}
                                        right={<img src={search} alt="Search" className="search-icon" />}
                                    />
                                </div>

                                {/* Group Name Input */}
                                <div className="filter-field">
                                    <label>Group Name</label>
                                    <TextInput
                                        type="text"
                                        placeholder="Search"
                                        value={filters.groupName || ''}
                                        onChange={(e) => handleFilterChange('groupName', e.target.value)}
                                        right={<img src={search} alt="Search" className="search-icon" />}
                                    />
                                </div>

                                {/* Mobile Input */}
                                <div className="filter-field">
                                    <label>Mobile #</label>
                                    <TextInput
                                        type="text"
                                        placeholder="Search"
                                        value={filters.mobile || ''}
                                        onChange={(e) => handleFilterChange('mobile', e.target.value)}
                                        right={<img src={search} alt="Search" className="search-icon" />}
                                    />
                                </div>

                                {/* Bill Date Range */}
                                <div className="filter-field">
                                    <label>Bill Date</label>
                                    <div className="date-range">
                                        <div style={{ position: 'relative', width: '50%' }}>
                                            {/* <TextInput
                                            type="date"
                                            value={filters.billDateFrom || ''}
                                            onChange={(e) => handleFilterChange('billDateFrom', e.target.value)}
                                            right={
                                                <img
                                                    src={calendar}
                                                    alt="Calendar"
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        width: '16px',
                                                        height: '16px',
                                                        pointerEvents: 'none',
                                                    }}
                                                />
                                            }
                                        /> */}
                                            <DateInput
                                                value={filters.billDateFrom || ""}
                                                onChange={(e) => handleFilterChange("billDateFrom", e.target.value)}
                                                style={{
                                                    width: '100px',
                                                    minWidth: '115px',}}
                                            />
                                        </div>
                                        <span style={{ margin: '0 10px' }}>-</span>
                                        <div style={{ position: 'relative', width: '50%' }}>
                                            {/* <TextInput
                                            type="date"
                                            value={filters.billDateTo || ''}
                                            onChange={(e) => handleFilterChange('billDateTo', e.target.value)}
                                            right={
                                                <img
                                                    src={calendar}
                                                    alt="Calendar"
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        width: '16px',
                                                        height: '16px',
                                                        pointerEvents: 'none',
                                                    }}
                                                />
                                            }
                                        /> */}
                                            <DateInput
                                                value={filters.billDateTo || ""}
                                                onChange={(e) => handleFilterChange("billDateTo", e.target.value)}
                                                style={{
                                                    width: '100px',
                                                    minWidth: '115px',}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* Filter Buttons */}
                            <div className="tasksScreen-filter-buttons">
                                <button
                                    onClick={applyFilters}
                                    className="tasksScreen-apply-button"
                                >
                                    Apply
                                </button>
                                <button
                                    onClick={toggleFilters}
                                    className="tasksScreen-cancel-button"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={resetFilters}
                                    className="tasksScreen-reset-button"
                                >
                                    Reset
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                <div className="tasksScreen-tasks-container" style={{ marginTop: 20 }}>
                    <div className="tasksScreen-task-table-wrapper">
                        <table className="tasksScreen-task-table">
                            <thead>
                                <tr>
                                    <th>Client Name</th>
                                    <th>Group Name</th>
                                    <th>Account Manager</th>
                                    <th>No. Of Bills</th>
                                    <th>Last Bill Date</th>
                                    <th>Outstanding</th>
                                </tr>
                            </thead>
                            <tbody style={{ backgroundColor: '#fff' }}>
                                {tasks.map((task, index) => (
                                    <tr key={index}>
                                        <td style={{ color: '#5A55D2', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { handleNav('manage-clients', 'BillingFees') }}>
                                            {task.clientName}
                                        </td>
                                        <td>{task.groupName}</td>
                                        <td>{task.accountManager}</td>
                                        <td style={{ color: '#5A55D2', textDecoration: 'underline' }}>
                                            {task.noOfBills}
                                        </td>
                                        <td>{task.lastBillDate}</td>
                                        <td>{task.outstanding.toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination */}
                    <div className="tasksScreen-table-footer">
                        <span className="tasksScreen-pagination-info">
                            {`${currentPage}-${totalPages} of items`}
                        </span>
                        <div className="tasksScreen-pagination">
                            <button
                                className="tasksScreen-pagination-button"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <img
                                    src={downArrow}
                                    alt="Previous"
                                    className="tasksScreen-arrow-left tasksScreen-icon-image"
                                />
                            </button>
                            {[...Array(totalPages)].map((_, index) => (
                                <button
                                    key={index}
                                    className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''
                                        }`}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                            <button
                                className="tasksScreen-pagination-button"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                <img
                                    src={downArrow}
                                    alt="Next"
                                    className="tasksScreen-arrow-right tasksScreen-icon-image"
                                />
                            </button>
                        </div>
                    </div>
                    <AddBillingModal isOpen={isModalOpen} onClose={handleCloseModal} />
                </div>
            </div>
        </>
    );
};

const styles = {
    header: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        fontFamily: 'Montserrat',
        //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader: {
        marginTop: '-5px'
    }
}

export default BillingPaymentListScreen;
