import React, { useState } from 'react';
import DateInput from '../../../../../Components/DateInput';
import Dropdown from '../../../../../Components/Dropdown';
import editImg from '../../../../../Assets/Icons/edit.png';
import deleteImg from '../../../../../Assets/Icons/delete.png';
import downArrow from '../../../../../Assets/Icons/chevron-down.png';
import filter from '../../../../../Assets/Icons/filter.png';
import AddNoticeBoardMsgModal from '../../../../../Components/Modals/AddNoticeBoardMsgModal';
import { useNavigate } from 'react-router-dom';

const NoticeBoardExpiredMsgList = ({ currentTasks, currentPage, totalPages, handlePageChange }) => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const [showFilters, setShowFilters] = useState(false);

    const createAddNewMessage = () => {
        setModalVisible(true)
    }

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const [filters, setFilters] = useState({
        createdBy: '',
        recipient: '',
        datePublished: '',
        expiryDate: '',
    });

    const handleFilterChange = (name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const applyFilters = () => {
        console.log('Applying filters:', filters);
    };

    const resetFilters = () => {
        setFilters({
            createdBy: '',
            recipient: '',
            datePublished: '',
            expiryDate: '',
        });
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    return (
        <>
            <div className="tasksScreen-tasks-header">
                <h2 style={{ color: '#1F384C', fontSize: '18px', fontWeight: '500', lineHeight: '19.08px', wordWrap: 'break-word' }}>Notice Board Messages</h2>
                <button className="tasksScreen-apply-button" style={{ marginBottom: '10px' }} onClick={createAddNewMessage}>Add New Notice Board Message</button>
            </div>

            <div className="tasksScreen-filters-row" style={{ position: 'relative' }}>
                <div className="tasksScreen-filters-button-wrapper">
                    <button
                        className="tasksScreen-filters-button"
                        onClick={toggleFilters}
                    >
                        <img src={filter} alt="Filter" />
                        {showFilters ? 'Filters' : 'Filters'}
                    </button>
                </div>
                {showFilters && (
                    <div className="tasksScreen-filters-container-overlay">
                        <div className="tasksScreen-filters-flex" style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
                            {/* Created By Dropdown */}
                            <div className="filter-field">
                                <Dropdown
                                    label="Created By"
                                    options={[
                                        { label: "--", value: "" },
                                        { label: "User1", value: "User1" },
                                        { label: "User2", value: "User2" },
                                        // Add more options as needed
                                    ]}
                                    value={filters.createdBy || ""}
                                    onChange={(value) => handleFilterChange("createdBy", value)}
                                />
                            </div>

                            {/* Recipient Dropdown */}
                            <div className="filter-field">
                                <Dropdown
                                    label="Recipient"
                                    options={[
                                        { label: "--", value: "" },
                                        { label: "Recipient1", value: "Recipient1" },
                                        { label: "Recipient2", value: "Recipient2" },
                                        // Add more options as needed
                                    ]}
                                    value={filters.recipient || ""}
                                    onChange={(value) => handleFilterChange("recipient", value)}
                                />
                            </div>

                            {/* Date Published Date Picker */}
                            <div className="filter-field">
                                <label style={{ fontSize: '12px', color: '#343C44' }}>Date Published</label>
                                <DateInput
                                    value={filters.datePublished || ''}
                                    onChange={(e) => handleFilterChange('datePublished', e.target.value)}
                                />
                            </div>

                            {/* Expiry Date Date Picker */}
                            <div className="filter-field">
                                <label style={{ fontSize: '12px', color: '#343C44' }}>Expiry Date</label>
                                <DateInput
                                    value={filters.expiryDate || ''}
                                    onChange={(e) => handleFilterChange('expiryDate', e.target.value)}
                                />
                            </div>
                        </div>

                        {/* Filter Buttons */}
                        <div className="tasksScreen-filter-buttons">
                            <button
                                onClick={applyFilters}
                                className="tasksScreen-apply-button"
                            >
                                Apply
                            </button>
                            <button
                                onClick={toggleFilters}
                                className="tasksScreen-cancel-button"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={resetFilters}
                                className="tasksScreen-reset-button"
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>

                        {/* Table Headers */}
                        <tr>
                            <th style={{ width: "10%" }}>Date Created</th>
                            <th style={{ width: "10%" }}>Published</th>
                            <th style={{ width: "35%" }}>Message</th>
                            <th style={{ width: "15%" }}>Recipient</th>
                            <th style={{ width: "10%" }}>Created By</th>
                            <th style={{ width: "10%" }}>Expiry</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#fff' }}>
                        {currentTasks.map((task, index) => (
                            <React.Fragment key={index}>
                                <tr className={`tasksScreen-main-task-row ${task.showDetails ? 'tasksScreen-show-border' : ''}`}>
                                    <td>{task.dateCreated}</td>
                                    <td>{task.published}</td>
                                    <td>{task.message}</td>
                                    <td>{task.recipient}</td>
                                    <td>{task.createdBy}</td>
                                    <td>{task.expiryDate}</td>
                                    <td>
                                        <button className="tasksScreen-edit-button">
                                            <img src={editImg} alt="Edit" />
                                        </button>
                                        <button className="tasksScreen-delete-button">
                                            <img src={deleteImg} alt="Delete" />
                                        </button>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="tasksScreen-table-footer">
                <span className="tasksScreen-pagination-info">
                    {`${currentPage}-${totalPages} of items`}
                </span>
                <div className="tasksScreen-pagination">
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
                    </button>
                    {[...Array(totalPages)].map((_, index) => (
                        <button key={index} className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    ))}
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                        <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
                    </button>
                </div>
                <AddNoticeBoardMsgModal isOpen={modalVisible} onClose={handleCloseModal} />
            </div>
        </>
    );
};

const styles = {
    header: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        fontFamily: 'Montserrat',
        //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader: {
        marginTop: '-5px'
    }
};

export default NoticeBoardExpiredMsgList;
