// ThemeContext.js
import React, { createContext, useContext, useEffect, useState, useRef } from 'react';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [theme, setTheme] = useState(null);
    const companyIduseRef = useRef(''); // Replace with actual dynamic company ID

    useEffect(() => {
        // Load theme when component mounts
        loadTheme();
    }, [companyIduseRef.current]);

    const applyTheme = (theme) => {
        if (!theme) return;
        document.documentElement.style.setProperty('--primary-color', theme.primaryColor || '#40513B');
        document.documentElement.style.setProperty('--secondary-color', theme.secondaryColor || '#609966');
        document.documentElement.style.setProperty('--background-color', theme.backgroundColor || '#F2F2F2');
        document.documentElement.style.setProperty('--font-color', theme.fontColor || '#060606');
        document.documentElement.style.setProperty('--primary-color-hover', theme.hover || '#3e7b61');
    };

    const loadTheme = async () => {
        const themeId =  localStorage.getItem(`themeId`);
        // const storedTheme = await localStorage.getItem(`appTheme_${compId}`);
        console.log('companyIduseRef:-----',companyIduseRef.current);
        var selectedTheme = companyIduseRef.current || themeId || 0;
        if (selectedTheme === 1) {
            console.log('selectedTheme',selectedTheme)
            const theme = {
                "primaryColor": "#4CAF50",
                "secondaryColor": "#FF5733",
                "backgroundColor": "#FFFFFF",
                "fontColor": "#000",
                "hover": "#71ed76"
            }
            setTheme(theme);
            applyTheme(theme);
            console.log('local theme')

        } else if (selectedTheme === 2){
             console.log('selectedTheme',selectedTheme)
            const theme = {
                "primaryColor": "#0077B6",
                "secondaryColor": "#00B4D8",
                "backgroundColor": "#CAF0F8",
                "fontColor": "#03045E",
                "hover": "#0077b67d"
            }
            
            setTheme(theme);
            applyTheme(theme);
            // localStorage.setItem(`appTheme_${companyId}`, JSON.stringify(theme));
        } else if (selectedTheme === 3){
            console.log('selectedTheme',selectedTheme)
            const theme = {
                "primaryColor": "#0077B6",
                "secondaryColor": "#00B4D8",
                "backgroundColor": "#F2F2F2",
                "fontColor": "#03045E",
                "hover": "#3e7b61"
            }
            
            setTheme(theme);
            applyTheme(theme);
            // localStorage.setItem(`appTheme_${companyId}`, JSON.stringify(theme));
        }else {
            console.log('no local theme')
            const theme = {
                "primaryColor": "#40513B",
                "secondaryColor": "#609966",
                "backgroundColor": "#F2F2F2",
                "fontColor": "#060606",
                "hover": "#3e7b61"
            }
            
            setTheme(theme);
            applyTheme(theme);
            // localStorage.setItem(`appTheme_${companyId}`, JSON.stringify(theme));
        }
    };

        // Function to update the company ID
        const setCompanyId = (newCompanyId) => {
            companyIduseRef.current = newCompanyId;
            loadTheme()
        };

 

    return (
        <GlobalContext.Provider value={{theme, setCompanyId}}>
            {children}
        </GlobalContext.Provider>
    );
};

export const useTheme = () => useContext(GlobalContext);
