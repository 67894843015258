import React, { useState } from 'react';
const GenerateEditReceiptController = () => {
    const [sendUpdateToClient, setSendUpdateToClient] = useState(false);
    const [sendUpdateToTeam, setSendUpdateToTeam] = useState(false);
    const [invoiceWithoutTask, setInvoiceWithoutTask] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({
        modeOfPayment: "Cash",
        collectedBy: "Username",
        followUpDate: "11/11/2024",
        receiptBook: "Username",
    });

    const handlePaymentDetailsChange = (field, value) => {
        setPaymentDetails((prevDetails) => ({
            ...prevDetails,
            [field]: value,
        }));
    };

    const [invoiceDetails, setInvoiceDetails] = useState({
        invoiceAmount: "2,000.00", // Static data moved to state
        amountReceived: "",
        advancePayment: "",
        balance: "2,000.00",
    });

    const handleInvoiceDetailsChange = (field, value) => {
        setInvoiceDetails((prevDetails) => ({
            ...prevDetails,
            [field]: value,
        }));
    };

    const [invoiceInformation, setInvoiceInformation] = useState({
        clientName: "John Doe",
        clientType: "Company",
        dateOfInvoice: "31/10/2024",
        invoiceNumber: "DLS24-0001",
        combinedBill: "No",
        groupInvoice: "No",
        taskStatus: "Completed",
        invoiceGeneratedBy: "Satish R",
    });




    return {
        paymentDetails,
        setPaymentDetails,
        handlePaymentDetailsChange,
        invoiceDetails,
        setInvoiceDetails,
        handleInvoiceDetailsChange,
        sendUpdateToClient,
        setSendUpdateToClient,
        sendUpdateToTeam,
        setSendUpdateToTeam,
        invoiceInformation,
        setInvoiceInformation
    }

}

export default GenerateEditReceiptController