import React, { useState } from 'react';
import backIcon from '../../../../Assets/Icons/back.png';
import Toggle from '../../../../Components/Toggle';
import Dropdown from '../../../../Components/Dropdown';
import Input from '../../../../Components/Input';
import ManageGroupDetailsController from '../../../Controllers/AdminManagementScreens/TeamsAndUsers/ManageGroupDetailsController';
import LoadingSkeleton from '../../../../Components/LoadingSkeleton';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import AddGroupMemberModal from '../../../../Components/Modals/AddGroupMemberModal';
import TextInput from '../../../../Components/TextInput';
import Loader from '../../../../Components/Loader';

const ManageGroupDetails = () => {

    const { handleBack,
        userToggleState,
        handleUserToggleChange,
        isGroupInfoEdit,
        loading,
        error,
        primaryData,
        fieldConfigs,
        handleEditGroupMember,
        handleDeleteGroupMember,
        handleUpdateGroup,
        handleDeleteGroup,
        handleCancelGroup,
        handleAddGroupMembers,
        groupMemberModalOpen,
        handleCloseGroupMemberModal,
        onValueChangeGroupMember,
        handleInputChange,
        groupMembers,
        groupMembersError,
        groupStatusLoading,
        groupStatusError
    } = ManageGroupDetailsController();

    return (
        <div style={styles.container}>
            {/* Header Section */}
            <div style={styles.headerContainer}>
                <img
                    src={backIcon}
                    alt="Back"
                    style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
                    onClick={handleBack}
                />
                <span style={styles.header}>Manage Group</span>
            </div>

            {/* Group Section */}
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems:'center' }}>
                        <div className='teamdetails-title'>{primaryData?.group_name}</div>
                        <Toggle initialStatus={primaryData?.group_status === 1? true:false} onToggle={(value)=>handleUserToggleChange(value,primaryData?.group_id)} size='big' />
                        {groupStatusLoading && <Loader loading={groupStatusLoading} color={'#000'} size='small' /> }
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Contact User : {primaryData?.client_id_primary_email}</span>
                        <span style={{ alignSelf: 'end' }}>{primaryData?.client_id_primary_mobile_no}</span>
                    </div>
                </div>
                <div className="company-details">{primaryData?.group_desc}</div>
            </div>
            {groupStatusError && <div className="list-error-msg"> {groupStatusError} </div>}
            {/* Group Information */}
            <div className='teamdetails-outer-card' style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end' }}>
                    <h3 className='basic-info-header'>Group Information</h3>
                    <button onClick={handleAddGroupMembers} className="add-client-button">Add Group Members</button>
                </div>

                <div className='basic-info-card' style={{ marginTop: 20 }}>
                    <div className='basic-info-row'>
                        {fieldConfigs.map((field) => (
                            <div className="basic-info-item" key={field.key}>
                                {isGroupInfoEdit.current ? (
                                    field.type === "dropdown" ? (
                                        <Dropdown
                                            label={field.label} // Dropdown already renders the label internally
                                            options={field.options}
                                            value={primaryData[field.key]}
                                            onChange={(selectedValue) => handleInputChange(field.key, selectedValue)}

                                        />
                                        // field.type === "search" ? (
                                        //     <TextInput
                                        //         type="text"
                                        //         placeholder="Search Client"
                                        //         value={searchUser}
                                        //         onChange={(e) => setSearchUser(e.target.value)}
                                        //         onRightClick={handleSearchClient}
                                        //         right={
                                        //             loading ? (
                                        //                 <Loader loading={loading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                                        //             ) : (
                                        //                 <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                                        //             )
                                        //         }
                                        //         style={{ padding: '20px 10px' }}
                                        //     />
                                    ) : (
                                        <Input
                                            label={field.label} // Input already renders the label internally
                                            placeholder={`Enter ${field.label}`}
                                            value={primaryData[field.key]}
                                            onChange={(e) => handleInputChange(field.key, e.target.value)}
                                        />
                                    )
                                ) : (
                                    <><span className="basic-info-label">{field.label}</span>
                                        <span className="basic-info-value">{primaryData[field.key]} {loading && <LoadingSkeleton width="40%" height="10px" />}</span></>
                                )}
                            </div>
                        ))}
                    </div>
                    {error && <div className="list-error-msg">{error} </div>}
                </div>

                <div className="tasksScreen-task-table-wrapper" style={{ marginTop: 20 }}>
                    <h3 className='basic-info-header'>Group Members & Contacts</h3>
                    <div className="basic-info-outer-card">
                        <table className='tasksScreen-task-table'>
                            <thead>
                                <tr>
                                    <th style={{ width: '20%' }}>Username</th>
                                    <th style={{ width: '25%' }}>Email ID</th>
                                    <th style={{ width: '40%' }}>Phone No.</th>
                                    <th style={{ width: '15%' }}></th>
                                </tr>
                            </thead>
                            <tbody style={{ backgroundColor: 'white' }}>
                                {groupMembers.map((groupMember, index) => (
                                    <tr key={index}
                                        className="tasksScreen-main-task-row"
                                        style={{ borderBottom: "1px solid #dee2e6", cursor: 'pointer' }} >
                                        <td style={{ ...styles.tableLink, width: '20%' }}>{groupMember?.client_name}</td>
                                        <td style={{ width: '25%' }}>{groupMember?.client_email}</td>
                                        <td style={{ width: '40%' }}>{groupMember?.client_mobile_no}</td>
                                        <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', gap: 20 }}>
                                            <button
                                                className="tasksScreen-edit-button"
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => handleEditGroupMember(groupMember)}
                                            >
                                                <img
                                                    src={editIcon}
                                                    alt="Edit"
                                                    style={{ width: "16px", height: "16px" }}
                                                />
                                            </button>
                                            <button
                                                className="tasksScreen-delete-button"
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => handleDeleteGroupMember(groupMember)}
                                            >
                                                <img
                                                    src={deleteIcon}
                                                    alt="Delete"
                                                    style={{ width: "16px", height: "16px" }}
                                                />
                                            </button>

                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div style={{ marginTop: 20 }}>
                    <h3 className='basic-info-header'>Notes</h3>
                    <div className="basic-info-outer-card">
                        <label
                            style={{
                                ...styles.labaleStyle,
                                // marginBottom: '8px',
                                display: 'block',
                            }}
                        >
                            Group Notes
                        </label>
                        <textarea
                            id="groupNotes"
                            placeholder="Enter your notes here..."
                            style={styles.textarea}
                            defaultValue="Barchasi"
                        ></textarea>

                    </div>

                </div>

                <div className="button-container" style={{ gap: 20, flexWrap: 'wrap' }}>
                    {isGroupInfoEdit.current && <button onClick={handleUpdateGroup} className="add-client-button">Update Group</button>}
                    {isGroupInfoEdit.current && <button onClick={handleCancelGroup} className="tasksScreen-cancel-button">Cancel</button>}
                    <button onClick={handleDeleteGroup} className="tasksScreen-cancel-button" style={{ marginLeft: 'auto' }}>Delete Group</button>
                </div>



            </div>

            <AddGroupMemberModal isOpen={groupMemberModalOpen} onClose={handleCloseGroupMemberModal} onValueChange={onValueChangeGroupMember} groupID={primaryData?.group_id}/>





        </div>
    )

}

const styles = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    header: {
        fontSize: "22px",
        color: "#060606",
        fontWeight: 500,
        fontFamily: 'Montserrat',
        letterSpacing: 0.22
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
        marginBottom: '20px'
    },
    labaleStyle: {
        fontSize: '12px',
        // marginBottom: '5px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        color: 'rgb(52, 60, 68)',
    },
    textarea: {
        width: "100%",
        height: "100px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "10px",
        fontSize: "14px",
        resize: "none",
        // backgroundColor: "#f9f9f9",
        outline: "none",
      },
      label: {
        display: "block",
        marginBottom: "5px",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#333",
      },
}

export default ManageGroupDetails;