import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../../Components/Tabs';
import TaskCategoryScreen from './TaskCategory';
import TaskTypeScreen from './TaskType';
import TaskCheckScreen from './TaskChecks';
import GroupCheckScreen from './GroupChecks';
import TaskManagementController from '../../../Controllers/AdminManagementScreens/TaskManagement/TaskManagementController';

const TaskManagementScreen = () => {

    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(state?.selectedTab || 0);

    const{
        categories,
        loading,
        error,
        categoryRefresh,
        subCategories,
        subCatError,
        subCatLoading,
        subCategoryRefresh,
        typeCheckList,
        typeCheckLoading,
        typeCheckError,
        typeCheckRefresh
    }= TaskManagementController();

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    return (
        <div style={styles.mainContent}>
            <div className='title-container' style={{paddingBottom:10}}>
                <h2 className='title'>Manage Task Settings</h2>
            </div>

            <div>
                <Tabs tabs={['Service','Service Category','Task Checks']}
                selectedIndex={selectedTab}
                onTabChange={handleTabChange}>
                    <TaskCategoryScreen categories={categories} loading={loading} error={error} onRefresh={categoryRefresh}/>
                    <TaskTypeScreen categories={categories} subCategories={subCategories} loading={subCatLoading} error={subCatError} onRefresh={subCategoryRefresh}/>
                    <TaskCheckScreen categories={categories} typeCheckList={typeCheckList} loading={typeCheckLoading} error={typeCheckError} onRefresh={typeCheckRefresh}/>
                    {/* <GroupCheckScreen /> */}
                </Tabs>
            </div>

        </div>


    )

};

const styles = {
    mainContent: {
        width: '95%',
        margin: 'auto'
    }

}

export default TaskManagementScreen;