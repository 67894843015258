
import React,{useState} from "react";

const WhatsAppConfigurationController =()=>{
    const [bussinessAccountName, setBusinessAccountName] = useState('');
    const [bussinessEmail, setBusinessEmail] = useState('');
    const [bussinessWhatsAppNumber, setBussinessWhatsAppNumber] = useState('');
    const [webHookUrl, setWebHookUrl] = useState('');
    const [otherParameters, setOtherParameters] = useState('');
    const [passKey, setPassKey] = useState('');

    return{
        bussinessAccountName,
        setBusinessAccountName,
        bussinessEmail,
        setBusinessEmail,
        bussinessWhatsAppNumber,
        setBussinessWhatsAppNumber,
        webHookUrl,
        setWebHookUrl,
        otherParameters,
        setOtherParameters,
        passKey,
        setPassKey
    }
}
export default WhatsAppConfigurationController