import { useEffect,useRef } from "react";
import { useNavigate,useLocation } from "react-router-dom";

function Protected(props){

    const {Component} = props;
    const navigate = useNavigate();
    const location = useLocation();
    // const [storedData, setstoredData] = useState('');
    const storedData = useRef(null);

    useEffect( ()=>{
              // Retrieve data from localStorage when the component mounts
            //   setstoredData(localStorage.getItem('isAdminLogin'));
            storedData.current = localStorage.getItem('userRole');
            console.log("storedData",storedData.current)
              if (storedData.current) {
                if(location.pathname === '/login' || location.pathname === '/'){
                  navigate('/dashboard');
                }
              
              }else{
                navigate('/login');
              }
    },[storedData,navigate]);

    return(
        <div>
            <Component />
        </div>
    )

}

export default Protected