
import React, { useState } from 'react';
const ServiceEstimateController = ()=>{
    const serviceEstimates = [
        {
          estimateNo: "DLS24-0001",
          clientName: "DLS INDIA",
          taskType: "GST Tax Filing",
          generatedDate: "23/10/2024",
          groupName: "Company Name/Group Name",
          generatedBy: "John Doe",
          amount: "3,000.00"
        },
        {
          estimateNo: "DLS24-0001",
          clientName: "DLS INDIA",
          taskType: "GST Tax Filing",
          generatedDate: "23/10/2024",
          groupName: "Company Name/Group Name",
          generatedBy: "John Doe",
          amount: "3,000.00"
        },
        {
          estimateNo: "DLS24-0001",
          clientName: "DLS INDIA",
          taskType: "GST Tax Filing",
          generatedDate: "23/10/2024",
          groupName: "Company Name/Group Name",
          generatedBy: "John Doe",
          amount: "3,000.00"
        },
        {
          estimateNo: "DLS24-0001",
          clientName: "DLS INDIA",
          taskType: "GST Tax Filing",
          generatedDate: "23/10/2024",
          groupName: "Company Name/Group Name",
          generatedBy: "John Doe",
          amount: "3,000.00"
        },
        {
          estimateNo: "DLS24-0001",
          clientName: "DLS INDIA",
          taskType: "GST Tax Filing",
          generatedDate: "23/10/2024",
          groupName: "Company Name/Group Name",
          generatedBy: "John Doe",
          amount: "3,000.00"
        },
        {
          estimateNo: "DLS24-0001",
          clientName: "DLS INDIA",
          taskType: "GST Tax Filing",
          generatedDate: "23/10/2024",
          groupName: "Company Name/Group Name",
          generatedBy: "John Doe",
          amount: "3,000.00"
        },
        {
          estimateNo: "DLS24-0001",
          clientName: "DLS INDIA",
          taskType: "GST Tax Filing",
          generatedDate: "23/10/2024",
          groupName: "Company Name/Group Name",
          generatedBy: "John Doe",
          amount: "10,000.00"
        },
        {
          estimateNo: "DLS24-0001",
          clientName: "DLS INDIA",
          taskType: "GST Tax Filing",
          generatedDate: "23/10/2024",
          groupName: "Company Name/Group Name",
          generatedBy: "John Doe",
          amount: "500.00"
        },
        {
            estimateNo: "DLS24-0001",
            clientName: "DLS INDIA",
            taskType: "GST Tax Filing",
            generatedDate: "23/10/2024",
            groupName: "Company Name/Group Name",
            generatedBy: "John Doe",
            amount: "7,000.00"
          },
          {
            estimateNo: "DLS24-0001",
            clientName: "DLS INDIA",
            taskType: "GST Tax Filing",
            generatedDate: "23/10/2024",
            groupName: "Company Name/Group Name",
            generatedBy: "John Doe",
            amount: "9,000.00"
          },
          {
            estimateNo: "DLS24-0001",
            clientName: "DLS INDIA",
            taskType: "GST Tax Filing",
            generatedDate: "23/10/2024",
            groupName: "Company Name/Group Name",
            generatedBy: "John Doe",
            amount: "22,000.00"
          }
      ];
      
      const ItemsPerPage = 10;
        const [currentPage, setCurrentPage] = useState(1);
        const totalPages = Math.ceil(serviceEstimates.length / ItemsPerPage);
    
        // Function to handle page change
        const handlePageChange = (pageNumber) => {
            setCurrentPage(pageNumber);
        };
    
        // Calculate the index of the first and last items on the current page
        const indexOfLastItem = currentPage * ItemsPerPage;
        const indexOfFirstItem = indexOfLastItem - ItemsPerPage;
    
        // Slice the data for the current page
        const currentTasks = serviceEstimates.slice(indexOfFirstItem, indexOfLastItem); 

        const [filterData, setFilterData] = useState({
          clientName: "",
          services:"",
          generatedDate: "",
          generatedBy:""
        });
      
        const handleInputChange = (field, value) => {
          setFilterData((prevData) => ({
            ...prevData,
            [field]: value,
          }));
        };
    return{
        serviceEstimates,
        handlePageChange,
        currentTasks,
        totalPages,
        currentPage,
        setFilterData,
        filterData,
        handleInputChange

    }
}

export default ServiceEstimateController