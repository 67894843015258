import React, { useState } from 'react';
import DateInput from '../../../../../Components/DateInput';
import Dropdown from '../../../../../Components/Dropdown';
import TextInput from '../../../../../Components/TextInput';
import editImg from '../../../../../Assets/Icons/edit.png';
import deleteImg from '../../../../../Assets/Icons/delete.png';
import downArrow from '../../../../../Assets/Icons/chevron-down.png';
import filter from '../../../../../Assets/Icons/filter.png';
import search from '../../../../../Assets/Icons/search.png';
import AddDigitalSignatureModal from '../../../../../Components/Modals/AddDigitalSignatureModal';
import { useNavigate } from 'react-router-dom';

const DSCActiveCertificate = ({ currentTasks, currentPage, totalPages, handlePageChange }) => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const [showFilters, setShowFilters] = useState(false);

    const addNewDigitalSignature = () => {
        setModalVisible(true)
    }

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const [filters, setFilters] = useState({
        clientName: '',
        status: '',
        validFrom: '',
        addedOn: '',
    });

    const handleFilterChange = (name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const applyFilters = () => {
        console.log('Applying filters:', filters);
    };

    const resetFilters = () => {
        setFilters({
            clientName: '',
            status: '',
            validFrom: '',
            addedOn: '',
        });
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    return (
        <>
            <div className="tasksScreen-tasks-header">
                <h2 style={{ color: '#1F384C', fontSize: '18px', fontWeight: '500', lineHeight: '19.08px', wordWrap: 'break-word' }}>Digital Certificates</h2>
                <button className="tasksScreen-apply-button" style={{ marginBottom: '10px' }} onClick={addNewDigitalSignature}>Add New Digital Signature</button>
            </div>

            <div className="tasksScreen-filters-row" style={{ position: 'relative' }}>
                <div className="tasksScreen-filters-button-wrapper">
                    <button
                        className="tasksScreen-filters-button"
                        onClick={toggleFilters}
                    >
                        <img src={filter} alt="Filter" />
                        Filters
                    </button>
                </div>
                {showFilters && (
                    <div className="tasksScreen-filters-container-overlay">
                        <div
                            className="tasksScreen-filters-flex"
                            style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}
                        >
                            {/* Client Name Input */}
                            <div>
                                <div className="filter-field" style={{ flex: '1 1 20%' }}>
                                    <label style={{ fontSize: '12px', color: '#343C44' }}>Client Name</label>
                                    <TextInput
                                        type="text"
                                        placeholder="Client Name"
                                        value={filters.clientName || ''}
                                        onChange={(e) => handleFilterChange('clientName', e.target.value)}
                                        right={<img src={search} alt="Search" className="search-icon" />}
                                    />
                                </div>
                            </div>

                            {/* Status Dropdown */}
                            <div className="filter-field" style={{ flex: '1 1 20%' }}>
                                <Dropdown
                                    label="Status"
                                    options={[
                                        { label: '--', value: '' },
                                        { label: 'Active', value: 'active' },
                                        { label: 'Inactive', value: 'inactive' },
                                    ]}
                                    value={filters.status || ''}
                                    onChange={(value) => handleFilterChange('status', value)}
                                />
                            </div>

                            {/* Valid From Date Picker */}
                            <div className="filter-field" style={{ flex: '1 1 20%' }}>
                                <label style={{ fontSize: '12px', color: '#343C44' }}>Valid From</label>
                                <DateInput
                                    value={filters.validFrom || ''}
                                    onChange={(e) => handleFilterChange('validFrom', e.target.value)}
                                />
                            </div>

                            {/* Added On Date Picker */}
                            <div className="filter-field" style={{ flex: '1 1 20%' }}>
                                <label style={{ fontSize: '12px', color: '#343C44' }}>Added On</label>
                                <DateInput
                                    value={filters.addedOn || ''}
                                    onChange={(e) => handleFilterChange('addedOn', e.target.value)}
                                />
                            </div>
                        </div>

                        {/* Filter Buttons */}
                        <div className="tasksScreen-filter-buttons">
                            <button
                                onClick={applyFilters}
                                className="tasksScreen-apply-button"
                            >
                                Apply
                            </button>
                            <button
                                onClick={toggleFilters}
                                className="tasksScreen-cancel-button"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={resetFilters}
                                className="tasksScreen-reset-button"
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                )}
            </div>


            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>

                        {/* Table Headers */}
                        <tr>
                            <th>Client Name</th>
                            <th>PAN No.</th>
                            <th>Physical Token No.</th>
                            <th>Organisation Name</th>
                            <th>Status</th>
                            <th>Valid From</th>
                            <th>Valid To</th>
                            <th>Added By</th>
                            <th>Added On</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#fff' }}>
                        {currentTasks.map((task, index) => (
                            <React.Fragment key={index}>
                                <tr className={`tasksScreen-main-task-row ${task.showDetails ? 'tasksScreen-show-border' : ''}`}>
                                    <td>{task.clientName}</td>
                                    <td>{task.panNo}</td>
                                    <td style={{ cursor: 'pointer', textDecoration: 'underline', color: '#5a55d2' }}>{task.physicalToken}</td>
                                    <td>{task.organizatioName}</td>
                                    <td>{task.status}</td>
                                    <td>{task.validFrom}</td>
                                    <td>{task.validTo}</td>
                                    <td>{task.addedBy}</td>
                                    <td>{task.addedOn}</td>
                                    <td>
                                        <button className="tasksScreen-edit-button">
                                            <img src={editImg} alt="Edit" />
                                        </button>
                                        <button className="tasksScreen-delete-button">
                                            <img src={deleteImg} alt="Delete" />
                                        </button>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="tasksScreen-table-footer">
                <span className="tasksScreen-pagination-info">
                    {`${currentPage}-${totalPages} of items`}
                </span>
                <div className="tasksScreen-pagination">
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
                    </button>
                    {[...Array(totalPages)].map((_, index) => (
                        <button key={index} className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    ))}
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                        <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
                    </button>
                </div>
                <AddDigitalSignatureModal isOpen={modalVisible} onClose={handleCloseModal} />
            </div>
        </>
    );
};

const styles = {
    header: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        fontFamily: 'Montserrat',
        //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader: {
        marginTop: '-5px'
    }
};

export default DSCActiveCertificate;
