
import React, { useState } from 'react';

const CreateEditEstimateController = () => {

    const [sendUpdateToClient, setSendUpdateToClient] = useState(false);
    const [sendUpdateToTeam, setSendUpdateToTeam] = useState(false);
    const [estimateWithoutTask, setEstimateWithoutTask] = useState(false);

    const [basicInformation, setBasicInformation] = useState({
        clientName: "",
        groupName: "",
        phoneNo: "",
        dateOfEstimate: "",
        countryCode: "",
    });

    const handleBasicInfoChange = (field, value) => {
        setBasicInformation((prevInfo) => ({
            ...prevInfo,
            [field]: value,
        }));
    };

    const [estimateOptions, setEstimateOptions] = useState({
        groupEstimate: "",
        noOfClients: "",
        systemBillingAmount: "",
        estimateAmount: "",
        generatedBy: "",
    });

    const handleEstimateOptionsChange = (field, value) => {
        setEstimateOptions((prevOptions) => ({
            ...prevOptions,
            [field]: value,
        }));
    };





    return {
        setBasicInformation,
        basicInformation,
        handleBasicInfoChange,
        setEstimateOptions,
        estimateOptions,
        handleEstimateOptionsChange,
        sendUpdateToClient,
        setSendUpdateToClient,
        sendUpdateToTeam,
        setSendUpdateToTeam,
        estimateWithoutTask,
        setEstimateWithoutTask
    }

}

export default CreateEditEstimateController