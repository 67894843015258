
import React, { useState } from "react";

const InvoiceReceiptNotesCOntroller = () => {
    const [narration, setNarration] = useState("DLS24-");
    const [notes, setNotes] = useState("DLS24-");

    return {
        narration,
        setNarration,
        notes,
        setNotes
    }

}

export default InvoiceReceiptNotesCOntroller