import { useNavigate, useLocation } from "react-router-dom";

const ResourceDetailsController = () => {
    const navigate = useNavigate();
    const location = useLocation();

    console.log("ddcbjhnkmldjnhsdkkl", location.state); // Debugging: Ensure location.state contains the resource

    const resource = location.state?.resource || {
        title: "Default Title",
        shortDescription: "Default short description",
        fullDescription: "Default full description.",
    };

    const navigateBack = () => {
        navigate(-1); // Navigate back
    };

    const navigateNext = () => {
        // navigate("/next-article", { state: { resource } });
    };

    return { resource, navigateBack, navigateNext };
};



export default ResourceDetailsController;
