import React, { useState } from "react";
import closeIcon from "../Assets/Icons/close.png";
import Input from "./Input";
import CheckBox from "./CheckBox";

const AddResourceModal = ({ isOpen, onClose, onValueChange }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [sendToTeam, setSendToTeam] = useState(false);

  const handleAddResource = () => {
    const resourceData = {
      title,
      description,
      sendToTeam,
    };
    onValueChange(resourceData);
    onClose();
  };

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setSendToTeam(false);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
        </button>
        <h2 className="modal-title">Add Resource</h2>
        <hr className="title-divider" />

        <div style={styles.formGroup}>
          {/* Title Input */}
          <div>
            <label style={styles.label}>Title</label>
            <Input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter Title"
              style={styles.input}
            />
          </div>

          {/* Description Input */}
          <div>
            <label style={styles.label}>Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter Description"
              style={styles.textarea}
            ></textarea>
          </div>

          {/* Checkbox for Send Update to Team */}
          <div style={styles.checkboxContainer}>
            {/* <input
              type="checkbox"
              checked={sendToTeam}
              onChange={(e) => setSendToTeam(e.target.checked)}
              style={styles.checkbox}
            />
            <label style={styles.label}>Send Update to Team</label> */}
            <CheckBox
                label="Send Update to Team"
                checked={sendToTeam}
                onChange={(isChecked) => setSendToTeam(isChecked)}
                style={styles.checkbox}
            />
          </div>
        </div>

        <hr style={styles.divider} />

        {/* Action Buttons */}
        <div style={styles.buttonContainer}>
          <button onClick={handleAddResource} style={styles.addButton}>
            Add
          </button>
          <button
            onClick={() => {
              resetForm();
              onClose();
            }}
            style={styles.cancelButton}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modal: {
    background: "white",
    borderRadius: "12px",
    padding: "24px",
    width: "500px",
    maxWidth: "90%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#40513b",
    marginBottom: "16px",
  },
  divider: {
    border: "none",
    borderTop: "1px solid #D6E4EC",
    margin: "10px 0",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginBottom: "16px",
  },
  label: {
    fontSize: "12px",
    color: "#343c44",
    fontWeight: "500",
  },
  input: {
    width: "100%",
    padding: "10px",
    border: "1px solid #D6E4EC",
    borderRadius: "2px",
    fontSize: "14px",
  },
  textarea: {
    width: "96%",
    padding: "10px",
    border: "1px solid #D6E4EC",
    borderRadius: "5px",
    fontSize: "14px",
    minHeight: "80px",
    resize: "none",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  checkbox: {
    margin: 0,
    height: "20px",
  },
  buttonContainer: {
    display: "flex",
    gap: "12px",
    justifyContent: "flex-start",
    marginTop: "20px",
  },
  addButton: {
    backgroundColor: "#40513b",
    color: "#fff",
    border: "none",
    borderRadius: "50px",
    cursor: "pointer",
    fontSize: "14px",
    width: "15%"
  },
  cancelButton: {
    background: "none",
    color: "#40513b",
    padding: "10px 20px",
    border: "1px solid #fff",
    borderRadius: "50px",
    cursor: "pointer",
    fontSize: "14px",
  },
  closeIcon: {
    width: "20px",
    height: "20px",
  },
  iconImage: {
    width: '15px',
    height: '15px',
  },
};

export default AddResourceModal;
