import React, { useState } from "react";
import Dropdown from '../../../../Components/Dropdown';
import HoldTaskIssuesModal from "../../../../Components/Modals/HoldTaskIssuesModal";
import pdfIcon from "../../../../Assets/Images/document-pdf.png";
import addDocumentIcon from "../../../../Assets/Icons/Downlaod.png";
import backIcon from "../../../../Assets/Icons/back.png";
import uploadIcon from "../../../../Assets/Icons/upload.png";
import circleTick from "../../../../Assets/Icons/circle-tick.png";
import plus from "../../../../Assets/Icons/Pluse.png";
import infoIcon from "../../../../Assets/Icons/error.png";
import checkBox from "../../../../Assets/Icons/check_box.png";
import editPen from '../../../../Assets/Icons/edit-pen.png';
import fastForwardIcon from '../../../../Assets/Icons/fast_forward.png'

const ClientTaskDetailsScreen = ({ task, onBack }) => {

    const [status, setStatus] = useState('');
    const [assignedTo, setAssignedTo] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [complitedBy,setComplitedBy] = useState('');

    const [taskChecklist, setTaskChecklist] = useState([
        { name: "GST Registration", completedBy: "John Doe", date: "23/10/2024 12:23 PM", status: "Completed", link: "View Document", selected: false },
        { name: "Check Income Statement", completedBy: "John Doe", date: "23/10/2024 12:23 PM", status: "Completed", link: "View", selected: false },
        { name: "Verify Documents", completedBy: "John Doe", date: "23/10/2024 12:23 PM", status: "Completed", link: "View", selected: false },
        { name: "GST Tax Filing", completedBy: "John Doe", date: "23/10/2024 12:23 PM", status: "In Progress", link: "E-Filing Portal", selected: false },
    ]);

    const [taskDetails, setTaskDetails] = useState([
        { heading: "Task Type", value: "GST Tax Filing" },
        { heading: "Start Date", value: "23/10/2024" },
        { heading: "ETD", value: "31/10/2024" },
        { heading: "Financial Year", value: "2024-25" },
        { heading: "Group Name", value: "Company Name/Group Name" },
        { heading: "Assigned To", value: "John Doe" },
        { heading: "File No.", value: "123455" },
        { heading: "Task Leader", value: "John Doe" },
        { heading: "Completed Check", value: "No" },
        { heading: "Completed By", value: "--" },
        { heading: "Period", value: "Q3 2024" },
        { heading: "Pending Issues", value: "--" },
        { heading: "Description", value: "--" }
    ]);


    if (!task) {
        return <p>No task selected.</p>;
    }

    const documents = [
        { name: "Bank Statement", type: "pdf" },
        { name: "Bank Statement", type: "pdf" },
        { name: "Bank Statement", type: "pdf" },
    ];

    const internalDocuments = [
        { name: "Bank Statement", type: "pdf" },
        { name: "Bank Statement", type: "pdf" },
        { name: "Bank Statement", type: "pdf" },
        { name: "Bank Statement", type: "pdf" },
        { name: "Bank Statement", type: "pdf" },
        { name: "Bank Statement", type: "pdf" },
    ];

    const handleHoldTask = () => {
        setIsModalOpen(true); // Open the modal
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };


    const handleStatusChange = (value) => {
        setStatus(value);
    };

    const handleAssignedToChange = (value) => {
        setAssignedTo(value);
    };
    const handleComplitedBy =(value) =>{
        setComplitedBy(value)
    }

    const handleRowClick = (index) => {
        setTaskChecklist((prev) =>
            prev.map((item, idx) =>
                idx === index ? { ...item, selected: !item.selected } : item
            )
        );
    };

    const onEdit = ()=>{

    }


    return (
        <div style={styles.container}>
            {/* Back Button */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", }}>
                <h3 style={styles.taskInfoTitle}>Task Information</h3>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-end",gap:20}}>
                <button onClick={onBack} style={styles.backButton}>
                    <img
                        src={backIcon}
                        alt="Back"
                        style={{ width: "16px", height: "16px", marginRight: "5px" }}
                    />
                    Back to Task Lists
                </button>
                <button onClick={onEdit} style={styles.editButton}>
                    <img
                        src={editPen}
                        alt="editPen"
                        style={{ width: "16px", height: "16px", marginRight: "5px" }}
                    />
                    Edit Task
                </button>
                </div>
               
            </div>

            {/* Task Information Section */}
            <div style={styles.section}>
                <div style={styles.taskInfoBox}>
                    {taskDetails.map((detail, index) => (
                        <div key={index} style={styles.taskInfoColumn}>
                            <p style={styles.taskInfoHeading}>{detail.heading}</p>
                            <p style={styles.taskInfoDetail}>{detail.value}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Documents */}
            <div style={styles.section}>
                <h3 style={styles.sectionTitle}>Documents</h3>
                <div style={styles.taskInfoBox}>
                    {documents.map((doc, index) => (
                        <div key={index} style={styles.documentCard}>
                            <div style={styles.documentIconWrapper}>
                                <img src={pdfIcon} alt="PDF Icon" style={styles.documentIcon} />
                            </div>
                            <p style={styles.documentTitle}>{doc.name}</p>
                            <button style={styles.downloadButton}>
                                <img src={addDocumentIcon} alt="Download Icon" style={styles.downloadIcon} />
                            </button>
                        </div>
                    ))}
                    {/* Add Document Card */}
                    <label style={styles.addDocumentCard}>
                        <input
                            type="file"
                            style={{ display: 'none' }} // Hide the input
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    // Handle the file upload here
                                    console.log("File uploaded:", file.name);
                                    // You can also update state or perform any other actions with the file
                                }
                            }}
                        />
                        <div style={styles.addDocumentIconWrapper}>
                            <span><img src={plus} alt="Plus" style={styles.tickIcon} /></span>
                            {/* <span>+</span> */}
                        </div>
                        <p style={styles.addDocumentText}>Add Document</p>
                        <button style={styles.downloadButton}>
                            <img src={uploadIcon} alt="Upload Icon" style={styles.downloadIcon} />
                        </button>
                    </label>
                </div>
            </div>

            {/*Internal Documents*/}
            <div style={styles.section}>
                <h3 style={styles.sectionTitle}> Internal Documents</h3>
                <div style={{ ...styles.taskInfoBox, gap: '0px', padding: '5px' }}>
                    {internalDocuments.map((doc, index) => (
                        <div key={index} style={{ ...styles.documentCard, width: '130px', height: '100px', backgroundColor: '#fff' }}>
                            <div style={{ ...styles.documentIconWrapper, width: '36px', height: '36px' }}>
                                <img src={pdfIcon} alt="PDF Icon" style={styles.documentIcon} />
                            </div>
                            <p style={styles.documentTitle}>{doc.name}</p>
                            <button style={styles.downloadButton}>
                                <img src={addDocumentIcon} alt="Download Icon" style={styles.downloadIcon} />
                            </button>
                        </div>
                    ))}
                    {/* Add Internal Document Card */}
                    <label style={{ ...styles.addDocumentCard, border: "1px dashed #fff", width: '120px', height: '100px' }}>
                        <input
                            type="file"
                            style={{ display: 'none' }} // Hide the input
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    // Handle the file upload here
                                    console.log("File uploaded:", file.name);
                                    // You can also update state or perform any other actions with the file
                                }
                            }}
                        />
                        <div style={styles.addDocumentIconWrapper}>
                            <span><img src={plus} alt="Plus" style={styles.tickIcon} /></span>
                            {/* <span>+</span> */}
                        </div>
                        {/* <button style={styles.downloadButton}>
                            <img src={uploadIcon} alt="Upload Icon" style={styles.downloadIcon} />
                        </button> */}
                    </label>
                </div>
            </div>

            {/* Task Checklist */}
            <div style={styles.section}>
                <h3 style={styles.sectionTitle}>Task Check List</h3>
                <div style={{ ...styles.taskInfoBox, padding: '0px' }}>
                    <table style={styles.table}>
                        <tbody>
                            {taskChecklist.map((item, index) => (
                                <tr
                                    key={index}
                                    onClick={() => handleRowClick(index)}
                                    style={styles.tableRow}
                                >
                                    <td style={styles.tableCell}>
                                        <div style={styles.checkbox}>
                                            {item.selected ? (
                                                <span><img src={circleTick} alt="Tick" style={styles.circleTickIcon} /></span>
                                            ) : (
                                                <span><div style={styles.emptyImage}></div></span>
                                            )}
                                        </div>
                                    </td>
                                    <td style={styles.tableCell}>{item.name}</td>
                                    <td style={styles.tableCell}>{item.completedBy}</td>
                                    <td style={styles.tableCell}>{item.date}</td>
                                    <td style={styles.tableCell}>
                                        <a style={styles.link}>
                                            {item.link}
                                        </a>
                                    </td>
                                    <td style={styles.tableCell}>
                                        <span
                                            style={{
                                                ...styles.statusBadge,
                                                backgroundColor:
                                                    item.status === "Completed"
                                                        ? "#C7C4C32E"
                                                        : item.status === "In Progress"
                                                            ? "#1A932E2E"
                                                            : "#f8d7da",
                                                color:
                                                    item.status === "Completed"
                                                        ? "#7B7B7B"
                                                        : item.status === "In Progress"
                                                            ? "#1A932E"
                                                            : "#721c24",
                                            }}
                                        >
                                            {item.status}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>


            {/* Action Section */}
            <div style={styles.section}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={styles.actionRow}>
                        <div style={styles.actionField}>
                            <Dropdown
                                label="Update Status"
                                options={['In Progress', 'Completed', 'Pending']}
                                value={status}
                                onChange={(value) => handleStatusChange(value)}
                            />
                        </div>
                        {/* <div style={{ ...styles.actionField, flex: 1 }}>
                        <label style={styles.label}>Comments</label>
                        <textarea placeholder="Enter comments here..." style={styles.textarea}></textarea>
                    </div> */}
                        <div style={styles.actionField}>
                            <Dropdown
                                label="Assign To"
                                options={['Auditor', 'Manager', 'Staff']}
                                value={assignedTo}
                                onChange={(value) => handleAssignedToChange(value)}
                            />
                        </div>
                        <div style={styles.actionField}>
                            <Dropdown
                                label="Complited By"
                                options={['Satish R.', 'Manager', 'Staff']}
                                value={complitedBy}
                                onChange={(value) => handleComplitedBy(value)}
                            />
                        </div>
                    </div>
                   
                </div>
                <div style={{ ...styles.actionField }}>
                    <label style={styles.label}>Comments</label>
                    <textarea placeholder="Enter comments here..." style={styles.textarea}></textarea>
                </div>
                <div style={styles.actionButtonsDiv}>
                    <div style={styles.actionButtons}>
                    <button style={styles.saveButton}>Save</button>
                    <button style={styles.scheduleButton}>Schedule Appointment</button>
                    <button style={styles.cancelButton}>Cancel</button>
                    </div>
                   
                    <div style={{ ...styles.actionRow}}>
                    <button style={styles.holdTaskButton} onClick={() => handleHoldTask()}><img
                            src={fastForwardIcon}
                            alt="Update Priority"
                            style={{ width: "16px", height: "16px", marginRight: '5px' }}
                        />Update Priority</button>
                        <button style={styles.holdTaskButton} onClick={() => handleHoldTask()}><img
                            src={infoIcon}
                            alt="Info"
                            style={{ width: "16px", height: "16px", marginRight: '5px' }}
                        />Hold Task</button>
                        <button style={styles.holdTaskButton} onClick={() => handleHoldTask()}><img
                            src={checkBox}
                            alt="Check Box"
                            style={{ width: "16px", height: "16px", marginRight: '5px' }}
                        />Task Issue</button>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <HoldTaskIssuesModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};

const styles = {
    section: {
        marginBottom: "30px",
    },
    container: {
        fontFamily: "Arial, sans-serif",
    },
    backButton: {
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
        backgroundColor: "transparent",
        border: "none",
        color: "#5A55D2",
        cursor: "pointer",
        fontSize: "14px",
        textDecoration: 'underline',
    },
    editButton: {
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
        backgroundColor: "transparent",
        border: '1px solid #858585',
        color: "#40513B",
        cursor: "pointer",
        fontSize: "12px",
        padding:'5px 10px',
        borderRadius:'20px'
    },
    taskInfoContainer: {
        marginBottom: "30px",
    },
    taskInfoTitle: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#609966",
        marginBottom: "15px",
        paddingBottom: "5px",
    },
    sectionTitle: {
        fontSize: "18px",
        fontWeight: "bold",
        marginBottom: "15px",
        color: "#4CAF50",
    },
    documentsWrapper: {
        display: "flex",
        gap: "20px",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#fff",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    },
    documentCard: {
        width: "220px",
        height: "130px",
        backgroundColor: "#f9f9f9",
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        position: "relative",
        textAlign: "center",
    },
    documentIconWrapper: {
        width: "48px",
        height: "48px",
        backgroundColor: "#fff",
        borderRadius: "25%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        marginBottom: "5px",
    },
    documentIcon: {
        width: "36px",
        height: "36x",
    },
    documentTitle: {
        fontSize: "14px",
        fontWeight: "500",
        color: "#333",
        marginBottom: "10px",
    },
    downloadButton: {
        position: "absolute",
        top: "10px",
        right: "10px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
    },
    downloadIcon: {
        width: "16px",
        height: "16px",
    },
    addDocumentCard: {
        width: "220px",
        height: "150px",
        border: "1px dashed #ddd",
        borderRadius: "15px",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        position: "relative",
    },
    addDocumentIconWrapper: {
        width: "40px",
        height: "40px",
        backgroundColor: "#f9f9f9",
        borderRadius: "25%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        marginBottom: "5px",
    },
    addDocumentIcon: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#000",
    },
    addDocumentText: {
        fontSize: "14px",
        fontWeight: "500",
        color: "#000",
    },
    table: {
        width: "100%",
        borderCollapse: "collapse",
    },
    tableHeader: {
        border: "1px solid #ddd",
        padding: "10px",
        textAlign: "left",
        fontWeight: "bold",
        backgroundColor: "#f9f9f9",
        color: "#4CAF50",
    },
    tableRow: {
        cursor: "pointer",
        transition: "background-color 0.3s ease",
        backgroundColor: "#fff",
    },
    tableCell: {
        padding: "10px",
        textAlign: "left",
        fontSize: "14px",
        color: "#060606",
    },
    link: {
        color: "#5A55D2",
        textDecoration: "underline",
    },
    checkbox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusBadge: {
        padding: "5px 10px",
        borderRadius: "15px",
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
    },
    actionRow: {
        display: "flex",
        gap: "20px",
        // justifyContent: "space-between",
        marginBottom: "20px",
        flexWrap: "wrap",
    },
    actionField: {
        display: "flex",
        flexDirection: "column",
    },
    label: {
        fontSize: "12px",
        fontWeight: "500",
        marginBottom: "8px",
        color: "#343C44",
    },
    dropdown: {
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "5px",
        backgroundColor: "#fff",
        fontSize: "14px",
        color: "#333",
    },
    textarea: {
        padding: "10px",
        border: "1px solid #D6E4EC",
        borderRadius: "5px",
        resize: "none",
        height: "87px",
        fontSize: "14px",
        color: "#333",
        width: '520px'
    },
    actionButtons: {
        display: "flex",
        gap: "15px",
        justifyContent: "flex-start",
    },
    actionButtonsDiv:{
        display: "flex",
        gap: "15px",
        justifyContent: "space-between",
        alignItems:'center',
        marginTop: '20px'
    },
    saveButton: {
        padding: "10px 20px",
        backgroundColor: "#40513B",
        color: "#FBFBFB",
        border: "none",
        borderRadius: "50px",
        cursor: "pointer",
        fontSize: "14px",
    },
    scheduleButton: {
        padding: "10px 20px",
        backgroundColor: "#fff",
        color: "#40513B",
        border: "1px solid #858585",
        borderRadius: "50px",
        cursor: "pointer",
        fontSize: "14px",
    },
    cancelButton: {
        padding: "10px 20px",
        backgroundColor: "#fff",
        color: "#40513B",
        border: "1px solid #858585",
        borderRadius: "50px",
        cursor: "pointer",
        fontSize: "14px",
    },
    taskInfoBox: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        border: "1px solid #ddd",
        backgroundColor: "#fff",
        padding: "15px",
        borderBottom: "2px solid #060606",
        gap: "15px",
    },
    taskInfoColumn: {
        minWidth: "200px",
        textAlign: "left",
    },
    taskInfoHeading: {
        fontSize: "12px",
        fontWeight: "bold",
        marginBottom: "5px",
        color: "#767676",
    },
    taskInfoDetail: {
        fontSize: "14px",
        fontWeight: "normal",
        color: "#060606",
    },
    emptyImage: {
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        border: "1px solid #ddd",
    },
    tickIcon: {
        width: "20px",
        height: "20px",
        filter: 'brightness(0)',
    },
    circleTickIcon: {
        width: "20px",
        height: "20px",
    },
    holdTaskButton: {
        backgroundColor: "#fff",
        color: "#40513B",
        border: "1px solid #858585",
        borderRadius: "50px",
        cursor: "pointer",
        fontSize: "14px",
        height: '35px',
        // width: '110px',
        display: 'flex',
        flexDirection: 'row',
        padding: "10px 10px",
    },
};

export default ClientTaskDetailsScreen;
