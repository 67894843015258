import React ,{useState,useEffect}from "react";

const UserPrimaryInformationController = (userInfo) => {
    
    const [primaryData2, setPrimaryData2] = useState({
        "emp_id": 10001,
        "emp_name": "John Doe",
        "emp_email": "john.doe@example.com",
        "emp_mobile_no": "1234567890",
        "emp_mobile_code": "+1",
        "role_id": 101,
        "team_id": 0,
        "emp_status": 1,
        "emp_code": "EMP001",
        "emp_task_assign_flag": 1,
        "team_name": null,
        "emp_id_team_manager": null,
        "emp_id_team_lead": null,
        "emp_id_team_manager_name": "--",
        "emp_id_team_lead_name": "--"
    });
    const [primaryData, setPrimaryData] = useState(userInfo);
    const fieldConfigs = [
        { label: "User Name", key: "emp_name", type: "text" },
        { label: "Email ID", key: "emp_email", type: "text" },
        { label: "Phone No.", key: "emp_mobile_no", type: "text" },
        { label: "Group Name", key: "groupName", type: "dropdown", options: ["Group A", "Group B"] },
        { label: "Team Name", key: "team_name", type: "dropdown", options: ["Team X", "Team Y"] },
        { label: "Team Leader", key: "emp_id_team_lead_name", type: "dropdown", options: ["Team X", "Team Y"] },
        { label: "Date Of Joining", key: "dateOfJoining", type: "date" },
        { label: "Manager", key: "emp_id_team_manager_name", type: "dropdown", options: ["Manager A", "Manager B"] },
        { label: "Role", key: "role", type: "dropdown", options: ["Role A", "Role B"] },
    ];

    useEffect(()=>{
        setPrimaryData(userInfo)
    },[userInfo])

    const handleInputChange = (key, value) => {
        setPrimaryData((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    
    const handleUpdateUser = () => {
        console.log('handleUpdateUser @@@')

    }

    const handleCancel = () => {
        console.log('handleCancel @@@')

    }

    return {
        handleUpdateUser,
        handleCancel,
        fieldConfigs,
        primaryData,
        handleInputChange
    };

}

export default UserPrimaryInformationController;