import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../../Components/Tabs';
import ServiceEstimateScreen from './ServiceEstimateScreen';
import InvoiceBillScreen from './InvoiceBillScreen';
import InvoiceReceiptScreen from './InvoiceReceiptScreen';
const BillingReceiptEstimateScreen = () => {

    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(state?.selectedTab || 0);

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    return (
        <div style={styles.mainContent}>
            <div className='title-container' style={{paddingBottom:10}}>
                <h2 className='title'>Invoices</h2>
            </div>

            <div >
                <Tabs tabs={['Service Estimates','Invoices','Receipt']}
                selectedIndex={selectedTab}
                onTabChange={handleTabChange}>
                    <ServiceEstimateScreen />
                    <InvoiceBillScreen />
                    <InvoiceReceiptScreen />
                </Tabs>
            </div>

        </div>


    )

};

const styles = {
    mainContent: {
        width: '95%',
        margin: 'auto'
    }

}

export default BillingReceiptEstimateScreen;