import React, { useState } from 'react';
const GenerateEditInvoiceController = () => {

    const [sendUpdateToClient, setSendUpdateToClient] = useState(false);
    const [sendUpdateToTeam, setSendUpdateToTeam] = useState(false);
    const [invoiceWithoutTask, setInvoiceWithoutTask] = useState(false);

    const [basicInformation, setBasicInformation] = useState({
        clientName: "",
        groupName: "",
        phoneNo: "",
        dateOfEstimate: "",
        countryCode: "",
    });

    const handleBasicInformationChange = (field, value) => {
        setBasicInformation((prevInfo) => ({
            ...prevInfo,
            [field]: value,
        }));
    };

    const [invoiceOptions, setInvoiceOptions] = useState({
        groupEstimate: "",
        noOfClients: "",
        systemBillingAmount: "",
        estimateAmount: "",
        generatedBy: "",
        taskCompletedBy: "",
        noTaxBill: "",
        generateSeperateBill: "",
        estimateNumber: "",
        advancePayment: "",
        invoiceAmount: ""
    });

    const handleInvoiceOptionsChange = (field, value) => {
        setInvoiceOptions((prevOptions) => ({
            ...prevOptions,
            [field]: value,
        }));
    };

    const [organizationDetails, setOrganizationDetails] = useState({
        organization: "",
        receiptBook: "",
        followUpDate: "",
    });

    const handleOrganizationDetailsChange = (field, value) => {
        setOrganizationDetails((prevDetails) => ({
            ...prevDetails,
            [field]: value,
        }));
    };





    return {
        setBasicInformation,
        basicInformation,
        handleBasicInformationChange,
        invoiceOptions,
        setInvoiceOptions,
        handleInvoiceOptionsChange,
        sendUpdateToClient,
        setSendUpdateToClient,
        sendUpdateToTeam,
        setSendUpdateToTeam,
        invoiceWithoutTask,
        setInvoiceWithoutTask,
        organizationDetails,
        setOrganizationDetails,
        handleOrganizationDetailsChange
    }
}

export default GenerateEditInvoiceController