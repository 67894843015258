import React, { useState } from 'react';
import CheckBox from '../../../../Components/CheckBox';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import ClientTaskListController from '../../../Controllers/AdminScreens/Clients/ClientTaskListController';
import Loader from '../../../../Components/Loader';

const ClientTaskListScreen = ({ onTaskEdit }) => {

  const [selectAll, setSelectAll] = useState(false);
  const [checkedStates, setCheckedStates] = useState([]);

  const {
    tasks,
    currentPage,
    totalPages,
    handlePageChange,
    toggleDetails,
    getStatusStyle,
    loading,
    error,
    handleGenerateInvoive,
  } = ClientTaskListController();

  // const tasks = [
  //     {
  //       taskName: "GST Filling",
  //       financialYear: "2023-24",
  //       assignedTo: "Yearly",
  //       taskLeader: "Yearly",
  //       startDate: "11/11/2024",
  //       etd: "11/11/2024",
  //       status: "In Progress",
  //     },
  //     {
  //       taskName: "Company Reg",
  //       financialYear: "2023-24",
  //       assignedTo: "Yearly",
  //       taskLeader: "Yearly",
  //       startDate: "11/11/2024",
  //       etd: "11/11/2024",
  //       status: "Not Started",
  //     },
  //     {
  //       taskName: "Company Registration",
  //       financialYear: "2023-24",
  //       assignedTo: "Yearly",
  //       taskLeader: "Yearly",
  //       startDate: "11/11/2024",
  //       etd: "11/11/2024",
  //       status: "Priority",
  //     },
  //     {
  //       taskName: "ITR",
  //       financialYear: "2023-24",
  //       assignedTo: "Yearly",
  //       taskLeader: "Yearly",
  //       startDate: "11/11/2024",
  //       etd: "11/11/2024",
  //       status: "Priority",
  //     },
  //     {
  //       taskName: "Retention Charges",
  //       financialYear: "2023-24",
  //       assignedTo: "Monthly",
  //       taskLeader: "Monthly",
  //       startDate: "11/11/2024",
  //       etd: "11/11/2024",
  //       status: "Priority",
  //     },
  //   ];

  const handleSelectAll = (isChecked) => {
    setSelectAll(isChecked);
    setCheckedStates(tasks.map(() => isChecked));
  };

  const handleCheckboxChange = (index, isChecked) => {
    const updatedStates = [...checkedStates];
    updatedStates[index] = isChecked;
    setCheckedStates(updatedStates);
  };


  return (
    <>
      <div className="tasksScreen-task-table-wrapper basic-info-outer-card">
        <div className='flexdisplaybetween' style={{ paddingBottom: 10 }}>
          <h3 className='basic-info-header' style={{ marginBottom: 0 }}>Task Lists</h3>
          <div style={styles.showTasktyle}>Show Archived Tasks</div>
        </div>

        <table className="tasksScreen-task-table" >
          <thead>
            <tr>
              <th style={{ width: "5%" }}>
                {/* <input type="checkbox" /> */}
                <CheckBox
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              <th style={{ width: "20%", }}>Task Name</th>
              <th style={{ width: "10%", }}>Financial Year</th>
              <th style={{ width: "10%", }}>Assigned to</th>
              <th style={{ width: "10%", }}>Task Leader</th>
              <th style={{ width: "10%", }}>Start Date</th>
              <th style={{ width: "10%", }}>ETD</th>
              <th style={{ width: "10%", }}>Status</th>
              <th style={{ width: "15%", }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: 'white' }}>
            {tasks?.map((task, index) => (
              <tr
                key={index}
                style={{
                  borderBottom: "3px solid #f8f9fa",
                }}
              >
                <td>
                  {/* <input type="checkbox" /> */}
                  <CheckBox
                    checked={checkedStates[index] || false}
                    onChange={(isChecked) => handleCheckboxChange(index, isChecked)}
                  />
                </td>
                <td style={{ ...styles.tableValue, color: '#5A55D2', textDecoration: 'underline' }}>{task?.service_sub_name}</td>
                <td style={styles.tableValue}>{task?.financial_year}</td>
                <td style={styles.tableValue}>{task?.emp_id_assigned_name}</td>
                <td style={styles.tableValue}>{task?.emp_id_assigned_name}</td>
                <td style={styles.tableValue}>{task?.task_start_date}</td>
                <td style={styles.tableValue}>{task?.task_due_date}</td>
                <td style={styles.tableValue}>
                  <div
                    style={{
                      display: "inline-block", // Makes the div wrap content and have its own width
                      padding: "5px 5px", // Padding for the button-like appearance
                      borderRadius: "15px",
                      ...getStatusStyle(task?.task_status), // Dynamic styles based on the status
                      width: "auto", // Width adjusts automatically to content
                    }}
                  >
                    {task?.task_status}
                  </div>
                </td>
                <td
                >
                  <button className="tasksScreen-edit-button" style={{ marginRight: "10px" }} onClick={() => onTaskEdit(task)}>
                    <img src={editIcon} alt="Edit" style={{ width: "16px", height: "16px" }} />
                  </button>
                  <button className="tasksScreen-delete-button">
                    <img src={deleteIcon} alt="Delete" style={{ width: "16px", height: "16px" }} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
        {error && <div className="list-error-msg">{error} </div>}

      </div>
      <div style={{ paddingTop: 15 }}>
        <button className="add-client-button" style={{ paddingLeft: 30, paddingRight: 30 }} onClick={handleGenerateInvoive}>
          Generate Invoices
        </button>
      </div>
    </>
  );

};

const styles = {
  header: {
    fontSize: "18px",
    color: "#609966",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: 'Roboto Slab'
  },
  tableValue: {
    colour: '#192A3E',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px'
  },
  tableLink: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#5A55D2'
  },
  showTasktyle: {
    color: '#5A55D2',
    fontSize: '12px',
    fontWeight: '400',
    textDecoration: 'underline',
    wordWrap: 'break-word'
  }
}

export default ClientTaskListScreen;