import React from "react";
import PropTypes from "prop-types";
import selectedImage from '../Assets/Icons/Check square.png';
import unselectedImage from '../Assets/Icons/Default.png';
import "../App.css";

const CheckBox = ({ label, checked, onChange, style, labelStyle, disabled }) => {
    const handleClick = () => {
        if (!disabled) {
            onChange(!checked);
        }
    };

    return (
        <div
            className="custom-checkbox-container"
            style={{ display: "flex", alignItems: "center", cursor: disabled ? "not-allowed" : "pointer", ...style }}
            onClick={handleClick} // Clicking the entire container toggles the checkbox
        >
            <img
                src={checked ? selectedImage : unselectedImage}
                alt={checked ? "Checked" : "Unchecked"}
                className="custom-checkbox-image"
                style={{ marginRight: "8px" }}
            />
            {label && (
                <span
                    className="custom-checkbox-label"
                    style={{ fontSize: "14px", color: disabled ? "#aaa" : "#333", ...labelStyle }}
                >
                    {label}
                </span>
            )}
        </div>
    );
};

CheckBox.propTypes = {
    label: PropTypes.string, // Optional label to display next to the checkbox
    checked: PropTypes.bool.isRequired, // Whether the checkbox is checked
    onChange: PropTypes.func.isRequired, // Callback function when checkbox state changes
    selectedImage: PropTypes.string.isRequired, // Image for the selected state
    unselectedImage: PropTypes.string.isRequired, // Image for the unselected state
    style: PropTypes.object, // Optional custom styles for the container
    labelStyle: PropTypes.object, // Optional custom styles for the label
    disabled: PropTypes.bool, // Disable the checkbox
};

CheckBox.defaultProps = {
    label: "",
    style: {},
    labelStyle: {},
    disabled: false,
};

export default CheckBox;
