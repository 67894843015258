export const API_URL = "https://k0s8ewhycd.execute-api.us-east-1.amazonaws.com/dev" // DEV
// export const API_URL = 'https://mae405i773.execute-api.us-east-1.amazonaws.com/qa'; //QA



export const SIGNUP_API = "/org/auth/employee-login" // POST METHOD
export const ORG_ADD_SERVICE = '/org/app/services/add-service' // POST METHOD 
export const ORG_GET_SERVICE_LIST = '/org/app/services/get-service-list' // GET METHOD
export const ORG_UPDATE_SERVICE = '/org/app/services/update-service-info' // POST METHOD
export const ORG_DELETE_SERVICE = '/org/app/services/delete-service' // POST METHOD

export const ORG_ADD_SUB_SERVICE = '/org/app/services-sub/add-service-sub' // POST METHOD 
export const ORG_GET_SUB_SERVICE_LIST = '/org/app/services-sub/get-service-sub-list' // GET METHOD
export const ORG_UPDATE_SUB_SERVICE = '/org/app/services-sub/update-service-sub' // POST METHOD
export const ORG_DELETE_SUB_SERVICE = '/org/app/services-sub/delete-service-sub' // POST METHOD

export const ORG_ADD_SUB_SERVICE_CHECK = '/org/app/services-checks/add-service-checks' // POST METHOD 
export const ORG_GET_SUB_SERVICE_CHECK_LIST = '/org/app/services-checks/get-service-checks-list' // GET METHOD 
export const ORG_UPDATE_SUB_SERVICE_CHECK = '/org/app/services-checks/update-service-checks' // POST METHOD
export const ORG_DELETE_SUB_SERVICE_CHECK = '/org/app/services-checks/delete-service-checks' // POST METHOD

export const ORG_ADD_GROUP = '/org/app/group/add-group' // POST METHOD 
export const ORG_GET_GROUP_LIST = '/org/app/group/groups-list' // GET METHOD
export const ORG_GET_GROUP_INFO = '/org/app/group/get-group-info' // GET METHOD
export const ORG_GET_GROUP_MEMBERS = '/org/app/group/get-group-clients-list' //GET METHOD
export const ORG_ADD_CLIENT_TO_GROUP = '/org/app/group/add-client-to-group' //POST METHOD
export const ORG_UPDATE_GROUP_STATUS = '/org/app/group/group-activate-inactivate' //POST METHOD
export const ORG_UPDATE_GROUP = '/org/app/group/edit-group' // POST METHOD
export const ORG_DELETE_GROUP = '/org/app/group/delete-group' // POST METHOD

export const ORG_ADD_CLIENT = '/org/app/clientile/clients/add-client' // GET METHOD 
export const ORG_GET_ORG_CODE = '/org/app/utils/org-dd-codes' // POST METHOD 
export const ORG_GET_CLIENT_TYPE_LIST = '/org/app/client-types/get-type-list' // GET METHOD
export const ORG_GET_CLIENT_LIST = '/org/app/clientile/clients/get-client-list' // GET METHOD
export const ORG_GET_CLIENT_INFO = '/org/app/clientile/clients/get-client-info' // GET METHOD
export const ORG_GET_CLIENT_CONTACT_LIST = '/org/app/clientile/client-contacts/get-contact-list' // GET METHOD
export const ORG_ADD_CLIENT_CONTACT = '/org/app/clientile/client-contacts/add-contacts' // POST METHOD
export const ORG_UPDATE_CLIENT_CONTACT = '/org/app/clientile/client-contacts/update-contact' // POST METHOD
export const ORG_DELETE_CLIENT_CONTACT = '/org/app/clientile/client-contacts/delete-contacts' // POST METHOD

export const ORG_GET_ALL_CODES_LIST = '/org/app/utils/org-dd-codes' // POST METHOD

export const ORG_ADD_TASK = '/org/app/tasks/add-task' // POST METHOD 
export const ORG_GET_TASK_LIST = '/org/app/tasks/task-list-search' // POST METHOD 

export const ORG_ADD_TEAM = '/org/app/emp-teams/add-team' // POST METHOD 
export const ORG_GET_TEAM_LIST = '/org/app/emp-teams/get-team-list' // GET METHOD
export const ORG_GET_TEAM_INFO = '/org/app/emp-teams/get-team-info' // GET METHOD
export const ORG_GET_TEAM_MEMBERS = '/org/app/emp-teams/get-team-emp-list' //GET METHOD 
export const ORG_ADD_EMP_TEAM = '/org/app/emp-teams/add-emp-to-team' //POST METHOD
export const ORG_UPDATE_TEAM_STATUS = '/org/app/emp-teams/team-activate-inactivate' //POST METHOD

export const ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER = '/org/app/clientile/clients/client-search-dd' // POST METHOD
export const ORG_EMP_SEARCH_TO_ADD_TEAMMEMBER = '/org/app/emp/emp-search-dd' // POST METHOD

export const ORG_ADD_EMPLOYEE = '/org/app/emp/emp-add' // POST METHOD 
export const ORG_GET_EMPLOYEE_LIST = '/org/app/emp/emp-list' // GET METHOD
export const ORG_GET_EMPLOYEE_INFO = '/org/app/emp/get-emp-info' // GET METHOD

export const ORG_GET_ROLES_LIST = '/org/app/roles/roles/roles-list' // GET METHOD
