import React, { useState } from 'react';
import InfoIcon from '../../../../Assets/Icons/Info.png';
import UserPrimaryInformationController from '../../../Controllers/AdminScreens/Users/UserPrimaryInformationController';
import Input from '../../../../Components/Input';
import Dropdown from '../../../../Components/Dropdown';
import DateInput from '../../../../Components/DateInput';
import LoadingSkeleton from '../../../../Components/LoadingSkeleton';

const UserPrimaryInformationScreen = ({ isUserEditable, userInfo, loading, error }) => {

   

    const { handleUpdateUser, handleCancel,
        fieldConfigs,
        primaryData,
        handleInputChange
     } = UserPrimaryInformationController(userInfo);
     console.log('UserPrimaryInfo isUserEditable @@ ', isUserEditable,'userInfo:-',primaryData)

    return (
        <div className='basic-info-container'>
            <h3 className='basic-info-header'>Basic Information</h3>
            <div className='basic-info-card'>
                <div className='basic-info-row'>
                    {fieldConfigs.map((field) => (
                        <div className="basic-info-item" key={field.key}>
                            {isUserEditable ? (
                                field.type === "dropdown" ? (
                                    <Dropdown
                                        label={field.label} // Dropdown already renders the label internally
                                        options={field.options}
                                        value={primaryData[field.key]}
                                        onChange={(selectedValue) => handleInputChange(field.key, selectedValue)}

                                    />
                                ) : field.type === "date" ? (
                                    <div className="basic-info-label">
                                        <label>{field.label}</label>
                                        <DateInput
                                            value={primaryData[field.key] || ""}
                                            onChange={(e) => handleInputChange(field.key, e.target.value)}
                                        />
                                    </div>
                                ) : (
                                    <Input
                                        label={field.label} // Input already renders the label internally
                                        placeholder={`Enter ${field.label}`}
                                        value={primaryData[field.key]}
                                        onChange={(e) => handleInputChange(field.key, e.target.value)}
                                    />
                                )
                            ) : (
                                <><span className="basic-info-label">{field.label}</span>
                                {field.key==='emp_mobile_no' ? <span> {primaryData?.emp_mobile_code}{primaryData[field.key]}{loading && <LoadingSkeleton width="40%" height="10px" />} </span>:
                                    <span className="basic-info-value"> {primaryData[field.key]} {loading && <LoadingSkeleton width="40%" height="10px" />}</span>}</>
                            )}
                        </div>
                    ))}
                </div>
                {/* <div className='basic-info-row'>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>User Name</span>
                        <span className='basic-info-value'>{primaryData.userName}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Email ID</span>
                        <span className='basic-info-value'>{primaryData.emailId}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Phone No.</span>
                        <span className='basic-info-value'>{primaryData.phoneNo}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Group Name</span>
                        <span className='basic-info-value'>{primaryData.groupName}</span>
                    </div>
                </div>
                <div style={{ ...styles.row, marginTop: 20 }}>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Team Name</span>
                        <span className='basic-info-value'>{primaryData.teamName}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Date Of Joining</span>
                        <span className='basic-info-value'>{primaryData.dateOfJoining}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Manager</span>
                        <span className='basic-info-value'>{primaryData.manager}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Task Type</span>
                        <span className='basic-info-value'>{primaryData.taskType}</span>
                    </div>
                </div> */}
            </div>
            <div className="tasksScreen-task-table-wrapper" style={{ marginTop: 17 }}>
                <h3 className='basic-info-header'>Recent Activity</h3>
                <div className="basic-info-outer-card">
                    <table className='tasksScreen-task-table'>
                        <thead>
                            <tr>
                                <th>Task Type</th>
                                <th>Client</th>
                                <th>Current Status</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: 'white' }}>
                            <tr>
                                <td >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                        <img
                                            src={InfoIcon}
                                            alt="No Data"
                                            style={{ width: '25px', height: '25px', objectFit: 'contain' }}
                                        />
                                        <span style={styles.recentData}>No Data to Display</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
            <div className="button-container" style={{ gap: 20, flexWrap:'wrap' }}>
                <button onClick={handleUpdateUser} className="add-client-button">Update User</button>
                <button onClick={handleCancel} className="tasksScreen-cancel-button">Cancel</button>
            </div>

        </div>
    )

};

const styles = {
    container: {
        margin: "20px",
        //   fontFamily: "Arial, sans-serif",
        backgroundColor: '#fff',
        // marginTop: '-18px'
    },
    header: {
        fontSize: "18px",
        color: "#609966",
        marginBottom: "10px",
        fontWeight: 500,
        fontFamily: 'Roboto Slab'
    },
    card: {
        backgroundColor: "#fff",
        borderRadius: "5px",
        padding: "20px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        borderBottom: "2px solid black",
    },
    row: {
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
    },
    item: {
        flex: "1 1 calc(25% - 20px)",
        minWidth: "200px",
    },
    label: {
        fontWeight: 400,
        color: "#767676",
        display: "block",
        marginBottom: "5px",
        fontSize: '12px',
        fontFamily: 'Roboto',
        textTransform: 'capitalize'
    },
    value: {
        fontSize: "14px",
        color: '#060606',
        fontWeight: 400,
        fontFamily: 'Roboto'
    },
    recentData: {
        color: '#B3261E',
        fontWeight: 400,
        fontSize: 12,
        fontFamily: 'Roboto'
    }
}

export default UserPrimaryInformationScreen;