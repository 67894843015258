import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../../../Components/Tabs'
import WhatsAppTemplateList from '../TemplateCenter/WhatsAppTemplateList';
import SMSTemplateList from '../TemplateCenter/SMSTemplateList'
import EmailTemplateList from '../TemplateCenter/EmailTemplateList';
import WhatsAppConfiguration from './WhatsAppConfiguration';


const ConfigurationCenterScreen = () => {

    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(state?.selectedTab || 0);

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    // const {

    // } = InvoiceSettingsController()

    return (
        <div style={styles.mainContent}>
            <div className='title-container' style={{paddingBottom:10}}>
                <h2 className='title'>Configuration Center</h2>
            </div>

            <div >
            <Tabs tabs={['WhatsApp','Email','SMS',]}
            selectedIndex={selectedTab}
            onTabChange={handleTabChange}>
                    <WhatsAppConfiguration />
                    <WhatsAppConfiguration />
                    <WhatsAppConfiguration />
                </Tabs>
            </div>

        </div>


    )

};

const styles = {
    mainContent: {
        width: '95%',
        margin: 'auto'
    }

}

export default ConfigurationCenterScreen;