import React, { useState, useEffect, useRef } from 'react';
import Input from './Input';
import Dropdown from './Dropdown';
import CheckBox from './CheckBox';
import MobileInput from './MobileInput';
import closeIcon from '../Assets/Icons/close.png';
import pluseIcon from '../Assets/Icons/Pluse.png';
import uploadIcon from '../Assets/Icons/upload.png';
import { ORG_GET_GROUP_LIST, ORG_ADD_CLIENT, ORG_GET_CLIENT_TYPE_LIST, ORG_GET_ORG_CODE } from '../ApiServices/BaseURL';
import { postData, getData } from '../ApiServices/ApiService';
import { validateEmail, validateMobileNumber } from '../Utils/Validation';
import Loader from './Loader';

const AddClientModal = ({ isOpen, onClose, onValueChange }) => {
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientFileId, setClientFiledId] = useState('');
  const [clientType, setClientType] = useState(null);
  const [identificationType, setIdentificationType] = useState(null);
  const [identificationNumber, setIdentificationNumber] = useState(null);
  const [groupClient, setGroupClient] = useState(null);
  const [mobileNumber, setMobileNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+91');
  const [existingClient, setExistingClient] = useState(false);
  const [showUpload, setShowUpload] = useState(false);

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('');

  const clientTypeOptions = ["Individual", "Company", "Company - GST Exempt", "Type - 4", "New Entity", "Other"];
  const identificationOptions = ["Passport", "Driver's License", "National ID"];
  const groupClientOptions = ["Group A", "Group B", "Group C"];
  const countryCodeOptions = [[
    { code: "IN", dialCode: "+91" },
    { code: "US", dialCode: "+1" },
    { code: "UK", dialCode: "+44" },
  ]];

  const groupClientOptionsRef = useRef(null);
  const identificationOptionsRef = useRef(null);
  const clientTypeOptionsRef = useRef(null);


  useEffect(() => {
    setError("");
    fetchGroupList();
    fetchClientTypesList();
    fetchIdentityCodes();
  }, [])

  const fetchGroupList = async () => {
    try {
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_GROUP_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              groupClientOptionsRef.current = responseData?.dataJ.map(service => ({
                label: service.group_name,
                value: service.group_id
              }));
            } else {
              setError(responseData?.info || 'Data Not Found');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data: ${String(error)}`);
      }
    } finally {
      setLoading(false);
    }
  }
  const fetchClientTypesList = async () => {
    try {
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_CLIENT_TYPE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              clientTypeOptionsRef.current = responseData?.dataJ.map(service => ({
                label: service.client_type_name,
                value: service.client_type_id
              }));
            } else {
              setError(responseData?.info || 'Data Not Found');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data: ${String(error)}`);
      }
    } finally {
      setLoading(false);
    }
  }

  const fetchIdentityCodes = async () => {
    try {
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const payLoad = {
        "code_id": "client_identity_codes"
      }
      const response = await postData(ORG_GET_ORG_CODE, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              identificationOptionsRef.current = responseData?.dataJ?.map(doc => {
                const [key, value] = Object.entries(doc)[0]; // Extract the first key-value pair
                return { label: value, value: key };
              });
            } else {
              setError(responseData?.info || 'Data Not Found');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data: ${String(error)}`);
      }
    } finally {
      setLoading(false);
    }
  }

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (clientName || clientType || identificationType || identificationNumber || groupClient || mobileNumber) {
      setShowUpload(true);
    } else {
      setShowUpload(false);
    }
  }, [clientName, clientType, identificationType, identificationNumber, groupClient, mobileNumber]);

  // Reset form fields to initial values
  const resetForm = () => {
    setClientName('');
    setClientType('');
    setIdentificationType('');
    setIdentificationNumber('');
    setGroupClient('');
    setMobileNumber('');
    setExistingClient(false);
    setClientEmail('');
  };

  // Run resetForm when modal closes
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const handleAddClient = async () => {
    // return

    setError('');

    // Validate email and mobile number
    if (!validateEmail(clientEmail)) {
      setError('Invalid email address.');
      return;
    }

    if (!validateMobileNumber(mobileNumber)) {
      setError('Mobile number must be 10 digits.');
      return;
    }


    try {
      setError('');

      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "client_name": clientName,
        "client_type_id": clientType.value,
        "group_id": groupClient.value,
        "client_email": clientEmail,
        "client_file_id": clientFileId,
        "client_identity_doc_id": identificationType.value,
        "client_identity_doc_id_number": identificationNumber,
        "client_mobile_no": mobileNumber,
        "client_mobile_code": countryCode
      }

      console.log('catagory Data', payLoad);
      // Make the API call
      const response = await postData(ORG_ADD_CLIENT, payLoad);
      const responseData = await response.json();
      console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setError(responseData?.info);
            setTimeout(() => {
              handleClose();
            }, 2000);
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data: ${String(error)}`);
      }
    } finally {
      setLoading(false);
    }

    // onValueChange(clientData); // Send data to Dashboard
    // onClose(); // Close modal
  };

  const handleClose = () => {
    resetForm();
    setError('');
    onClose();
  }

  // Handle file input change
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      console.log("Uploaded file:", files[0]);
      // Handle file upload here (e.g., upload to server or process locally)
    }
  };

  // Trigger file input click
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={handleClose}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
        </button>
        <h2 className="modal-title">Add Client</h2>
        <hr className="title-divider" />

        <div className="form-grid">
          <Input label="Client Name" placeholder="Enter client name" value={clientName} onChange={(e) => setClientName(e.target.value)} />
          <Input label="Client Email" placeholder="Enter email id" value={clientEmail} onChange={(e) => setClientEmail(e.target.value)} />
          <Dropdown label="Client Type" options={clientTypeOptionsRef.current} value={clientType} onChange={setClientType} />
          <Dropdown label="Select Identification" options={identificationOptionsRef.current} value={identificationType} onChange={setIdentificationType} />
          <Input label="Identification Number" placeholder="Enter ID number" value={identificationNumber} onChange={(e) => setIdentificationNumber(e.target.value)} />
          <Dropdown label="Group Client" options={groupClientOptionsRef.current} value={groupClient} onChange={setGroupClient} />
          {/* <Dropdown label="Select Country Code" options={countryCodeOptions} value={countryCode} onChange={setCountryCode} />
          <Input label="Mobile No." placeholder="Enter mobile number" value={mobileNumber} onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, "");
            if (value.length <= 10) {
              setMobileNumber(value);
            }
          }} maxLength={10} /> */}
          <MobileInput
            label="Mobile No."
            countryCode={countryCode}
            mobileNumber={mobileNumber}
            onCountryChange={setCountryCode}
            onMobileChange={setMobileNumber}
            placeholder="Enter mobile number"
            countries={[
              { code: "IN", dialCode: "+91" },
              { code: "US", dialCode: "+1" },
              { code: "UK", dialCode: "+44" },
            ]}
          />
          <Input label="Client File Id" placeholder="Enter client file id" value={clientFileId} onChange={(e) => setClientFiledId(e.target.value)} />

        </div>
        {showUpload && (
          <div style={styles.uploadContainer} onClick={handleUploadClick} onDrop={(e) => e.preventDefault()}>
            <div style={styles.uploadBox} onDragOver={(e) => e.preventDefault()}>
              <span style={styles.uploadCircle}>
                <img src={pluseIcon} alt="Plus Icon" style={styles.iconImage} />
              </span>
              <p style={styles.uploadText}>Upload Form 16 for faster onboarding, Drop here or click on upload</p>
              <img src={uploadIcon} alt="Upload Icon" style={styles.uploadIconImage} />

              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                style={styles.fileInput}
                onChange={handleFileChange}
              />
            </div>
          </div>
        )}
        <div className="existing-client-checkbox">
          <label style={styles.exisitingHeading}>Existing Client?</label>
          {/* <input type="checkbox" checked={existingClient} onChange={(e) => setExistingClient(e.target.checked)} /> */}
          <CheckBox
            label=""
            checked={existingClient}
            onChange={(isChecked) => setExistingClient(isChecked)}
          />
        </div>
        <hr className="footer-divider" />

        {error && <div className='errorBox'>{error}</div>}

        <div className="button-container">
          <button className="add-client-button" onClick={handleAddClient} disabled={loading}><span style={{ paddingRight: loading ? 10 : 0 }}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Add Client</button>
          <button className="cancel-button" onClick={() => { handleClose(); }}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  uploadContainer: {
    marginTop: '20px',
    width: '97%',
  },
  uploadBox: {
    width: '100%',
    border: '1px dashed #8FC7F2',
    borderRadius: '10px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    position: 'relative', // Position relative to contain the hidden input
  },
  uploadCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
  },
  plusIcon: {
    fontSize: '16px',
    color: '#333',
  },
  uploadText: {
    flex: 1,
    color: '#262626',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  uploadArrow: {
    fontSize: '18px',
    color: '#555',
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
  iconImage: {
    width: '15px',
    height: '15px',
  },
  uploadIconImage: {
    width: '25px',
    height: '25px',
    marginRight: '5px'
  },
  exisitingHeading: {
    color: '#343C44',
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'Roboto'
  },
  errorBox: {
    color: "red",
    padding: "10px",
    marginBottom: "20px",
    fontSize: "12px",
  },
}

export default AddClientModal;
