import React, { useState } from "react";
import Input from "../../../../Components/Input"; // Import Input Component
import Dropdown from "../../../../Components/Dropdown"; // Import Dropdown Component
import CheckBox from "../../../../Components/CheckBox";
import closeIcon from '../../../../Assets/Icons/X circle.png'
import DateInput from '../../../../Components/DateInput';
import TextInput from "../../../../Components/TextInput";
import backIcon from '../../../../Assets/Icons/back.png'
import { useNavigate } from "react-router-dom";
import GenerateEditReceiptController from "../../../Controllers/AdminScreens/Invoices/GenerateEditReceiptController";

const GenerateEditReceiptScreen = () => {

  const {
    paymentDetails,
    setPaymentDetails,
    handlePaymentDetailsChange,
    invoiceDetails,
    setInvoiceDetails,
    handleInvoiceDetailsChange,
    sendUpdateToClient,
    setSendUpdateToClient,
    sendUpdateToTeam,
    setSendUpdateToTeam,
    invoiceInformation,
    setInvoiceInformation
  } = GenerateEditReceiptController()
  const navigate = useNavigate()
  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };
  const [isEditable, setIsEditable] = useState(false);
  const [clientName, setClientName] = useState("DLS INDIA Corp");
  const [modeOfPayment, setModeOfPayment] = useState("Cash");
  const [collectedBy, setCollectedBy] = useState("Username");
  const [followUpDate, setFollowUpDate] = useState("11/11/2024");
  const [receiptBook, setReceiptBook] = useState("Username");
  const [amountReceived, setAmountReceived] = useState("1000.00");
  const [advancePayment, setAdvancePayment] = useState("0.00");
  const [balance, setBalance] = useState("1000.00");


  return (
    <>
      <div style={{ width: '93%', margin: 'auto' }}>
        <h3 style={styles.headerTop}>
          <div style={styles.headerContainer} >
            <img
              src={backIcon}
              alt="Back"
              style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
              onClick={handleBack}
            />
            <span>Generate Receipt</span>
          </div>
        </h3>
      </div>

      <div style={styles.container}>
        <h2 style={styles.title}>Generate Receipt</h2>

        {/* Basic Information */}
        <h3 style={{ ...styles.sectionTitle, marginBottom: "12px" }}>Invoice Information</h3>
        <div style={styles.row}>
          <div style={styles.staticField}>
            <label style={styles.label}>Client Name</label>
            <span style={styles.staticValue}>{invoiceInformation?.clientName}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Client Type</label>
            <span style={styles.staticValue}>{invoiceInformation?.clientType}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Date of Invoice</label>
            <span style={styles.staticValue}>{invoiceInformation?.dateOfInvoice}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Invoice Number</label>
            <span style={{...styles.staticValue, textDecoration:'underline', color:'#5A55D2'}}>{invoiceInformation?.invoiceNumber}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Combined Bill</label>
            <span style={styles.staticValue}>{invoiceInformation?.combinedBill}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Group Invoice</label>
            <span style={styles.staticValue}>{invoiceInformation?.groupInvoice}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Task Status</label>
            <span style={styles.staticValue}>{invoiceInformation?.taskStatus}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Invoice Generated By</label>
            <span style={styles.staticValue}>{invoiceInformation?.invoiceGeneratedBy}</span>
          </div>
        </div>

        {/* Editable Fields Section */}
        <div style={styles.inputRow}>
          <Dropdown
            label="Mode of Payment"
            options={["Cash", "Card", "Online"]}
            value={paymentDetails?.modeOfPayment}
            onChange={(e) => handlePaymentDetailsChange("modeOfPayment", e.target.value)}
          />
          <Dropdown
            label="Collected By"
            options={["Username", "Staff A", "Staff B"]}
            value={paymentDetails?.collectedBy}
            onChange={(e) => handlePaymentDetailsChange("collectedBy", e.target.value)}
          />
          {/* <Input
          label="Follow Up Date"
          placeholder="Follow Up Date"
          value={followUpDate}
          onChange={(e) => setFollowUpDate(e.target.value)}
        /> */}
          <div className="filter-field" style={{ marginBottom: "15px" }}> {/* Add margin-bottom here */}
            <label style={{ ...styles.labaleStyle,  display: "block" }}>
              Follow Up Date
            </label>
            <DateInput
              value={paymentDetails?.followUpDate}
              onChange={(e) => handlePaymentDetailsChange("followUpDate", e.target.value)}
              style={{ padding: "20px 10px" }}
            />
          </div>
          <Dropdown
            label="Receipt Book"
            options={["Username", "Book A", "Book B"]}
            value={paymentDetails?.receiptBook}
            onChange={(e) => handlePaymentDetailsChange("receiptBook", e.target.value)}
          />
        </div>

        {/* Payment Details Section */}
        <h3 style={styles.header}>Payment Details</h3>

        <div style={styles.paymentDetails}>
          {/* Invoice Amount */}
          <div style={styles.sameRow}>
            <label style={styles.paymentLabel}>Invoice Amount</label>
            <span style={styles.amountvalue}>{invoiceDetails?.invoiceAmount}</span>
          </div>

          {/* Amount Received */}
          <div style={{...styles.sameRow, justifyContent:'center', alignItems:'center'}}>
            <label style={styles.paymentLabel}>Amount Received</label>
            <TextInput
              placeholder="Amount Received"
              value={invoiceDetails?.amountReceived}
              onChange={(e) =>
                handleInvoiceDetailsChange("amountReceived", e.target.value)
              }
              style={styles.input}
            />
          </div>

          {/* Advance Payment */}
          <div style={{...styles.sameRow, justifyContent:'center', alignItems:'center'}}>
            <p style={styles.paymentLabel}>Advance Payment</p>
            <TextInput
              placeholder="Advance Payment"
              value={invoiceDetails?.advancePayment}
              onChange={(e) =>
                handleInvoiceDetailsChange("advancePayment", e.target.value)
              }
              style={styles.input}
            />
          </div>

          {/* Balance */}
          <hr
            className="title-divider"
            style={{ width: "40%", margin: "10px auto 10px 0px" }}
          />
          <div style={{ ...styles.sameRow, columnGap: '35px', marginBottom: '15px', marginTop: '15px' }}>
            <label style={styles.paymentLabel}>Balance</label>
            <span style={styles.amountvalue}>{invoiceDetails?.balance}</span>
          </div>
          <hr
            className="title-divider"
            style={{ width: "40%", margin: "10px auto 10px 0px" }}
          />
        </div>

        <div style={{ marginTop: '18px' }}>
          <div style={{ marginBottom: '15px', display: "flex", alignItems: "center", }}>
            {/* <input type="checkbox" id="sendUpdateClient" checked={sendUpdateToClient}
              onChange={(e) => setSendUpdateToClient(e.target.checked)} />
            <label htmlFor="sendUpdateClient" style={styles.checkboxLabel}>
              Send Update to Client
            </label> */}
            <CheckBox
              label="Send Update to Client"
              checked={sendUpdateToClient}
              onChange={(isChecked) => setSendUpdateToClient(isChecked)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", }}>
            {/* <input type="checkbox" id="sendUpdateTeam" checked={sendUpdateToTeam}
              onChange={(e) => setSendUpdateToTeam(e.target.checked)} />
            <label htmlFor="sendUpdateTeam" style={styles.checkboxLabel}>
              Send Update to Team
            </label> */}
            <CheckBox
              label="Send Update to Team"
              checked={sendUpdateToTeam}
              onChange={(isChecked) => setSendUpdateToTeam(isChecked)}
            />
          </div>
        </div>

        {/* Footer Buttons */}
        <div className="button-container" style={{ marginTop: '32px' }}>
          <button className="add-client-button" style={{ paddingLeft: '30px', paddingRight: '30px',marginRight:'10px' }}>Generate Receipt</button>
          <button className="add-client-button" style={{ ...styles.addServiceButton,paddingLeft: '30px', paddingRight: '30px' }}>Print</button>
          <button className="cancel-button" >Cancel</button>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "90%",
    margin: "auto",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    marginBottom:'50px'
  },
  title: {
    fontSize: "28px",
    marginBottom: "23px",
    fontWeight: "500",
    color: "#1F384C",
    // fontFamily: "Roboto Slab"
  },
  section: {
    marginBottom: "21px",
  },
  sectionTitle: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#609966",
    fontFamily: 'Roboto Slab'
  },
  estimatedRow: {
    padding: '16px 11px',
    display: "flex",
    flexWrap: "wrap",
    columnGap: "70px",
    rowGap: "15px",
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
    paddingBottom: '63px'
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
  },
  checkboxLabel: {
    fontSize: "12px",
    color: "#343C44",
    fontFamily: 'Roboto',
    fontWeight: '400'
  },
  serviceRow: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "15px 70px", // Gap between two elements in the same row
    marginBottom: "15px", // Space between rows
    flexWrap: "wrap",
  },
  dropdownWithClose: {
    display: "flex",
    alignItems: "center", // Vertically aligns the dropdown and close icon
    gap: "26px", // Gap between dropdown and close icon
  },
  closeButton: {
    width: '15px',
    height: '15px',
    cursor: "pointer",
    verticalAlign: "middle",
    objectFit: "contain",
  },
  addServiceButton: {
    backgroundColor: "transparent",
    border: "1px #858585 solid",
    borderRadius: "5px",
    padding: "8px 12px",
    color: "#40513B",
    cursor: "pointer",
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    borderRadius: '28px',
    // marginBottom:'16px'
  },
  removeButton: {
    backgroundColor: "transparent",
    border: "none",
    color: "#f00",
    fontSize: "16px",
    cursor: "pointer",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  createButton: {
    backgroundColor: "#609966",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  servicesContainer: {
    // display: "flex",
    // flexWrap: "wrap", // Allows items to wrap to the next row if needed
    // gap: "20px", // Space between rows and items
    padding: '16px 11px',
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
  },
  mainContainer: {
    display: "flex",
    gap: "15px",
    flexWrap: "wrap",
    // overflowX: "auto",
    // whiteSpace: "nowrap",
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap', // Allow wrapping to the next row
    columnGap: '70px', // Gap between items (both horizontal and vertical)
    rowGap: '15px',
    padding: '16px 11px',
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
    justifyContent: 'space-between', // Distribute items evenly
  },

  staticField: {
    flex: '1 1 calc(25% - 70px)', // Adjusts for 4 items per row with gaps
    minWidth: '200px', // Minimum width of each item
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  label: {
    fontSize: "12px",
    marginBottom: "2px",
    color: "#767676",
    fontFamily: 'Roboto',
    fontWeight: '400'
  },
  staticValue: {
    fontSize: "14px",
    fontWeight: "400",
    color: '#060606',
    fontFamily: 'Roboto'
  },
  inputRow: {
    display: 'flex',
    flexWrap: 'wrap', // Allow wrapping to the next row
    columnGap: '70px', // Gap between items (both horizontal and vertical)
    // rowGap:'15px',
    padding: '16px 0px',
    // border: '1px #EFF0F4 solid',
    // borderBottom: '2px #060606 solid',
    justifyContent: 'space-between', // Distribute items evenly
  },
  header: {
    marginBottom: "36px",
    marginTop: '30px',
    color: "black",
    fontSize: '18px',
    fontWeight: '500',
    fontWeight: 'Roboto Slab'
  },
  sameRow: {
    display: 'flex',
    columnGap: '30px',
    marginBottom: '30px',
    // width: "100%",
  },
  paymentDetails: {
    // padding: "20px",
    display: "flex",
    flexDirection: "column",
  },
  paymentLabel: {
    fontSize: "14px",
    marginBottom: "2px",
    color: "black",
    fontFamily: 'Roboto',
    fontWeight: '400',
    whiteSpace: "nowrap",
    flex: "0 0 110px",
  },
  amountvalue: {
    fontSize: "18px",
    color: "black",
    fontFamily: 'Roboto Slab',
    fontWeight: '700'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center', // Vertically align items
  },
  headerTop: {
    fontSize: "22px",
    color: "#060606",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: 'Montserrat',
    //   marginLeft:'20px'
  },
  leftHeader: {
    marginTop: '-5px'
  },
  labaleStyle: {
    fontSize: '12px',
    // marginBottom: '5px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: 'rgb(52, 60, 68)'
  },
  addServiceButton: {
    backgroundColor: "transparent",
    border: "1px #858585 solid",
    borderRadius: "5px",
    padding: "8px 12px",
    color: "#40513B",
    cursor: "pointer",
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    borderRadius: '28px',
    // marginBottom:'16px'
  },
};

export default GenerateEditReceiptScreen;
