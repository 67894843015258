import React, { useState } from 'react';

const NotificationRemindersScreen = () => {

    return (
        <div style={{alignItems:'center', minHeight: 400, textAlign: 'center', marginTop:'30'}}>Notification & Reminders</div>
    )

};

export default NotificationRemindersScreen;