import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../../../Components/Tabs'
import EmailContactList from './EmailContactList';
import WhatsAppContactList from './WhatsAppContactList';
import SMSContactList from './SMSContactList';


const ContactsCenterScreen = () => {

    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(state?.selectedTab || 0);

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    // const {

    // } = InvoiceSettingsController()

    return (
        <div style={styles.mainContent}>
            <div className='title-container' style={{paddingBottom:10}}>
                <h2 className='title'>Contacts Center</h2>
            </div>

            <div >
            <Tabs tabs={['WhatsApp','Email','SMS',]}
            selectedIndex={selectedTab}
            onTabChange={handleTabChange}>
                    <WhatsAppContactList />
                    <EmailContactList />
                    <SMSContactList />
                </Tabs>
            </div>

        </div>


    )

};

const styles = {
    mainContent: {
        width: '95%',
        margin: 'auto'
    }

}

export default ContactsCenterScreen;