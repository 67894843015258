import React, { useState } from 'react';
import Tabs from '../../../../Components/Tabs';
import UserPrimaryInformationScreen from './UserPrimaryInformationScreen';
import UserAccessScreen from './UserAccessScreen';
import UserAssignmentsScreen from './UserAssignmentsScreen';
import UserSpecializationScreen from './UserSpecializationScreen';
import backIcon from '../../../../Assets/Icons/back.png';
import ManageUserController from '../../../Controllers/AdminScreens/Users/ManageUserController';
import { useLocation } from 'react-router-dom';
import Toggle from '../../../../Components/Toggle';
import playArrowIcon from '../../../../Assets/Icons/play_arrow_filled.png';
import LoadingSkeleton from '../../../../Components/LoadingSkeleton';


const ManageUserScreen = () => {
    const location = useLocation();
    const { isUserEditable } = location.state || {};
    const [selectedTab, setSelectedTab] = useState(location?.selectedTab || 0);

    console.log('isUserEditable @@@ ', isUserEditable)

    const { handleBack,
        userToggleState,
        handleUserToggleChange,
        loading,
        error,
        userData
    } = ManageUserController();

    console.log('userToggleState @', userToggleState)

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };


    return (
        <div style={styles.container}>
            {/* Header Section */}
            <div style={styles.headerContainer}>
                <img
                    src={backIcon}
                    alt="Back"
                    style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
                    onClick={handleBack}
                />
                <span style={styles.header}>Manage Employee</span>
            </div>
            {/* User Section */}
            <div >
                <div style={{ ...styles.userInfo }}>
                    <div className="company-name" style={{ marginRight: 15 }}>{userData?.emp_name} {loading && <LoadingSkeleton width="40%" height="10px" />}</div>
                    <Toggle initialStatus={userToggleState} onToggle={handleUserToggleChange} size='big' />
                    <div style={{ ...styles.busyworkborder, marginLeft: 15 }}>
                        <img
                            src={playArrowIcon}
                            alt="Back"
                            style={{ width: '15px', height: '15px', cursor: 'pointer', marginRight: '10px' }}
                        />
                        Free to work
                    </div>
                </div>
                <div style={{ ...styles.userInfo, width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom:10}}>
                    <div className="company-details">Accountant | Manager Name | Team Name</div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <span>Contact User : Satish@example.com</span>
                        <span style={{ alignSelf: 'end' }}>98900 98900</span>
                    </div>
                </div>
            </div>

            {/* Tab Section */}
            <div>
                <Tabs tabs={['Primary Information', 'Speciallization', 'Assignments', 'User Access']}
                selectedIndex={selectedTab}
                onTabChange={handleTabChange}>
                    <UserPrimaryInformationScreen isUserEditable={isUserEditable} userInfo={userData} loading={loading} error={error}/>
                    <UserSpecializationScreen />
                    <UserAssignmentsScreen />
                    <UserAccessScreen />
                </Tabs>
            </div>

        </div>
    )

};

const styles = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    header: {
        fontSize: "22px",
        color: "#060606",
        fontWeight: 500,
        fontFamily: 'Montserrat',
        letterSpacing: 0.22
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
        marginBottom: '20px'
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'row',
    },
    busyworkborder: {
        border: '1px #40513B solid',
        borderRadius: '20px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "white",
        color: "#40513B",
        padding: "5px 8px",
        cursor: "pointer",
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        height: '20px',
        alignSelf: 'center'
    }
}

export default ManageUserScreen;