import React, { useState, useRef } from 'react';
import UploadClientsListFileController from '../../../../Controllers/AdminScreens/Services/Settings/UploadClientsListFileController';
import downArrow from '../../../../../Assets/Icons/chevron-down.png';
import plusIcon from '../../../../../Assets/Icons/Pluse.png';
import uploadIcon from '../../../../../Assets/Icons/upload.png';
import { useNavigate } from 'react-router-dom';

const UploadClientsListFileScreen = () => {
    const navigate = useNavigate();

    const {
        tasks,
        currentPage,
        totalPages,
        handlePageChange,
    } = UploadClientsListFileController();

    const fileInputRef = useRef(null);

    const handleFileUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log("Uploaded file:", file.name);
        }
    };


    return (
        <>
            <div>
                <h2 className="tasksScreen-tasks-title">Import Clients</h2>
            </div>
            <div
                style={{
                    position: "relative",
                    border: "1px dashed #9DC08B",
                    borderRadius: "10px",
                    padding: "35px",
                    textAlign: "center",
                    backgroundColor: "#fff",
                    marginBottom: "50px",
                    marginTop: "15px",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        cursor: "pointer",
                    }}
                    onClick={handleFileUploadClick}
                >
                    <img src={uploadIcon} alt="Upload Icon" style={{ width: "20px", height: "20px" }} />
                </div>
                <div onClick={handleFileUploadClick} style={{ cursor: "pointer" }}>
                    <div
                        style={{
                            width: "60px",
                            height: "60px",
                            margin: "auto",
                            backgroundColor: "#F2F2F2",
                            borderRadius: "25%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img src={plusIcon} alt="Plus Icon" style={{ width: "30px", height: "30px", filter: 'brightness(0)' }} />
                    </div>
                    <p style={{ margin: "10px 0", color: "#262626", fontWeight: '500', fontSize: '14px' }}>
                        Drag or Upload CSV file to import clients, max size 2 MB
                    </p>
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                />
            </div>
            <div className="tasksScreen-tasks-header">
                <h2 className="tasksScreen-tasks-title" style={{ fontSize: "18px" }}>Upload History</h2>
            </div>

            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>

                        {/* Table Headers */}
                        <tr>
                            <th style={{ width: "30%", }}>Upload Item</th>
                            <th style={{ width: "45%", }}>Date</th>
                            <th style={{ width: "15%", }}>Imported By</th>
                            <th style={{ width: "15%", }}>Status</th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#fff' }}>
                        {tasks.map((task, index) => (
                            <tr key={index}>
                                <td
                                    style={{
                                        textDecoration: "underline",
                                        color: "#5A55D2",
                                        cursor: "pointer",
                                    }}
                                >
                                    {task.clientName}
                                </td>
                                <td>{task.description || "--"}</td>
                                <td>{task.assignedTo}</td>
                                <td>
                                    <span
                                        style={{
                                            display: "inline-block",
                                            padding: "5px 10px",
                                            borderRadius: "15px",
                                            // backgroundColor: task.status === "Completed" ? "#E8F5E9" : "#FFEBEE",
                                            backgroundColor: task.status === "Completed" ? "#C7C4C32E" : "#C7C4C32E",
                                            // color: task.status === "Completed" ? "#43A047" : "#E53935",
                                            color: task.status === "Completed" ? "#7B7B7B" : "#7B7B7B",
                                        }}
                                    >
                                        {task.status}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="tasksScreen-table-footer">
                <span className="tasksScreen-pagination-info">
                    {`${currentPage}-${totalPages} of items`}
                </span>
                <div className="tasksScreen-pagination">
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
                    </button>
                    {[...Array(totalPages)].map((_, index) => (
                        <button key={index} className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    ))}
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                        <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
                    </button>
                </div>
            </div>
        </>
    );
};


export default UploadClientsListFileScreen;
