// InputComponent.js
import React from 'react';

const Input = ({ label, placeholder, value, onChange,width=200 }) => {
  return (
    <div style={styles.container}>
      <label style={styles.label}>{label}</label>
      <input
        type="text"
        placeholder={placeholder}
        style={{...styles.input,width:width}}
        value={value}       // Controlled input
        onChange={onChange} // Controlled input handler
      />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    // marginRight: '20px',
  },
  label: {
    fontSize: '12px',
    marginBottom: '5px',
    color:'#343C44',
    fontWeight:400,
    fontFamily:'Roboto'
  },
  input: {
    // maxWidth: '200px',
    // minWidth:'200px',
    height: '25px',
    borderRadius: '5px',
    border: '1px solid #d1e3ed',
    padding: '5px 10px',
    fontSize: '14px',
    padding:'10px'
  },
};

export default Input;
