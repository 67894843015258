import React, { useState, useEffect } from "react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import closeIcon from "../../Assets/Icons/close.png";

const HoldTaskIssuesModal = ({ isOpen, onClose }) => {
    const [comments, setComments] = useState("");
    const [task, setTask] = useState("");

    const taskOptions = ["Hold Task", "Task Issue"];

    useEffect(() => {
        if (!isOpen) {
            resetForm();
        }
    }, [isOpen]);

    const resetForm = () => {
        setComments("");
        setTask("");
    };

    const handleAddCallType = () => {
        const newCallType = {
            comments,
            task,
        };
        console.log("New Call Type:", newCallType);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" style={styles.closeIcon} />
                </button>
                <h2 className="modal-title">Hold or Task Issues</h2>
                <hr className="title-divider" />
                <div className="form-grid" style={{ gridTemplateColumns: '1fr' }}>
                    <Dropdown
                        label="Select"
                        options={taskOptions}
                        value={task}
                        onChange={setTask}
                    />
                    <div style={{ ...styles.actionField }}>
                        <label style={styles.label}>Comments</label>
                        <textarea placeholder="Enter comments here..." style={styles.textarea}></textarea>
                    </div>
                </div>
                <hr className="footer-divider" />
                <div className="button-container">
                    <button className="add-client-button" onClick={handleAddCallType}>
                        Add Comment
                    </button>
                    <button className="cancel-button" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    closeIcon: {
        width: "16px",
        height: "16px",
    },
    actionField: {
        display: "flex",
        flexDirection: "column",
    },
    label: {
        fontSize: "12px",
        fontWeight: "500",
        marginBottom: "8px",
        color: "#343C44",
    },
    textarea: {
        padding: "10px",
        border: "1px solid #D6E4EC",
        borderRadius: "5px",
        resize: "none",
        height: "87px",
        fontSize: "14px",
        color: "#333",
        width: '575px'
    },
};

export default HoldTaskIssuesModal;
