import React, { useState } from "react";
import closeIcon from '../../Assets/Icons/close.png';
import Dropdown from '../Dropdown';
import CheckBox from "../CheckBox";

const ManageUserAccessModal = ({ isOpen, onClose }) => {

    const subModules = [
        "GSTR ",
        "Income Tax",
        "Audit",
        "Registrations",
        "Company Related",
        "TDS",
        "Misc.Services",
        "Accounting",
        "Settings",
    ];

    const accessLevelOptions = ["Manager A", "Manager B", "Manager C"];
    const [accessLevel, setAccessLevel] = useState('');

    const [selSubModules, setSelSubModules] = useState([]);

    const handleCheckboxChange = (subModule) => {
        setSelSubModules((prev) =>
            prev.includes(subModule)
                ? prev.filter((item) => item !== subModule)
                : [...prev, subModule]
        );
    };

    const handleUpdate = () => {
        console.log(" handleUpdate selSubModules:", selSubModules);
        onClose(); // Close the modal
    }

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content" style={{ width: '664px' }}>
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" style={styles.closeIcon} />
                </button>
                <h2 className="modal-title">Manage Access</h2>
                <hr className="title-divider" />

                <div style={{ marginBottom: "20px" }}>
                    <label style={styles.label}>Module Name</label>
                    <div style={styles.categoryValue}>Services</div>
                </div>

                <div style={{ marginBottom: "20px" }}>
                    <label style={styles.label}>Select Sub Modules</label>
                    <div style={styles.specializationsGrid}>
                        {subModules.map((subModule, index) => (
                            <div key={index} style={styles.specializationItem}>
                                <label style={styles.labelName}>
                                    {/* <input
                                        type="checkbox"
                                        checked={selSubModules.includes(subModule)}
                                        onChange={() => handleCheckboxChange(subModule)}
                                        style={styles.checkbox}
                                    />
                                    <span>{subModule}</span> */}
                                    <CheckBox
                                        label={subModule}
                                        checked={selSubModules.includes(subModule)}
                                        onChange={(isChecked) => handleCheckboxChange(subModule, isChecked)}
                                        style={styles.checkbox}
                                    />
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <Dropdown
                        label={'Access Level'}
                        options={accessLevelOptions}
                        value={accessLevel}
                        onChange={setAccessLevel}
                    />
                </div>

                <hr className="footer-divider" />

                <div className="button-container">
                    <button className="add-client-button" onClick={handleUpdate}>
                        Update
                    </button>
                    <button className="cancel-button" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )

};

const styles = {
    closeIcon: {
        width: "16px",
        height: "16px",
    },
    label: {
        display: "block",
        fontWeight: "400",
        marginBottom: "10px",
        color: "#767676",
        fontSize: "12px",
    },
    categoryValue: {
        color: "#060606",
        fontSize: "14px",
        fontWeight: "400",
    },
    specializationsGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(143px, 1fr))",
        gap: "10px",
    },
    specializationItem: {
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        color: "#343C44",
    },
    checkbox: {
        marginRight: "10px",
        color: "#343C44",
    },
    labelName: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

export default ManageUserAccessModal;