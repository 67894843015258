
import React, { useState } from 'react';
const GenerateInvoiceController = () => {

    const [sendUpdateToClient, setSendUpdateToClient] = useState(false);
    const [sendUpdateToTeam, setSendUpdateToTeam] = useState(false);

    const invoiceTableData = [
        {
            taskName: "GST Filling",
            financialYear: "2023-24",
            assignedTo: "Yearly",
            taskLeader: "Yearly",
            startDate: "11/11/2024",
            etd: "11/11/2024",
            status: "In Progress"
        },
        {
            taskName: "Company Reg",
            financialYear: "2023-24",
            assignedTo: "Yearly",
            taskLeader: "Yearly",
            startDate: "11/11/2024",
            etd: "11/11/2024",
            status: "Not Started"
        },
        {
            taskName: "Company Registration",
            financialYear: "2023-24",
            assignedTo: "Yearly",
            taskLeader: "Yearly",
            startDate: "11/11/2024",
            etd: "11/11/2024",
            status: "Priority"
        },
        {
            taskName: "ITR",
            financialYear: "2023-24",
            assignedTo: "Yearly",
            taskLeader: "Yearly",
            startDate: "11/11/2024",
            etd: "11/11/2024",
            status: "Priority"
        },
        {
            taskName: "Retention Charges",
            financialYear: "2023-24",
            assignedTo: "Monthly",
            taskLeader: "Monthly",
            startDate: "11/11/2024",
            etd: "11/11/2024",
            status: "Priority"
        }
    ]

    const [basicInformation, setBasicInformation] = useState({
        clientName: "",
        groupName: "",
        phoneNo: "",
        dateOfEstimate: "",
        countryCode: "",
    });

    const handleBasicInformationChange = (field, value) => {
        setBasicInformation((prevInfo) => ({
            ...prevInfo,
            [field]: value,
        }));
    };

    const [invoiceDetails, setInvoiceDetails] = useState({
        invoiceType: "",
        noTaxInvoices: "",
        generateSeperateInvoices: "",
        estimateNumber: "",
        advancePayments: "",
        systemInvoicingAmount: "",
        invoiceAmount: "",
        taskCompletedBy: "",
        generatedBy: "",
        organization: "",
        receiptBook: "",
        followUpDate: "",
    });

    const handleInvoiceDetailsChange = (field, value) => {
        setInvoiceDetails((prevDetails) => ({
            ...prevDetails,
            [field]: value,
        }));
    };


    return {
        basicInformation,
        setBasicInformation,
        handleBasicInformationChange,
        invoiceTableData,
        invoiceDetails,
        setInvoiceDetails,
        handleInvoiceDetailsChange,
        sendUpdateToClient,
        setSendUpdateToClient,
        sendUpdateToTeam,
        setSendUpdateToTeam,
    }
}

export default GenerateInvoiceController