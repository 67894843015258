import { useState,useEffect } from 'react';
import { validateEmail, validateNumber } from '../../../Utils/Validation';
import { useTheme } from '../../../GlobalContext/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { getData,postData } from '../../../ApiServices/ApiService';
import { ORG_GET_SERVICE_LIST,ORG_GET_SUB_SERVICE_LIST,ORG_GET_GROUP_LIST,
  ORG_GET_CLIENT_LIST,ORG_GET_ALL_CODES_LIST,ORG_ADD_TASK } from '../../../ApiServices/BaseURL';

const CreateTaskController = () => {
  const [fileName, setFileName] = useState("");

  const [formData, setFormData] = useState({
    clientName: {}, // Text input for Client Name
    services: {}, // Multiselect dropdown for Services
    taskCategory: {}, // Dropdown for Task Category
    group: {}, // Dropdown for Group
    primaryAssignedUser: '', // Dropdown for Primary Assigned User
    taskLeader: '', // Dropdown for Task Leader
    taskManager: '', // Dropdown for Task Manager
    financialYear: {}, // Dropdown for Financial Year
    period: {}, // Text input for Period
    billAmount: '', // Text input for Bill Amount
    agreedBillAmount:'',
    fileNo: '', // Text input for File Number
    taskDescription: '', // Textarea for Task Description
    startDate: '', // Date Picker for Start Date
    dueDate: '', // Date Picker for Due Date
    taskPriority: {label:'Not Priority',value:0}, // Dropdown for Task Priority
    toDoList: '', // Multiselect dropdown for To-Do List
    completedBy: '', // Dropdown for Completed By
    allowTimePosting: false, // Checkbox for Time Posting
    documents: null
  });

  const [clientList,setClientList] = useState([])
  const [serviceList,setServiceList] = useState([]);
  const [categoryList,setCategoryList] = useState([])
  const [groupList,setGroupList] = useState([]);
  const [yearList,setYearList] = useState([]);
  const [periodList,setPeriodList] = useState([]);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('');

  useEffect(()=>{
    setError("");
    fetchServiceList();
    fetchGroupList();
    fetchClientList();
    fetchYearList();
  },[]);

  const fetchServiceList =async ()=>{
    try {
        setLoading(true);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const response = await getData(`${ORG_GET_SERVICE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                 // Transforming the data
                 const serviceOptions = await responseData?.dataJ.map(service => ({
                  label: service.service_name,
                  value: service.service_id
                }));
                setServiceList(serviceOptions);
              } else {
                setError(responseData?.info || 'Data Not Found');
              }
            } else {
              setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setError(`Failed to fetch Task Categorie data: ${String(error)}`);
        }
      } finally {
        setLoading(false);
      }
  }

  const fetchCategoryList =async (setviceId)=>{
    try {      
      setCategoryList([])
        setLoading(true);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const response = await getData(`${ORG_GET_SUB_SERVICE_LIST}?service_id=${setviceId}&emp_id_auth=${storedUserInfo?.emp_id}`);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                 // Transforming the data
                 const serviceOptions = await responseData?.dataJ.map(service => ({
                  label: service.service_sub_name,
                  value: service.service_sub_id
                }));
                setCategoryList(serviceOptions);
              } else {
                setError(responseData?.info || 'Data Not Found');
              }
            } else {
              setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setError(`Failed to fetch Task Categorie data: ${String(error)}`);
        }
      } finally {
        setLoading(false);
      }
  }

  const fetchGroupList =async ()=>{
    try {
        setLoading(true);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const response = await getData(`${ORG_GET_GROUP_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                  // Transforming the data
                  const groupOptions = await responseData?.dataJ.map(service => ({
                    label: service.group_name,
                    value: service.group_id
                  }));
                setGroupList(groupOptions);
              } else {
                setError(responseData?.info || 'Data Not Found');
              }
            } else {
              setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setError(`Failed to fetch Task Categorie data: ${String(error)}`);
        }
      } finally {
        setLoading(false);
      }
  }

  const fetchClientList =async ()=>{
    console.log('get client')
    try {
        setLoading(true);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const response = await getData(`${ORG_GET_CLIENT_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                   // Transforming the data
                   const clientOptions = await responseData?.dataJ.map(service => ({
                    label: service.client_name,
                    value: service.client_id
                  }));
                setClientList(clientOptions);                  
              } else {
                setError(responseData?.info || 'Data Not Found');
              }
            } else {
              setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setError(`Failed to fetch Task Categorie data: ${String(error)}`);
        }
      } finally {
        setLoading(false);
      }
  }

  const fetchYearList =async ()=>{
    try {
        setLoading(true);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const payLoad = {"code_id": "org_all_codes"}
        const response = await postData(ORG_GET_ALL_CODES_LIST,payLoad);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ) {               
                      // Transforming the data
                     const yerarListOption = await responseData?.dataJ?.financial_year_codes_list.map(doc => {
                        const [key, value] = Object.entries(doc)[0]; // Extract the first key-value pair
                        return { label: value, value: key };
                    });
                    setYearList(yerarListOption)             
                        // Transforming the data
                        const periodOption = await responseData?.dataJ?.financial_period_codes_list.map(doc => {
                          const [key, value] = Object.entries(doc)[0]; // Extract the first key-value pair
                          return { label: value, value: key };
                      });
                      setPeriodList(periodOption)     
              } else {
                setError(responseData?.info || 'Data Not Found');
              }
            } else {
              setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setError(`Failed to fetch Task Categorie data: ${String(error)}`);
        }
      } finally {
        setLoading(false);
      }
  }


  const handleChange = (name, value) => {
    // const { name, value } = e.target;
    console.log('name:----',name,'value:-----', value)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if(name === 'services' && value){
      fetchCategoryList(value.value);
    }else{
      console.log('name not valid')
    }
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      documents: e.target.files[0],
    }));
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name); // Set the selected file name
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setError("");

    if (!validateNumber(formData.billAmount)) {
      setError("Invalid Bill Amount.");
      return false;
    }

    if (!validateNumber(formData.agreedBillAmount)) {
      setError("Invalid Agreed Bill Amount.");
      return false;
    }
    // Submit form data to your backend API
    console.log('Form Data Submitted:', formData);
    try {
      setError('');
     
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "client_id": formData?.clientName?.value,
        "service_id": formData?.services?.value,
        "service_sub_id": formData?.taskCategory?.value,
        "task_description": formData?.taskDescription,
        "task_start_date": formData?.startDate,
        "task_due_date": formData?.dueDate,
        "task_priority": formData?.taskPriority?.value,
        "task_bill_amount": formData?.agreedBillAmount,
        "task_bill_amount_estimated": formData?.billAmount,
        "financial_year": formData?.financialYear?.value,
        "financial_period": formData?.period?.value,
        "employee_id_assigned": storedUserInfo?.emp_id
      }
  
      console.log('catagory Data',payLoad);
      // Make the API call
      const response = await postData(ORG_ADD_TASK,payLoad);
      const responseData = await response.json();
      console.log('response Data',responseData);
  
      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setError(responseData?.info);
           
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
        }
      } 
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } 
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      } 
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data: ${String(error)}`);
      }
    } finally {
      setLoading(false);
    }

  };



  return {
    formData,
    fileName,
    handleFileChange,
    handleSubmit,
    handleChange,
    serviceList,
    categoryList,
    groupList,
    clientList,
    yearList,
    periodList,
    error,
    loading
  };
};

export default CreateTaskController;
