import React, { useState } from "react";
import leftArrowBtsIcon from '../../../../Assets/Icons/left-btn.png'
import Dropdown from "../../../../Components/Dropdown";
import GstServiceController from "../../../Controllers/AdminScreens/Services/GstServiceController";

// Sample JSON data
// const data = {
//   taskOverview: [
//     { label: "Completed", value: 1203 },
//     { label: "Pending", value: 21 },
//     { label: "Priority", value: 4 },
//     { label: "Long Due", value: 4 },
//     { label: "Not Started", value: 34 },
//     { label: "Inprogress", value: 34 },
//   ],
//   sections: [
//     {
//       title: "Task with Status",
//       items: [
//         { label: "Tasks with Pending Issues", value: 32 },
//         { label: "Tasks kept on Hold", value: 3 },
//         { label: "Tasks with No Issues", value: 32 },
//       ],
//     },
//     {
//       title: "Task with Dates",
//       items: [
//         { label: "Due by Today", value: 21 },
//         { label: "Due within 7 Days", value: 0 },
//         { label: "Crossed Due Date", value: 1 },
//       ],
//     },
//     {
//       title: "Schedule",
//       items: [
//         { label: "Follow Ups", value: 11 },
//         { label: "Appointments", value: 0 },
//         { label: "Meetings", value: 1 },
//       ],
//     },
//     {
//       title: "Pending issues with Tasks",
//       items: [
//         { label: "No. of Demand Refunds", value: 32 },
//         { label: "No. of Digital Signature Pending", value: 3 },
//         { label: "Loan Statement Pending", value: 32 },
//         { label: "Mismatches", value: 3 },
//         { label: "Tax Payments", value: 32 },
//       ],
//     },
//     {
//       title: "Checkwise pending Tasks",
//       items: [
//         { label: "Info received from client for GSTR 1", value: 32 },
//         { label: "Preparation of GSTR 1", value: 3 },
//         { label: "Checking for GSTR 1", value: 32 },
//         { label: "Submission of GSTR 1", value: 3 },
//         { label: "Filling of GSTR 1", value: 32 },
//       ],
//     },
//   ],
//   financialYearReport: {
//     title: "Financial Year Report",
//     options: [
//       { label: "2023 - 24", value: "2023-24" },
//       { label: "2022 - 23", value: "2022-23" },
//     ],
//     statusOptions: [
//       { label: "Completed", value: "completed" },
//       { label: "Pending", value: "pending" },
//     ],
//   },
//   checkwiseGroupReport: {
//     title: "Checkwise Group Report",
//     options: [
//       { label: "Group Name", value: "group-name" },
//     ],
//     statusOptions: [
//       { label: "Pending", value: "pending" },
//       { label: "Completed", value: "completed" },
//     ],
//   },
// };

const GstServiceScreen = () => {
  const [selectedYear, setSelectedYear] = useState("2023-24");
  const [selectedStatus, setSelectedStatus] = useState("completed");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedCheck, setSelectedCheck] = useState(null);

  const sampleNotifications = [
    { message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
    { message: "Curabitur accumsan turpis pharetra augue tincidunt." },
    { message: "Pellentesque habitant morbi tristique senectus et netus." },
    { message: "Suspendisse potenti. Sed porttitor lectus nibh." }
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    serviceOptions,
    serviceSubOptions,
    financialYearOptions,
    statusOptions,
    taskOverview,
    sections,
    checkwiseGroupReport,
    loading,
  } = GstServiceController();

  // Function to navigate to the next notification
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % sampleNotifications.length);
  };

  // Function to navigate to the previous notification
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + sampleNotifications.length) % sampleNotifications.length
    );
  };
  // Function to truncate notification if it exceeds 100 characters
  const getTruncatedNotification = (notification) => {
    return notification.message.length > 100 ? `${notification.message.substring(0, 100)}...` : notification.message;
  };

  const cards = [
    {
      title: "Financial Year Report",
      dropdowns: [
        { label: "Year", options: financialYearOptions, value: selectedYear, setValue: setSelectedYear },
        { label: "Status", options: statusOptions, value: selectedStatus, setValue: setSelectedStatus },
      ],
    },
    {
      title: "Userwise Task at a Glance",
      dropdowns: [
        { label: "Status", options: statusOptions, value: selectedStatus, setValue: setSelectedStatus },
        { label: "Select Checks", options: financialYearOptions, value: selectedCheck, setValue: setSelectedCheck },
      ],
    },
    {
      title: "Overall Task at a Glance",
      dropdowns: [
        { label: "Status", options: statusOptions, value: selectedStatus, setValue: setSelectedStatus },
        { label: "Select Checks", options: financialYearOptions, value: selectedCheck, setValue: setSelectedCheck },
        { label: "Year", options: financialYearOptions, value: selectedYear, setValue: setSelectedYear },
        { label: "Year", options: financialYearOptions, value: selectedYear, setValue: setSelectedYear },
      ],
    },
  ];

  const cards1 = [
    {
      title: "Checkwise Group Report",
      dropdowns: [
        { label: "Status", options: statusOptions, value: selectedStatus, setValue: setSelectedStatus },
        { label: "Select Checks", options: financialYearOptions, value: selectedCheck, setValue: setSelectedCheck },
      ],
    },
    {
      title: "To Do List Report",
      dropdowns: [
        { label: "Status", options: statusOptions, value: selectedStatus, setValue: setSelectedStatus },
        { label: "Select Checks", options: financialYearOptions, value: selectedCheck, setValue: setSelectedCheck },
        { label: "Year", options: financialYearOptions, value: selectedYear, setValue: setSelectedYear },
      ],
    },
  ];

  return (
    <div style={styles.container}>

      <div className="notifications-taskoverview-wrapper" style={{ marginBottom: 50 }}>
        <div className='title-container' style={{ paddingBottom: 10, justifyContent: 'flex-start', gap: '25px' }}>
          <h2 className='title'>Service Dashboard</h2>
          <Dropdown
            options={serviceOptions}
            style={{ backgroundColor: '#fff', borderRadius: '50px', width: '100px' }}
          />
          <Dropdown
            options={serviceSubOptions}
            style={{ backgroundColor: '#fff', borderRadius: '50px', width: '100px' }}
          />
        </div>
        <div className="notifications-section">
          <div className="notifications-header">
            <h3 className='notifications-heading'>Notifications</h3>
            <p className="notification-message">
              {getTruncatedNotification(sampleNotifications[currentIndex])}
            </p>
            <div className="notification-navigation">
              <button className="nav-button" onClick={handlePrevious}>
                <img src={leftArrowBtsIcon} alt="Previous" />
              </button>

              <button className="nav-button" onClick={handleNext}>
                <img src={leftArrowBtsIcon} alt="Next" className="rotate-icon" />
              </button>
            </div>
          </div>

        </div>
        <div className="task-overview-section">
          <div className="overview-content">
            <h3 className="task-overview-title">Tasks<br />Overview</h3>
            {taskOverview?.map((item, index) => (
              <div className="task-overview-item" key={index}>
                <h4 className="task-count">{item.value}</h4>
                <div className="task-label-container">
                  <p className="task-label">{item.label}</p>


                </div>
              </div>
            ))}
          </div>
        </div>

      </div>

      {/* Sections */}
      <div style={styles.sections}>
        {sections.map((section, index) => (
          <div key={index} style={styles.sectionCard}>
            <h4 style={styles.sectionTitle}>{section.title}</h4>
            {section.items.map((item, idx) => (
              <div key={idx} style={styles.sectionItem} className="nth-child-color">
                <p>{item.label}</p>
                <span style={{ ...styles.itemValue, textDecoration: 'underline' }}>{item.value}</span>
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* Financial Year Report */}
      <div style={{ padding: '0px 20px' }}>
        {/* <div style={styles.reportSection}>
        <h4>{data.financialYearReport.title}</h4>
        <select
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
          style={styles.dropdown}
        >
          {data.financialYearReport.options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <select
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          style={styles.dropdown}
        >
          {data.financialYearReport.statusOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div> */}
        <div style={styles.reportSection}>
          <h4>Financial Year</h4>
          <Dropdown
            options={financialYearOptions}
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.value)}
          />
          <Dropdown
            options={statusOptions}
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.value)}
          />
        </div>
        {/* <div style={styles.reportSection}>
        <h4>{data.checkwiseGroupReport.title}</h4>
        <select
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
          style={styles.dropdown}
        >
          {data.checkwiseGroupReport.options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <select
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          style={styles.dropdown}
        >
          {data.checkwiseGroupReport.statusOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div> */}
        <div style={styles.reportSection}>
          <h4>{checkwiseGroupReport.title}</h4>
          <Dropdown
            options={checkwiseGroupReport.options}
            value={selectedGroup}
            onChange={setSelectedGroup}
            width="300px"
          />
          <Dropdown
            options={checkwiseGroupReport.statusOptions}
            value={selectedStatus}
            onChange={setSelectedStatus}
            width="300px"
          />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={styles.container1}>
          {cards.map((card, index) => (
            <div key={index} style={styles.card}>
              <div style={styles.cardTitle}>{card.title}</div>
              <div style={styles.dropdownContainer}>
                <div style={styles.dropdownContainer}>
                  {card.dropdowns.map((dropdown, idx) => (
                    <Dropdown
                      key={idx}
                      options={dropdown.options}
                      value={dropdown.value}
                      onChange={dropdown.setValue}
                      style={{ backgroundColor: '#fff', borderRadius: '50px', width: '80px' }}
                    />
                  ))}
                </div>
                <div>
                  <button style={styles.iconButton}>
                    <img src={require("../../../../Assets/Icons/right_arrow_button.png")} alt="Go" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div style={styles.container1}>
          {cards1.map((card, index) => (
            <div key={index} style={styles.card}>
              <div style={styles.cardTitle}>{card.title}</div>
              <div style={styles.dropdownContainer}>
                <div style={styles.dropdownContainer}>
                  {card.dropdowns.map((dropdown, idx) => (
                    <Dropdown
                      key={idx}
                      options={dropdown.options}
                      value={dropdown.value}
                      onChange={dropdown.setValue}
                      style={{ backgroundColor: '#fff', borderRadius: '50px', width: '80px' }}
                    />
                  ))}
                </div>
                <div>
                  <button style={styles.iconButton}>
                    <img src={require("../../../../Assets/Icons/right_arrow_button.png")} alt="Go" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    display: 'grid',
  },
  notifications: {
    backgroundColor: "#f9f9f9",
    padding: "10px",
    borderRadius: "5px",
    marginBottom: "20px",
  },
  taskOverview: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  taskOverviewCard: {
    flex: 1,
    textAlign: "center",
    margin: "5px",
    backgroundColor: "#fff",
    padding: "15px",
    borderRadius: "5px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
  },
  taskLabel: {
    fontSize: "14px",
    color: "#888",
  },
  taskValue: {
    fontSize: "24px",
    fontWeight: "bold",
    margin: "5px 0",
  },
  sections: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    padding: '0px 20px'
  },
  sectionCard: {
    flex: "1 1 calc(25% - 20px)",
    backgroundColor: "#fff",
    borderRadius: "5px",
    padding: "15px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
  },
  sectionTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",

  },
  sectionItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
    padding: '20px 10px'
  },
  itemValue: {
    fontWeight: "bold",
    color: "#5A55D2",
  },
  reportSection: {
    marginTop: "20px",
    padding: "15px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
  },
  dropdown: {
    margin: "10px 0",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    width: "100%",
  },
  container1: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
  },
  card: {
    width: "355px",
    // minHeight: "160px",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
  },
  cardTitle: {
    fontSize: "18px",
    fontWeight: "400",
    marginBottom: "15px",
    textAlign: "left",
    color: '#060606',
  },
  dropdownContainer: {
    backgroundColor: '#F7F8FA',
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: '10px',
    borderRadius: '10px',
    flexWrap: 'wrap',
  },
  iconButton: {
    border: "none",
    borderRadius: "50%",
    height: '10px',
    width: '10px',
    cursor: "pointer",
    marginTop: '10px',
  },

};

export default GstServiceScreen;
