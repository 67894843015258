
import React, {useState} from "react";
import Input from "../../../../../Components/Input";
import Dropdown from "../../../../../Components/Dropdown";
import editIcon from '../../../../../Assets/Icons/edit.png'
import deleteIcon from '../../../../../Assets/Icons/delete.png';
import filterIcon from '../../../../../Assets/Icons/filter.png'
import leftBtuIcon from '../../../../../Assets/Icons/back.png'
import WhatsAppTemplateListController from "../../../../Controllers/AdminScreens/Configuration/TemplateCenter/WhatsAppTemplateListController";
import ConfigurationAddTemplateModal from "../../../../../Components/Modals/ConfigurationAddTemplateModal";
import DateInput from '../../../../../Components/DateInput'

const WhatsAppTemplateList = ()=>{

    const {
        whatsAppTemplateData,
        handleInputChange,
        filterData,
        setFilterData
    } = WhatsAppTemplateListController();

    const [showFilters, setShowFilters] = useState(false);
    
  const toggleFilters = () => {
    setShowFilters((prev) => !prev); // Toggle the filter visibility
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddNewContact = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

    return(
        <>
      <div className='basic-info-container'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={styles.categoryHeader}>WhatsApp Template</h3>
          <div style={{display:'flex', marginBottom:'10px'}}>
            <button className="add-client-button" style={{ paddingLeft: 30, paddingRight: 30, marginRight:'15px' }} onClick={handleAddNewContact}>
              Add Template
            </button>
            {/* <button className="cancel-button" style={{ border: '1px #40513B solid', borderRadius: '20px', paddingLeft:'30px', paddingRight:'30px' }} onClick={handleAddNewContact}>
              Upload Contacts
            </button> */}
          </div>
        </div>
        <div style={styles.container}>
          <div style={styles.filtersHeader}>
            <button style={styles.filterButton} onClick={toggleFilters}> <img src={filterIcon} style={styles.filterIcon} /> Filters</button>
            <div>
              <img src={leftBtuIcon} style={{ marginRight: '5px', cursor: 'pointer', width: '28px', height: '28px' }} />
              <img src={leftBtuIcon} style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease', cursor: 'pointer', width: '28px', height: '28px' }} />
            </div>
          </div>
        </div>
        {/* Filters using Flexbox */}
        {showFilters && (
          <div style={styles.filtersContainer}>
            <div style={{ display: 'flex', flexWrap: 'wrap', columnGap:'16px' }}>
            <Input
                label="Template Name"
                placeholder="Contact Name"
                value={filterData?.templateName}
                onChange={(e) => handleInputChange('templateName', e.target.value)}
              />
              <Dropdown
                label="Created By"
                options={["Option 1", "Option 2"]}
                placeholder="------"
                value={filterData?.createdBy}
                onChange={(value) => handleInputChange('createdBy', value)}
              />
              
              <div className="filter-field">
                  <label style={{ ...styles.labelStyle, display: "block" }}>Date</label>
                  <DateInput
                      value={filterData?.date}
                      onChange={(e) => handleInputChange('date', e.target.value)}
                  />
              </div>
            </div>
            <div className="button-container" style={{ marginBottom: '15px' }}>
              <button className="add-client-button" style={{ marginRight: '8px', paddingLeft: '30px', paddingRight: '30px' }}>Apply</button>
              <button className="cancel-button" style={{ border: '1px #40513B solid', borderRadius: '20px' }} >Cancel</button>
              <button className="cancel-button">Reset</button>
            </div>
          </div>
        )}
      </div>

      <div className='basic-info-card' style={{ border: 'none', padding: '0px' }}>
        <div style={styles.tableWrapper}>
          <table className="tasksScreen-task-table">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Template Name</th>
                <th style={{ width: "60%" }}>Template Content</th>
                <th style={{ width: "20%" }}>Created Date</th>
                <th style={{ width: "10%" }}>Created By</th>
                {/* <th style={{ width: "15%" }}>Added By</th>
                <th style={{ width: "10%" }}>Opt Out</th>
                <th style={{ width: "5%" }}></th> */}
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "white" }}>
              {whatsAppTemplateData?.map((category, index) => (
                <tr key={index}>
                  <td style={styles.tableValue}>{category.templateName}</td>
                  <td style={styles.tableValue}>{category.templateContent}</td>
                  <td style={styles.tableValue}>{category.createdDate}</td>
                  <td style={styles.tableValue}>{category.createdBy}</td>
                  {/* <td style={styles.tableValue}>{category.addedBy}</td>
                  <td style={styles.tableValue}>{category.optOut}</td> */}
                  {/* <td>
                    <div
                      style={{
                        display: "flex",
                        gap: "2px", 
                      }}
                    >
                     
                      <button
                        className="tasksScreen-edit-button"
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={editIcon}
                          alt="Edit"
                          style={{ width: "16px", height: "16px" }}
                        onClick={handleAddNewContact}
                        />
                      </button>

                      
                      <button
                        className="tasksScreen-delete-button"
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </button>
                    </div>
                  </td> */}

                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isModalOpen && (
        <ConfigurationAddTemplateModal isOpen={isModalOpen} onClose={handleCloseModal} />
      )}
      </div>
      {/* <hr className="divider" style={{ marginTop: '20px' }} /> */}
    </>
    )

}

const styles = {
    categoryHeader: {
      color: '#1F384C',
      fontSize: 18,
      // fontFamily: 'Roboto Slab',
      fontWeight: 500,
      paddingBottom: 20
    },
    tableWrapper: {
      overflowX: "auto", // Horizontal scroll
      whiteSpace: "nowrap", // Prevent table from wrapping on small devices
      // marginBottom:50
    },
    tableValue: {
      colour: '#192A3E',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '12px'
    },
    tableLink: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '12px',
      textDecoration: 'underline',
      color: '#5A55D2'
    },
    container: {
      padding: "5px",
      backgroundColor: "#EFF0F4",
      border: "none",
      // borderRadius: "5px",
      border: '1px #EFF0F4 solid'
    },
    filtersHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // marginBottom: "15px",
    },
    filterButton: {
      backgroundColor: "white",
      color: "#40513B",
      border: "none",
      borderRadius: "5px",
      padding: "5px 8px",
      cursor: "pointer",
      borderRadius: '20px',
      fontSize: '12px',
      fontWeight: '400',
      fontFamily: 'Roboto',
      border: '1px #40513B solid'
  
    },
    filterIcon: {
      width: '10px',
      height: '10px',
      paddingRight: '5px'
    },
  
    paginationButtons: {
      display: "flex",
      gap: "5px",
    },
    paginationButton: {
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      borderRadius: "5px",
      padding: "5px 10px",
      cursor: "pointer",
    },
    filtersContainer: {
      // display: "flex",
      flexWrap: "wrap", // Wrap inputs to the next line if needed
      gap: "20px", // Space between inputs
      marginBottom: "15px",
      // justifyContent: "space-between", // Distribute inputs evenly
      border: '1px #EFF0F4 solid',
      padding: '10px'
    },
    buttonsContainer: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
    applyButton: {
      backgroundColor: "#609966",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      padding: "8px 15px",
      cursor: "pointer",
    },
    cancelButton: {
      backgroundColor: "transparent",
      border: "1px solid #ccc",
      borderRadius: "5px",
      padding: "8px 15px",
      cursor: "pointer",
    },
    resetButton: {
      backgroundColor: "transparent",
      color: "#555",
      border: "none",
      cursor: "pointer",
    },
    labaleStyle: {
      fontSize: '12px',
      // marginBottom: '5px',
      fontWeight: '400',
      fontFamily: 'Roboto',
      color: 'rgb(52, 60, 68)'
    },
    labelStyle: {
      fontSize: "12px",
      fontWeight: "400",
      fontFamily: "Roboto",
      color: "rgb(52, 60, 68)",
    },
  
  }

export default WhatsAppTemplateList