import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../../Components/Tabs';
import ServiceEstimateScreen from './ServiceEstimateScreen';
import InvoiceBillScreen from './InvoiceBillScreen';
import InvoiceReceiptScreen from './InvoiceReceiptScreen';
import InvoiceSettingsController from '../../../Controllers/AdminScreens/Invoices/InvoiceSettingsController';
import InvoiceSettingsConfigurationsScreen from './InvoiceSettingsConfigurationsScreen';
import InvoiceReceiptNotesScreen from './InvoiceReceiptNotesScreen';
import InvoiceOrganizationScreen from './InvoiceOrganizationScreen';
import InvoiceReceiptBooksScreen from './InvoiceReceiptBooksScreen';
const InvoiceSettingsScreen = () => {

    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(state?.selectedTab || 0);

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    // const {

    // } = InvoiceSettingsController()

    return (
        <div style={styles.mainContent}>
            <div className='title-container' style={{paddingBottom:10}}>
                <h2 className='title'>Invoice Settings</h2>
            </div>

            <div >
            <Tabs tabs={['Configuration','Receipt Narration & Notes','Organization','Receipt Books','Taxation','Auto Invoice']}
            selectedIndex={selectedTab}
            onTabChange={handleTabChange}>
                    <InvoiceSettingsConfigurationsScreen />
                    <InvoiceReceiptNotesScreen />
                    <InvoiceOrganizationScreen />
                    <InvoiceReceiptBooksScreen />
                </Tabs>
            </div>

        </div>


    )

};

const styles = {
    mainContent: {
        width: '95%',
        margin: 'auto'
    }

}

export default InvoiceSettingsScreen;