import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../../Components/Tabs';
import DocumentListScreen from './DocumentListScreen';
import InwardOutwardListScreen from './InwardOutwardListScreen';


const DocumentRackScreen = () => {

    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(state?.selectedTab || 0);

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    return (
        <div style={styles.mainContent}>
            <div className='title-container' style={{ paddingBottom: 10 }}>
                <h2 className='title'>Document Rack</h2>
            </div>

            <div >
                <Tabs tabs={['Inward/Outward', 'Document']}
                selectedIndex={selectedTab}
                onTabChange={handleTabChange}>
                    <InwardOutwardListScreen />
                    <DocumentListScreen />
                </Tabs>
            </div>

        </div>


    )

};

const styles = {
    mainContent: {
        width: '95%',
        margin: 'auto'
    }

}

export default DocumentRackScreen;