import React, { useState } from 'react';
import InwardOutwardListController from '../../../Controllers/AdminScreens/DocumentRack/InwardOutwardListController';
import editImg from '../../../../Assets/Icons/edit.png';
import deleteImg from '../../../../Assets/Icons/delete.png';
import downArrow from '../../../../Assets/Icons/chevron-down.png';
import filter from '../../../../Assets/Icons/filter.png';
import Dropdown from '../../../../Components/Dropdown';
import CreateEntryModal from '../../../../Components/Modals/CreateEntryModal';
import { useNavigate } from 'react-router-dom';

const InwardOutwardListScreen = () => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);

    const handleNav = (pageName, task) => {
        console.log('page', pageName, 'task', task);
        navigate(`/${pageName}`, { state: { task } }); // Pass task in state
    };
    const {
        tasks,
        currentPage,
        totalPages,
        handlePageChange,
        toggleDetails
    } = InwardOutwardListController();


    const [showFilters, setShowFilters] = useState(false); // Control the visibility of filters
    const [filters, setFilters] = useState({
        taskType: '',
        financialYear: '',
        period: '',
        status: '',
    });

    const handleFilterChange = (name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const applyFilters = () => {
        console.log('Applying filters:', filters);
        setShowFilters(false);
    };

    const resetFilters = () => {
        setFilters({
            taskType: '',
            financialYear: '',
            period: '',
            status: '',
        });
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const createEntryModalVisible = () => {
        setModalVisible(true)
    }

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    return (
        <>
            <div className="tasksScreen-tasks-header">
                <h2 className="tasksScreen-tasks-title">Inward/Outward</h2>
                <button className="tasksScreen-apply-button" onClick={createEntryModalVisible}>Create Entry</button>
            </div>

            {/* Filters Row */}
            <div className="tasksScreen-filters-row">
                <div className="tasksScreen-filters-button-wrapper">
                    <button className="tasksScreen-filters-button" onClick={toggleFilters}>
                        <img src={filter} alt="Filter" />
                        {showFilters ? 'Filters' : 'Filters'}
                    </button>
                </div>
            </div>

            {showFilters && (
                <div className={`tasksScreen-filters-container ${showFilters ? 'tasksScreen-open-filters' : ''}`}>
                    <div className="tasksScreen-filters">
                        {/* Replace each filter select with Dropdown component */}
                        <Dropdown
                            label="Task Type"
                            options={['Type 1', 'Type 2']}
                            value={filters.taskType}
                            onChange={(value) => handleFilterChange('taskType', value)}
                        />

                        <Dropdown
                            label="Financial Year"
                            options={['2023-24', '2024-25']}
                            value={filters.financialYear}
                            onChange={(value) => handleFilterChange('financialYear', value)}
                        />

                        <Dropdown
                            label="Period"
                            options={['Q1', 'Q2', 'Q3', 'Q4']}
                            value={filters.period}
                            onChange={(value) => handleFilterChange('period', value)}
                        />

                        <Dropdown
                            label="Status"
                            options={['In Progress', 'Not Started', 'Priority', 'Delayed']}
                            value={filters.status}
                            onChange={(value) => handleFilterChange('status', value)}
                        />
                    </div>

                    <div className="tasksScreen-filter-buttons">
                        <button onClick={applyFilters} className="tasksScreen-apply-button">Apply</button>
                        <button onClick={toggleFilters} className="tasksScreen-cancel-button">Cancel</button>
                        <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
                    </div>
                </div>
            )}

            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>

                        {/* Table Headers */}
                        <tr>
                            <th>Document Name</th>
                            <th>Source</th>
                            <th>Delivery Type</th>
                            <th>Client Name</th>
                            <th>Document No.</th>
                            <th>Restriction</th>
                            <th>Created By</th>
                            <th>Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#fff' }}>
                        {tasks.map((task, index) => (
                            <React.Fragment key={index}>
                                <tr className={`tasksScreen-main-task-row ${task.showDetails ? 'tasksScreen-show-border' : ''}`}>
                                    <td style={{ color: '#5A55D2', textDecoration: 'underline', cursor: 'pointer' }}>{task.documentName}</td>
                                    <td>{task.source}</td>
                                    <td>{task.deliveryType}</td>
                                    <td>{task.clientNamFe}</td>
                                    <td>{task.documentNumber}</td>
                                    <td></td>
                                    <td>{task.createdBy}</td>
                                    <td>{task.date}</td>
                                    <td>
                                        <button className="tasksScreen-edit-button">
                                            <img src={editImg} alt="Edit" onClick={() => handleNav('manage-clients', 'Tasks')} />
                                        </button>
                                        <button className="tasksScreen-delete-button">
                                            <img src={deleteImg} alt="Delete" />
                                        </button>
                                    </td>
                                </tr>
                                {task.showDetails && (
                                    <tr className="tasksScreen-additional-info-row">
                                        <td colSpan="10">
                                            <div className="tasksScreen-additional-info">
                                                <div>
                                                    <span>File No.</span>
                                                    <p>{task.fileNumber}</p>
                                                </div>
                                                <div>
                                                    <span>Period</span>
                                                    <p>{task.period}</p>
                                                </div>
                                                <div>
                                                    <span>Task Leader</span>
                                                    <p>{task.taskLeader}</p>
                                                </div>
                                                <div>
                                                    <span>Completed Check</span>
                                                    <p>{task.completedCheck}</p>
                                                </div>
                                                <div>
                                                    <span>Completed By</span>
                                                    <p>{task.completedBy || '--'}</p>
                                                </div>
                                                <div>
                                                    <span>Description</span>
                                                    <p>{task.description || '--'}</p>
                                                </div>
                                                <div>
                                                    <span>Pending Issues</span>
                                                    <p>{task.pendingIssues || '--'}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="tasksScreen-table-footer">
                <span className="tasksScreen-pagination-info">
                    {`${currentPage}-${totalPages} of items`}
                </span>
                <div className="tasksScreen-pagination">
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
                    </button>
                    {[...Array(totalPages)].map((_, index) => (
                        <button key={index} className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    ))}
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                        <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
                    </button>
                </div>
                <CreateEntryModal isOpen={modalVisible} onClose={handleCloseModal} />
            </div>
            {/* </div> */}
        </>
    );
};

const styles = {
    header: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        fontFamily: 'Montserrat',
        //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader: {
        marginTop: '-5px'
    }
};

export default InwardOutwardListScreen;
