
import React from 'react';
import Input from '../../../../Components/Input';
import uploadIcon from '../../../../Assets/Icons/upload.png'
import InvoiceReceiptNotesCOntroller from '../../../Controllers/AdminScreens/Invoices/InvoiceReceiptNotesController';


const InvoiceReceiptNotesScreen = () => {
    const {
        narration,
        setNarration,
        notes,
        setNotes
    } = InvoiceReceiptNotesCOntroller();

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Receipt Narration & Notes</h2>
            <div style={styles.field}>
                <label style={styles.label}>Narration</label>
                <textarea
                    placeholder="DLS24-"
                    value={narration}
                    onChange={(e) => setNarration(e.target.value)}
                    style={styles.textarea}
                    rows={4}
                />
            </div>
            <div style={styles.field}>
                <label style={styles.label}>Notes</label>
                <textarea
                    placeholder="DLS24-"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    style={styles.textarea}
                    rows={4}
                />
            </div>

            <hr className="footer-divider" />
            <div className="button-container" style={{ gap: "10px" }}>
                <button className="add-client-button" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                    Update
                </button>
                <button style={{ ...styles.cancelButton, paddingLeft: '30px', paddingRight: '30px' }}>
                    Cancel
                </button>
            </div>
        </div>
    );

}

const styles = {
    // container: {
    //   width: "600px",
    //   margin: "0 auto",
    //   fontFamily: "Arial, sans-serif",
    // },
    title: {
        color: 'black',
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: '500',
        marginBottom: '5px'
    },
    field: {
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
    },
    label: {
        flex: "0 0 200px", // Ensures all labels are the same width
        color: 'black',
        fontSize: 14,
        fontFamily: 'Roboto',
        fontWeight: '400',
    },
    // input: {
    //   flex: "1",
    //   height: "30px",
    //   padding: "5px 10px",
    //   fontSize: "14px",
    //   border: "1px solid #d1d5db",
    //   borderRadius: "4px",
    // },

    addServiceButton: {
        backgroundColor: "transparent",
        border: "1px #858585 solid",
        borderRadius: "5px",
        padding: "8px 12px",
        color: "#40513B",
        cursor: "pointer",
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: '400',
        borderRadius: '28px',
        // marginBottom:'16px'
        display: "flex", // Use flexbox for alignment
        alignItems: "center", // Vertically align icon and text
        justifyContent: "center",
    },
    icon: {
        width: "12px",
        height: "12px",
        marginRight: "10px",
    },
    cancelButton: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        color: "#40513B",
        border: "1px solid #858585",
        padding: "8px 16px",
        borderRadius: '20px',
        cursor: "pointer",
    },
    textarea: {
        border: "1px solid #d1e3ed",
        borderRadius: "5px",
        fontSize: "14px",
        padding: "10px",
        width: "100%",
        resize: "none", // Prevents resizing for consistent appearance
    },
};

export default InvoiceReceiptNotesScreen