import React, { useState } from 'react';
import backIcon from '../../../../Assets/Icons/back.png';
import ManageTeamDetailsController from '../../../Controllers/AdminManagementScreens/TeamsAndUsers/ManageTeamDetailsController';
import Toggle from '../../../../Components/Toggle';
import Dropdown from '../../../../Components/Dropdown';
import Input from '../../../../Components/Input';
import DateInput from '../../../../Components/DateInput';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import AddTeamMemberModal from '../../../../Components/Modals/AddTeamMemberModal';
import LoadingSkeleton from '../../../../Components/LoadingSkeleton';
import Loader from '../../../../Components/Loader';

const ManageTeamDetails = () => {

    const { 
        handleBack,
        userToggleState,
        handleUserToggleChange,
        handleEditTeamMember,
        handleDeleteTeamMember,
        handleUpdateTeam,
        handleDeleteTeam,
        handleCancelTeam,
        handleAddTeamMembers,
        teamMemberModalOpen,
        handleCloseTeamMemberModal,
        onValueChangeTeamMember,
        loading,
        error,
        isTeamInfoEdit,
        primaryData,
        fieldConfigs,
        handleInputChange,
        teamMembers,
        teamMembersError,
        teamID,
        statusLoading,
        teamStatusError
    } = ManageTeamDetailsController();

    console.log('isTeamInfoEdit teamdetails @@@:-----', isTeamInfoEdit.current)
    console.log('primaryData teamdetails @@@:-----', primaryData)

    console.log("##teamMembers##", teamMembers)






    return (
        <div style={styles.container}>
            {/* Header Section */}
            <div style={styles.headerContainer}>
                <img
                    src={backIcon}
                    alt="Back"
                    style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
                    onClick={handleBack}
                />
                <span style={styles.header}>Manage Teams</span>
            </div>
            {/* Team Section */}
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 ,alignItems:'center'}}>
                        <div className='teamdetails-title'>{primaryData?.team_name}{loading && <LoadingSkeleton width="40%" height="10px" />}</div>
                        <Toggle initialStatus={primaryData?.team_status === 1? true:false} onToggle={(value)=>handleUserToggleChange(value,primaryData?.team_id)} size='big' />
                            {statusLoading && <Loader loading={statusLoading} color={'#000'} size='small' /> }
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Contact User : Satish@example.com</span>
                        <span style={{ alignSelf: 'end' }}>98900 98900</span>
                    </div>
                </div>
                <div className="company-details">{primaryData?.emp_id_team_manager_name}</div>
            </div>
            {teamStatusError && <div className="list-error-msg"> {teamStatusError} </div>}
            
            {/* Team Information */}
            <div className='teamdetails-outer-card' style={{ marginTop: 20 }}>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'end'}}>
                    <h3 className='basic-info-header'>Team Information</h3>
                    <button onClick={handleAddTeamMembers} className="add-client-button">Add Team Members</button>
                </div>

                <div className='basic-info-card' style={{ marginTop: 20 }}>
                    <div className='basic-info-row'>
                        {fieldConfigs.map((field) => (
                            <div className="basic-info-item" key={field.key}>
                                {isTeamInfoEdit.current ? (
                                    field.type === "dropdown" ? (
                                        <Dropdown
                                            label={field.label} // Dropdown already renders the label internally
                                            options={field.options}
                                            value={primaryData[field.key]}
                                            onChange={(selectedValue) => handleInputChange(field.key, selectedValue)}

                                        />
                                    ) : field.type === "date" ? (
                                        <div className="basic-info-label">
                                            <label>{field.label}</label>
                                            <DateInput
                                                value={primaryData[field.key] || ""}
                                                onChange={(e) => handleInputChange(field.key, e.target.value)}
                                            />
                                        </div>
                                    ) : (
                                        <Input
                                            label={field.label} // Input already renders the label internally
                                            placeholder={`Enter ${field.label}`}
                                            value={primaryData[field.key]}
                                            onChange={(e) => handleInputChange(field.key, e.target.value)}
                                        />
                                    )
                                ) : (
                                    <><span className="basic-info-label">{field.label}</span>
                                        <span className="basic-info-value">{primaryData[field.key]} {loading && <LoadingSkeleton width="40%" height="10px" />}</span></>
                                )}
                            </div>
                        ))}
                    </div>
                    {error && <div className="list-error-msg">{error} </div>}
                </div>

                <div className="tasksScreen-task-table-wrapper" style={{ marginTop: 20 }}>
                    <h3 className='basic-info-header'>Team Members</h3>
                    <div className="basic-info-outer-card">
                        <table className='tasksScreen-task-table'>
                            <thead>
                                <tr>
                                    <th style={{ width: '20%' }}>Employee</th>
                                    <th style={{ width: '25%' }}>Email ID</th>
                                    <th style={{ width: '40%' }}>Phone No.</th>
                                    <th style={{ width: '15%' }}></th>
                                </tr>
                            </thead>
                            <tbody style={{ backgroundColor: 'white' }}>
                                {teamMembers?.map((teamMember, index) => (
                                    <tr key={index}
                                        className="tasksScreen-main-task-row"
                                        style={{ borderBottom: "1px solid #dee2e6",cursor:'pointer' }} >
                                        <td style={{ ...styles.tableLink, width: '20%' }}>{teamMember?.emp_name}</td>
                                        <td style={{ width: '25%' }}>{teamMember?.emp_email}</td>
                                        <td style={{ width: '40%' }}>{teamMember?.emp_mobile_no}</td>
                                        <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', gap: 20 }}>
                                            <button
                                                className="tasksScreen-edit-button"
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => handleEditTeamMember(teamMember)}
                                            >
                                                <img
                                                    src={editIcon}
                                                    alt="Edit"
                                                    style={{ width: "16px", height: "16px" }}
                                                />
                                            </button>
                                            <button
                                                className="tasksScreen-delete-button"
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => handleDeleteTeamMember(teamMember)}
                                            >
                                                <img
                                                    src={deleteIcon}
                                                    alt="Delete"
                                                    style={{ width: "16px", height: "16px" }}
                                                />
                                            </button>

                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
                        {teamMembersError && <div className="list-error-msg">{teamMembersError} </div>}
                    </div>
                </div>

                <div className="button-container" style={{ gap: 20, flexWrap: 'wrap' }}>
                    {isTeamInfoEdit.current && <button onClick={handleUpdateTeam} className="add-client-button">Update Team</button>}
                    {isTeamInfoEdit.current &&<button onClick={handleCancelTeam} className="tasksScreen-cancel-button">Cancel</button>}
                    <button onClick={handleDeleteTeam} className="tasksScreen-cancel-button" style={{ marginLeft: 'auto' }}>Delete Team</button>
                </div>

            </div>
            <AddTeamMemberModal isOpen={teamMemberModalOpen} onClose={handleCloseTeamMemberModal} onValueChange={onValueChangeTeamMember} teamId={teamID}/>
        </div>
    )

}

const styles = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    header: {
        fontSize: "22px",
        color: "#060606",
        fontWeight: 500,
        fontFamily: 'Montserrat',
        letterSpacing: 0.22
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
        marginBottom: '20px'
    },
    tableLink: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px',
        textDecoration: 'underline',
        color: '#5A55D2'
    },

}

export default ManageTeamDetails;