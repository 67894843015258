import { useState, useEffect } from 'react';

const NoticeBoardController = () => {
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items per page

    useEffect(() => {
        // Fetch or load the data from an API or local file
        const data = [
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Global',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Team Name',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Team Name,User Name,User Name',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Global  ',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Global  ',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Global  ',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Global  ',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Global  ',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name ,Holiday announced today, Company Name/Group Name, Holiday announced today, Company Name/Group Name Holiday announced today, Company Name/Group Name',
                recipient: 'Team Name, Team NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam Name',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },

            // Add more sample data as needed
        ];
        setTasks(data);
        setFilteredTasks(data); // Initially, all tasks are displayed
    }, []);

    // Handle filtering tasks
    const filterTasks = (filters) => {
        const { published, message } = filters;
        const filtered = tasks.filter((task) => {
            const matchesSource = published ? task.published === published : true;
            const matchesDeliveryType = message ? task.message === message : true;
            return matchesSource && matchesDeliveryType;
        });
        setFilteredTasks(filtered);
        setCurrentPage(1); // Reset to the first page after filtering
    };

    // Handle pagination
    const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return {
        tasks: currentTasks, // Only return tasks for the current page
        currentPage,
        totalPages,
        handlePageChange,
        filterTasks, // Expose the filter function
    };
};

export default NoticeBoardController;
