import { useState, useEffect } from "react";

const UploadClientsListFileController = () => {
    const [tasks, setTasks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Adjust as needed

    useEffect(() => {
        const data = [
            { clientName: "Client Import", description: "--", assignedTo: "John Doe", status: "Completed" },
            { clientName: "Client Import", description: "--", assignedTo: "John Doe", status: "Completed" },
            { clientName: "Client Import", description: "--", assignedTo: "John Doe", status: "Incompleted" },
            { clientName: "Client Import", description: "--", assignedTo: "John Doe", status: "Completed" },
            { clientName: "Client Import", description: "--", assignedTo: "John Doe", status: "Completed" },
            { clientName: "Client Import", description: "--", assignedTo: "John Doe", status: "Completed" },
        ];
        setTasks(data);
    }, []);

    const totalPages = Math.ceil(tasks.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTasks = tasks.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return {
        tasks: currentTasks,
        currentPage,
        totalPages,
        handlePageChange,
    };
};

export default UploadClientsListFileController;
