import React ,{ useEffect,useState } from "react";
import { ORG_GET_GROUP_LIST } from "../../../../ApiServices/BaseURL";
import { postData,getData } from "../../../../ApiServices/ApiService";
import { useNavigate } from "react-router-dom";

const useManageGroupsController = () => {
    const [username, setUsername] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [countryCode, setCountryCode] = useState('+91');
    const [manager, setManager] = useState("");
    const [email, setEmail] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('');
    const [groupsList,setgroupsList] = useState([])

    const navigate = useNavigate()
  
    useEffect(()=>{
      fetchTaskCategorieList();
    },[])
  
    const fetchTaskCategorieList =async ()=>{
      try {
          setLoading(true);
          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      
          // Make the API call
          const response = await getData(`${ORG_GET_GROUP_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
          const responseData = await response.json();
      
          // Check for success (200) and internal status code
          if (response?.status === 200) {
            if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                if (responseData?.dataJ?.length > 0) {
                    setgroupsList(responseData?.dataJ);
                } else {
                  setError(responseData?.info || 'Data Not Found');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
              }
            } else {
              setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
            }
          } 
          // Token-related errors: 400, 401, 403
          else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
            if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
              setError('Unauthorized access. Your session may have expired. Please log in again.');
              // Optionally, redirect to the login page or refresh the token
              // Example: redirectToLogin();
            } else {
              setError(responseData?.info || 'Bad request. Please check the request parameters.');
            }
          } 
          // Internal Server Error: 500
          else if (response?.status === 500) {
            setError(responseData?.info || 'Internal Server Error. Please try again later.');
          } 
          // Unexpected or network-related errors
          else {
            setError('Unexpected error occurred. Please try again later.');
          }
        } catch (error) {
          // Handle various error scenarios
          if (error.response) {
            // Server responded with a status outside the 2xx range
            setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
          } else if (error.request) {
            // Request was made but no response was received
            setError('No response received from the server. Please check your network connection and try again.');
          } else {
            // An error occurred in setting up the request
            setError(`Failed to fetch Task Categorie data: ${String(error)}`);
          }
        } finally {
          setLoading(false);
        }
    }

    const handleAddDocument = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const OnAddUpdateSucess = () => {
      fetchTaskCategorieList();
    }

    const getStatusStyle = (status) => {
        switch (status) {
        //   case "Incomplete":
        //     return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" }; // Green
          case 0:
            return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" }; // Yellow
          case 1:
            return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" }; // Red
          default:
            return {};
        }
      };

      const handleEditGroup = (group,isEdit)=>{
        console.log('group:-',group);
        navigate('/manage-group-details', {state : { selGroupInfo:group,isEdit: isEdit} });
      }


    return {
        groupsList,
        isModalOpen,
        username,
        mobileNumber,
        countryCode,
        manager,
        email,
        handleAddDocument,
        handleCloseModal,
        getStatusStyle,
        setEmail,
        setUsername,
        setMobileNumber,
        setCountryCode,
        setManager,
        OnAddUpdateSucess,
        loading,
        error,
        handleEditGroup
    };

};
export default useManageGroupsController;
