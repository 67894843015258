import { useState, useEffect } from "react";

const NatureCallTypeListController = () => {
  const [tasks, setTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    // Simulated data
    const data = [
        {
          clientName: "Follow up",
          description: "--",
          assignedTo: "John Doe",
          status: "Active",
        },
        {
          clientName: "Schedule Appointment",
          description: "--",
          assignedTo: "John Doe",
          status: "Incomplete",
        },
        {
          clientName: "Call Back",
          description: "--",
          assignedTo: "John Doe",
          status: "Disabled",
        },
        {
          clientName: "More Info",
          description: "--",
          assignedTo: "John Doe",
          status: "Disabled",
        },
        {
          clientName: "Company Name/Group Name",
          description: "--",
          assignedTo: "John Doe",
          status: "Disabled",
        },
        {
          clientName: "Follow up",
          description: "Follow-up for client queries.",
          assignedTo: "Jane Smith",
          status: "Active",
        },
        {
          clientName: "Feedback Collection",
          description: "Collecting feedback after task completion.",
          assignedTo: "Alice Brown",
          status: "Incomplete",
        },
        {
          clientName: "Move Info",
          description: "Additional client information update.",
          assignedTo: "Michael Scott",
          status: "Disabled",
        },
        {
          clientName: "Reminder Call",
          description: "Task reminder call for the client.",
          assignedTo: "Dwight Schrute",
          status: "Active",
        },
        {
          clientName: "Support Call",
          description: "Handling client support-related calls.",
          assignedTo: "Jim Halpert",
          status: "Incomplete",
        },
        {
          clientName: "Follow Up Call",
          description: "Ongoing project follow-ups.",
          assignedTo: "Pam Beesly",
          status: "Disabled",
        },
        {
          clientName: "Schedule Next Meeting",
          description: "Planning next meeting schedules.",
          assignedTo: "Angela Martin",
          status: "Active",
        },
        {
          clientName: "Update Profile",
          description: "Client's profile update and verification.",
          assignedTo: "Oscar Martinez",
          status: "Incomplete",
        },
        {
          clientName: "Document Collection",
          description: "Gathering required documents.",
          assignedTo: "Kevin Malone",
          status: "Disabled",
        },
        {
          clientName: "Schedule Review",
          description: "Reviewing client’s submitted details.",
          assignedTo: "Stanley Hudson",
          status: "Active",
        },
        {
          clientName: "Payment Reminder",
          description: "Reminding clients about due payments.",
          assignedTo: "Phyllis Vance",
          status: "Incomplete",
        },
        {
          clientName: "Follow-Up Visit",
          description: "Scheduling follow-up visits for the client.",
          assignedTo: "Creed Bratton",
          status: "Disabled",
        },
        {
          clientName: "Client Onboarding",
          description: "Starting the onboarding process.",
          assignedTo: "Toby Flenderson",
          status: "Active",
        },
        {
          clientName: "General Inquiry",
          description: "Handling general client inquiries.",
          assignedTo: "Kelly Kapoor",
          status: "Incomplete",
        },
        {
          clientName: "Technical Support",
          description: "Resolving client technical issues.",
          assignedTo: "Ryan Howard",
          status: "Disabled",
        },
      ];      
    setTasks(data);
  }, []);

  const totalPages = Math.ceil(tasks.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTasks = tasks.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return {
    tasks: currentTasks,
    currentPage,
    totalPages,
    handlePageChange,
  };
};

export default NatureCallTypeListController;
