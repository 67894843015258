import React, { useState } from 'react';
import TaskListController from '../../Controllers/AdminScreens/TaskListController';
import editImg from '../../../Assets/Icons/edit.png';
import deleteImg from '../../../Assets/Icons/delete.png';
import downArrow from '../../../Assets/Icons/chevron-down.png';
import filter from '../../../Assets/Icons/filter.png';
import Dropdown from '../../../Components/Dropdown';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../Components/Loader';

const TaskListScreen = () => {
  const navigate = useNavigate();

  const handleNav = (pageName, task, clientId) => {
    console.log('page', pageName, 'task', task);
    navigate(`/${pageName}`, { state: { task:task,clientId:clientId } }); // Pass task in state
  };
  const {
    tasks,
    currentPage,
    totalPages,
    handlePageChange,
    toggleDetails,
    loading,
    error
  } = TaskListController();

  const [showFilters, setShowFilters] = useState(false); // Control the visibility of filters
  const [filters, setFilters] = useState({
    taskType: '',
    financialYear: '',
    period: '',
    status: '',
  });

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const applyFilters = () => {
    console.log('Applying filters:', filters);
    setShowFilters(false);
  };

  const resetFilters = () => {
    setFilters({
      taskType: '',
      financialYear: '',
      period: '',
      status: '',
    });
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <>
    <div style={{ width: '93%', margin: 'auto' }}>
            <h3 style={styles.header}>
                <div style={styles.headerContainer}>
                    <span>Tasks</span>
                    <button className="add-client-button" style={{ marginBottom: 10, marginLeft: 'auto' }} onClick={()=>{handleNav('create-task')}}>
                        Add Task
                    </button>
                </div>
            </h3>
        </div>
    {/* <div className="tasksScreen-task-screen"> */}
      <div className="tasksScreen-main-content" style={{width:'unset'}}>
        <div className="tasksScreen-tasks-container" >
          <div className="tasksScreen-tasks-header">
            <h2 className="tasksScreen-tasks-title">Tasks Details</h2>
          </div>

          {/* Filters Row */}
          <div className="tasksScreen-filters-row">
            <div className="tasksScreen-filters-button-wrapper">
              <button className="tasksScreen-filters-button" onClick={toggleFilters}>
                <img src={filter} alt="Filter" />
                {showFilters ? 'Filters' : 'Filters'}
              </button>
            </div>
          </div>

          {showFilters && (
            <div className={`tasksScreen-filters-container ${showFilters ? 'tasksScreen-open-filters' : ''}`}>
              <div className="tasksScreen-filters">
                {/* Replace each filter select with Dropdown component */}
                <Dropdown
                  label="Task Type"
                  options={['Type 1', 'Type 2']}
                  value={filters.taskType}
                  onChange={(value) => handleFilterChange('taskType', value)}
                />

                <Dropdown
                  label="Financial Year"
                  options={['2023-24', '2024-25']}
                  value={filters.financialYear}
                  onChange={(value) => handleFilterChange('financialYear', value)}
                />

                <Dropdown
                  label="Period"
                  options={['Q1', 'Q2', 'Q3', 'Q4']}
                  value={filters.period}
                  onChange={(value) => handleFilterChange('period', value)}
                />

                <Dropdown
                  label="Status"
                  options={['In Progress', 'Not Started', 'Priority', 'Delayed']}
                  value={filters.status}
                  onChange={(value) => handleFilterChange('status', value)}
                />
              </div>

              <div className="tasksScreen-filter-buttons">
                <button onClick={applyFilters} className="tasksScreen-apply-button">Apply</button>
                <button onClick={toggleFilters} className="tasksScreen-cancel-button">Cancel</button>
                <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
              </div>
            </div>
          )}

          <div className="tasksScreen-task-table-wrapper">
            <table className="tasksScreen-task-table">
              <thead>

                {/* Table Headers */}
                <tr>
                  <th></th>
                  <th>Client Name</th>
                  <th>Task Type</th>
                  <th>Start Date</th>
                  <th>ETD</th>
                  <th>Financial Year</th>
                  <th>Group Name</th>
                  <th>Assigned To</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tasks.map((task, index) => (
                  <React.Fragment key={index}>
                    <tr className={`tasksScreen-main-task-row ${task.showDetails ? 'tasksScreen-show-border' : ''}`}>
                      <td>
                        <button onClick={() => toggleDetails(index)}>
                          {task.showDetails ? <img src={downArrow} alt="Up Arrow" className="tasksScreen-arrow-up " /> : <img src={downArrow} alt="Down Arrow" />}
                        </button>
                      </td>
                      <td>{task?.client_name}</td>
                      <td>{task?.service_sub_name}</td>
                      <td>{task?.task_start_date}</td>
                      <td>{task?.task_due_date}</td>
                      <td>{task?.financial_year}</td>
                      <td>{task.group_name}</td>
                      <td>{task?.emp_id_assigned_name}</td>
                      <td>
                        <span className={`tasksScreen-status-badge`}>
                          {task?.task_status}
                        </span>
                      </td>
                      <td>
                        <button className="tasksScreen-edit-button">
                          <img src={editImg} alt="Edit" onClick={() => handleNav('manage-clients','Tasks',task?.client_id)} />
                        </button>
                        <button className="tasksScreen-delete-button">
                          <img src={deleteImg} alt="Delete" />
                        </button>
                      </td>
                    </tr>
                    {task.showDetails && (
                      <tr className="tasksScreen-additional-info-row">
                        <td colSpan="10">
                          <div className="tasksScreen-additional-info">
                            <div>
                              <span>File No.</span>
                              <p>{task.fileNumber}</p>
                            </div>
                            <div>
                              <span>Period</span>
                              <p>{task?.financial_period}</p>
                            </div>
                            <div>
                              <span>Task Leader</span>
                              <p>{task.taskLeader}</p>
                            </div>
                            <div>
                              <span>Completed Check</span>
                              <p>{task.completedCheck}</p>
                            </div>
                            <div>
                              <span>Completed By</span>
                              <p>{task.completedBy || '--'}</p>
                            </div>
                            <div>
                              <span>Description</span>
                              <p>{task.task_description || '--'}</p>
                            </div>
                            <div>
                              <span>Pending Issues</span>
                              <p>{task.pendingIssues || '--'}</p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
            {error && <div className="list-error-msg">{error} </div>}
          </div>

          <div className="tasksScreen-table-footer">
            <span className="tasksScreen-pagination-info">
              {`${currentPage}-${totalPages} of items`}
            </span>
            <div className="tasksScreen-pagination">
              <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button key={index} className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
              ))}
              <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
              </button>
            </div>
          </div>
        </div>
      </div>
    {/* </div> */}
    </>
  );
};

const styles = {
  header: {
    fontSize: "22px",
    color: "#060606",
    marginBottom: "10px",
    fontWeight:500,
    fontFamily:'Montserrat',
  //   marginLeft:'20px'
  },
  headerContainer: {
      display: 'flex',
      alignItems: 'center', // Vertically align items
  },
  leftHeader:{
      marginTop:'-5px'
  }
};

export default TaskListScreen;
