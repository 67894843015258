import React, { useState, useEffect, useRef } from 'react';
import Input from '../Input';
import CheckBox from '../CheckBox';
import MobileInput from '../MobileInput';
import closeIcon from '../../Assets/Icons/close.png';
import pluseIcon from '../../Assets/Icons/Pluse.png';
import { postData, getData } from '../../ApiServices/ApiService';
import { ORG_ADD_CLIENT_CONTACT, ORG_UPDATE_CLIENT_CONTACT } from '../../ApiServices/BaseURL';
import Loader from '../Loader';
import { validateEmail } from '../../Utils/Validation';

const AddNewContactModal = ({ isOpen, onClose, clientId, editData, onValueChange }) => {
  const [contactName, setContactName] = useState(editData?.contact_name || '');
  const [role, setRole] = useState(editData?.contact_role || '');
  const [email, setEmail] = useState(editData?.contact_email || '');
  const [mobileNumber, setMobileNumber] = useState(editData?.contact_phone_no || '');
  const [countryCode, setCountryCode] = useState(editData?.contact_phone_code || '+91');
  const [primaryContact, setPrimartContact] = useState(editData?.is_primary_contact === 1 ? true : false || false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  console.log('addupdate contact @@@', editData);


  useEffect(() => {
    console.log('addupdate contact ', editData);
    if (editData) {
      console.log('addupdate contact if @@@@', editData)
      setContactName(editData?.contact_name)
      setMobileNumber(editData?.contact_phone_no)
      setCountryCode(editData?.contact_phone_code)
      setEmail(editData?.contact_email)
      setRole(editData?.contact_role)
      setPrimartContact(editData?.is_primary_contact === 1 ? true : false)
    }

  }, [editData])

  // Reset form fields to initial values
  const resetForm = () => {
    if (!editData) {
      setContactName('');
      setRole('');
      setEmail('');
      setPrimartContact('');
      setMobileNumber('');
    } else{
      setError('');
    }
  };

  // Run resetForm when modal closes
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const handleNewContact = async () => {
    try {
      console.log('handleNewContact countryCode ', countryCode)
      setError('');
      if (!contactName || !mobileNumber || !email || !role) {
        setError('Please Fill All FIleds'); return
      }
      if (!validateEmail(email)) {
        setError('Please enter a valid email'); return
      }
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "client_id": clientId,
        "contact_name": contactName,
        "contact_phone_no": mobileNumber,
        "contact_email": email,
        "contact_role": role,
        "contact_phone_code": countryCode,
        "is_primary_contact": primaryContact === true ? 1 : 0
      }

      console.log('handleNewContact payload', payLoad);
      // Make the API call
      const response = await postData(ORG_ADD_CLIENT_CONTACT, payLoad);
      const responseData = await response.json();
      console.log('handleNewContact Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setError(responseData?.info);
            setTimeout(() => {
              handleClose();
              onValueChange();
            }, 2000);
          } else {
            setError(responseData?.info || 'Failed to add new contact. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to add new contact. Status code error.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to add new contact. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to add new contact: ${String(error)}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateContact = async () => {
    try {
      console.log('handleUpdateContact countryCode', countryCode)
      setError('');
      if (!contactName || !mobileNumber || !email || !role) {
        setError('Please Fill All FIleds'); return
      }
      if (!validateEmail(email)) {
        setError('Please enter a valid email'); return
      }
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "contact_id": editData?.contact_id,
        "contact_name": contactName,
        "contact_phone_no": mobileNumber,
        "contact_email": email,
        "contact_role": role,
        "contact_phone_code": countryCode,
        "is_primary_contact": primaryContact === true ? 1 : 0
      }

      console.log('handleUpdateContact payload', payLoad);
      // Make the API call
      const response = await postData(ORG_UPDATE_CLIENT_CONTACT, payLoad);
      const responseData = await response.json();
      console.log('handleUpdateContact Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setError(responseData?.info);
            setTimeout(() => {
              handleClose();
              onValueChange();
            }, 2000);
          } else {
            setError(responseData?.info || 'Failed to update contact. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to update contact. Status code error.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update contact. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to update contact: ${String(error)}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setError('');
    onClose();
  }



  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
        </button>
        {!editData ? <h2 className="modal-title">Add New Contact</h2> :
          <h2 className="modal-title">Update Contact</h2>}
        <hr className="title-divider" />

        <div className="form-grid">
          <Input label="Contact Name" placeholder="Enter Contact Name" value={contactName || ''} onChange={(e) => setContactName(e.target.value)} />
          <Input label="Role" placeholder="Enter Role" value={role || ''} onChange={(e) => setRole(e.target.value)} />
          <Input label="Email ID" placeholder="Enter Email ID" value={email || ''} onChange={(e) => setEmail(e.target.value)} />
          {/* <Input label="Phone No." placeholder="Enter mobile number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} /> */}
          <MobileInput
            label="Phone No."
            countryCode={countryCode}
            mobileNumber={mobileNumber || ''}
            onCountryChange={setCountryCode}
            onMobileChange={setMobileNumber}
            placeholder="Enter Phone No."
            countries={[
              { code: "IN", dialCode: "+91" },
              { code: "US", dialCode: "+1" },
              { code: "UK", dialCode: "+44" },
            ]}
          />

        </div>

        <div className="existing-client-checkbox">
          {primaryContact}
          <CheckBox
            label=""
            checked={primaryContact}
            onChange={(isChecked) => setPrimartContact(isChecked)}
          />
          <label style={styles.exisitingHeading}>Primary Contact</label>
          {/* <input type="checkbox" checked={primaryContact} onChange={(e) => setPrimartContact(e.target.checked)} /> */}

        </div>
        {error && <div style={{ textAlign: 'center', color: 'red' }}> {error} </div>}
        <hr className="footer-divider" />

        <div className="button-container">
          {!editData ? <button className="add-client-button" onClick={handleNewContact} disabled={loading}><span style={{ paddingRight: loading ? 10 : 0 }}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Add Contact</button> :
            <button className="add-client-button" onClick={handleUpdateContact} disabled={loading}><span style={{ paddingRight: loading ? 10 : 0 }}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Update Contact</button>}
          <button className="cancel-button" onClick={() => { resetForm(); onClose(); }}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  iconImage: {
    width: '15px',
    height: '15px',
  },
  exisitingHeading: {
    color: '#343C44',
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'Roboto'
  }
}

export default AddNewContactModal;