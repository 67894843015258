import React, { useState } from 'react';
import AddScheduleReminderModal from "../../../Components/AddScheduleReminderModal";

const ScheduleRemindersScreen = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAddUserClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };


    return (
        <div className="tasksScreen-main-content">
            {/* Header Section */}
            <div className='title-container'>
                <h2 className='title'>Schedule & Reminders</h2>
                <button
                    className="add-client-button"
                    style={{ marginBottom: 10 }}
                    onClick={handleAddUserClick}
                >
                    Add Remainder
                </button>
            </div>
            <div className="tasksScreen-tasks-container" style={{ minHeight: 400 }}>
                <img src={require('../../../Assets/Images/Schedule & Reminders - week.png')} alt="Schedule & Reminders - week " style={{ width: '100%', objectFit: 'contain' }} />
                {/* Add Resource Modal */}
                <AddScheduleReminderModal isOpen={isModalOpen} onClose={handleCloseModal} />
            </div>
        </div>
    )

};

export default ScheduleRemindersScreen;