import React, { useState } from 'react';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import forwordIcon from '../../../../Assets/Icons/forward.png'
import AddDocumentModal from '../../../../Components/Modals/AddDocumentModal';
const KYCDocumentsScreen = () => {

    const documents = [
        {
          documentName: "Form 16",
          description: "Period 2024 - 2025",
          downloadLink: "https://example.com/form16",
        },
        {
          documentName: "GST Registration",
          description: "Period 2024 - 2025",
          downloadLink: "https://example.com/gst",
        },
        {
          documentName: "Company Registration",
          description: "Period 2024 - 2025",
          downloadLink: "https://example.com/company",
        },
        {
          documentName: "ITR",
          description: "Period 2024 - 2025",
          downloadLink: "https://example.com/itr2024",
        },
        {
          documentName: "ITR",
          description: "Period 2023 - 2024",
          downloadLink: "https://example.com/itr2023",
        },
      ];
      const [isModalOpen, setIsModalOpen] = useState(false);

        const handleAddDocument = () => {
            setIsModalOpen(true);
        };

        const handleCloseModal = () => {
            setIsModalOpen(false);
        };
    
      return (
        <div className="tasksScreen-task-table-wrapper">
          <h3 className='basic-info-header'>Available Documents</h3>
          <div className="basic-info-outer-card">
          <table className="tasksScreen-task-table" >
            <thead>
              <tr>
                <th style={{ width: "30%", }}>Document Name</th>
                <th style={{ width: "35%" }}>Description</th>
                <th style={{ width: "20%" }}>Download</th>
                <th
                  style={{
                    width: "10%",
                    cursor:'pointer'
                  }}
                  onClick={handleAddDocument}
                >
                  <span style={{fontSize:'15px'}}>+</span> New Document
                </th>
              </tr>
            </thead>
            <tbody style={{backgroundColor:'white'}}>
              {documents?.map((doc, index) => (
                <tr
                  key={index}
                  style={{
                    borderBottom: "3px solid #f8f9fa",
                  }}
                >
                  <td style={styles.tableValue}>{doc.documentName}</td>
                  <td style={styles.tableValue}>{doc.description}</td>
                  <td style={styles.tableLink}>
                    <a>
                      Download <img src={forwordIcon} alt="Forward" style={{ width: "12px", height: "12px" }} />
                    </a>
                  </td>
                  <td
                  >
                    <button className="tasksScreen-edit-button">
                      <img
                        src={editIcon}
                        alt="Edit"
                        style={{ width: "16px", height: "16px" }}
                      />
                    </button>
                    <button className="tasksScreen-delete-button">
                      <img
                        src={deleteIcon}
                        alt="Delete"
                        style={{ width: "16px", height: "16px" }}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          <AddDocumentModal isOpen={isModalOpen} onClose={handleCloseModal}/>
        </div>
      );

};

const styles = {
    header: {
      fontSize: "18px",
      color: "#609966",
      marginBottom: "10px",
      fontWeight:500,
      fontFamily:'Roboto Slab'
    },
    tableValue:{
        colour:'#192A3E',
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px'
    },
    tableLink:{
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px',
        textDecoration:'underline',
        color: '#5A55D2'
    }
  };

export default KYCDocumentsScreen;