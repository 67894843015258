import { useState } from "react"

const UserAccessController = () => {

    const [isManageAccessModalOpen, setIsManageAccessModalOpen] = useState(false);
    const [selUserAccessService, setSelUserAccessService] = useState(null);

    const userAccessList = [
        { moduleName: 'Services', accessLevel: 'Manager', pageAccess: 'GST,Income Tax,Settings', toggleState: 'true' },
        { moduleName: 'Invoices', accessLevel: 'Manager', pageAccess: '_', toggleState: 'false'},
        { moduleName: 'Progress Card', accessLevel: 'Manager', pageAccess: 'Reports,Settings', toggleState: 'false' },
        { moduleName: 'Employee Management', accessLevel: 'Manager', pageAccess: '_', toggleState: 'true'},
        { moduleName: 'Add On', accessLevel: 'Manager', pageAccess: 'Notice Board', toggleState: 'false'},
        { moduleName: 'Configurations', accessLevel: 'Manager', pageAccess: '_', toggleState: 'false'},
        { moduleName: 'Document Rack', accessLevel: 'Manager', pageAccess: '_', toggleState: 'false'},
        { moduleName: 'Settings', accessLevel: 'Manager', pageAccess: '_', toggleState: 'true'},

    ]

    const handleEditUserAccess = (userAccess) => {
        console.log('handleEditUserAccess userAccess @', userAccess)
        setSelUserAccessService(userAccess);
        setIsManageAccessModalOpen(true); 
    }

    const handleToggleChange = (newState) => {
        console.log('Toggle userAccess:', newState);
    }

    const handleCloseManageAccessModal = () => {
        setSelUserAccessService(null);
        setIsManageAccessModalOpen(false); 
    }

    return {
        userAccessList,
        handleEditUserAccess,
        handleToggleChange,
        isManageAccessModalOpen,
        handleCloseManageAccessModal,
        selUserAccessService
    };

}

export default UserAccessController;