import React, { useState } from 'react';
import NatureCallTypeListController from '../../../../Controllers/AdminScreens/Services/Settings/NatureCallTypeListController';
import AddCallTypeModal from '../../../../../Components/Modals/AddCallTypeModal';
import editImg from '../../../../../Assets/Icons/edit.png';
import deleteImg from '../../../../../Assets/Icons/delete.png';
import downArrow from '../../../../../Assets/Icons/chevron-down.png';
import { useNavigate } from 'react-router-dom';

const NatureCallTypeListScreen = () => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);

    const handleNav = (pageName, task) => {
        console.log('page', pageName, 'task', task);
        navigate(`/${pageName}`, { state: { task } }); // Pass task in state
    };
    const {
        tasks,
        currentPage,
        totalPages,
        handlePageChange,
        toggleDetails
    } = NatureCallTypeListController();

    const getStatusStyle = (status) => {
        switch (status) {
            case "Incomplete":
                return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" }; // Green
            case "Disabled":
                return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" }; // Yellow
            case "Active":
                return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" }; // Red
            default:
                return {};
        }
    };

    const addCallTypeModalVisible = () => {
        setModalVisible(true)
    }

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    return (
        <>
            <div className="tasksScreen-tasks-header">
                <h2 className="tasksScreen-tasks-title">Call Types</h2>
                <button className="tasksScreen-apply-button" onClick={addCallTypeModalVisible}>Add New</button>
            </div>

            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>

                        {/* Table Headers */}
                        <tr>
                            <th style={{ width: "30%", }}>Nature of Call</th>
                            <th style={{ width: "40%", }}>Description</th>
                            <th style={{ width: "10%", }}>Created By</th>
                            <th style={{ width: "12%", }}>Status</th>
                            <th style={{ width: "8%", }}></th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#fff' }}>
                        {tasks.map((task, index) => (
                            <React.Fragment key={index}>
                                <tr className={`tasksScreen-main-task-row ${task.showDetails ? 'tasksScreen-show-border' : ''}`}>
                                    <td style={{ textDecoration: 'underline', color: '#5A55D2', cursor: 'pointer' }}>{task.clientName}</td>
                                    <td>{task.description || "--"}</td>
                                    <td>{task.assignedTo}</td>
                                    <td>
                                        <div
                                            style={{
                                                display: "inline-block",
                                                padding: "5px 5px",
                                                borderRadius: "15px",
                                                ...getStatusStyle(task.status),
                                                width: "auto",
                                            }}
                                        >
                                            {task.status}
                                        </div>
                                    </td>
                                    <td>
                                        <button className="tasksScreen-edit-button">
                                            <img src={editImg} alt="Edit" onClick={() => handleNav('manage-clients', 'Tasks')} />
                                        </button>
                                        <button className="tasksScreen-delete-button">
                                            <img src={deleteImg} alt="Delete" />
                                        </button>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="tasksScreen-table-footer">
                <span className="tasksScreen-pagination-info">
                    {`${currentPage}-${totalPages} of items`}
                </span>
                <div className="tasksScreen-pagination">
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
                    </button>
                    {[...Array(totalPages)].map((_, index) => (
                        <button key={index} className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    ))}
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                        <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
                    </button>
                </div>
                <AddCallTypeModal isOpen={modalVisible} onClose={handleCloseModal} />
            </div>
        </>
    );
};

const styles = {
    header: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        fontFamily: 'Montserrat',
        //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader: {
        marginTop: '-5px'
    }
};

export default NatureCallTypeListScreen;
