import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const ManageUsersController = () => {

    const navigate = useNavigate();
    const [isUserEditable, SetIsUserEditable] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [username, setUsername] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [countryCode, setCountryCode] = useState('+91');
    const [manager, setManager] = useState("");
    const [email, setEmail] = useState("");
    const manageUsers = [
        {
            username: "John Doe",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
            status: "Active"
        },
        {
            username: "John Doe",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
            status: "Incomplete"
        },
        {
            username: "John Doe",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
            status: "Disabled"
        },
        {
            username: "John Doe",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
            status: "Disabled"
        },
        {
            username: "John Doe",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
            status: "Disabled"
        },
        {
            username: "John Doe",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
            status: "Disabled"
        },
        {
            username: "John Doe",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
            status: "Incomplete"
        },
        {
            username: "John Doe",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
            status: "Incomplete"
        }
    ]

    const handleAddDocument = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const getStatusStyle = (status) => {
        switch (status) {
            // case "Incomplete":
            //     return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" }; // Green
            case 0:
                return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" }; // Yellow
            case 1:
                return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" }; // Red
            default:
                return {};
        }
    };

    const handleEditUser = (value,userInfo) => {
        const editable = value === 'editUser';
        SetIsUserEditable(editable);
        console.log('editable controller : ', editable)
        navigate('/manage-user', { state: { isUserEditable: editable,userInfo:userInfo } });
    }

    return {
        isModalOpen,
        username,
        mobileNumber,
        countryCode,
        manager,
        email,
        setCountryCode,
        setEmail,
        setManager,
        setMobileNumber,
        setUsername,
        handleAddDocument,
        handleCloseModal,
        getStatusStyle,
        handleEditUser,
        isUserEditable
    };
}

export default ManageUsersController;