import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postDataLogin } from '../../../ApiServices/ApiService';
import { SIGNUP_API } from '../../../ApiServices/BaseURL';

const MFAScreenController = () => {
    const [mfa, setMfa] = useState(''); // State to store the OTP entered by the user
    const [error, setError] = useState(''); // State for error messages
    const [loading, setLoading] = useState(false); // State to track loading
    const [resendTimer, setResendTimer] = useState(15); // Timer for resend functionality


    const navigate = useNavigate();

    // Function to handle input changes for MFA code
    const handleMfaChange = (text) => {
        // Only allow numbers and limit the input to 6 characters
        const value = text.replace(/[^0-9]/g, '').slice(0, 6);
        setMfa(value);
    };

    // Function to verify the MFA code
    const verify = async () => {
        setError('');
        if (mfa.length !== 6) {
            setError('Please enter a valid 6-digit code.');
            return;
        }

        setLoading(true);
        setTimeout(() => {
            navigate('/dashboard');
            setLoading(false);
        }, 1000);

        return;

        try {
            setLoading(true);

            const payload = {
                mfa_code: mfa,
            };

            const response = await postDataLogin(SIGNUP_API, payload);
            const responseData = await response.json();

            if (response.status === 200 && responseData.statusCode === 200) {
                // Successful verification
                console.log('Verification successful');
                navigate('/dashboard'); // Navigate to the dashboard or the next page
            } else {
                setError(responseData?.message || 'Failed to verify the code. Please try again.');
            }
        } catch (err) {
            setError('An error occurred while verifying the code. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    // Function to handle resend logic
    const resendCode = async () => {
        setError('');
        if (resendTimer > 0) return;

        try {
            setLoading(true);

            // Add API call to resend the MFA code here
            const response = await postDataLogin(SIGNUP_API, { resend: true });
            const responseData = await response.json();

            if (response.status === 200 && responseData.statusCode === 200) {
                setResendTimer(15); // Reset the timer
            } else {
                setError(responseData?.message || 'Failed to resend the code. Please try again.');
            }
        } catch (err) {
            setError('An error occurred while resending the code. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    // Countdown for resend timer
    useState(() => {
        const timer = setInterval(() => {
            setResendTimer((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return {
        mfa,
        setMfa,
        handleMfaChange,
        verify,
        resendCode,
        loading,
        error,
        resendTimer,
    };
};

export default MFAScreenController;
