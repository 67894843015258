import React, { useState } from 'react';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import eyeIcon from '../../../../Assets/Icons/Eye-2.png'
import eyeCloseIcon from '../../../../Assets/Icons/Eye off.png'
import forwordIcon from '../../../../Assets/Icons/forward.png'
import AddUserIdPasswordModal from '../../../../Components/Modals/AddUserIdPasswordModal';

const UserIdPasswordScreen = () => {

    const [userCredentials, setUserCredentials] = useState([
        { portalName: "GSTIN", username: "exampleUserID", password: "A@#MAOP", showPassword: false, url: "https://portal.example.com" },
        { portalName: "TRACES", username: "exampleUserID", password: "B#@CDE", showPassword: false, url: "https://portal.example.com" },
        { portalName: "ITR Portal", username: "exampleUserID", password: "12345678", showPassword: false, url: "https://portal.example.com" },
        { portalName: "E-Way Bill", username: "exampleUserID", password: "password", showPassword: false, url: "https://portal.example.com" },
        { portalName: "Income Tax", username: "exampleUserID", password: "mySecret", showPassword: false, url: "https://portal.example.com" },
      ]);

      const [isModalOpen, setIsModalOpen] = useState(false);

        const handleAddCredientailsClick = () => {
            setIsModalOpen(true);
        };

        const handleCloseModal = () => {
            setIsModalOpen(false);
        };

        const togglePasswordVisibility = (index) => {
            setUserCredentials((prevCredentials) =>
              prevCredentials.map((credential, i) =>
                i === index ? { ...credential, showPassword: !credential.showPassword } : credential
              )
            );
          };
        
    
      return (
        <div className="tasksScreen-task-table-wrapper">
          <h3 className='basic-info-header'>User Names</h3>
          <div className="basic-info-outer-card">
          <table
            className="tasksScreen-task-table">
            <thead>
              <tr>
                <th style={{ width: "25%" }}>Portal Name</th>
                <th style={{ width: "20%"}}>Username</th>
                <th style={{ width: "20%" }}>Password</th>
                <th style={{ width: "20%"}}>URL</th>
                <th
                  style={{
                    width: "10%", 
                    cursor:'pointer'
                  }}
                  onClick={handleAddCredientailsClick}
                >
                  <span style={{fontSize:'15px'}}>+</span> New Credential
                </th>
              </tr>
            </thead>
            <tbody style={{backgroundColor:'white'}}>
              {userCredentials?.map((credential, index) => (
                <tr
                  key={index}
                  style={{
                    borderBottom: "3px solid #f8f9fa",
                  }}
                >
                  <td style={styles.tableValue}>{credential.portalName}</td>
                  <td style={styles.tableValue}>{credential.username}</td>
                  <td style={styles.tableValue}>
                    {credential.showPassword ? credential.password : "*********"}{" "}
                    <button
                    style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        marginLeft: "5px",
                    }}
                    onClick={() => togglePasswordVisibility(index)}
                    >
                    <img
                        src={credential.showPassword ? eyeCloseIcon : eyeIcon}
                        alt="Toggle Visibility"
                        style={{ width: "16px", height: "16px" }}
                    />
                    </button>
                </td>
                  <td style={styles.tableLink}>
                    <a href={credential.url} target='_blank'>
                      Portal Link <img src={forwordIcon} />
                    </a>
                  </td>
                  <td
                  >
                    <button className="tasksScreen-edit-button">
                      <img
                        src={editIcon}
                        alt="Edit"
                        style={{ width: "16px", height: "16px" }}
                      />
                    </button>
                    <button className="tasksScreen-delete-button">
                      <img
                        src={deleteIcon}
                        alt="Delete"
                        style={{ width: "16px", height: "16px" }}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          <AddUserIdPasswordModal isOpen={isModalOpen} onClose={handleCloseModal}/>
        </div>
      );

};

const styles = {
    header: {
      fontSize: "18px",
      color: "#609966",
      marginBottom: "10px",
      fontWeight:500,
      fontFamily:'Roboto Slab'
    },
    tableValue:{
        colour:'#192A3E',
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px'
    },
    tableLink:{
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px',
        textDecoration:'underline',
        color: '#5A55D2'
    }
  };

export default UserIdPasswordScreen;