import React, { useState } from 'react';
import CheckBox from '../../../../Components/CheckBox';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import forwordIcon from '../../../../Assets/Icons/forward.png';

const BillingScreen = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [checkedStates, setCheckedStates] = useState([]);

  const services = [
    {
      serviceName: "GST Filling",
      fees: 2000,
      frequency: "Yearly",
      remark: "Yearly",
      status: "Paid",
      invoiceLink: "https://example.com/invoice/gst",
    },
    {
      serviceName: "Company Reg",
      fees: 2000,
      frequency: "Yearly",
      remark: "Yearly",
      status: "Not Submitted",
      invoiceLink: "https://example.com/invoice/company-reg",
    },
    {
      serviceName: "Company Registration",
      fees: 2000,
      frequency: "Yearly",
      remark: "Yearly",
      status: "Pending",
      invoiceLink: "https://example.com/invoice/company-registration",
    },
    {
      serviceName: "ITR",
      fees: 2000,
      frequency: "Yearly",
      remark: "Yearly",
      status: "Pending",
      invoiceLink: "https://example.com/invoice/itr",
    },
    {
      serviceName: "Retention Charges",
      fees: 2000,
      frequency: "Monthly",
      remark: "Monthly",
      status: "Pending",
      invoiceLink: "https://example.com/invoice/retention",
    },
  ];

  const getStatusStyle = (status) => {
    switch (status) {
      case "Paid":
        return { backgroundColor: "#d4edda", color: "#155724" }; // Green
      case "Not Submitted":
        return { backgroundColor: "#fff3cd", color: "#856404" }; // Yellow
      case "Pending":
        return { backgroundColor: "#f8d7da", color: "#721c24" }; // Red
      default:
        return {};
    }
  };

  const totalBalance = services.reduce((acc, service) => acc + service.fees, 0);

  const handleSelectAll = (isChecked) => {
    setSelectAll(isChecked);
    setCheckedStates(services.map(() => isChecked));
  };

  const handleCheckboxChange = (index, isChecked) => {
    const updatedStates = [...checkedStates];
    updatedStates[index] = isChecked;
    setCheckedStates(updatedStates);
  };

  return (
    <div className="tasksScreen-task-table-wrapper">
      <h3 className='basic-info-header'>Agreed Billing & Fees</h3>
      <table className="tasksScreen-task-table">
        <thead>
          <tr>
            <th style={{ width: "5%" }}>
              {/* <input type="checkbox" /> */}
              <CheckBox
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            <th style={{ width: "20%" }}>
              Service Name
            </th>
            <th style={{ width: "10%" }}>
              Fees
            </th>
            <th style={{ width: "10%" }}>
              Frequency
            </th>
            <th style={{ width: "10%" }}>
              Remark
            </th>
            <th style={{ width: "15%" }}>
              Status
            </th>
            <th style={{ width: "15%" }}>
              Invoice/Receipt
            </th>
            <th style={{ width: "10%" }}>
              + New Service
            </th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: 'white' }}>
          {services?.map((service, index) => (
            <tr
              key={index}
              style={{
                borderBottom: "3px solid #f8f9fa",
              }}
            >
              <td >
                {/* <input type="checkbox" /> */}
                <CheckBox
                  checked={checkedStates[index] || false}
                  onChange={(isChecked) => handleCheckboxChange(index, isChecked)}
                />
              </td>
              <td style={styles.tableValue}>
                {service.serviceName}
              </td>
              <td style={styles.tableValue}>
                {service.fees}
              </td>
              <td style={styles.tableValue}>
                {service.frequency}
              </td>
              <td style={styles.tableValue}>
                {service.remark}
              </td>
              <td style={styles.tableValue}>
                <div
                  style={{
                    display: "inline-block",
                    padding: "5px 10px",
                    borderRadius: "15px",
                    ...getStatusStyle(service.status),
                  }}
                >
                  {service.status}
                </div>
              </td>
              <td style={styles.tableLink}>
                <a >
                  Download <img src={forwordIcon} alt="Forward" style={{ width: "12px", height: "12px" }} />
                </a>
              </td>
              <td
              >
                <button className="tasksScreen-edit-button" style={{ marginRight: "10px" }}>
                  <img src={editIcon} alt="Edit" style={{ width: "16px", height: "16px" }} />
                </button>
                <button className="tasksScreen-delete-button">
                  <img src={deleteIcon} alt="Delete" style={{ width: "16px", height: "16px" }} />
                </button>
              </td>
            </tr>
          ))}
          {/* Total Balance Row */}
          <tr style={{ backgroundColor: "#EDF1D6", borderBottom: '2px solid black' }}>
            <td colSpan="2" style={styles.tableValue} >
              Total Balance
            </td>
            <td colSpan="1" style={styles.tableValue}>
              {totalBalance.toFixed(2)}
            </td>
            <td colSpan="5" style={{ padding: "10px 12px", }}></td>
          </tr>
        </tbody>
      </table>
      <button
        style={{
          marginTop: "10px",
          padding: "10px 20px",
          backgroundColor: "#2b542c",
          color: "#fff",
          border: "none",
          borderRadius: "20px",
          cursor: "pointer",
        }}
      >
        Send Reminder
      </button>
    </div>
  );
};

const styles = {
  header: {
    fontSize: "18px",
    color: "#609966",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: 'Roboto Slab'
  },
  tableValue: {
    colour: '#192A3E',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px'
  },
  tableLink: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#5A55D2'
  }
};

export default BillingScreen;
