import React, { useEffect,useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getData } from '../../../../ApiServices/ApiService';
import { ORG_GET_TEAM_LIST,ORG_GET_EMPLOYEE_LIST } from '../../../../ApiServices/BaseURL';

const TeamsUsersManagementController = () => {
    const navigate = useNavigate();
    const [teamList, setTeamList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');  

    const [userList, setUserList] = useState([]);
    const [userLoading, setUserLoading] = useState(false);
    const [userError, setUserError] = useState('');  

    useEffect(()=>{
        fetchTeamList();
        fetchUserList();
      },[])

    const fetchTeamList =async ()=>{
        try {
            setLoading(true);
            setError('');
            setTeamList([]);


            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_TEAM_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    setTeamList(responseData?.dataJ);
                  } else {
                    setError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch Task Categorie data: ${String(error)}`);
            }
          } finally {
            setLoading(false);
          }
      }

      const fetchUserList =async ()=>{
        try {
          setUserLoading(true);
          setUserError('');
            setUserList([]);


            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_EMPLOYEE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    setUserList(responseData?.dataJ);
                  } else {
                    setUserError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setUserError(responseData?.info || 'Failed to fetch User data. Please try again.');
                }
              } else {
                setUserError(responseData?.info || 'Failed to fetch User data. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setUserError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setUserError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setUserError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setUserError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setUserError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setUserError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setUserError(`Failed to fetch User data: ${String(error)}`);
            }
          } finally {
            setUserLoading(false);
          }
      }

      const refreshTeamList=()=>{

      }

      const refreshUserList=()=>{

      }

    return {
        teamList,
        error,
        loading,
        refreshTeamList,
        userList,
        userError,
        userLoading,
        refreshUserList
    }

}

export default TeamsUsersManagementController;