const UserAssignmentsController = () => {

    const userAssignmentsList = [
        { clientName: 'John Doe', groupName: 'Company', groupMaster: '_', tasks: '12', managerName: 'John Doe' },
        { clientName: 'John Doe', groupName: 'Company', groupMaster: '_', tasks: '19', managerName: 'John Doe' },
        { clientName: 'John Doe', groupName: 'Company', groupMaster: '_', tasks: '10', managerName: 'John Doe' },
        { clientName: 'John Doe', groupName: 'Company', groupMaster: '_', tasks: '09', managerName: 'John Doe' },
        { clientName: 'John Doe', groupName: 'Company', groupMaster: '_', tasks: '12', managerName: 'John Doe' }

    ]

    const handleAddClients = () => {
        
    }

    return {
        userAssignmentsList,
        handleAddClients
    };

}

export default UserAssignmentsController;