import { useState,useEffect } from 'react';
import { validateEmail } from '../../../Utils/Validation';
import { useTheme } from '../../../GlobalContext/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { getParamsData } from '../../../ApiServices/ApiService';
import { DASHBOARD_DETAILS } from '../../../ApiServices/BaseURL';

const useDashboardController = ()=>{
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const screenName = 'DashboardScreen';
  const navigator = useNavigate(); 



  const handleCreateTask = () =>{
    navigator('/create-task')
  }

  return {
    loading,
    handleCreateTask
};
}

export default useDashboardController;