import React from 'react';
import ForgotPasswordController from '../../Controllers/AuthenticationScreens/ForgotPasswordContoller';
import TextInput from '../../../Components/TextInput';
import Loader from '../../../Components/Loader';
import logo from '../../../Assets/Images/logo.png';

const ForgotPasswordScreen = () => {
    const {
        step,
        email,
        newPassword,
        confirmPassword,
        error,
        loading,
        setEmail,
        setNewPassword,
        setConfirmPassword,
        handleEmailSubmit,
        handlePasswordSubmit,
        resetState,
        showNewPassword,
        showConfirmPassword,
        toggleShowNewPassword,
        toggleShowConfirmPassword,
        handleLoginRedirect
    } = ForgotPasswordController();

    return (
        <div className="login">
            <div className="frameParent" style={{ width: '1000px' }}>
                {step === 1 && (
                    <div className="frameGroup">
                        <div className="groupParent">
                            <img className="frameChild" alt="" src={logo} />
                            <div className="companyName">Company Name</div>
                        </div>
                        <h2 style={{ color: '#609966', fontSize: '18px', }}>Forgot Password</h2>
                        <div style={{ width: '275px', wordWrap: 'break-word', fontSize: '12px', textAlign: 'center' }}>
                            <p>Please enter your username and we will send you instructions to reset your password. address</p>
                        </div>
                        <div>
                            <TextInput
                                placeholder="Username"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{ minWidth: '360px' }}
                            />
                        </div>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <button className="verifyButton" onClick={handleEmailSubmit} disabled={loading}>
                            <Loader loading={loading} color={'#FFFFFF'} size="small" />
                            <span style={{ paddingLeft: loading ? 10 : 0 }}>Continue</span>
                        </button>
                        <div style={{ textAlign: 'center' }}>
                            <span style={{ color: '#8388A2', fontSize: '12px', fontWeight: '500', wordWrap: 'break-word' }}>Not sure about username, </span>
                            <span style={{ color: '#8388A2', fontSize: '12px', fontWeight: '500', textDecoration: 'underline', wordWrap: 'break-word' }}>contact admin.</span>
                        </div>
                    </div>
                )}

                {step === 2 && (
                    <div className="frameGroup">
                        <div className="groupParent">
                            <img className="frameChild" alt="" src={logo} />
                            <div className="companyName">Company Name</div>
                        </div>
                        <h2>Forgot Password</h2>
                        <p>Update password for username: <strong>{email}</strong></p>
                        <div>
                            <TextInput
                                placeholder="Password"
                                value={newPassword} // Ensure value is a string
                                onChange={(e) => setNewPassword(e.target.value)}
                                type={showNewPassword ? 'text' : 'password'}
                                style={{ minWidth: '360px' }}
                                right={
                                    <button onClick={toggleShowNewPassword} className="toggle-button">
                                        {showNewPassword ? (
                                            <img src={require('../../../Assets/Icons/Eye off.png')} alt="Hide " />
                                        ) : (
                                            <img src={require('../../../Assets/Icons/Eye-2.png')} alt="Show " />
                                        )}
                                    </button>
                                }
                            />
                        </div>
                        <div>
                            <TextInput
                                placeholder="Re-Enter Password"
                                value={confirmPassword} // Ensure value is a string
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type={showConfirmPassword ? 'text' : 'password'}
                                style={{ minWidth: '360px' }}
                                right={
                                    <button onClick={toggleShowConfirmPassword} className="toggle-button">
                                        {showConfirmPassword ? (
                                            <img src={require('../../../Assets/Icons/Eye off.png')} alt="Hide " />
                                        ) : (
                                            <img src={require('../../../Assets/Icons/Eye-2.png')} alt="Show " />
                                        )}
                                    </button>
                                }
                            />
                        </div>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <button className="verifyButton" onClick={handlePasswordSubmit} disabled={loading}>
                            <Loader loading={loading} color={'#FFFFFF'} size="small" />
                            <span style={{ paddingLeft: loading ? 10 : 0 }}>Continue</span>
                        </button>
                        <div style={{ textAlign: 'center' }}>
                            <span style={{ color: '#8388A2', fontSize: '12px', fontWeight: '500', wordWrap: 'break-word' }}>Facing difficulties? </span>
                            <span style={{ color: '#8388A2', fontSize: '12px', fontWeight: '500', textDecoration: 'underline', wordWrap: 'break-word' }}>contact admin.</span>
                        </div>
                    </div>
                )}

                {step === 3 && (
                    <div className="frameGroup" style={{ gap: '65px' }}>
                        <div className="groupParent">
                            <img className="frameChild" alt="" src={logo} />
                            <div className="companyName">Company Name</div>
                        </div>
                        <h2 style={{ color: '#609966', fontSize: '28px', }}>Password Reset Successful</h2>
                        <button className="verifyButton" onClick={handleLoginRedirect}>
                            <span style={{ paddingLeft: loading ? 10 : 0 }}>Login Now</span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ForgotPasswordScreen;
