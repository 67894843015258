import React, { useState } from 'react';
import Tabs from '../../../../Components/Tabs';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import forwordIcon from '../../../../Assets/Icons/forward.png'
import AddTaskTypeModal from '../../../../Components/Modals/AddTaskTypeModal';
import useTaskTypeController from '../../../Controllers/AdminManagementScreens/TaskManagement/TaskTypeController';
import Loader from '../../../../Components/Loader';
import filter from '../../../../Assets/Icons/filter.png';
import downArrow from '../../../../Assets/Icons/chevron-down.png';
import Dropdown from '../../../../Components/Dropdown';

const TaskTypeScreen = ({categories,subCategories,loading,error,onRefresh}) => {
   
  const {
    // subCategories,
    isModalOpen,
    handleAddCategory,
    handleCloseModal,
    getStatusStyle,
    handleEditService,
    categorieInfo,
    subCategoryInfoRef,
    OnAddUpdateSucess,
    handleDeleteTaskType,
    // loading,
    deleteLoading,
    deleteError,
    filterError,
    showFilters,
    filters,
    handleFilterChange,
    applyFilters,
    resetFilters,
    toggleFilters,
    categoryListRef,
    subCategoryListRef,    
    subLoading,
    subError,
    tasks,
    currentPage,
    totalPages,
    handlePageChange,
  } = useTaskTypeController(onRefresh,categories,subCategories,loading,error);
  console.log('subCategories:----------',subCategories)

  console.log('TaskTypeScreen @@@ deleteLoading ', deleteLoading)
  console.log('TaskTypeScreen @@@ deleteError ', deleteError)


    return (
        <div className='basic-info-container'>
            <div style={{display:'flex', justifyContent:'space-between'}}>
            <h3 style={styles.categoryHeader}>Task Type</h3>
            <div>
            <button className="add-client-button" style={{paddingLeft:30,paddingRight:30}} onClick={handleAddCategory}>
              Add Task Type
            </button>
            </div>
            </div>
            <div className="tasksScreen-filters-row">
                    <div className="tasksScreen-filters-button-wrapper">
                        <button className="tasksScreen-filters-button" onClick={toggleFilters}>
                            <img src={filter} alt="Filter"/>  <span>
                            {showFilters ? 'Filters' : 'Filters'}
                            </span>
                            
                        </button>
                    </div>
            </div>

                {showFilters && (
                    <div className={`tasksScreen-filters-container ${showFilters ? 'tasksScreen-open-filters' : ''}`}>
                        <div className="tasksScreen-filters">
                            {/* Replace each filter select with Dropdown component */}
                            <Dropdown
                                label="Service"
                                options={categoryListRef.current || []}
                                value={filters?.service}
                                onChange={(value) => handleFilterChange('service', value)}
                            />

                        </div>
                        <div>
                            {filterError && <div style={{color:'red',paddingTop:5}}> {filterError}</div>}

                        <div className="tasksScreen-filter-buttons">
                            <button onClick={applyFilters} className="tasksScreen-apply-button">Apply</button>
                            <button onClick={toggleFilters} className="tasksScreen-cancel-button">Cancel</button>
                            <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
                        </div>
                        </div>
                    </div>
                )}
            <div style={styles.tableWrapper}>
            <table className="tasksScreen-task-table">
                <thead>
                    <tr>
                    <th style={{ width: "30%" }}>Service Category </th>
                    <th style={{ width: "30%" }}>Description</th>
                    <th style={{ width: "20%" }}>Service</th>
                    <th style={{ width: "10%" }}>Created By</th>
                    <th style={{ width: "10%" }}>Status</th>
                    <th style={{ width: "10%" }}></th>
                    </tr>
                </thead>
                {tasks?.length > 0 &&
                <tbody style={{ backgroundColor: "white" }}>
                    {tasks?.map((category, index) => (
                    <tr key={index}>
                        <td style={styles.tableLink}>{category?.service_sub_name}</td>
                        <td style={styles.tableValue}>{category?.service_sub_desc}
                        {deleteError[category?.service_sub_id] && 
                        <div style={{...styles.deleteErrorMsg, paddingTop: 10}} >{deleteError[category?.service_sub_id]}</div>}
                        </td>
                        <td style={styles.tableLink}>{category?.service_name}</td>
                        <td style={styles.tableValue}>{category?.create_by_name}</td>
                        <td style={styles.tableValue}>
                                {/* Status Label */}
                                <div
                                style={{
                                    display: "inline-block", // Makes the div wrap content and have its own width
                                    padding: "5px 10px", // Padding for the button-like appearance
                                    borderRadius: "15px",
                                    ...getStatusStyle(category?.service_sub_status), // Dynamic styles based on the status
                                    width: "auto", // Width adjusts automatically to content
                                }}
                                >
                                {category?.service_sub_status === 1 ? 'Active':'Disabled'}
                                </div>

                                {/* Action Buttons */}
                            </td>
                            <td>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "2px", // Space between edit and delete buttons
                                }}
                                >
                                {/* Edit Button */}
                                <button
                                    className="tasksScreen-edit-button"
                                    style={{
                                    border: "none",
                                    background: "transparent",
                                    cursor: "pointer",
                                    }}
                                    onClick={() => {handleEditService(category)}}
                                >
                                    <img
                                    src={editIcon}
                                    alt="Edit"
                                    style={{ width: "16px", height: "16px" }}
                                    />
                                </button>

                                {/* Delete Button */}
                                <button
                                    className="tasksScreen-delete-button"
                                    style={{
                                    border: "none",
                                    background: "transparent",
                                    cursor: "pointer",
                                    opacity: deleteLoading[category?.service_sub_id] ? 0.3 : 1
                                    }}
                                    onClick={() => {handleDeleteTaskType(category)}}
                                    disabled={deleteLoading[category?.service_sub_id]}
                                >
                                    <img
                                    src={deleteIcon}
                                    alt="Delete"
                                    style={{ width: "16px", height: "16px" }}
                                    />
                                </button>
                                {deleteLoading[category?.service_sub_id] &&
                                    <span><Loader loading={deleteLoading[category?.service_sub_id]} color={'var(--primary-color)'} size='small' /></span> 
                                }
                                </div>
                            </td>

                    </tr>
                    ))}
                </tbody>
                }
                </table>
                {subLoading && <Loader loading={subLoading} color={'var(--primary-color)'} />}
                {subError && <div className="list-error-msg">{subError} </div>}
                {isModalOpen &&
                <AddTaskTypeModal isOpen={isModalOpen} onClose={handleCloseModal} editData={subCategoryInfoRef.current} onValueChange={OnAddUpdateSucess}/>}
            </div>
              {/* Pagination */}
              <div className="tasksScreen-table-footer" >
                    <span className="tasksScreen-pagination-info">
                        {`${currentPage}-${totalPages} of items`}
                    </span>
                    <div className="tasksScreen-pagination">
                        <button
                            className="tasksScreen-pagination-button"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            <img
                                src={downArrow}
                                alt="Previous"
                                className="tasksScreen-arrow-left tasksScreen-icon-image"
                            />
                        </button>
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            className="tasksScreen-pagination-button"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            <img
                                src={downArrow}
                                alt="Next"
                                className="tasksScreen-arrow-right tasksScreen-icon-image"
                            />
                        </button>
                    </div>
                </div>

        </div>


    )

};

const styles = {
    categoryHeader: {
        color:'#1F384C',
        fontSize:28,
        fontFamily:'Roboto Slab',
        fontWeight:500,
        paddingBottom:20
    },
    tableWrapper: {
        overflowX: "auto", // Horizontal scroll
        whiteSpace: "nowrap", // Prevent table from wrapping on small devices
        marginBottom:50
      },
      tableValue:{
        colour:'#192A3E',
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px'
    },
    tableLink:{
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px',
        textDecoration:'underline',
        color: '#5A55D2'
    },
    deleteErrorMsg: {
        color: 'red',
    }

}

export default TaskTypeScreen;