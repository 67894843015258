import React, { useState } from 'react';
import UserSpecializationController from '../../../Controllers/AdminScreens/Users/UserSpecializationController';
import editIcon from '../../../../Assets/Icons/edit.png';
import Toggle from '../../../../Components/Toggle';
import ClientManageServicesModal from '../../../../Components/Modals/ClientManageServicesModal';

const UserSpecializationScreen = () => {

    const { userSpecializationServices,
        handleEditSpecialization,
        handleToggleChange,
        selUserSpecializationService,
        isUserSpecializationModalOpen,
        handleCloseUserSpecializationModal
    } = UserSpecializationController();

    return (
        <div className="basic-info-container">
            <h3 className='basic-info-header'>Specialization</h3>
            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>
                        <tr>
                            <th style={{ width: '25%' }}>Service Category</th>
                            <th style={{ width: '65%' }}>Service Type</th>
                            <th style={{ width: '10%' }}></th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: 'white' }}>
                        {userSpecializationServices.map((service, index) => (
                            <tr
                                key={index}
                                className="tasksScreen-main-task-row"
                                style={{ borderBottom: "1px solid #dee2e6" }}
                            >
                                <td style={{ cursor: 'pointer', textDecoration: 'underline', color: '#5a55d2' }}>{service.category}</td>
                                <td>{service.type}</td>
                                <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <button
                                        className="tasksScreen-edit-button"
                                        onClick={() => handleEditSpecialization(service)}
                                    >
                                        <img
                                            src={editIcon}
                                            alt="Edit"
                                            style={{ width: "16px", height: "16px" }}
                                        />
                                    </button>
                                    {/* <button className="tasksScreen-delete-button">
                                    <img
                                        src={deleteIcon}
                                        alt="Delete"
                                        style={{ width: "16px", height: "16px" }}
                                    />
                                </button> */}
                                    <Toggle initialStatus={false} onToggle={handleToggleChange} size='small'/>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
            {/* Manage Specialization Modal */}
            {isUserSpecializationModalOpen && (
                <ClientManageServicesModal
                    isOpen={isUserSpecializationModalOpen}
                    onClose={handleCloseUserSpecializationModal}
                    selUserSpecializationService={selUserSpecializationService} // Pass the selected service
                />
            )}
        </div>
    )

};

export default UserSpecializationScreen;