import React, { useState } from 'react';
import Tabs from '../../../../Components/Tabs';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import forwordIcon from '../../../../Assets/Icons/forward.png'
import AddTaskCategoryModal from '../../../../Components/Modals/AddTaskCategoryModal';
import Input from '../../../../Components/Input';
import MobileInput from '../../../../Components/MobileInput';
import filterIcon from '../../../../Assets/Icons/filter.png'
import leftBtuIcon from '../../../../Assets/Icons/back.png'
import AddUserModal from '../../../../Components/AddUserModal';
import ManageUsersController from '../../../Controllers/AdminManagementScreens/TeamsAndUsers/ManageUsersController';
import Loader from '../../../../Components/Loader';

const ManageUsersScreen = ({userList,error,loading,onRefresh}) => {

  const { handleEditUser, isUserEditable,
    isModalOpen,
    username,
    mobileNumber,
    countryCode,
    manager,
    email,
    setCountryCode,
    setEmail,
    setManager,
    setMobileNumber,
    setUsername,
    handleAddDocument,
    handleCloseModal,
    getStatusStyle,
  } = ManageUsersController(onRefresh);
      
      


    return (
        <>
        <div className='basic-info-container'>
        <div style={{display:'flex', justifyContent:'space-between'}}>
            <h3 style={styles.categoryHeader}>Search User</h3>
            <div>
            <button className="add-client-button" style={{paddingLeft:30,paddingRight:30}} onClick={handleAddDocument}>
              Add User
            </button>
            </div>
            </div>
        <div style={styles.container}>
      <div style={styles.filtersHeader}>
        <button style={styles.filterButton}> <img src={filterIcon} style={styles.filterIcon}/> Filters</button>
        <div >
          <img src={leftBtuIcon}  style={{marginRight:'5px',cursor:'pointer',width:'28px',height:'28px'}}/>
          <img src={leftBtuIcon} style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease',cursor:'pointer',width:'28px',height:'28px' }} />
        </div>
      </div>
      </div>
      {/* Filters using Flexbox */}
      <div style={styles.filtersContainer}>
        <div style={{display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
        <Input
          label="Username"
          placeholder="------"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        {/* <Input
          label="Mobile No."
          placeholder="------"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        /> */}
        <MobileInput
                label="Mobile No."
                countryCode={countryCode}
                mobileNumber={mobileNumber}
                onCountryChange={setCountryCode}
                onMobileChange={setMobileNumber}
                placeholder="------"
                countries={[
                    { code: "IN", dialCode: "+91" },
                    { code: "US", dialCode: "+1" },
                    { code: "UK", dialCode: "+44" },
                ]}
                width='100%'
            />
        <Input
          label="Manager"
          placeholder="------"
          value={manager}
          onChange={(e) => setManager(e.target.value)}
        />
        <Input
          label="Email ID"
          placeholder="------"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        </div>
        <div className="button-container" style={{marginBottom:'15px'}}>
          <button className="add-client-button" style={{marginRight:'8px', paddingLeft:'30px', paddingRight:'30px'}}>Apply</button>
          <button className="cancel-button" style={{border:'1px #40513B solid', borderRadius:'20px'}} >Cancel</button>
          <button className="cancel-button">Reset</button>
        </div>
      </div>

        </div>

        <div className='basic-info-card' style={{border:'none'}}>
            <div style={styles.tableWrapper}>
            <table className="tasksScreen-task-table">
                <thead>
                    <tr>
                    <th style={{ width: "20%" }}>User Name</th>
                    <th style={{ width: "15%" }}>Mobile#</th>
                    <th style={{ width: "20%" }}>Email</th>
                    <th style={{ width: "25%" }}>Team Name</th>
                    <th style={{ width: "10%" }}>Manager</th>
                    <th style={{ width: "5%" }}>Status</th>
                    <th style={{ width: "5%" }}></th>
                    </tr>
                </thead>
                {userList?.length > 0 &&
                <tbody style={{ backgroundColor: "white" }}>
                    {userList?.map((category, index) => (
                    <tr key={index}>
                        <td style={styles.tableLink} onClick={() =>  handleEditUser('userName',category)}>{category?.emp_name}</td>
                        <td style={styles.tableValue}>{category?.emp_mobile_code}{category?.emp_mobile_no}</td>
                        <td style={styles.tableValue}>{category?.emp_email}</td>
                        <td style={styles.tableValue}>{category?.team_name}</td>
                        <td style={styles.tableValue}>{category?.emp_id_team_manager_name}</td>
                        <td style={styles.tableValue}>
                                {/* Status Label */}
                                <div
                                style={{
                                    display: "inline-block", // Makes the div wrap content and have its own width
                                    padding: "5px 5px", // Padding for the button-like appearance
                                    borderRadius: "15px",
                                    ...getStatusStyle(Number(category?.emp_status)), // Dynamic styles based on the status
                                    width: "auto", // Width adjusts automatically to content
                                }}
                                >
                                {category?.emp_status === 0 ? 'InActive':'Active' }
                                </div>

                                {/* Action Buttons */}
                            </td>
                            <td>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "2px", // Space between edit and delete buttons
                                }}
                                >
                                {/* Edit Button */}
                                <button
                                    className="tasksScreen-edit-button"
                                    style={{
                                    border: "none",
                                    background: "transparent",
                                    cursor: "pointer",
                                    }}
                                    onClick={() => handleEditUser('editUser')}
                                >
                                    <img
                                    src={editIcon}
                                    alt="Edit"
                                    style={{ width: "16px", height: "16px" }}
                                    />
                                </button>

                                {/* Delete Button */}
                                <button
                                    className="tasksScreen-delete-button"
                                    style={{
                                    border: "none",
                                    background: "transparent",
                                    cursor: "pointer",
                                    }}
                                >
                                    <img
                                    src={deleteIcon}
                                    alt="Delete"
                                    style={{ width: "16px", height: "16px" }}
                                    />
                                </button>
                                </div>
                            </td>

                    </tr>
                    ))}
                </tbody>
                }
                </table>
                {isModalOpen &&
                <AddUserModal isOpen={isModalOpen} onClose={handleCloseModal}/>}
                {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
                {error && <div className="list-error-msg">{error} </div>}
            </div>

        </div>
        </>

    )

};

const styles = {
    categoryHeader: {
        color:'#1F384C',
        fontSize:28,
        fontFamily:'Roboto Slab',
        fontWeight:500,
        paddingBottom:20
    },
    tableWrapper: {
        overflowX: "auto", // Horizontal scroll
        whiteSpace: "nowrap", // Prevent table from wrapping on small devices
        // marginBottom:50
      },
      tableValue:{
        colour:'#192A3E',
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px'
    },
    tableLink:{
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px',
        textDecoration:'underline',
        color: '#5A55D2'
    },
    container: {
        padding: "5px",
        backgroundColor: "#EFF0F4",
        border: "none",
        // borderRadius: "5px",
        border:'1px #EFF0F4 solid'
      },
      filtersHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // marginBottom: "15px",
      },
      filterButton: {
        backgroundColor: "white",
        color: "#40513B",
        border: "none",
        borderRadius: "5px",
        padding: "5px 8px",
        cursor: "pointer",
        borderRadius:'20px',
        fontSize:'12px',
        fontWeight:'400',
        fontFamily:'Roboto',
        border: '1px #40513B solid'

      },
      filterIcon:{
        width:'10px',
        height:'10px',
        paddingRight:'5px'
      },

      paginationButtons: {
        display: "flex",
        gap: "5px",
      },
      paginationButton: {
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "5px 10px",
        cursor: "pointer",
      },
      filtersContainer: {
        // display: "flex",
        flexWrap: "wrap", // Wrap inputs to the next line if needed
        gap: "20px", // Space between inputs
        marginBottom: "15px",
        // justifyContent: "space-between", // Distribute inputs evenly
        border:'1px #EFF0F4 solid',
        padding:'10px'
      },
      buttonsContainer: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
      },
      applyButton: {
        backgroundColor: "#609966",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        padding: "8px 15px",
        cursor: "pointer",
      },
      cancelButton: {
        backgroundColor: "transparent",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "8px 15px",
        cursor: "pointer",
      },
      resetButton: {
        backgroundColor: "transparent",
        color: "#555",
        border: "none",
        cursor: "pointer",
      },
    

}

export default ManageUsersScreen;