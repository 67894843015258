import { useState, useEffect } from 'react';

const InwardOutwardListController = () => {
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items per page

    useEffect(() => {
        // Fetch or load the data from an API or local file
        const data = [
            {
                documentName: 'Form 16',
                source: 'Sent by Client',
                deliveryType: 'WhatsApp',
                clientName: 'DLS India',
                documentNumber: '123456',
                createdBy: 'John Doe',
                date: '11/11/2024',
            },
            {
                documentName: 'Invoice 001',
                source: 'Sent by Staff',
                deliveryType: 'Email',
                clientName: 'ABC Corp',
                documentNumber: '987654',
                createdBy: 'Jane Doe',
                date: '10/11/2024',
            },
            {
                documentName: 'Form 15',
                source: 'Sent by Client',
                deliveryType: 'Email',
                clientName: 'XYZ Ltd.',
                documentNumber: '654321',
                createdBy: 'John Doe',
                date: '09/11/2024',
            },
            {
                documentName: 'Form 16',
                source: 'Sent by Client',
                deliveryType: 'WhatsApp',
                clientName: 'DLS India',
                documentNumber: '123456',
                createdBy: 'John Doe',
                date: '11/11/2024',
            },
            {
                documentName: 'Invoice 001',
                source: 'Sent by Staff',
                deliveryType: 'Email',
                clientName: 'ABC Corp',
                documentNumber: '987654',
                createdBy: 'Jane Doe',
                date: '10/11/2024',
            },
            {
                documentName: 'Form 15',
                source: 'Sent by Client',
                deliveryType: 'Email',
                clientName: 'XYZ Ltd.',
                documentNumber: '654321',
                createdBy: 'John Doe',
                date: '09/11/2024',
            },
            {
                documentName: 'Form 16',
                source: 'Sent by Client',
                deliveryType: 'WhatsApp',
                clientName: 'DLS India',
                documentNumber: '123456',
                createdBy: 'John Doe',
                date: '11/11/2024',
            },
            {
                documentName: 'Invoice 001',
                source: 'Sent by Staff',
                deliveryType: 'Email',
                clientName: 'ABC Corp',
                documentNumber: '987654',
                createdBy: 'Jane Doe',
                date: '10/11/2024',
            },
            {
                documentName: 'Form 15',
                source: 'Sent by Client',
                deliveryType: 'Email',
                clientName: 'XYZ Ltd.',
                documentNumber: '654321',
                createdBy: 'John Doe',
                date: '09/11/2024',
            },
            {
                documentName: 'Form 16',
                source: 'Sent by Client',
                deliveryType: 'WhatsApp',
                clientName: 'DLS India',
                documentNumber: '123456',
                createdBy: 'John Doe',
                date: '11/11/2024',
            },
            {
                documentName: 'Invoice 001',
                source: 'Sent by Staff',
                deliveryType: 'Email',
                clientName: 'ABC Corp',
                documentNumber: '987654',
                createdBy: 'Jane Doe',
                date: '10/11/2024',
            },
            {
                documentName: 'Form 15',
                source: 'Sent by Client',
                deliveryType: 'Email',
                clientName: 'XYZ Ltd.',
                documentNumber: '654321',
                createdBy: 'John Doe',
                date: '09/11/2024',
            },
            {
                documentName: 'Form 16',
                source: 'Sent by Client',
                deliveryType: 'WhatsApp',
                clientName: 'DLS India',
                documentNumber: '123456',
                createdBy: 'John Doe',
                date: '11/11/2024',
            },
            {
                documentName: 'Invoice 001',
                source: 'Sent by Staff',
                deliveryType: 'Email',
                clientName: 'ABC Corp',
                documentNumber: '987654',
                createdBy: 'Jane Doe',
                date: '10/11/2024',
            },
            {
                documentName: 'Form 15',
                source: 'Sent by Client',
                deliveryType: 'Email',
                clientName: 'XYZ Ltd.',
                documentNumber: '654321',
                createdBy: 'John Doe',
                date: '09/11/2024',
            },
            {
                documentName: 'Form 16',
                source: 'Sent by Client',
                deliveryType: 'WhatsApp',
                clientName: 'DLS India',
                documentNumber: '123456',
                createdBy: 'John Doe',
                date: '11/11/2024',
            },
            {
                documentName: 'Invoice 001',
                source: 'Sent by Staff',
                deliveryType: 'Email',
                clientName: 'ABC Corp',
                documentNumber: '987654',
                createdBy: 'Jane Doe',
                date: '10/11/2024',
            },
            {
                documentName: 'Form 15',
                source: 'Sent by Client',
                deliveryType: 'Email',
                clientName: 'XYZ Ltd.',
                documentNumber: '654321',
                createdBy: 'John Doe',
                date: '09/11/2024',
            },
            {
                documentName: 'Form 16',
                source: 'Sent by Client',
                deliveryType: 'WhatsApp',
                clientName: 'DLS India',
                documentNumber: '123456',
                createdBy: 'John Doe',
                date: '11/11/2024',
            },
            {
                documentName: 'Invoice 001',
                source: 'Sent by Staff',
                deliveryType: 'Email',
                clientName: 'ABC Corp',
                documentNumber: '987654',
                createdBy: 'Jane Doe',
                date: '10/11/2024',
            },
            {
                documentName: 'Form 15',
                source: 'Sent by Client',
                deliveryType: 'Email',
                clientName: 'XYZ Ltd.',
                documentNumber: '654321',
                createdBy: 'John Doe',
                date: '09/11/2024',
            },

            // Add more sample data as needed
        ];
        setTasks(data);
        setFilteredTasks(data); // Initially, all tasks are displayed
    }, []);

    // Handle filtering tasks
    const filterTasks = (filters) => {
        const { source, deliveryType } = filters;
        const filtered = tasks.filter((task) => {
            const matchesSource = source ? task.source === source : true;
            const matchesDeliveryType = deliveryType ? task.deliveryType === deliveryType : true;
            return matchesSource && matchesDeliveryType;
        });
        setFilteredTasks(filtered);
        setCurrentPage(1); // Reset to the first page after filtering
    };

    // Handle pagination
    const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return {
        tasks: currentTasks, // Only return tasks for the current page
        currentPage,
        totalPages,
        handlePageChange,
        filterTasks, // Expose the filter function
    };
};

export default InwardOutwardListController;
