import React, { useState } from "react";
import Input from "../../../../Components/Input"; // Import Input Component
import Dropdown from "../../../../Components/Dropdown"; // Import Dropdown Component
import CheckBox from "../../../../Components/CheckBox";
import MobileInput from "../../../../Components/MobileInput";
import closeIcon from '../../../../Assets/Icons/X circle.png'
import DateInput from '../../../../Components/DateInput'
import backIcon from '../../../../Assets/Icons/back.png'
import search from '../../../../Assets/Icons/search.png';
import TextInput from '../../../../Components/TextInput';
import { useNavigate } from "react-router-dom";
import CreateEditEstimateController from "../../../Controllers/AdminScreens/Invoices/CreateEditEstimateController";
import { useLocation } from "react-router-dom";

const CreateEditEstimateScreen = () => {
  const location = useLocation();
  const rowData = location?.state;
  console.log("rowData", rowData);

  const {
    setBasicInformation,
    basicInformation,
    handleBasicInfoChange,
    setEstimateOptions,
    estimateOptions,
    handleEstimateOptionsChange,
    sendUpdateToClient,
    setSendUpdateToClient,
    sendUpdateToTeam,
    setSendUpdateToTeam,
    estimateWithoutTask,
    setEstimateWithoutTask
  } = CreateEditEstimateController();

  const navigate = useNavigate()
  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };


  const [serviceCategoryRow1, setServiceCategoryRow1] = useState("");
  const [serviceTypeRow1, setServiceTypeRow1] = useState([]);

  const [serviceCategoryRow2, setServiceCategoryRow2] = useState("");
  const [serviceTypeRow2, setServiceTypeRow2] = useState([]);

  const [serviceCategoryRow3, setServiceCategoryRow3] = useState("");
  const [serviceTypeRow3, setServiceTypeRow3] = useState([]);

  return (
    <>
      <div style={{ width: '93%', margin: 'auto' }}>
        <h3 style={styles.header}>
          <div style={styles.headerContainer} >
            <img
              src={backIcon}
              alt="Back"
              style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
              onClick={handleBack}
            />
            <span>{rowData ? "Edit Estimate" : "Create Estimate"}</span>
          </div>
        </h3>
      </div>
      <div style={styles.container}>
        <h2 style={styles.title}>Create Estimates</h2>

        {/* Basic Information */}
        <div style={styles.section}>
          <h3 style={{ ...styles.sectionTitle, marginBottom: "12px" }}>Basic Information</h3>
          <div style={styles.row}>
            {/* <Input label="Client Name" placeholder="Client Name" value={clientName}
            onChange={(e) => setClientName(e.target.value)} /> */}
            <div className="filter-field" style={{ marginBottom: "15px" }}> {/* Add margin-bottom here */}
              <label style={{ ...styles.labaleStyle, marginBottom: "8px", display: "block" }}>
                Client Name
              </label>
              <TextInput
                type="text"
                placeholder="Search"
                value={basicInformation?.clientName}
                onChange={(e) => handleBasicInfoChange('clientName', e.target.value)}
                right={<img src={search} alt="Search" className="search-icon" />}
                style={{ padding: "20px 10px" }} // Add padding inside the TextInput
              />
            </div>

            <Dropdown label="Group Name" options={["Option 1", "Option 2"]} value={basicInformation?.groupName}
              onChange={(e) => handleBasicInfoChange('groupName', e.target.value)} />
            {/* <Input label="Phone No." placeholder="Barchasi" value={basicInformation?.phoneNo}
              onChange={(e) => handleBasicInfoChange('phoneNo', e.target.value)} /> */}

            <MobileInput
              label="Phone No."
              countryCode={basicInformation?.countryCode}
              mobileNumber={basicInformation?.phoneNo}
              onMobileChange={(value) => handleBasicInfoChange('phoneNo', value)}
              onCountryChange={(value) => handleBasicInfoChange('countryCode', value)}
              placeholder="Enter mobile number"
              countries={[
                { code: "IN", dialCode: "+91" },
                { code: "US", dialCode: "+1" },
                { code: "UK", dialCode: "+44" },
              ]}
              width="100%"
            />
            <div>
              {/* <Input label="Date of Estimate" placeholder="11/11/2024" value={dateOfEstimate}
            onChange={(e) => setDateOfEstimate(e.target.value)} /> */}

              <div className="filter-field" style={{ marginBottom: "15px" }}> {/* Add margin-bottom here */}
                <label style={{ ...styles.labaleStyle, marginBottom: "8px", display: "block" }}>
                  Date of Estimate
                </label>
                <DateInput
                  value={basicInformation?.dateOfEstimate}
                  onChange={(e) => handleBasicInfoChange('dateOfEstimate', e.target.value)}
                  style={{ padding: "20px 10px" }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Requested Services */}
        <div style={styles.mainContainer}>
          <div >
            <div style={{ display: 'flex', gap: '10px', marginBottom: '12px' }}>
              <h3 style={styles.sectionTitle}>Requested Services</h3>
              <div style={styles.checkboxContainer}>
                {/* <input type="checkbox" id="estimateWithoutTask" checked={estimateWithoutTask}
                  onChange={(e) => setEstimateWithoutTask(e.target.checked)} />
                <label htmlFor="estimateWithoutTask" style={styles.checkboxLabel}>
                  Estimate without Task
                </label> */}
                <CheckBox
                  label="Estimate without Task"
                  checked={estimateWithoutTask}
                  onChange={(isChecked) => setEstimateWithoutTask(isChecked)}
                />
              </div>
            </div>
            <div style={{ ...styles.servicesContainer, paddingRight: '28px' }}>
              {/* Row 1 */}
              <div style={styles.serviceRow}>
                <Dropdown label="Service" options={["GST", "Income Tax", "Audit"]} value={serviceCategoryRow1}
                  onChange={(value) => setServiceCategoryRow1(value)} />
                <Dropdown label="Service Category" options={[{ label: "Option 1", value: "Option 1" }, { label: "Option 2", value: "Option 2" }]} value={serviceTypeRow1}
                  onChange={setServiceTypeRow1} isMulti />
              </div>

              {/* Row 2 */}
              <div style={styles.serviceRow}>
                <Dropdown label="Service" options={["Option 1", "Option 2"]} value={serviceCategoryRow2}
                  onChange={(value) => setServiceCategoryRow2(value)} />
                <div style={styles.dropdownWithClose}>
                  <Dropdown
                    label="Service Category"
                    options={[{ label: "Option 1", value: "Option 1" }, { label: "Option 2", value: "Option 2" }]}
                    value={serviceTypeRow2}
                    onChange={setServiceTypeRow2}
                    isMulti
                  />
                  {/* <button style={styles.closeButton} >✖</button> */}
                  <img src={closeIcon} style={styles.closeButton} />
                </div>

              </div>

              {/* Row 3 */}
              <div style={styles.serviceRow}>
                <Dropdown label="Service" options={["Option 1", "Option 2"]} value={serviceCategoryRow3}
                  onChange={(value) => setServiceCategoryRow3(value)} />
                <div style={styles.dropdownWithClose}>
                  <Dropdown label="Service Category" options={[{ label: "Option 1", value: "Option 1" }, { label: "Option 2", value: "Option 2" }]} value={serviceTypeRow3}
                    onChange={setServiceTypeRow3} isMulti />
                  <img src={closeIcon} style={styles.closeButton} />
                </div>
              </div>

              <button style={styles.addServiceButton} >
                <span style={{ marginRight: '10px' }}>+</span> Add More Services
              </button>
            </div>
          </div>

          {/* Estimate Options */}
          <div >
            <h3 style={{ ...styles.sectionTitle, marginBottom: "12px" }}>Estimate Options</h3>
            <div style={{ ...styles.servicesContainer, paddingBottom: '48px' }}>
              <div style={styles.serviceRow}>
                <Dropdown label="Group Estimation" options={["GST", "Income Tax", "Audit"]} value={estimateOptions?.groupEstimate}
                  onChange={(e) => handleEstimateOptionsChange('groupEstimate', e.target.value)} />
                <Dropdown label="No. Of Clients" options={["Option 1", "Option 2"]} value={estimateOptions?.noOfClients}
                  onChange={(e) => handleEstimateOptionsChange('noOfClients', e.target.value)} />
              </div>
              <div style={styles.serviceRow}>
                <Input label="System Billing Amount" placeholder="4,000.00" value={estimateOptions?.systemBillingAmount}
                  onChange={(e) => handleEstimateOptionsChange('systemBillingAmount', e.target.value)} />
                <Input label="Estimate Amount" placeholder="2,000.00" value={estimateOptions?.estimateAmount}
                  onChange={(e) => handleEstimateOptionsChange('estimateAmount', e.target.value)} />
              </div>
              <div style={styles.serviceRow}>
                <Dropdown label="Generated By" options={["Option 1", "Option 2"]} value={estimateOptions?.generatedBy}
                  onChange={(e) => handleEstimateOptionsChange('generatedBy', e.target.value)} />
              </div>
            </div>
          </div>
        </div>
        {/* Footer Options */}
        <div style={{ marginTop: '18px' }}>
          <div style={{ marginBottom: '15px', display: "flex", alignItems: "center", }}>
            {/* <input type="checkbox" id="sendUpdateClient" checked={sendUpdateToClient}
              onChange={(e) => setSendUpdateToClient(e.target.checked)} />
            <label htmlFor="sendUpdateClient" style={styles.checkboxLabel}>
              Send Update to Client
            </label> */}
            <CheckBox
              label="Send Update to Client"
              checked={sendUpdateToClient}
              onChange={(isChecked) => setSendUpdateToClient(isChecked)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", }}>
            {/* <input type="checkbox" id="sendUpdateTeam" checked={sendUpdateToTeam}
              onChange={(e) => setSendUpdateToTeam(e.target.checked)} />
            <label htmlFor="sendUpdateTeam" style={styles.checkboxLabel}>
              Send Update to Team
            </label> */}
            <CheckBox
              label="Send Update to Team"
              checked={sendUpdateToTeam}
              onChange={(isChecked) => setSendUpdateToTeam(isChecked)}
            />
          </div>
        </div>

        {/* Footer Buttons */}
        <div className="button-container" style={{ marginTop: '32px' }}>
          {rowData ? (
            // Render buttons if data is present in `rowData`
            <>
              <button
                className="add-client-button"
                style={{ paddingLeft: '30px', paddingRight: '30px', marginRight: '10px' }}
              >
                Update
              </button>
              {/* <button
                className="convert-button"
                style={{ ...styles.addServiceButton, paddingLeft: '30px', paddingRight: '30px' }}
              >
                Convert Estimate to Bill
              </button> */}
              <button className="cancel-button">Cancel</button>
            </>
          ) : (
            // Render default buttons if no data in `rowData`
            <>
              <button
                className="add-client-button"
                style={{ paddingLeft: '30px', paddingRight: '30px' }}
              >
                Create
              </button>
              <button className="cancel-button">Cancel</button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "90%",
    margin: "auto",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontSize: "28px",
    marginBottom: "23px",
    fontWeight: "500",
    color: "#1F384C",
    // fontFamily: "Roboto Slab"
  },
  section: {
    marginBottom: "21px",
  },
  sectionTitle: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#609966",
    fontFamily: 'Roboto Slab'
  },
  row: {
    padding: '16px 11px',
    display: "flex",
    flexWrap: "wrap",
    columnGap: "70px",
    rowGap: "15px",
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid'
  },
  estimatedRow: {
    padding: '16px 11px',
    display: "flex",
    flexWrap: "wrap",
    columnGap: "70px",
    rowGap: "15px",
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
    paddingBottom: '63px'
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
  },
  checkboxLabel: {
    fontSize: "12px",
    color: "#343C44",
    fontFamily: 'Roboto',
    fontWeight: '400'
  },
  serviceRow: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "15px 70px", // Gap between two elements in the same row
    marginBottom: "15px", // Space between rows
    flexWrap: "wrap",
  },
  dropdownWithClose: {
    display: "flex",
    alignItems: "center", // Vertically aligns the dropdown and close icon
    gap: "26px", // Gap between dropdown and close icon
  },
  closeButton: {
    width: '15px',
    height: '15px',
    cursor: "pointer",
    verticalAlign: "middle",
    objectFit: "contain",
  },
  addServiceButton: {
    backgroundColor: "transparent",
    border: "1px #858585 solid",
    borderRadius: "5px",
    padding: "8px 12px",
    color: "#40513B",
    cursor: "pointer",
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    borderRadius: '28px',
    // marginBottom:'16px'
  },
  removeButton: {
    backgroundColor: "transparent",
    border: "none",
    color: "#f00",
    fontSize: "16px",
    cursor: "pointer",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  createButton: {
    backgroundColor: "#609966",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  servicesContainer: {
    // display: "flex",
    // flexWrap: "wrap", // Allows items to wrap to the next row if needed
    // gap: "20px", // Space between rows and items
    padding: '16px 11px',
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
  },
  mainContainer: {
    display: "flex",
    gap: "15px",
    flexWrap: "wrap",
    // overflowX: "auto",
    // whiteSpace: "nowrap",
  },
  "@media (max-width: 768px)": {
    // For tablets and smaller screens
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',

    }
  },
  "@media (max-width: 768px)": {
    serviceRow: {
      flexDirection: "column", // Stack items vertically
      gap: "15px", // Reduce the gap for smaller screens
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center', // Vertically align items
  },
  header: {
    fontSize: "22px",
    color: "#060606",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: 'Montserrat',
    //   marginLeft:'20px'
  },
  leftHeader: {
    marginTop: '-5px'
  },
  labaleStyle: {
    fontSize: '12px',
    marginBottom: '5px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: 'rgb(52, 60, 68)'
  }
};

export default CreateEditEstimateScreen;
