import React, { useState } from "react";
import Input from "./Input";
import Dropdown from "./Dropdown";
import CheckBox from "./CheckBox";
import TextInput from "./TextInput";
import DateInput from "./DateInput";
import closeIcon from "../Assets/Icons/close.png";
import search from "../Assets/Icons/search.png";

const AddScheduleReminderModal = ({ isOpen, onClose, onValueChange }) => {
    const [type, setType] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [location, setLocation] = useState("");
    const [sendUpdateToClient, setSendUpdateToClient] = useState(false);
    const [sendUpdateToTeam, setSendUpdateToTeam] = useState(false);
    const [clientName, setClientName] = useState("");

    const typeOptions = ["Follow Up", "Meeting", "Task"];
    const locationOptions = ["Online Meeting", "In-Person", "Call"];
    const descriptionOptions = ["Online Meeting", "In-Person", "Call"];

    const handleAddSchedule = () => {
        const scheduleData = {
            type,
            dateTime,
            title,
            description,
            location,
            sendUpdateToClient,
            sendUpdateToTeam,
            clientName,
        };
        onValueChange(scheduleData);
        onClose();
    };

    const resetForm = () => {
        setType("");
        setDateTime("");
        setTitle("");
        setDescription("");
        setLocation("");
        setSendUpdateToClient(false);
        setSendUpdateToTeam(false);
        setClientName("");
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" style={styles.iconImage} />
                </button>
                <h2 className="modal-title">Add Schedule/Reminder</h2>
                <hr className="title-divider" />

                <div className="form-grid">
                    <Dropdown
                        label="Select Type"
                        options={typeOptions}
                        value={type}
                        onChange={setType}
                    />
                    {/* <Input
                        label="Date & Time"
                        placeholder="Select Date & Time"
                        value={dateTime}
                        onChange={(e) => setDateTime(e.target.value)}
                    /> */}
                    <div>
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Date & Time</label>
                        <DateInput
                            label="Published Date"
                            value={dateTime}
                            onChange={(date) => setDateTime(date)}
                            width={'250px'}
                        />
                    </div>
                    <Input
                        label="Title"
                        placeholder="Enter Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <Input
                        label="Description"
                        placeholder="Enter Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <Dropdown
                        label="Location"
                        options={locationOptions}
                        value={location}
                        onChange={setLocation}
                    />
                    <div>
                        <div className="filter-field">
                            <label>Client</label>
                            <TextInput
                                type="text"
                                placeholder="Client"
                                value={clientName}
                                onChange={(e) => setClientName(e.target.value)}
                                right={<img src={search} alt="Search" className="search-icon" />}
                            />
                        </div>
                    </div>
                </div>

                <div className="existing-client-checkbox">
                    {/* <input
                        type="checkbox"
                        checked={sendUpdateToClient}
                        onChange={(e) => setSendUpdateToClient(e.target.checked)}
                        style={styles.checkbox}
                    />
                    <label style={styles.exisitingHeading}>Send Update to Client</label> */}
                    <CheckBox
                        label="Send Update to Client"
                        checked={sendUpdateToClient}
                        onChange={(isChecked) => setSendUpdateToClient(isChecked)}
                        style={styles.checkbox}
                    />
                </div>
                <div className="existing-client-checkbox">
                    {/* <input
                        type="checkbox"
                        checked={sendUpdateToTeam}
                        onChange={(e) => setSendUpdateToTeam(e.target.checked)}
                        style={styles.checkbox}
                    />
                    <label style={styles.exisitingHeading}>Send Update to Team</label> */}
                    <CheckBox
                        label="Send Update to Team"
                        checked={sendUpdateToTeam}
                        onChange={(isChecked) => setSendUpdateToTeam(isChecked)}
                        style={styles.checkbox}
                    />
                </div>
                <hr className="footer-divider" />

                <div className="button-container">
                    <button className="add-client-button" onClick={handleAddSchedule}>Add</button>
                    <button className="cancel-button" onClick={() => { resetForm(); onClose(); }}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    iconImage: {
        width: '15px',
        height: '15px',
    },
    exisitingHeading: {
        color: '#343C44',
        fontSize: '10px',
        fontWeight: 500,
        fontFamily: 'Roboto'
    }
};

export default AddScheduleReminderModal;
