import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../../../Utils/Validation';

const ForgotPasswordController = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1); // Step in the forgot password flow
    const [email, setEmail] = useState(''); // User's email input
    const [newPassword, setNewPassword] = useState(''); // New password
    const [confirmPassword, setConfirmPassword] = useState(''); // Confirm new password
    const [error, setError] = useState(''); // Error message
    const [loading, setLoading] = useState(false); // Loading state
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleLoginRedirect = () => {
        resetState();
        navigate('/login'); // Replace '/' with the route to your login page
    };

    const handleEmailSubmit = async () => {
        setError('');
        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            setLoading(true);
            // Simulate an API call for email submission
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setStep(2); // Move to the OTP step
        } catch (err) {
            setError('Failed to send the OTP. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordSubmit = async () => {
        setError('');
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            setLoading(true);
            // Simulate an API call for password reset
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setStep(3); // Move to the success step
        } catch (err) {
            setError('Failed to reset the password. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const resetState = () => {
        setStep(1);
        setEmail('');
        setNewPassword('');
        setConfirmPassword('');
        setError('');
    };

    return {
        step,
        email,
        newPassword,
        confirmPassword,
        error,
        loading,
        setEmail,
        setNewPassword,
        setConfirmPassword,
        handleEmailSubmit,
        handlePasswordSubmit,
        resetState,
        showNewPassword,
        showConfirmPassword,
        toggleShowNewPassword,
        toggleShowConfirmPassword,
        handleLoginRedirect,
    };
};

export default ForgotPasswordController;
