
import InvoiceAccountsController from "../../../Controllers/AdminScreens/Invoices/InvoiceAccountsController";
import React, { useState } from 'react';
import Dropdown from "../../../../Components/Dropdown";
import filterIcon from '../../../../Assets/Icons/filter.png'
import leftBtuIcon from '../../../../Assets/Icons/back.png'
import useManageGroupsController from '../../../Controllers/AdminManagementScreens/TeamsAndUsers/ManageGroupsController';
import Loader from '../../../../Components/Loader';
import { useNavigate } from "react-router-dom";

const InvoiceAccountsScreen = () => {
  const navigator = useNavigate();

  const handleClientNameNavigate = ()=>{
    navigator('/invoice/client-info')
  }
      
    const {
        accountsData,
        getStatusStyle,
        setFilterValues,
        filterValues,
        handleFilterChange
    } = InvoiceAccountsController()
    

    return (
        <div style={{padding:'20px 40px'}}>
        <div className='basic-info-container ' style={styles.searchFilterDiv} >
        <div style={{display:'flex'}}>
            <h3 style={styles.categoryHeader}>Search Clients</h3>
            </div>
        <div style={styles.container}>
      <div style={styles.filtersHeader}>
        <button style={styles.filterButton}> <img src={filterIcon} style={styles.filterIcon}/> Filters</button>
        <div >
          <img src={leftBtuIcon}  style={{marginRight:'5px',cursor:'pointer',width:'28px',height:'28px'}}/>
          <img src={leftBtuIcon} style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease',cursor:'pointer',width:'28px',height:'28px' }} />
        </div>
      </div>
      </div>
      {/* Filters using Flexbox */}
      <div style={styles.filtersContainer}>
        <div style={{display:'flex', flexWrap:'wrap', columnGap:'20px', rowGap:'30px'}}>
        <Dropdown
          label="Client Name"
          placeholder="------"
          value={filterValues?.clientName}
          onChange={(value) => handleFilterChange("clientName", value)}
        />
        <Dropdown
          label="Client Type"
          placeholder="------"
          value={filterValues?.clientType}
          onChange={(value) => handleFilterChange("clientType", value)}
        />
        <Dropdown
          label="GSTIN NO"
          placeholder="------"
          value={filterValues?.gstinNo}
          onChange={(value) => handleFilterChange("gstinNo", value)}
        />
        <Dropdown
          label="Pending Task"
          placeholder="------"
          value={filterValues?.pendingTask}
          onChange={(value) => handleFilterChange("pendingTask", value)}
        />
        <Dropdown
          label="City"
          placeholder="------"
          value={filterValues?.city}
          onChange={(value) => handleFilterChange("city", value)}
        />
        <Dropdown
          label="Assigned To"
          placeholder="------"
          value={filterValues?.assignedTo}
          onChange={(value) => handleFilterChange("assignedTo", value)}
        />
        <Dropdown
          label="Group Name"
          placeholder="------"
          value={filterValues?.groupName}
          onChange={(value) => handleFilterChange("groupName", value)}
        />
        <Dropdown
          label="Services"
          placeholder="------"
          value={filterValues?.services}
          onChange={(value) => handleFilterChange("services", value)}
        />
        </div>
        <div className="button-container" style={{marginBottom:'15px'}}>
          <button className="add-client-button" style={{marginRight:'8px',paddingLeft:'30px', paddingRight:'30px'}}>Apply</button>
          <button className="cancel-button" style={{border:'1px #40513B solid', borderRadius:'20px'}} >Cancel</button>
          <button className="cancel-button">Reset</button>
        </div>
      </div>

        </div>

        <div className='basic-info-card' style={{border:'none'}}>
            <div style={styles.tableWrapper}>
            <table className="tasksScreen-task-table">
                <thead>
                    <tr>
                    <th style={{ width: "20%" }}>Client Name</th>
                    <th style={{ width: "10%" }}>Outstanding Amount</th>
                    <th style={{ width: "15%" }}>Client Type</th>
                    {/* <th style={{ width: "10%" }}>Created By</th> */}
                    <th style={{ width: "10%" }}>GSTIN No.</th>
                    <th style={{ width: "20%" }}>Group Name</th>
                    <th style={{ width: "13%" }}>Assigned To</th>
                    <th style={{ width: "12%" }}>Account Status</th>
                    {/* <th style={{ width: "5%" }}></th> */}
                    </tr>
                </thead>
                <tbody style={{ backgroundColor: "white" }}>
                    {accountsData?.map((category, index) => (
                    <tr key={index}>
                        <td style={{...styles.tableLink,cursor:'pointer' }} onClick={handleClientNameNavigate}>{category?.groupName}</td>
                        <td
                            style={{
                            ...styles.tableValue,
                            color: category?.accountStatus === "Advance Payment" ? "red" : "",
                            textAlign: "right", // Align values to the right under the header
                            }}
                        >
                            {category?.outstandingAmount}
                        </td>
                        <td style={styles.tableValue}>{category?.clientType}</td>
                        <td style={styles.tableValue}>{category?.gstinNo}</td>
                        <td style={styles.tableValue}>{category?.groupName}</td>
                        <td style={styles.tableValue}>{category?.assignedTo}</td>
                        {/* <td style={styles.tableValue}>{category.createdby}</td> */}
                        <td style={styles.tableValue}>
                                {/* Status Label */}
                                <div
                                style={{
                                    display: "inline-block", // Makes the div wrap content and have its own width
                                    padding: "5px 5px", // Padding for the button-like appearance
                                    borderRadius: "15px",
                                    ...getStatusStyle(category?.accountStatus), // Dynamic styles based on the status
                                    width: "auto", // Width adjusts automatically to content
                                }}
                                >
                                {category?.accountStatus}
                                </div>

                                {/* Action Buttons */}
                            </td>

                    </tr>
                    ))}
                </tbody>
                </table>
                {/* {loading && <Loader loading={loading} color={'var(--primary-color)'} />} */}
                {/* {error && <div className="list-error-msg">{error} </div>} */}
                {/* <AddGroupModal isOpen={isModalOpen} onClose={handleCloseModal} onValueChange={OnAddUpdateSucess}/> */}
            </div>

        </div>
        </div>

    )

};

const styles = {
    searchFilterDiv:{
      padding: '15px 20px',
      marginBottom: '20px',
      borderRadius: '10px',
    //   borderBottomLeftRadius: '10px',
    //   borderLeft: '1px solid #9DC08B'
    },
    categoryHeader: {
        color:'#1F384C',
        fontSize:28,
        // fontFamily:'Roboto Slab',
        fontWeight:500,
        paddingBottom:20
    },
    tableWrapper: {
        overflowX: "auto", // Horizontal scroll
        whiteSpace: "nowrap", // Prevent table from wrapping on small devices
        // marginBottom:50
      },
      tableValue:{
        colour:'#192A3E',
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px'
    },
    tableLink:{
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px',
        textDecoration:'underline',
        color: '#5A55D2'
    },
    container: {
        padding: "5px",
        backgroundColor: "#EFF0F4",
        border: "none",
        // borderRadius: "5px",
        border:'1px #EFF0F4 solid'
      },
      filtersHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // marginBottom: "15px",
      },
      filterButton: {
        backgroundColor: "white",
        color: "#40513B",
        border: "none",
        borderRadius: "5px",
        padding: "5px 8px",
        cursor: "pointer",
        borderRadius:'20px',
        fontSize:'12px',
        fontWeight:'400',
        fontFamily:'Roboto',
        border: '1px #40513B solid'

      },
      filterIcon:{
        width:'10px',
        height:'10px',
        paddingRight:'5px'
      },

      paginationButtons: {
        display: "flex",
        gap: "5px",
      },
      paginationButton: {
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "5px 10px",
        cursor: "pointer",
      },
      filtersContainer: {
        // display: "flex",
        flexWrap: "wrap", // Wrap inputs to the next line if needed
        gap: "20px", // Space between inputs
        marginBottom: "15px",
        // justifyContent: "space-between", // Distribute inputs evenly
        border:'1px #EFF0F4 solid',
        padding:'10px'
      },
      buttonsContainer: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
      },
      applyButton: {
        backgroundColor: "#609966",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        padding: "8px 15px",
        cursor: "pointer",
      },
      cancelButton: {
        backgroundColor: "transparent",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "8px 15px",
        cursor: "pointer",
      },
      resetButton: {
        backgroundColor: "transparent",
        color: "#555",
        border: "none",
        cursor: "pointer",
      },
    

}

export default InvoiceAccountsScreen;