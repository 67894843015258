import React, { useState } from 'react';
import UserAssignmentsController from '../../../Controllers/AdminScreens/Users/UserAssignmentsController.js';

const UserAssignmentsScreen = () => {

    const { userAssignmentsList,
        handleAddClients
    } = UserAssignmentsController();

    return (
        <div className='basic-info-container'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 className='basic-info-header'>Assignments</h3>
                <button className="add-client-button" style={{ paddingLeft: 30, paddingRight: 30 }} onClick={handleAddClients}>
                    Add Clients
                </button>
            </div>

            <div className="tasksScreen-task-table-wrapper" style={{ paddingTop: 10 }}>
                <table className='tasksScreen-task-table'>
                    <thead>
                        <tr>
                            <th>Clients</th>
                            <th>Group Name</th>
                            <th>Group Master</th>
                            <th>Tasks</th>
                            <th>Manager</th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: 'white' }}>
                        {userAssignmentsList.map((assignment, index) => (
                            <tr
                                key={index}
                                className="tasksScreen-main-task-row"
                                style={{ borderBottom: "1px solid #dee2e6" }}
                            >
                                <td style={{ ...styles.tableLink, width: '20%' }} >{assignment?.clientName}</td>
                                <td style={{ width: '25%' }}>{assignment?.groupName}</td>
                                <td style={{ width: '25%' }}>{assignment?.groupMaster}</td>
                                <td style={{ width: '15%' }}>{assignment?.tasks}</td>
                                <td style={{ width: '20%' }}>{assignment?.managerName}</td>

                            </tr>
                        ))}

                    </tbody>

                </table>
            </div>
        </div>
    )

};

const styles = {
    tableLink: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px',
        textDecoration: 'underline',
        color: '#5A55D2'
    },
}

export default UserAssignmentsScreen;