
import React,{useState} from "react";

const ReminderRegisterController = ()=>{

    const [selectList, setSelectList] = useState('');
    const [selectClient, setSelectClient] = useState('');
    const [channel, setChannel] = useState('');
    const [selectTemplate, setSelectTemplate] = useState('');
    const [messageContent, setMessageContent] = useState('');
    const [scheduleFor, setScheduleFor] = useState('');
    const [date, setDate] = useState('');

    const clientsList = [
        { clientName: "Client Name" },
        { clientName: "Client Name" },
        { clientName: "Client Name" },
        { clientName: "Client Name" },
        { clientName: "Client Name" },
        { clientName: "Client Name" },
      ];

    return{
        clientsList,
        selectList,
        setSelectList,
        selectClient,
        setSelectClient,
        channel,
        setChannel,
        selectTemplate,
        setSelectTemplate,
        messageContent,
        setMessageContent,
        scheduleFor,
        setScheduleFor,
        date,
        setDate
    }

}

export default ReminderRegisterController