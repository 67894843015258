import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ORG_GET_GROUP_INFO, ORG_GET_GROUP_MEMBERS, ORG_UPDATE_GROUP_STATUS, ORG_UPDATE_GROUP, ORG_DELETE_GROUP } from '../../../../ApiServices/BaseURL';
import { getData, postData} from "../../../../ApiServices/ApiService";


const ManageGroupDetailsController = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const isGroupInfoEdit = useRef(location.state?.isEdit);
    const [groupID, setGroupID] = useState(location.state?.selGroupInfo?.group_id || null);
    const [userToggleState, setUserToggleState] = useState(true);
    const [groupMemberModalOpen, setGroupMemberModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [groupMembers, setGroupMembers] = useState([]);
    const [groupMembersError, setGroupMembersError] = useState('');
    const [groupStatusLoading, setGroupStatusLoading] = useState(false);
    const [groupStatusError, setGroupStatusError] = useState('');

    console.log('groupdetails selGroupInfo', location.state?.selGroupInfo);

    const [primaryData, setPrimaryData] = useState({
        group_id: null,
        group_name: "",
        group_clients_count: 0,
        group_status: 0,
        group_desc: "",
        emp_id_manager_name: "",
        emp_id_manager: "",
        client_id_primary: null,
        client_id_primary_email: "--",
        client_id_primary_mobile_no: "--",
        client_id_primary_name: "--"
    });

    const fieldConfigs = [
        { label: "Group Name", key: "group_name", type: "text" },
        { label: "Group Master", key: "client_id_primary_name", type: "search" },
        { label: "Primary Contact", key: "client_id_primary_mobile_no", type: "text" }
    ];

    useEffect(() => {
        if (!location.state?.isEdit) {
            if (location.state?.selGroupInfo?.group_id) {
                fetchGroupInfo(location.state?.selGroupInfo?.group_id)
            }
        } else {
            isGroupInfoEdit.current = location.state?.isEdit
            setPrimaryData(location.state?.selGroupInfo);

        }
        fetchGroupMembers(location.state?.selGroupInfo?.group_id)

    }, [location.state?.isEdit])

    const fetchGroupInfo = async (groupIdValue) => {
        try {
            setLoading(true);
            setError('');
            // setTeamInfo({});


            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_GROUP_INFO}?group_id=${groupIdValue}&emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            setPrimaryData(responseData?.dataJ[0]);
                            // selGroupInfo.current = responseData?.dataJ[0]
                        } else {
                            setError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Group Data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setError(`Failed to fetch Task Categorie data: ${String(error)}`);
            }
        } finally {
            setLoading(false);
        }
    }

    const fetchGroupMembers = async (groupIdValue) => {
        console.log("__Fetch Member call__")
        try {
            setLoading(true);
            setGroupMembersError('');


            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_GROUP_MEMBERS}?emp_id_auth=${storedUserInfo?.emp_id}&group_id=${groupIdValue}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        console.log("Fetch Member success")
                        if (responseData?.dataJ?.length > 0) {
                            setGroupMembers(responseData?.dataJ);
                            // selGroupInfo.current = responseData?.dataJ[0]
                        } else {
                            setGroupMembersError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setGroupMembersError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setGroupMembersError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setGroupMembersError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setGroupMembersError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setGroupMembersError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setGroupMembersError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setGroupMembersError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Group Info. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setGroupMembersError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setGroupMembersError(`Failed to fetch Task Categorie data: ${String(error)}`);
            }
        } finally {
            setLoading(false);
        }
    }

    const handleBack = () => {
        console.log('handleBack group')
        navigate('/services/manage-group');
    }

    const handleUserToggleChange = async (newState, group_id) => {
        console.log('newState',newState)
        try {
            setGroupStatusLoading(true);
            setGroupStatusError('');

            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const payLoad={
              "emp_id_auth": storedUserInfo?.emp_id,
              "group_status": newState === true ? 1:0,
              "group_id": group_id
            }
            console.log('group updatestatus payload:---',payLoad);
            const response = await postData(ORG_UPDATE_GROUP_STATUS,payLoad);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  console.log("Group Status success")
                  setGroupStatusError(responseData?.info);
                } else {
                  setGroupStatusError(responseData?.info || 'Failed to update group status. Please try again.');
                }
              } else {
                setGroupStatusError(responseData?.info || 'Failed to update group status. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setGroupStatusError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setGroupStatusError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setGroupStatusError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setGroupStatusError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setGroupStatusError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update group status. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setGroupStatusError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setGroupStatusError(`Failed to update group status: ${String(error)}`);
            }
          } finally {
            setGroupStatusLoading(false);
          }


    }

    const handleEditGroupMember = (teamMember) => {
        console.log('handleEditGroupMember @@', teamMember)

    }

    const handleDeleteGroupMember = (teamMember) => {
        console.log('handleDeleteGroupMember @@', teamMember)
    }

    const handleUpdateGroup = () => {

    }

    const handleDeleteGroup = () => {

    }

    const handleCancelGroup = () => {

    }

    const handleAddGroupMembers = () => {
        console.log('handleAddGroupMembers')
        setGroupMemberModalOpen(true);
    }

    const handleCloseGroupMemberModal = () => {
        setGroupMemberModalOpen(false);
    }

    const onValueChangeGroupMember = () => {
        console.log('onValueChangeGroupMember');
        fetchGroupMembers(location.state?.selGroupInfo?.group_id);
    }

    const handleInputChange = (key, value) => {
        setPrimaryData((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    return {
        handleBack,
        userToggleState,
        handleUserToggleChange,
        isGroupInfoEdit,
        loading,
        error,
        primaryData,
        fieldConfigs,
        handleEditGroupMember,
        handleDeleteGroupMember,
        handleUpdateGroup,
        handleDeleteGroup,
        handleCancelGroup,
        handleAddGroupMembers,
        groupMemberModalOpen,
        handleCloseGroupMemberModal,
        onValueChangeGroupMember,
        handleInputChange,
        groupMembers,
        groupMembersError,
        groupStatusLoading,
        groupStatusError
    };

}

export default ManageGroupDetailsController;