import React, { useState } from "react";
import Input from "../../../../Components/Input"; // Import Input Component
import Dropdown from "../../../../Components/Dropdown"; // Import Dropdown Component
import CheckBox from "../../../../Components/CheckBox";
import MobileInput from "../../../../Components/MobileInput";
import closeIcon from '../../../../Assets/Icons/X circle.png'
import DateInput from '../../../../Components/DateInput';
import backIcon from '../../../../Assets/Icons/back.png';
import search from '../../../../Assets/Icons/search.png';
import TextInput from '../../../../Components/TextInput';
import { useNavigate } from "react-router-dom";
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import GenerateInvoiceController from "../../../Controllers/AdminScreens/Invoices/GenerateInvoiceController";

const GenerateInvoiceScreen = () => {

  const [selectAll, setSelectAll] = useState(false);
  const [checkedStates, setCheckedStates] = useState([]);

  const {
    basicInformation,
    setBasicInformation,
    handleBasicInformationChange,
    invoiceTableData,
    invoiceDetails,
    setInvoiceDetails,
    handleInvoiceDetailsChange,
    sendUpdateToClient,
    setSendUpdateToClient,
    sendUpdateToTeam,
    setSendUpdateToTeam,
  } = GenerateInvoiceController();

  const navigate = useNavigate()
  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };


  const getStatusStyle = (status) => {
    switch (status) {
      case "Not Started":
        return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" }; // Green
      case "Priority":
        return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" }; // Yellow
      case "In Progress":
        return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" }; // Red
      default:
        return {};
    }
  };

  const handleSelectAll = (isChecked) => {
    setSelectAll(isChecked);
    setCheckedStates(invoiceTableData.map(() => isChecked));
  };

  const handleCheckboxChange = (index, isChecked) => {
    const updatedStates = [...checkedStates];
    updatedStates[index] = isChecked;
    setCheckedStates(updatedStates);
  };


  return (
    <>
      <div style={{ width: '93%', margin: 'auto' }}>
        <h3 style={styles.header}>
          <div style={styles.headerContainer} >
            <img
              src={backIcon}
              alt="Back"
              style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
              onClick={handleBack}
            />
            <span>Generate Invoice</span>
          </div>
        </h3>
      </div>

      <div style={styles.container}>
        <h2 style={styles.title}>Generate Invoice</h2>

        {/* Basic Information */}
        <div style={styles.section}>
          <h3 style={{ ...styles.sectionTitle, marginBottom: "12px" }}>Basic Information</h3>
          <div style={styles.row}>
            {/* <Input label="Client Name" placeholder="Client Name" value={clientName}
            onChange={(e) => setClientName(e.target.value)} /> */}
            <div className="filter-field" style={{ marginBottom: "15px" }}> {/* Add margin-bottom here */}
              <label style={{ ...styles.labaleStyle, display: "block" }}>
                Client Name
              </label>
              <TextInput
                type="text"
                placeholder="Search"
                value={basicInformation?.clientName}
                onChange={(e) => handleBasicInformationChange("clientName", e.target.value)}
                right={<img src={search} alt="Search" className="search-icon" />}
                style={{ padding: "20px 10px" }} // Add padding inside the TextInput
              />
            </div>
            <Dropdown label="Group Name" options={["Option 1", "Option 2"]} value={basicInformation?.groupName}
              onChange={(e) => handleBasicInformationChange("groupName", e.target.value)} />
            {/* <Input label="Phone No." placeholder="Barchasi" value={basicInformation?.phoneNo}
              onChange={(e) => handleBasicInformationChange("phoneNo", e.target.value)} /> */}
            <MobileInput
              label="Phone No."
              countryCode={basicInformation?.countryCode}
              mobileNumber={basicInformation?.phoneNo}
              onMobileChange={(value) => handleBasicInformationChange('phoneNo', value)}
              onCountryChange={(value) => handleBasicInformationChange('countryCode', value)}
              placeholder="Enter mobile number"
              countries={[
                { code: "IN", dialCode: "+91" },
                { code: "US", dialCode: "+1" },
                { code: "UK", dialCode: "+44" },
              ]}
              width="100%"
            />
            <div>
              {/* <Input label="Date of Invoicing" placeholder="11/11/2024" value={dateOfEstimate}
            onChange={(e) => setDateOfEstimate(e.target.value)} /> */}
              <div className="filter-field" style={{ marginBottom: "15px" }}> {/* Add margin-bottom here */}
                <label style={{ ...styles.labaleStyle, display: "block" }}>
                  Date of Invoicing
                </label>
                <DateInput
                  value={basicInformation?.dateOfEstimate}
                  onChange={(e) => handleBasicInformationChange("dateOfEstimate", e.target.value)}
                  style={{ padding: "20px 10px" }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Requested Services */}
        <div style={styles.tableBorder}>
          <h3 style={{ ...styles.sectionTitle, marginBottom: "12px" }}>Select Tasks Without Invoices</h3>
          <div className='basic-info-card' style={{ border: 'none', padding: '0px' }}>
            <div style={styles.tableWrapper}>
              <table className="tasksScreen-task-table">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>
                      {/* <input type="checkbox" /> */}
                      <CheckBox
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th style={{ width: "30%" }}>Service Name</th>
                    <th style={{ width: "10%" }}>Financial Year</th>
                    <th style={{ width: "10%" }}>Assigned To</th>
                    <th style={{ width: "10%" }}>Task Leader</th>
                    <th style={{ width: "10%" }}>Start Date</th>
                    <th style={{ width: "10%" }}>ETD</th>
                    <th style={{ width: "10%" }}>Status</th>
                    <th style={{ width: "5%" }}></th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: "white" }}>
                  {invoiceTableData?.map((category, index) => (
                    <tr key={index}>
                      <td>
                        {/* <input type="checkbox" /> */}
                        <CheckBox
                          checked={checkedStates[index] || false}
                          onChange={(isChecked) => handleCheckboxChange(index, isChecked)}
                        />
                      </td>
                      <td >{category.taskName}</td>
                      <td >{category.financialYear}</td>
                      <td >{category.assignedTo}</td>
                      <td >{category.taskLeader}</td>
                      <td >{category.startDate}</td>
                      <td >{category.etd}</td>
                      <td ><div
                        style={{
                          display: "inline-block", // Makes the div wrap content and have its own width
                          padding: "5px 5px", // Padding for the button-like appearance
                          borderRadius: "15px",
                          ...getStatusStyle(category.status), // Dynamic styles based on the status
                          width: "auto", // Width adjusts automatically to content
                        }}
                      >
                        {category.status}
                      </div></td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            gap: "2px", // Space between edit and delete buttons
                          }}
                        >
                          {/* Edit Button */}
                          <button
                            className="tasksScreen-edit-button"
                            style={{
                              border: "none",
                              background: "transparent",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={editIcon}
                              alt="Edit"
                              style={{ width: "16px", height: "16px" }}
                            />
                          </button>

                          {/* Delete Button */}
                          <button
                            className="tasksScreen-delete-button"
                            style={{
                              border: "none",
                              background: "transparent",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={deleteIcon}
                              alt="Delete"
                              style={{ width: "16px", height: "16px" }}
                            />
                          </button>
                        </div>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <AddUserModal isOpen={isModalOpen} onClose={handleCloseModal}/> */}
            </div>

          </div>
        </div>

        <div style={{ ...styles.row, border: 'none' }}>

          <Dropdown label="Invoice Type" options={["Option 1", "Option 2"]} value={invoiceDetails?.invoiceType}
            onChange={(e) => handleInvoiceDetailsChange("invoiceType", e.target.value)} />
          <Dropdown label="No Tax Invoices" options={["Option 1", "Option 2"]} value={invoiceDetails?.noTaxInvoices}
            onChange={(e) => handleInvoiceDetailsChange("noTaxInvoices", e.target.value)} />
          <Dropdown label="Generate Seperate Invoices" options={["Option 1", "Option 2"]} value={invoiceDetails?.generateSeperateInvoices}
            onChange={(e) => handleInvoiceDetailsChange("generateSeperateInvoices", e.target.value)} />
          <div className="filter-field" style={{ marginBottom: "15px" }}> {/* Add margin-bottom here */}
            <label style={{ ...styles.labaleStyle, display: "block" }}>
              Estimate Number
            </label>
            <TextInput
              type="text"
              placeholder="Search"
              value={invoiceDetails?.estimateNumber}
              onChange={(e) => handleInvoiceDetailsChange("estimateNumber", e.target.value)}
              right={<img src={search} alt="Search" className="search-icon" />}
              style={{ padding: "20px 10px" }} // Add padding inside the TextInput
            />
          </div>
          <Input label="Advance Payment" placeholder="11/11/2024" value={invoiceDetails?.advancePayments}
            onChange={(e) => handleInvoiceDetailsChange("advancePayments", e.target.value)} />
          <Input label="System Invoicing Amount" placeholder="11/11/2024" vvalue={invoiceDetails?.systemInvoicingAmount}
            onChange={(e) => handleInvoiceDetailsChange("systemInvoicingAmount", e.target.value)} />
          <Input label="Invoice Amount" placeholder="11/11/2024" value={invoiceDetails?.invoiceAmount}
            onChange={(e) => handleInvoiceDetailsChange("invoiceAmount", e.target.value)} />
          <Dropdown label="Task Completed By" options={["Option 1", "Option 2"]} value={invoiceDetails?.taskCompletedBy}
            onChange={(e) => handleInvoiceDetailsChange("taskCompletedBy", e.target.value)} />
          <Dropdown label="Generated By" options={["Option 1", "Option 2"]} value={invoiceDetails?.generatedBy}
            onChange={(e) => handleInvoiceDetailsChange("generatedBy", e.target.value)} />
          <Dropdown label="Organization" options={["Option 1", "Option 2"]} value={invoiceDetails?.organization}
            onChange={(e) => handleInvoiceDetailsChange("organization", e.target.value)} />
          <Dropdown label="Receipt Book" options={["Option 1", "Option 2"]} value={invoiceDetails?.receiptBook}
            onChange={(e) => handleInvoiceDetailsChange("receiptBook", e.target.value)} />
          <div className="filter-field" style={{ marginBottom: "15px" }}> {/* Add margin-bottom here */}
            <label style={{ ...styles.labaleStyle, display: "block" }}>
              Follow Up Date
            </label>
            <DateInput
              value={invoiceDetails?.followUpDate}
              onChange={(e) => handleInvoiceDetailsChange("followUpDate", e.target.value)}
              style={{ padding: "20px 10px" }}
            />
          </div>
        </div>

        {/* Footer Options */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '18px' }}>
          <div>
            <div style={{ marginBottom: '15px', display: "flex", alignItems: "center", }}>
              {/* <input type="checkbox" id="sendUpdateClient" checked={sendUpdateToClient}
                onChange={(e) => setSendUpdateToClient(e.target.checked)} />
              <label htmlFor="sendUpdateClient" style={styles.checkboxLabel}>
                Send Update to Client
              </label> */}
              <CheckBox
                label="Send Update to Client"
                checked={sendUpdateToClient}
                onChange={(isChecked) => setSendUpdateToClient(isChecked)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", }}>
              {/* <input type="checkbox" id="sendUpdateTeam" checked={sendUpdateToTeam}
                onChange={(e) => setSendUpdateToTeam(e.target.checked)} />
              <label htmlFor="sendUpdateTeam" style={styles.checkboxLabel}>
                Send Update to Team
              </label> */}
              <CheckBox
                label="Send Update to Team"
                checked={sendUpdateToTeam}
                onChange={(isChecked) => setSendUpdateToTeam(isChecked)}
              />
            </div>
          </div>
        </div>

        {/* Footer Buttons */}
        <div className="button-container" style={{ marginTop: '32px' }}>
          <button className="add-client-button" style={{ paddingLeft: '30px', paddingRight: '30px' }}>Generate Invoice</button>
          <button className="cancel-button" >Cancel</button>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "90%",
    margin: "auto",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontSize: "28px",
    marginBottom: "23px",
    fontWeight: "500",
    color: "#1F384C",
    // fontFamily: "Roboto Slab"
  },
  section: {
    marginBottom: "21px",
  },
  sectionTitle: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#609966",
    fontFamily: 'Roboto Slab'
  },
  row: {
    padding: '16px 11px',
    display: "flex",
    flexWrap: "wrap",
    columnGap: "70px",
    rowGap: "15px",
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid'
  },
  estimatedRow: {
    padding: '16px 11px',
    display: "flex",
    flexWrap: "wrap",
    columnGap: "70px",
    rowGap: "15px",
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
    paddingBottom: '63px'
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
  },
  checkboxLabel: {
    fontSize: "12px",
    color: "#343C44",
    fontFamily: 'Roboto',
    fontWeight: '400'
  },
  serviceRow: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "15px 70px", // Gap between two elements in the same row
    marginBottom: "15px", // Space between rows
    flexWrap: "wrap",
  },
  dropdownWithClose: {
    display: "flex",
    alignItems: "center", // Vertically aligns the dropdown and close icon
    gap: "26px", // Gap between dropdown and close icon
  },
  closeButton: {
    width: '15px',
    height: '15px',
    cursor: "pointer",
    verticalAlign: "middle",
    objectFit: "contain",
  },
  addServiceButton: {
    backgroundColor: "transparent",
    border: "1px #858585 solid",
    borderRadius: "5px",
    padding: "8px 12px",
    color: "#40513B",
    cursor: "pointer",
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    borderRadius: '28px',
    // marginBottom:'16px'
  },
  removeButton: {
    backgroundColor: "transparent",
    border: "none",
    color: "#f00",
    fontSize: "16px",
    cursor: "pointer",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  createButton: {
    backgroundColor: "#609966",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  servicesContainer: {
    // display: "flex",
    // flexWrap: "wrap", // Allows items to wrap to the next row if needed
    // gap: "20px", // Space between rows and items
    padding: '16px 11px',
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
  },
  mainContainer: {
    display: "flex",
    gap: "15px",
    flexWrap: "wrap",
    // overflowX: "auto",
    // whiteSpace: "nowrap",
  },
  "@media (max-width: 768px)": {
    // For tablets and smaller screens
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',

    }
  },
  "@media (max-width: 768px)": {
    serviceRow: {
      flexDirection: "column", // Stack items vertically
      gap: "15px", // Reduce the gap for smaller screens
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center', // Vertically align items
  },
  header: {
    fontSize: "22px",
    color: "#060606",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: 'Montserrat',
    //   marginLeft:'20px'
  },
  leftHeader: {
    marginTop: '-5px'
  },
  labaleStyle: {
    fontSize: '12px',
    // marginBottom: '5px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: 'rgb(52, 60, 68)'
  },
  tableWrapper: {
    overflowX: "auto", // Horizontal scroll
    whiteSpace: "nowrap", // Prevent table from wrapping on small devices
    // marginBottom:50
  },
  tableBorder: {
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
    padding: '10px'
  }
};

export default GenerateInvoiceScreen;
