
import React, { useState } from "react";

const EmailTemplateListController = () => {
    const [filterData, setFilterData] = useState({
        contactName: "",
        phoneNo: "",
        optOut: "",
        countryCode: "",
    });

    const handleInputChange = (field, value) => {
        setFilterData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };
    const EmailTemplateData = [
        {
            createdDate: "11/11/2024",
            templateContent: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum",
            sentTo: "List Name, Username",
            createdBy: "John Doe",
            templateName: 'Greetings'
        },
        {
            createdDate: "11/11/2024",
            templateContent: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum",
            sentTo: "List Name, Username",
            createdBy: "John Doe",
            templateName: 'Billing Remainder'
        },
        {
            createdDate: "11/11/2024",
            templateContent: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum",
            sentTo: "List Name, Username",
            createdBy: "John Doe",
            templateName: 'Diwali Wishes'
        },
        {
            createdDate: "11/11/2024",
            templateContent: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum",
            sentTo: "List Name, Usernames",
            createdBy: "John Doe",
            templateName: 'Remainder'
        }
    ]

    return {
        EmailTemplateData,
        filterData,
        setFilterData,
        handleInputChange
    }

}

export default EmailTemplateListController