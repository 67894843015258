import React from 'react';
// import './App.css';
import notifIcon from '../Assets/Icons/notificationIcon.png'
import AddClientModal from './AddClientModal';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import searchIcon from '../Assets/Icons/search.png';
import quickActionIcon from '../Assets/Icons/bitcoin-convert.png';
import plusIcon from '../Assets/Icons/Pluse.png'

const Header = ({ title, onValueChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);

  const quickActions = [
    { label: 'Add Client', modal: 'addClient' },
    // { label: 'Post Time Sheet', modal: 'postTimeSheet' },
    // { label: 'Leave/Attendance', modal: 'leaveAttendance' },
    // { label: 'Add Expense', modal: 'addExpense' },
    { label: 'Document In/Out', modal: 'documentInOut' },
    // { label: 'Add Notes', modal: 'addNotes' },
    // { label: 'Create Task', isActive: true },
    { label: 'Add To-Do', modal: 'addToDoModal' },
  ];
  const location = useLocation();
  const navigator = useNavigate();

  const handleAddClientClick = () => {
    setIsModalOpen(true);
  };
  const handleAddTaskClick = () => {
    navigator('/create-task')
  };
  const handleShowQuickActionClick = () => {
    setIsActionModalOpen(true);
  };

  const handleCloseActionModal = () => {
    setIsActionModalOpen(false);
  }

  const handleQuickActionClick = (modalType) => {
    setActiveModal(modalType); // Open specific modal
    setIsActionModalOpen(false); // Close Quick Actions modal
  };


  const handleCloseModal = () => {
    setActiveModal(null);
    setIsModalOpen(false);
  };

  const hadleNotification =()=>{
    navigator('/resourceslist')
  }
  console.log('titte', title)
  return (
    <div className="header-div">
      <div className="header">
        <div className="header-greeting">
          {title}
        </div>
        <div className="header-controls">
          {/* Search Bar */}
          <div style={styles.searchBar}>
            <img src={searchIcon} alt="Search" style={styles.searchIcon} className='grayscale' />
            <input type="text" placeholder="Search Something..." style={styles.searchInput} className='search-input' />
          </div>

          {/* Notification Button */}
          <button style={styles.notificationButton} onClick={hadleNotification}>
            <img src={notifIcon} alt="Notification" style={styles.notificationIcon} />
          </button>

          <button className="add-client-button-header" onClick={handleAddClientClick}>
            +&emsp; Add Client
          </button>

          <button className="add-client-button-header" onClick={handleAddTaskClick}>
            +&emsp; Add Task
          </button>

          <div style={styles.quickActiondiv} onClick={handleShowQuickActionClick}>
            <img src={quickActionIcon} alt="Search" style={styles.quickActionIcon} />
          </div>

          {isActionModalOpen && (
            <div 
            style={styles.modalOverlay} 
            // className='modal-overlay-m'
            onClick={handleCloseActionModal}>
              <div
                style={{
                  ...styles.modalContent,
                }}
                className='modal-content-m'
              >
                <h3 style={styles.quickActionsTitle}>
                  <img src={quickActionIcon} alt="Quick Actions" style={styles.quickActionIcon} />
                  Quick Actions
                </h3>

                <div style={styles.quickActionsContainer}>
                  {quickActions?.map((action, index) => (
                    <div
                      key={index}
                      style={{
                        ...styles.quickActionCard,
                        ...(action.isActive ? styles.activeCard : {}),
                      }}
                      onClick={() => handleQuickActionClick(action.modal)}
                    >
                      <div style={styles.quickActionContent}>
                        <p style={styles.quickActionLabel}>{action.label}</p>
                        <div style={styles.quickActionIconContainer}>
                          <img src={plusIcon} alt="Plus" style={styles.plusIcon} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {activeModal === 'addClient' && (
            <AddClientModal isOpen={!!activeModal} onClose={handleCloseModal} onValueChange={onValueChange} />
          )}
          {/* {activeModal === 'postTimeSheet' && <div>Post Time Sheet Modal</div>}
          {activeModal === 'leaveAttendance' && <div>Leave/Attendance Modal</div>}
          {activeModal === 'addExpense' && <div>Add Expense Modal</div>}
          {activeModal === 'documentInOut' && <div>Document In/Out Modal</div>}
          {activeModal === 'addNotes' && <div>Add Notes Modal</div>} */}

        </div>
        {isModalOpen &&
        <AddClientModal isOpen={isModalOpen} onClose={handleCloseModal} onValueChange={onValueChange} />}
      </div>
    </div>

  );
};


const styles = {
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    border: 'none',
    borderRadius: '10px',
    padding: '8px 10px',
    width: '100%',
    maxWidth: 300,
  },
  searchIcon: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
  searchInput: {
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    fontSize: '14px',
    color: '#A0A0A0',
    width: '100%',
  },
  notificationButton: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #858585',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  notificationIcon: {
    width: '20px',
    height: '20px',
  },
  quickActionIcon: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
  quickActiondiv: {
    backgroundColor: 'var(--secondary-color)',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    cursor: 'pointer'
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'flex-end', // Align modal to the right
    alignItems: 'flex-start', // Align modal to the top
    zIndex: 1000,
    padding: '10px'
  },
  modalContent: {
    backgroundColor: '#609966',
    borderRadius: '35px',
    padding: '10px',
    maxWidth: '1200px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    gap: '20px',
    justifyContent: 'flex-end'
  },
  quickActionsTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '400',
    color: '#F2F2F2',
    marginBottom: '10px',
  },
  quickActionIcon: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
  },
  quickActionsContainer: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    backgroundColor: '#EDF1D6',
    padding: '15px',
    borderRadius: '25px',
  },
  quickActionCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '120px',
    height: '100px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s ease',
  },
  activeCard: {
    border: '2px solid #62C462',
    transform: 'translateY(-5px)',
  },
  quickActionContent: {
    textAlign: 'center',
  },
  quickActionLabel: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#333333',
    marginBottom: '8px',
  },
  quickActionIconContainer: {
    width: '32px',
    height: '32px',
    backgroundColor: '#40513B',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 4px 16px rgba(90, 85, 210, 0.4)',
  },
  plusIcon: {
    width: '16px',
    height: '16px',
  },
}

export default Header;
