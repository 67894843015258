import { useState, useEffect } from "react";

const GstServiceController = () => {
    const [serviceOptions, setServiceOptions] = useState([]);
    const [serviceSubOptions, setServiceSubOptions] = useState([]);
    const [financialYearOptions, setFinancialYearOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [taskOverview, setTaskOverview] = useState([]);
    const [sections, setSections] = useState([]);
    const [checkwiseGroupReport, setCheckwiseGroupReport] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulating an API call to fetch data
        const fetchData = async () => {
            setLoading(true);
            try {
                const services = [
                    { label: "GST", value: "gst" },
                    { label: "Income Tax", value: "income-tax" },
                    { label: "Audit", value: "audit" },
                    { label: "Registrations", value: "registrations" },
                    { label: "Company Related", value: "company-related" },
                    { label: "TDS", value: "tds" },
                    { label: "Misc. Services", value: "misc-services" },
                    { label: "Accounting", value: "accounting" },
                ];

                const gstServices = [
                    { label: "GSTR 1", value: "gstr-1" },
                    { label: "GSTR 3B", value: "gstr-3b" },
                    { label: "GSTR 4", value: "gstr-4" },
                    { label: "GSTR 8", value: "gstr-8" },
                    { label: "GSTR 9", value: "gstr-9" },
                    { label: "GSTR 9C", value: "gstr-9c" },
                    { label: "GSTR 10", value: "gstr-10" },
                    { label: "GST Registration", value: "gst-registration" },
                    { label: "GST LUT", value: "gst-lut" },
                    { label: "GST Refund", value: "gst-refund" },
                    { label: "GST Appeal/Notices", value: "gst-appeal-notices" },
                    { label: "GST Cancellation/Surrender", value: "gst-cancellation-surrender" },
                    { label: "GST Challan Generation", value: "gst-challan-generation" },
                    { label: "GST Others", value: "gst-others" },
                    { label: "Others", value: "others" },
                ];

                const years = [
                    { label: "2023-24", value: "2023-24" },
                    { label: "2022-23", value: "2022-23" },
                ];

                const statuses = [
                    { label: "Completed", value: "completed" },
                    { label: "Pending", value: "pending" },
                ];

                const taskOverviewData = [
                    { label: "Completed", value: 1203 },
                    { label: "Pending", value: 21 },
                    { label: "Priority", value: 4 },
                    { label: "Long Due", value: 4 },
                    { label: "Not Started", value: 34 },
                    { label: "Inprogress", value: 34 },
                ];

                const sectionsData = [
                    {
                        title: "Task with Status",
                        items: [
                            { label: "Tasks with Pending Issues", value: 32 },
                            { label: "Tasks kept on Hold", value: 3 },
                            { label: "Tasks with No Issues", value: 32 },
                        ],
                    },
                    {
                        title: "Task with Dates",
                        items: [
                            { label: "Due by Today", value: 21 },
                            { label: "Due within 7 Days", value: 0 },
                            { label: "Crossed Due Date", value: 1 },
                        ],
                    },
                    {
                        title: "Schedule",
                        items: [
                            { label: "Follow Ups", value: 11 },
                            { label: "Appointments", value: 0 },
                            { label: "Meetings", value: 1 },
                        ],
                    },
                    {
                        title: "Pending issues with Tasks",
                        items: [
                            { label: "No. of Demand Refunds", value: 32 },
                            { label: "No. of Digital Signature Pending", value: 3 },
                            { label: "Loan Statement Pending", value: 32 },
                            { label: "Mismatches", value: 3 },
                            { label: "Tax Payments", value: 32 },
                        ],
                    },
                    // {
                    //     title: "Checkwise pending Tasks",
                    //     items: [
                    //         { label: "Info received from client for GSTR 1", value: 32 },
                    //         { label: "Preparation of GSTR 1", value: 3 },
                    //         { label: "Checking for GSTR 1", value: 32 },
                    //         { label: "Submission of GSTR 1", value: 3 },
                    //         { label: "Filling of GSTR 1", value: 32 },
                    //     ],
                    // },
                ];

                const groupReport = {
                    title: "Checkwise Group Report",
                    options: [{ label: "Group Name", value: "group-name" }],
                    statusOptions: [
                        { label: "Pending", value: "pending" },
                        { label: "Completed", value: "completed" },
                    ],
                };

                await new Promise((resolve) => setTimeout(resolve, 500)); // Simulate delay

                setServiceOptions(services);
                setServiceSubOptions(gstServices);
                setFinancialYearOptions(years);
                setStatusOptions(statuses);
                setTaskOverview(taskOverviewData);
                setSections(sectionsData);
                setCheckwiseGroupReport(groupReport);
            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return {
        serviceOptions,
        serviceSubOptions,
        financialYearOptions,
        statusOptions,
        taskOverview,
        sections,
        checkwiseGroupReport,
        loading,
    };
};

export default GstServiceController;
