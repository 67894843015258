import React, { useState, useEffect, useRef } from 'react';
import Input from './Input';
import Dropdown from './Dropdown';
import CheckBox from './CheckBox';
import MobileInput from './MobileInput';
import closeIcon from '../Assets/Icons/close.png';
import { ORG_GET_TEAM_LIST,ORG_GET_ROLES_LIST,ORG_ADD_EMPLOYEE } from '../ApiServices/BaseURL';
import { getData,postData } from '../ApiServices/ApiService';
import { validateEmail,validateMobileNumber } from '../Utils/Validation';

const AddUserModal = ({ isOpen, onClose, onValueChange }) => {
    const [username, setUsername] = useState('');
    const [userId, setUserId] = useState('');
    const [mobile, setMobile] = useState('');
    const [countryCode, setCountryCode] = useState('+91');
    const [email, setEmail] = useState('');
    const [group, setGroup] = useState(null);
    const [role, setRole] = useState(null);
    const [team, setTeam] = useState(null);
    const [sendToUser, setSendToUser] = useState(false);
    const [sendToManager, setSendToManager] = useState(false);
    const teamListRef = useRef([]);
    const rolesListRef = useRef([])

    const groupOptions = ['Group A', 'Group B', 'Group C'];
    const teamOptions = ['Team X', 'Team Y', 'Team Z'];

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('');

    const [isRoleLoading, setIsRoleLoading] = useState(false)
    const [roleError, setRoleError] = useState('');

    useEffect(()=>{
        fetchTeamList();
        fetchRoleList();
    },[])

    const fetchTeamList =async ()=>{
        try {
            setLoading(true);
            setError('');
            teamListRef.current = [];
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_TEAM_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    teamListRef.current = responseData?.dataJ.map(service => ({
                        label: service.team_name,
                        value: service.team_id
                      }));
                  } else {
                    setError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setError(responseData?.info || 'Failed to fetch team data. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch team data. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch team data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch team data: ${String(error)}`);
            }
          } finally {
            setLoading(false);
          }
      }

      const fetchRoleList =async ()=>{
        try {
            setIsRoleLoading(true);
            setRoleError('');
            rolesListRef.current = [];
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_ROLES_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    // rolesListRef.current = responseData?.dataJ?.map(doc => {
                    //     const [key, value] = Object.entries(doc)[0]; // Extract the first key-value pair
                    //     return { label: value, value: key };
                    //   });
                    rolesListRef.current =responseData?.dataJ.map(service => ({
                        label: service.role_name,
                        value: service.role_id
                      }));
                  } else {
                    setRoleError(responseData?.info || 'Data Not Found');
                  }
                } else {
                    setRoleError(responseData?.info || 'Failed to fetch Roles data. Please try again.');
                }
              } else {
                setRoleError(responseData?.info || 'Failed to fetch Roles data. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setRoleError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setRoleError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setRoleError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
                setRoleError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setRoleError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Roles data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setRoleError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setRoleError(`Failed to fetch Roles List: ${String(error)}`);
            }
          } finally {
            setIsRoleLoading(false);
          }
      }
      

    const handleAddUser = async () => {
       
          setError('');

          // Validate email and mobile number
          if (!validateEmail(email)) {
            setError('Invalid email address.');
            return;
          }
      
          if (!validateMobileNumber(mobile)) {
            setError('Mobile number must be 10 digits.');
            return;
          }
      
      
          try {
            setError('');
      
            setLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id,
                "emp_name": username,
                "emp_email": email,
                "emp_mobile_no": mobile,
                "emp_mobile_code": countryCode,
                "role_id": role?.value,
                "emp_code": userId,
                "team_id":  team?.value
              }
      
            console.log('catagory Data', payLoad);
            // Make the API call
            const response = await postData(ORG_ADD_EMPLOYEE, payLoad);
            const responseData = await response.json();
            console.log('response Data', responseData);
      
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  setError(responseData?.info);
                  setTimeout(() => {
                    handleClose();
                  }, 2000);
                } else {
                  setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
              }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch Task Categorie data: ${String(error)}`);
            }
          } finally {
            setLoading(false);
          }
        // onValueChange(userData);
        // onClose();
    };

    const handleClose = () => {
        resetForm();
        setError('');
        onClose();
      }

    const resetForm = () => {
        setUsername('');
        setUserId('');
        setMobile('');
        setEmail('');
        setGroup('');
        setRole(null)
        setTeam(null);
        setSendToUser(false);
        setSendToManager(false);
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" style={styles.iconImage} />
                </button>
                <h2 className="modal-title">Add Employee</h2>
                <hr className="title-divider" />

                <div className="form-grid">
                    <Input
                        label="Username"
                        placeholder="Enter Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <Input
                        label="User ID/Employee Code"
                        placeholder="Enter User ID"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                    />
                    {/* <Input
                        label="Mobile"
                        placeholder="Enter Mobile"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                    /> */}
                    <MobileInput
                label="Mobile"
                countryCode={countryCode}
                mobileNumber={mobile}
                onCountryChange={setCountryCode}
                onMobileChange={setMobile}
                placeholder="Enter Mobile"
                countries={[
                    { code: "IN", dialCode: "+91" },
                    { code: "US", dialCode: "+1" },
                    { code: "UK", dialCode: "+44" },
                ]}
            />
                    <Input
                        label="Email ID"
                        placeholder="Enter Email ID"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {/* <Dropdown
                        label="Group"
                        options={groupOptions}
                        value={group}
                        onChange={setGroup}
                    /> */}
                    <Dropdown
                        label="Team"
                        options={teamListRef.current}
                        value={team}
                        onChange={setTeam}
                        loading={loading}
                    />

                    <Dropdown
                        label="Role"
                        options={rolesListRef.current}
                        value={role}
                        onChange={setRole}
                        loading={isRoleLoading}
                    />
                </div>
                <div className="existing-client-checkbox">
                    {/* <input
                        type="checkbox"
                        checked={sendToUser}
                        onChange={(e) => setSendToUser(e.target.checked)}
                        style={styles.checkbox}
                    />
                    <label style={styles.exisitingHeading}>Send Update To User</label> */}
                    <CheckBox
                        label="Send Update to User"
                        checked={sendToUser}
                        onChange={(isChecked) => setSendToUser(isChecked)}
                        style={styles.checkbox}
                    />
                </div>
                <div className="existing-client-checkbox">
                    {/* <input
                        type="checkbox"
                        checked={sendToManager}
                        onChange={(e) => setSendToManager(e.target.checked)}
                        style={styles.checkbox}
                    />
                    <label style={styles.exisitingHeading}>Send Update To Manager</label> */}
                    <CheckBox
                        label="Send Update to Manager"
                        checked={sendToManager}
                        onChange={(isChecked) => setSendToManager(isChecked)}
                        style={styles.checkbox}
                    />
                </div>
                <hr className="footer-divider" />
                {error && <div className='errorBox'>{error}</div>}
                {roleError && <div className='errorBox'>{roleError}</div>}

                <div className="button-container">
                    <button className="add-client-button" onClick={handleAddUser} style={{ paddingLeft: '30px', paddingRight: '30px' }}>Add</button>
                    <button className="cancel-button" onClick={() => { resetForm(); onClose(); }}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    uploadContainer: {
        marginTop: '20px',
        width: '97%',
    },
    uploadBox: {
        width: '100%',
        border: '1px dashed #8FC7F2',
        borderRadius: '10px',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
        boxSizing: 'border-box',
        position: 'relative', // Position relative to contain the hidden input
    },
    uploadCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
    },
    plusIcon: {
        fontSize: '16px',
        color: '#333',
    },
    uploadText: {
        flex: 1,
        color: '#262626',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    uploadArrow: {
        fontSize: '18px',
        color: '#555',
    },
    fileInput: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        cursor: 'pointer',
    },
    iconImage: {
        width: '15px',
        height: '15px',
    },
    uploadIconImage: {
        width: '25px',
        height: '25px',
        marginRight: '5px'
    },
    exisitingHeading: {
        color: '#343C44',
        fontSize: '10px',
        fontWeight: 500,
        fontFamily: 'Roboto'
    }
}

export default AddUserModal;
