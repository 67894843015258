import { useState, useEffect } from 'react';

const UsersListController = () => {
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        // Sample data to match the provided table
        const data = [
            {
                userName: 'John Doe',
                mobile: '98900 98900',
                email_id: '--',
                groupName: 'Company Name/Group Name',
                taskAssign: 12,
                manager: 'John Doe',
            },
            {
                userName: 'Pranay R',
                mobile: '98900 98900',
                email_id: '--',
                groupName: 'Company Name/Group Name',
                taskAssign: 23,
                manager: 'John Doe',
            },
            {
                userName: 'Satish R',
                mobile: '98900 98900',
                email_id: '--',
                groupName: 'Company Name/Group Name',
                taskAssign: 0,
                manager: 'John Doe',
            },
            {
                userName: 'Ashok',
                mobile: '98900 98900',
                email_id: '--',
                groupName: 'Company Name/Group Name',
                taskAssign: 11,
                manager: 'John Doe',
            },
            {
                userName: 'Peter Parker',
                mobile: '98900 98900',
                email_id: '--',
                groupName: 'Company Name/Group Name',
                taskAssign: 32,
                manager: 'John Doe',
            },
            {
                userName: 'Steve Boardman',
                mobile: '98900 98900',
                email_id: '--',
                groupName: 'Company Name/Group Name',
                taskAssign: 4,
                manager: 'John Doe',
            },
            {
                userName: 'Allison Shaw',
                mobile: '98900 98900',
                email_id: '--',
                groupName: 'Company Name/Group Name',
                taskAssign: 43,
                manager: 'John Doe',
            },
            {
                userName: 'Slavka Bateman',
                mobile: '98900 98900',
                email_id: '--',
                groupName: 'Company Name/Group Name',
                taskAssign: 21,
                manager: 'John Doe',
            },
        ];
        setTasks(data);
        setFilteredTasks(data);
    }, []);

    const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return {
        tasks: currentTasks,
        currentPage,
        totalPages,
        handlePageChange,
    };
};

export default UsersListController;
