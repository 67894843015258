import React, { useState, useEffect } from "react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import DateInput from "../DateInput";
import closeIcon from "../../Assets/Icons/close.png";

const AddNoticeBoardMsgModal = ({ isOpen, onClose }) => {
    const [noticeMessage, setNoticeMessage] = useState("");
    const [recipientType, setRecipientType] = useState("");
    const [recipients, setRecipients] = useState([]);
    const [publishedDate, setPublishedDate] = useState("");
    const [scheduleDate, setScheduleDate] = useState("");
    const [expiryDate, setExpiryDate] = useState("");

    const recipientTypeOptions = [
        { label: "Teamwise", value: "teamwise" },
        { label: "Departmentwise", value: "departmentwise" },
        { label: "Organization", value: "organization" },
    ];

    const recipientOptions = [
        { label: "Team A", value: "team-a" },
        { label: "Team B", value: "team-b" },
        { label: "Team C", value: "team-c" },
    ];

    useEffect(() => {
        if (!isOpen) {
            resetForm();
        }
    }, [isOpen]);

    const resetForm = () => {
        setNoticeMessage("");
        setRecipientType("");
        setRecipients([]);
        setPublishedDate("");
        setScheduleDate("");
        setExpiryDate("");
    };

    const handleAddNotice = () => {
        const newNotice = {
            noticeMessage,
            recipientType,
            recipients,
            publishedDate,
            scheduleDate,
            expiryDate,
        };
        console.log("New Notice Board Message:", newNotice);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" style={styles.closeIcon} />
                </button>
                <h2 className="modal-title">Add Notice Board Message</h2>
                <hr className="footer-divider" />
                <div style={styles.actionField}>
                    <label style={styles.label}>Notice Board Message</label>
                    <textarea placeholder="Enter your notice message" style={styles.textarea}></textarea>
                </div>
                <div className="form-grid" style={{ marginTop: "20px", }}>
                    <Dropdown
                        label="Recipient Type"
                        options={recipientTypeOptions}
                        value={recipientType}
                        onChange={(e) => setRecipientType(e)}
                        width={'250px'}
                    />
                    <Dropdown
                        label="Select Recipient"
                        options={recipientOptions}
                        value={recipients}
                        onChange={setRecipients}
                        width={'250px'}
                        isMulti
                    />
                    <div>
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Published Date</label>
                        <DateInput
                            label="Published Date"
                            value={publishedDate}
                            onChange={(date) => setPublishedDate(date)}
                            width={'250px'}
                        />
                    </div>
                    <div>
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Scheduled Date</label>
                        <DateInput
                            label="Schedule Date"
                            value={scheduleDate}
                            onChange={(date) => setScheduleDate(date)}
                        />
                    </div>
                    <div>
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Expiry Date</label>
                        <DateInput
                            label="Expiry Date"
                            value={expiryDate}
                            onChange={(date) => setExpiryDate(date)}
                        />
                    </div>
                </div>
                <hr className="footer-divider" />
                <div className="button-container">
                    <button className="add-client-button" onClick={handleAddNotice}>
                        Add
                    </button>
                    <button className="cancel-button" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    closeIcon: {
        width: "16px",
        height: "16px",
    },
    formGrid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
        marginTop: "20px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "10px",
        marginTop: "20px",
    },
    actionField: {
        display: "flex",
        flexDirection: "column",
        marginTop: '15px'
    },
    label: {
        fontSize: "12px",
        fontWeight: "500",
        marginBottom: "8px",
        color: "#343C44",
    },
    textarea: {
        padding: "10px",
        border: "1px solid #D6E4EC",
        borderRadius: "5px",
        resize: "none",
        height: "87px",
        fontSize: "14px",
        color: "#333",
        width: '575px'
    },
};

export default AddNoticeBoardMsgModal;
