
import React, {useState} from "react";

const WhatsAppTemplateListController = () =>{
    const [filterData, setFilterData] = useState({
        templateName: "",
        createdBy:"",
        date: "",
      });

      const handleInputChange = (field, value) => {
        setFilterData((prevData) => ({
          ...prevData,
          [field]: value,
        }));
      };
    const whatsAppTemplateData = [
        {
            createdDate: "11/11/2024",
            templateContent: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum",
            sentTo: "List Name, Username",
            createdBy: "John Doe",
            templateName:'Greetings'
        },
        {
            createdDate: "11/11/2024",
            templateContent: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum",
            sentTo: "List Name, Username",
            createdBy: "John Doe",
            templateName:'Billing Remainder'
        },
        {
            createdDate: "11/11/2024",
            templateContent: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum",
            sentTo: "List Name, Username",
            createdBy: "John Doe",
            templateName:'Diwali Wishes'
        },
        {
            createdDate: "11/11/2024",
            templateContent: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum",
            sentTo: "List Name, Usernames",
            createdBy: "John Doe",
            templateName:'Remainder'
        }
    ]

    return{
        whatsAppTemplateData,
        filterData,
        setFilterData,
        handleInputChange
    }
    
}

export default WhatsAppTemplateListController