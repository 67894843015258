import React, { useState, useEffect, useRef } from 'react';
import Input from '../Input';
import Dropdown from '../Dropdown';
import closeIcon from '../../Assets/Icons/close.png';
import { postData } from '../../ApiServices/ApiService';
import { ORG_ADD_TEAM } from '../../ApiServices/BaseURL';
import Loader from '../Loader';

const AddTeamModal = ({ isOpen, onClose, onValueChange }) => {
    const [teamName, setTeamName] = useState('');
    const [teamDescription, setTeamDescription] = useState('');
    const [teamStatus, setTeamStatus] = useState(null); // Dropdown
    const [teamLead, setTeamLead] = useState(null); // Dropdown
    const [teamManager, setTeamManager] = useState(null); // Dropdown

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('');

    // Options for dropdowns
    const teamStatusOptions = [
        { label: 'Active', value: 1 },
        { label: 'Inactive', value: 0 },
    ];

    const teamLeadOptions = [
        { label: 'John Doe', value: '10001' },
        { label: 'Jane Smith', value: '10002' },
        { label: 'Alice Brown', value: '10003' },
    ];

    const teamManagerOptions = [
        { label: 'Tom Wilson', value: '10004' },
        { label: 'Sarah Johnson', value: '10005' },
        { label: 'Emma Davis', value: '10006' },
    ];

    const handleAddTeam = async () => {
   
        try {
            setError('');           
            setLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
              emp_id_auth: storedUserInfo?.emp_id,
              team_name: teamName,
              team_description: teamDescription,
              team_status: teamStatus?.value,
            //   emp_id_team_lead: null,
            //   emp_id_team_manager: null,
            }
        
            console.log('team Data',payLoad);
            // Make the API call
            const response = await postData(ORG_ADD_TEAM,payLoad);
            const responseData = await response.json();
            console.log('response Data',responseData);
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  setError(responseData?.info);
                  setTimeout(() => {
                    handleClose();
                    // onValueChange();
                  }, 2000);
                } else {
                  setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch Task Categorie data: ${String(error)}`);
            }
          } finally {
            setLoading(false);
          }

        // onValueChange(teamData);
        // onClose();
    };

    const handleClose=()=>{
        setError('');
        onClose();
      }

    const resetForm = () => {
        setTeamName('');
        setTeamDescription('');
        setTeamStatus(null);
        setTeamLead(null);
        setTeamManager(null);
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" style={styles.iconImage} />
                </button>
                <h2 className="modal-title">Add Team</h2>
                <hr className="title-divider" />

                <div className="form-grid">
                <Input
                        label="Team Name"
                        placeholder="Enter Team Name"
                        value={teamName}
                        onChange={(e) => setTeamName(e.target.value)}
                    />
                    <Input
                        label="Team Description"
                        placeholder="Enter Team Description"
                        value={teamDescription}
                        onChange={(e) => setTeamDescription(e.target.value)}
                    />
                    <Dropdown
                        label="Team Status"
                        options={teamStatusOptions}
                        value={teamStatus}
                        onChange={setTeamStatus}
                    />
                    <Dropdown
                        label="Team Lead"
                        options={teamLeadOptions}
                        value={teamLead}
                        onChange={setTeamLead}
                    />
                    <Dropdown
                        label="Team Manager"
                        options={teamManagerOptions}
                        value={teamManager}
                        onChange={setTeamManager}
                    />
                </div>
                {error && <div style={{textAlign:'center',color:'red'}}> {error} </div>}
                <hr className="footer-divider" />

                <div className="button-container">
                    <button className="add-client-button" onClick={handleAddTeam} style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                         <span style={{paddingRight:loading?10:0}}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Add</button>
                    <button className="cancel-button" onClick={() => { resetForm(); onClose(); }}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    uploadContainer: {
        marginTop: '20px',
        width: '97%',
    },
    uploadBox: {
        width: '100%',
        border: '1px dashed #8FC7F2',
        borderRadius: '10px',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
        boxSizing: 'border-box',
        position: 'relative', // Position relative to contain the hidden input
    },
    uploadCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
    },
    plusIcon: {
        fontSize: '16px',
        color: '#333',
    },
    uploadText: {
        flex: 1,
        color: '#262626',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    uploadArrow: {
        fontSize: '18px',
        color: '#555',
    },
    fileInput: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        cursor: 'pointer',
    },
    iconImage: {
        width: '15px',
        height: '15px',
    },
    uploadIconImage: {
        width: '25px',
        height: '25px',
        marginRight: '5px'
    },
    exisitingHeading: {
        color: '#343C44',
        fontSize: '10px',
        fontWeight: 500,
        fontFamily: 'Roboto'
    }
}

export default AddTeamModal;
