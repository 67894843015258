import React from "react";
import "../App.css";

const DateInput = ({ placeholder, style, value, onChange, right, ...props }) => {
    return (
        <div className="input-container2" style={{ position: "relative", width: "100%" }}>
            <input
                type="date"
                className="input-field"
                placeholder={placeholder}
                style={{
                    ...style,
                    paddingRight: "5px",
                    fontSize: "12px",
                    padding: "0px 10px"
                }}
                value={value || ""}
                onChange={onChange}
                {...props}
            />
            {right && <div className="right-element">{right}</div>}
        </div>
    );
};

export default DateInput;
