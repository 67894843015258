import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import profileImg from '../Assets/Images/temp-img.png';
import dashboardIcon from '../Assets/Icons/dashboard.png';
import tasksIcon from '../Assets/Icons/check-list.png';
import clientIcon from '../Assets/Icons/briefcase.png';
import clockIcon from '../Assets/Icons/clock.png';
import resourcesIcon from '../Assets/Icons/resources.png';
import usersIcon from '../Assets/Icons/people.png';
import billingIcon from '../Assets/Icons/layer.png';
import settingIcon from '../Assets/Icons/setting-2.png';
import logoutIcon from '../Assets/Icons/user.png';
import userIcon from '../Assets/Icons/user-s.png';
import logo from '../Assets/Images/logo.png';
import menuIcon from '../Assets/Icons/side-bar-previous.png';
import downArrowIcon from '../Assets/Icons/chevron-down.png';
import pluseIcon from '../Assets/Icons/pluse-s.png';
import cardIcon from '../Assets/Icons/qns-mark.png';
import DocumentIcon from '../Assets/Icons/crose-arrow.png';
import setting2Icon from '../Assets/Icons/setting-s.png';

const Sidebar = ({ onValueChange }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuShow,setIsMenuShow] = useState(false)
  const [userInfo, setUserInfo] = useState({});
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  
  useEffect(() => {
    const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    setUserInfo(storedUserInfo);
  }, []);

  useEffect(()=>{
    if(screenSize.width <= 958){
      console.log('window.innerWidth:-----',screenSize)

      setIsMenuShow(true)
    }

  },[screenSize])

  const handleNav = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    localStorage.removeItem('userRole');
    localStorage.removeItem('_userInfo_');
    localStorage.clear();
    navigate('/login');
  };

  const isActive = (path) =>{
    // console.log('location.pathname',location.pathname,'path:-',path)
    return location.pathname === path
  } ;

  // Menu Configuration
  const menuItems = {
    // admin: [
    //   // { label: 'Dashboard', icon: dashboardIcon, path: '/dashboard' },
    //   { label: 'Client Management', icon: clientIcon, path: '/clientlist' },
    //   { label: 'Task Management', icon: tasksIcon, path: '/task-management-list' },
    //   { label: 'Teams & Users', icon: usersIcon, path: '/teams-userlist' },
    //   // { label: 'Billing & Payments', icon: billingIcon, path: '/admin-billing-payment-list' },
    //   // { label: 'Schedule & Reminders', icon: clockIcon, path: '/admin-schedule-reminder' },
    //   // { label: 'Resources', icon: resourcesIcon, path: '/resourceslist' },
    //   // { label: 'Settings', icon: settingIcon, path: '/admin-settings' },
    // ],
    user: [
      // { label: 'Dashboard', icon: dashboardIcon, path: '/dashboard' },
      // { label: 'Tasks', icon: tasksIcon, path: '/tasklist' },
      // { label: 'Clients', icon: clientIcon, path: '/clientlist' },
      // { label: 'Schedule & Reminders', icon: clockIcon, path: '/schedule-reminder' },
      // { label: 'Resources', icon: resourcesIcon, path: '/resourceslist' },
      // { label: 'Users', icon: usersIcon, path: '/userlist' },
      // { label: 'Billing & Payments', icon: billingIcon, path: '/billing-payment-list' },
      // { label: 'Settings', icon: settingIcon, path: '/admin-settings' },

      // { label: 'Client Management', icon: clientIcon, path: '/clientlist' },
      // { label: 'Task Management', icon: tasksIcon, path: '/task-management-list' },
      // { label: 'Teams & Users', icon: usersIcon, path: '/teams-userlist' },
    ],
  };

  const navigationItems = [
    {
      title: "Dashboard",
      icon: dashboardIcon,
      path: '/dashboard',
      items:[]
    },
    {
      title: "Services",
      icon: tasksIcon,
      items: [
        { subtitle: "Service Dashboard", path: "/services/gst-services" },
        { subtitle: "Client List", path: "/clientlist" },
        { subtitle: "Group Master", path: "/services/manage-group" },
        { subtitle: "Manage Services", path: "/task-management-list" },
        { subtitle: "Others", path: "/services/other-settings" },
        // { subtitle: "Audit", path: "/audit" },
        // { subtitle: "Registrations", path: "/registrations" },
        // { subtitle: "Company Related", path: "/company-related" },
        // { subtitle: "TDS", path: "/tds" },
        // { subtitle: "Misc. Services", path: "/misc-services" },
        // { subtitle: "Accounting", path: "/accounting" },
        { subtitle: "Settings", path: "/settings" }
      ]
    },
    // {
    //   title: "Tasks",
    //   icon: tasksIcon,
    //   path: '/tasklist',
    //   items:[]
    // },
    // {
    //   title: "Clients",
    //   icon: clientIcon,
    //   path: '/clientlist',
    //   items:[]
    // },
    {
      title: "Invoices",
      icon: billingIcon,
      items: [
        { subtitle: "Bills/Receipt/Estimate", path: "/bills-receipts" },
        { subtitle: "Accounts", path: "/invoice/accounts" },
        { subtitle: "Reports", path: "/invoice/reports" },
        { subtitle: "Settings", path: "/invoice/settings" }
      ]
    },
    {
      title: "Progress Card",
      icon: cardIcon,
      items: [
        { subtitle: "Reports", path: "/progress-reports" },
        { subtitle: "Settings", path: "/settings" }
      ]
    },
    {
      title: "Employee Mgmt.",
      icon: userIcon,
      items: [
        // { subtitle: "Timesheet", path: "/timesheet" },
        // { subtitle: "Leave Management", path: "/leave-management" },
        // { subtitle: "Attendance", path: "/attendance" },
        // { subtitle: "Expense Management", path: "/expense-management" },
        { subtitle: "Add/Edit Employee", path: "/teams-userlist" },
        { subtitle: "Settings", path: "/settings" }
      ]
    },
    {
      title: "Add On’s",
      icon: pluseIcon,
      items: [
        { subtitle: "Notice Board", path: "/notice-board" },
        { subtitle: "DSC Center", path: "/dsc-center" },
        { subtitle: "Income Tax Portal", path: "/income-tax-portal" },
        { subtitle: "Settings", path: "/settings" }
      ]
    },
    {
      title: "Configuration",
      icon: settingIcon,
      items: [
        { subtitle: "Configuration Center", path: "/configuration/config-center" },
        { subtitle: "Activity Register", path: "/configuration/activity-register" },
        { subtitle: "Reminder Register", path: "/configuration/reminder-register" },
        { subtitle: "Template Center", path: "/configuration/template-center" },
        { subtitle: "Contact Center", path: "/configuration/contact-center" },
        { subtitle: "Settings", path: "/settings" }
      ]
    },
    {
      title: "Document Rack",
      icon: DocumentIcon,
      items: [
        { subtitle: "Documents", path: "/documents" },
        { subtitle: "Settings", path: "/settings" }
      ]
    },
    {
      title: "Settings *",
      icon: setting2Icon,
      path: '/admin-settings',
      items:[]
    },
    {
      title: "Billing & Payments *",
      icon: billingIcon,
      path: '/billing-payment-list',
      items:[]
    },
    // {
    //   title: "Task Management *",
    //   icon: tasksIcon,
    //   path: '/task-management-list',
    //   items:[]
    // },
    // {
    //   title: "Teams & Users *",
    //   icon: usersIcon,
    //   path: '/teams-userlist',
    //   items:[]
    // },
  ];
  

  // Dynamically select menu based on roles ,SUPER-ADMIN,ADMIN,MANAGER
  const currentMenu = userInfo?.emp_role === 'SUPER-ADMIN' ? menuItems.user : menuItems.user;

  const handleMenuShowHide =()=>{
    console.log('sgokhh')
    setIsMenuShow(!isMenuShow);
    onValueChange(!isMenuShow)
  }
  const [activeDropdown, setActiveDropdown] = useState(null);
  const handleDropdownToggle = (title) => {
    setActiveDropdown(activeDropdown === title ? null : title);
  };
  const handleNavigation = (path) => {
    // setActiveDropdown(null)
    navigate(path); // Navigate to the given path
  };

  return (
    <div className="sidebar" style={{width:isMenuShow ? 50:'15%'}}>
      <div className='menu-icon-div-sty' onClick={handleMenuShowHide} style={{display:screenSize.width <= 958 ? 'none':'block'}}>
      <img className="menu-icon-sty" src={menuIcon} alt="Company Logo" style={{transform: isMenuShow ? 'rotate(180deg)':'rotate(0deg)'}}/>
      </div>
      <div className='display-full-height'>
      {/* Company Info */}
      <div className="companyInfo-sty">
        <img className="smallLogo-Sty" src={logo} alt="Company Logo" style={{display:isMenuShow ? 'none':'block'}}/>
        <h3 className="companyname-Sty" style={{display:isMenuShow ? 'none':'block'}}>{userInfo?.emp_company_name}</h3>
      </div>

      {/* Profile Section */}
      <div className="sidebar-profile" style={{display:isMenuShow ? 'none':'block'}}>
        <img src={profileImg} alt="Profile" className="profile-image" />
        <h3>{userInfo?.emp_name}</h3>
        <p>{userInfo?.emp_role}</p>
      </div>

      {/* Menu Section */}
      <div className="sidebar-menu">
        {currentMenu.map((item) => (
          <button
            key={item.label}
            onClick={() => handleNav(item.path)}
            className={`sidebar-menu-li ${isActive(item.path) ? 'active' : ''}`}
            style={{justifyContent:isMenuShow ? 'center':'flex-start'}}
            aria-label={item.label}
          >
            <img src={item.icon} alt={item.label} className={`sidebar-icon ${isActive(item?.path) ? 'sidebar-icon-white' : ''}`}/>
            <span className="displayNone" style={{display:isMenuShow ? 'none':'block'}}>{item?.label}</span>
          </button>
        ))}
      </div>

      {/* Dropdown section*/}
      <div className="sidebar-menu">
      {navigationItems.map((navItem) => (
        <div key={navItem.title} className="dropdown">
          <button
            className={`sidebar-menu-li ${isActive(navItem.path) && navItem?.items?.length > 0  ? 'active' : ''}`}
            style={{justifyContent:isMenuShow ? 'center':''}}
            onClick={() =>
              navItem?.items?.length
                ? handleDropdownToggle(navItem?.title)
                : (handleNavigation(navItem?.path),setActiveDropdown(null))
            }
          >
            
            <img
              src={navItem?.icon}
              alt={navItem?.title}
              className={`sidebar-icon ${isActive(navItem?.path) ? 'sidebar-icon-white' : ''}`}
            />
            <span className={`dropdown-title ${isActive(navItem?.path) ? 'sidebar-icon-white' : ''}`} style={{display:isMenuShow ? 'none':'block'}}>{navItem?.title}</span>
            <span className="dropdown-arrow">  
            {navItem?.items?.length > 0 && (            
              <img className="down-icon-sty" src={downArrowIcon} alt="Company Logo" style={{transform: activeDropdown === navItem.title ? 'rotate(180deg)':'rotate(0deg)'}}/>
            )}
            </span>
          </button>
          {activeDropdown === navItem.title && navItem.items.length > 0 && (
            <div className="dropdown-content" style={{position: isMenuShow ?'absolute':'',left:35}}>
              {navItem.items.map((item, index) => (
                <div
                  key={index}
                  className={`dropdown-link ${isActive(item?.path) ? 'sub-active' : 'sub-inactive'}`}
                  style={{paddingLeft: isMenuShow ? 10: 30,cursor:'pointer'}}
                  onClick={() => handleNavigation(item.path)}
                >
                  {item.subtitle}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>

      {/* Logout Section */}
      <div className="sidebar-logout">
        <button onClick={handleLogout} className="center-alin-sty">
          <img src={logoutIcon} alt="Logout" className="logout-icon" />
          <span style={{display:isMenuShow ? 'none':'block',paddingLeft:10}}>Log out</span>
        </button>
      </div>
      </div>

    </div>
  );
};

export default Sidebar;
