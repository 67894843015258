import { useState, useEffect } from 'react';

const ClientBulkUpdateListController = () => {
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    useEffect(() => {
        // Sample data with status and showDetails property
        const data = [
            {
                clientName: 'DLS India',
                groupName: 'DLS',
                assignedTo: 'John Doe',
                taskLeader: 'John Doe',
                location: 'Hyderabad',
                services: 'GST, Income Tax',
            },
            {
                clientName: 'Satish R',
                groupName: 'DLS',
                assignedTo: 'John Doe',
                taskLeader: 'John Doe',
                location: 'Hyderabad',
                services: 'GST, Income Tax',
            },
            {
                clientName: 'Bharat M',
                groupName: 'DLS',
                assignedTo: 'John Doe',
                taskLeader: 'John Doe',
                location: 'Hyderabad',
                services: 'GST, Income Tax',
            },
            {
                clientName: 'Ashok R',
                groupName: 'DLS',
                assignedTo: 'John Doe',
                taskLeader: 'John Doe',
                location: 'Hyderabad',
                services: 'GST, Income Tax',
            },
            {
                clientName: 'Pranay R',
                groupName: 'DLS',
                assignedTo: 'John Doe',
                taskLeader: 'John Doe',
                location: 'Hyderabad',
                services: 'GST, Income Tax',
            },
            {
                clientName: 'Vijay S',
                groupName: 'XYZ',
                assignedTo: 'Jane Smith',
                taskLeader: 'Jane Smith',
                location: 'Barchasi',
                services: 'Audit, Compliance',
            },
            {
                clientName: 'Ramesh K',
                groupName: 'XYZ',
                assignedTo: 'Jane Smith',
                taskLeader: 'Jane Smith',
                location: 'Barchasi',
                services: 'Audit, Compliance',
            },
            {
                clientName: 'Harish V',
                groupName: 'DLS',
                assignedTo: 'John Doe',
                taskLeader: 'John Doe',
                location: 'Hyderabad',
                services: 'Accounting, Tax Filing',
            },
            {
                clientName: 'Karthik G',
                groupName: 'DLS',
                assignedTo: 'John Doe',
                taskLeader: 'John Doe',
                location: 'Hyderabad',
                services: 'Tax Filing',
            },
        ];
        setTasks(data);
        setFilteredTasks(data);
    }, []);

    const toggleDetails = (index) => {
        setFilteredTasks((prevTasks) =>
            prevTasks.map((task, i) =>
                i === index ? { ...task, showDetails: !task.showDetails } : task
            )
        );
    };

    const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return {
        tasks: currentTasks,
        currentPage,
        totalPages,
        handlePageChange,
        toggleDetails,
    };
};

export default ClientBulkUpdateListController;
