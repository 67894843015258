import { useState, useEffect,useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getData } from "../../../../ApiServices/ApiService";
import { ORG_GET_EMPLOYEE_INFO } from "../../../../ApiServices/BaseURL";

const ManageUserController = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [userToggleState, setUserToggleState] = useState(true);
    // const { userInfo } = location.state || {};
    const [userData,setUserData] = useState({})

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (!location.state?.isUserEditable) {
                fetchUserInfo(location.state?.userInfo?.emp_id)
        } else {

        }

    }, [location.state?.userInfo])

    const fetchUserInfo = async (userId) => {
        try {
            setLoading(true);
            setError('');
            setUserData({});


            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_EMPLOYEE_INFO}?emp_id_auth=${storedUserInfo?.emp_id}&emp_id=${userId}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            setUserData(responseData?.dataJ[0]);
                            // selGroupInfo.current = responseData?.dataJ[0]
                        } else {
                            setError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Group Data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setError(`Failed to fetch Task Categorie data: ${String(error)}`);
            }
        } finally {
            setLoading(false);
        }
    }

    const handleBack = () => {
        console.log('handleBack')
        navigate('/teams-userlist');
    }

    const handleUserToggleChange = (newState) => {

    }

    return {
        handleBack,
        userToggleState,
        handleUserToggleChange,
        loading,
        error,
        userData
    };

};

export default ManageUserController;