import React from "react";
import { useTheme, GlobalC } from "../../../GlobalContext/GlobalContext";
import { useNavigate } from 'react-router-dom';
import logImg from '../../../Assets/Images/loginImg.png'
import logo from '../../../Assets/Images/logo.png'
import MFAScreenController from "../../Controllers/AuthenticationScreens/MFAScreenController";
import TextInput from "../../../Components/TextInput";
import Loader from "../../../Components/Loader";

const MFAScreen = () => {
    const { theme, setCompanyId } = useTheme();
    console.log('theme:-', theme);
    const navigate = useNavigate();

    const {
        mfa,
        setMfa,
        handleMfaChange,
        loading,
        error,
        setError,
        verify
    } = MFAScreenController();


    return (
        <div className="login">
            <div className="frameParent">
                <div className="artboard113WfAutomationIllWrapper">
                    <img className="logimgstyle" alt="" src={logImg} />
                </div>
                <div className="frameGroup" style={{ gap: '10px' }}>
                    <div className="groupParent">
                        <img className="frameChild" alt="" src={logo} />
                        <div className="companyName">Company Name</div>
                    </div>
                    <div className="input">
                        <div className="xodimlar">Enter 6 digit code sent to your email ID to verify your identity</div>

                        <TextInput
                            placeholder="OTP"
                            value={mfa || ''}
                            onChange={(e) => handleMfaChange(e.target.value)}
                            style={{ minWidth: '360px' }}
                            maxLength={6}
                        />
                    </div>
                    <div> {error && <span style={{ textAlign: 'center', color: 'red' }}> {error} </span>} </div>
                    {/* <div className="button" onClick={login} >
                          <div className="label flexdisplay" ><Loader loading={loading} color={'#FFFFFF'} size='small' />  Login</div>
                    </div> */}
                    <button className="verifyButton" onClick={verify} disabled={loading}><span style={{ paddingRight: loading ? 10 : 0 }}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Verify</button>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '125px' }}>
                        <div className="forgotPassword">Resend code in 15 second</div>
                        <div className="forgotPassword">Cancel Sign In</div>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default MFAScreen;

