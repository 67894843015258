import { useState, useEffect } from 'react';
import { postData,getData } from '../../../../ApiServices/ApiService';
import { ORG_GET_TASK_LIST } from '../../../../ApiServices/BaseURL';
import { useNavigate } from 'react-router-dom';

const ClientTaskListController = () => {
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        // Sample data with status and showDetails property
     
        // setTasks(data);
        // setFilteredTasks(data);
        fetchTaskList();
    }, []);

    const fetchTaskList =async ()=>{
        console.log('get client')
        try {
          setFilteredTasks([])
            setLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const payLoad={
                "emp_id_auth": storedUserInfo?.emp_id
            }
            const response = await postData(ORG_GET_TASK_LIST,payLoad);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    setTasks(responseData?.dataJ);
                    setFilteredTasks(responseData?.dataJ);               
                  } else {
                    setError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch Task Categorie data: ${String(error)}`);
            }
          } finally {
            setLoading(false);
          }
      }

    const toggleDetails = (index) => {
        setFilteredTasks((prevTasks) =>
            prevTasks.map((task, i) =>
                i === index ? { ...task, showDetails: !task.showDetails } : task
            )
        );
    };

    const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getStatusStyle = (status) => {
        switch (status) {
          case "In Progress":
            return { backgroundColor: "#d4edda", color: "#155724" }; // Green
          case "Not Started":
            return { backgroundColor: "#fff3cd", color: "#856404" }; // Yellow
          case "Priority":
            return { backgroundColor: "#f8d7da", color: "#721c24" }; // Red
          default:
            return {};
        }
      };

      const handleGenerateInvoive = () => {
        navigate("/generate-invoice");
      };

    return {
        tasks: currentTasks,
        currentPage,
        totalPages,
        handlePageChange,
        toggleDetails,
        getStatusStyle,
        loading,
        error,
        handleGenerateInvoive
    };
};

export default ClientTaskListController;
