import {useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
const ManageTeamsController = (teamList) => {
    console.log('teamList:-',teamList)

    const navigate = useNavigate();

    const [teamName, setTeamName] = useState("");
    const [teamManager, setTeamManager] = useState("");
    const [group, setGroup] = useState("");
    const [teamLeader, setTeamLeader] = useState("");
    const teamLeaderOptions = [ "Team Leader A", "Team Leader B"];
    const teamListRef = useRef(teamList);
    useEffect(()=>{
        teamListRef.current = teamList;

    },[teamList])

    const handleCreateTeam = () => {
        console.log('handleCreateTeam ')
        navigate('/manage-team-details', {state : { selTeamInfo: null } })
    }

    const handleEditTeam = (team,isEdit) => {
        console.log('handleEditTeam team editicon', team)
        // navigate('/manage-team-details', {state : { selTeamInfo:team,isEdit: isEdit} });
        navigate('/manage-team-details', { state: { selTeamInfo: team, isEdit: isEdit, selectedTab: 1 } });
    }

    const manageUsers2 = [
        {
            teamname: "Compant Name/ Group Name",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            teammanager: "John Doe",
          status: "Active",
          teammember: "--",
          createdby:'John Doe'
        },
        {
            teamname: "Compant Name/ Group Name",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
          status: "Incomplete",
          teammanager: "John Doe",
          teammember: "--",
          createdby:'John Doe'
        },
        {
            teamname: "Compant Name/ Group Name",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
          status: "Disabled",
          teammanager: "John Doe",
          teammember: "--",
          createdby:'John Doe'
        },
        {
            teamname: "Compant Name/ Group Name",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
          status: "Disabled",
          teammanager: "John Doe",
          teammember: "--",
          createdby:'John Doe'
        },
        {
            teamname: "Compant Name/ Group Name",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
          status: "Disabled",
          teammanager: "John Doe",
          teammember: "--",
          createdby:'John Doe'
        },
        {
            teamname: "Compant Name/ Group Name",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
          status: "Disabled",
          teammanager: "John Doe",
          teammember: "--",
          createdby:'John Doe'
        },
        {
            teamname: "Compant Name/ Group Name",
            mobile: "Company",
            email: "--",
            groupName: "Company Name/Group Name",
            manager: "John Doe",
          status: "Incomplete",
          teammanager: "John Doe",
          teammember: "--",
          createdby:'John Doe'
        },
        {
            teamname: "Compant Name/ Group Name",
          mobile: "Company",
          email: "--",
          groupName: "Company Name/Group Name",
          manager: "John Doe",
          status: "Incomplete",
          teammanager: "John Doe",
          teammember: "--",
          createdby:'John Doe'
        }
      ]
      

        const getStatusStyle = (status) => {
            switch (status) {
              // case "Incomplete":
              //   return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" }; // Green
              case 0:
                return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" }; // Yellow
              case 1:
                return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" }; // Red
              default:
                return {};
            }
          };

    return {
        teamName, setTeamName,
        teamManager, setTeamManager,
        group, setGroup,
        teamLeader, setTeamLeader,
        teamLeaderOptions,
        teamListRef,
        handleCreateTeam,
        handleEditTeam,
        getStatusStyle
    }

}

export default ManageTeamsController;