import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../../../Components/Tabs';
import ClientBulkUpdateListScreen from './ClientBulkUpdateListScreen';
import NatureCallTypeListScreen from './NatureCallTypeListScreen';
import UploadClientsListFileScreen from './UploadClientsListFileScreen';


const OtherSettingsScreen = () => {

    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(state?.selectedTab || 0);

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    return (
        <div style={styles.mainContent}>
            <div className='title-container' style={{ paddingBottom: 10 }}>
                <h2 className='title'>Other Settings</h2>
            </div>

            <div >
                <Tabs tabs={['Bulk Update Client Information', 'Manage Nature of Calls', 'Import Master']}
                selectedIndex={selectedTab}
                onTabChange={handleTabChange}>
                    <ClientBulkUpdateListScreen />
                    <NatureCallTypeListScreen />
                    <UploadClientsListFileScreen />
                </Tabs>
            </div>

        </div>


    )

};

const styles = {
    mainContent: {
        width: '95%',
        margin: 'auto'
    }

}

export default OtherSettingsScreen;