
import React, { useState } from 'react'

const InvoiceReportsController = () => {
    const [reportName, setReportName] = useState("");
    const [clientName, setClientName] = useState("");
    const [groupName, setGroupName] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const deliveryTypeOptions = ["Employee Report", "Work Process Revenue", "Consolidated Report", "User Activity Report", "To-Do List Report", "User Revenue Report", "Other Report", "Client Login Documents", "Attendance Report"];

    const statusOptions = [{ label: 'Pending', value: 'Pending' }, { label: 'In Progress', value: 'In Progress' }];

    return {
        reportName,
        setReportName,
        clientName,
        setClientName,
        groupName,
        setGroupName,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        deliveryTypeOptions,
        statusOptions
    }

}

export default InvoiceReportsController