
import React,{useState} from 'react'

const InvoiceAccountsController = ()=>{

    const [filterValues, setFilterValues] = useState({
        clientName: "",
        clientType: "",
        gstinNo: "",
        pendingTask: "",
        city: "",
        assignedTo: "",
        groupName: "",
        services: "",
      });

      const handleFilterChange = (field, value) => {
        setFilterValues((prevFilters) => ({
          ...prevFilters,
          [field]: value,
        }));
      };
      
      

    const accountsData = [
        {
          clientName: "DLS CORP Corp",
          outstandingAmount: "0.00",
          clientType: "Company",
          gstinNo: "--",
          groupName: "Company Name/Group Name",
          assignedTo: "John Doe",
          accountStatus: "Paid"
        },
        {
          clientName: "DLS CORP Corp",
          outstandingAmount: "-20,000.00",
          clientType: "Company",
          gstinNo: "--",
          groupName: "Company Name/Group Name",
          assignedTo: "John Doe",
          accountStatus: "Advance Payment"
        },
        {
          clientName: "DLS CORP Corp",
          outstandingAmount: "3,000.00",
          clientType: "Company",
          gstinNo: "--",
          groupName: "Company Name/Group Name",
          assignedTo: "John Doe",
          accountStatus: "Overdue"
        },
        {
          clientName: "DLS CORP Corp",
          outstandingAmount: "3,000.00",
          clientType: "Company",
          gstinNo: "--",
          groupName: "Company Name/Group Name",
          assignedTo: "John Doe",
          accountStatus: "Overdue"
        },
        {
          clientName: "DLS CORP Corp",
          outstandingAmount: "3,000.00",
          clientType: "Company",
          gstinNo: "--",
          groupName: "Company Name/Group Name",
          assignedTo: "John Doe",
          accountStatus: "Overdue"
        },
        {
          clientName: "DLS CORP Corp",
          outstandingAmount: "3,000.00",
          clientType: "Company",
          gstinNo: "--",
          groupName: "Company Name/Group Name",
          assignedTo: "John Doe",
          accountStatus: "Overdue"
        },
        {
          clientName: "DLS CORP Corp",
          outstandingAmount: "-15,000.00",
          clientType: "Company",
          gstinNo: "--",
          groupName: "Company Name/Group Name",
          assignedTo: "John Doe",
          accountStatus: "Advance Payment"
        },
        {
          clientName: "DLS CORP Corp",
          outstandingAmount: "-15,000.00",
          clientType: "Company",
          gstinNo: "--",
          groupName: "Company Name/Group Name",
          assignedTo: "John Doe",
          accountStatus: "Advance Payment"
        }
      ]

      const getStatusStyle = (status) => {
        switch (status) {
          case "Advance Payment":
            return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" }; // yellow
          case "Overdue":
            return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" }; // red
          case "Paid":
            return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" }; // green
          default:
            return {};
        }
      };
      
      return{
        accountsData,
        getStatusStyle,
        setFilterValues,
        filterValues,
        handleFilterChange
      }

}

export default InvoiceAccountsController