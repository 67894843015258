
import React, {useState} from "react";

const InvoiceAccountClientInformationController = ()=>{
    const [invoiceInformation, setInvoiceInformation] = useState({
        clientName: "DLS IND Corp",
        clientType: "Company",
        dateOfInvoice: "31/10/2024",
        invoiceNumber: "DLS24-0001",
        combinedBill: "No",
        groupInvoice: "No",
        taskStatus: "Completed",
        serviceAgent: "Satish R",
        teamLeader:'John Doe'
    });

    const [summaryData, setSummaryData] = useState({
        invoicedAmount: 23000.00,
        amountPaid: 23000.00,
        pendingPayment: 23000.00,
      });

      const [invoiceData, setInvoiceData] = useState({
        generated: 21,
        upcoming: 10,
        dleted: 30,
      });

      const [otherInformationData, setOtherInformationData] = useState({
        clientTaxable: 'No',
        advancePayment: 20000.00,
        pendingIssues: 10,
      });

      const [receiptsData, setReceiptsData] = useState({
        generated: 31,
        pending: 25,
        overdue: 40,
      });
      const [estimatesData, setEstimatesData] = useState({
        generated: 11,
        converted: 21,
        deleted: 10,
      });

    return{
        invoiceInformation,
        setInvoiceInformation,
        summaryData,
        setSummaryData,
        invoiceData,
        setInvoiceData,
        otherInformationData,
        setOtherInformationData,
        receiptsData,
        setReceiptsData,
        estimatesData,
        setEstimatesData
    }

}

export default InvoiceAccountClientInformationController