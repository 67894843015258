
import React from 'react';
import Input from '../../../../Components/Input';
import uploadIcon from '../../../../Assets/Icons/upload.png'
import InvoiceSettingsConfigurationsController from '../../../Controllers/AdminScreens/Invoices/InvoiceSettingsConfigurationsController';


const InvoiceSettingsConfigurationsScreen = () => {
    const {
        setServiceEstimatePrefix,
        serviceEstimatePrefix,
        setInvoicePrefix,
        invoicePrefix,
        setReceiptPrefix,
        receiptPrefix,
        setBooksOpeningBalance,
        booksOpeningBalance
    } = InvoiceSettingsConfigurationsController();

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Configuration</h2>
            <div style={styles.field}>
                <label style={styles.label}>Service Estimate Series Prefix</label>
                <Input placeholder="DLS24-" value={serviceEstimatePrefix}
                    onChange={(e) => setServiceEstimatePrefix(e.target.value)} />
            </div>
            <div style={styles.field}>
                <label style={styles.label}>Invoice Series Prefix</label>
                <Input placeholder="DLS24-" value={invoicePrefix}
                    onChange={(e) => setInvoicePrefix(e.target.value)} />
            </div>
            <div style={styles.field}>
                <label style={styles.label}>Receipt Series Prefix</label>
                <Input placeholder="DLS24-" value={receiptPrefix}
                    onChange={(e) => setReceiptPrefix(e.target.value)} />
            </div>
            <hr className="footer-divider" />
            <div style={styles.field}>
                <label style={styles.label}>Books Opening Balance</label>
                <Input placeholder="0.00" value={booksOpeningBalance}
                    onChange={(e) => setBooksOpeningBalance(e.target.value)} />
            </div>
            <hr className="footer-divider" />
            <div style={styles.field}>
                <label style={styles.label}>Import Invoices</label>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                <input
                    type="file"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer',
                    }}
                    onChange={(e) => {
                        const files = e.target.files;
                        if (files.length > 0) {
                            console.log('File selected:', files[0]);
                        }
                    }}
                />
                <button style={{ ...styles.addServiceButton, paddingLeft: '12px', paddingRight: '12px',cursor:'pointer' }}>
                    <img src={uploadIcon} style={{...styles.icon, cursor:'pointer'}} alt="Upload Icon" />
                    <span style={{...styles.buttonText, cursor:'pointer'}}>Import/Upload</span>
                </button>
            </div>

            </div>
            <hr className="footer-divider" />
            <div className="button-container" style={{ gap: "10px" }}>
                <button className="add-client-button" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                    Update
                </button>
                <button style={{ ...styles.cancelButton, paddingLeft: '30px', paddingRight: '30px' }}>
                    Cancel
                </button>
            </div>
        </div>
    );

}

const styles = {
    // container: {
    //   width: "600px",
    //   margin: "0 auto",
    //   fontFamily: "Arial, sans-serif",
    // },
    title: {
        color: 'black',
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: '500',
    },
    field: {
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
    },
    label: {
        flex: "0 0 200px", // Ensures all labels are the same width
        color: 'black',
        fontSize: 14,
        fontFamily: 'Roboto',
        fontWeight: '400',
    },
    // input: {
    //   flex: "1",
    //   height: "30px",
    //   padding: "5px 10px",
    //   fontSize: "14px",
    //   border: "1px solid #d1d5db",
    //   borderRadius: "4px",
    // },

    addServiceButton: {
        backgroundColor: "transparent",
        border: "1px #858585 solid",
        borderRadius: "5px",
        padding: "8px 12px",
        color: "#40513B",
        cursor: "pointer",
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: '400',
        borderRadius: '28px',
        // marginBottom:'16px'
        display: "flex", // Use flexbox for alignment
        alignItems: "center", // Vertically align icon and text
        justifyContent: "center",
    },
    icon: {
        width: "12px",
        height: "12px",
        marginRight: "10px",
    },
    cancelButton: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        color: "#40513B",
        border: "1px solid #858585",
        padding: "8px 16px",
        borderRadius: '20px',
        cursor: "pointer",
    }
};

export default InvoiceSettingsConfigurationsScreen