import React, { useState } from 'react';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import AddNewContactModal from '../../../../Components/Modals/AddNewContactModal';
import Input from '../../../../Components/Input';
import Dropdown from '../../../../Components/Dropdown';
import DateInput from '../../../../Components/DateInput';
import PrimaryInformationController from '../../../Controllers/AdminScreens/Clients/PrimaryInformationController';
import ClientAvailedServicesScreen from './ClientAvailedServicesScreen';
import LoadingSkeleton from '../../../../Components/LoadingSkeleton';
import Loader from '../../../../Components/Loader';

const PrimaryInformationScreen = ({ isEditable, onUpdate, onCancel }) => {

  const {
    basicInformation,
    isModalOpen,
    clientContactList,
    dropdownFields,
    clientInformation,
    fieldConfigs,
    handleFieldChange,
    handleCloseModal,
    handleAddContactClick,
    loading,
    error,
    clientContactListLoading,
    clientContactListError,
    handleEditClientContact,
    handleDeleteClientContact,
    contactInfoRef,
    onAddUpdateSuccess,
    clientId,
    deleteLoading,
    deleteError
  } = PrimaryInformationController();


  return (
    <div className='basic-info-container'>
      {/* Basic Information Section */}
      <h3 className='basic-info-header'>Basic Information</h3>
      <div className='basic-info-card'>
        <div className='basic-info-row'>
          {fieldConfigs.map((field) => (
            <div className="basic-info-item" key={field.key}>
              {isEditable ? (
                field.type === "dropdown" ? (
                  <Dropdown
                    label={field.label} // Dropdown already renders the label internally
                    options={field.options}
                    value={basicInformation[field.key]}
                    onChange={(selectedValue) => handleFieldChange(field.key, selectedValue)}

                  />
                ) : field.type === "date" ? (
                  <div className="basic-info-label">
                    <label>{field.label}</label>
                    <DateInput
                      value={basicInformation[field.key] || ""}
                      onChange={(e) => handleFieldChange(field.key, e.target.value)}
                    />
                  </div>
                ) : (
                  <Input
                    label={field.label} // Input already renders the label internally
                    placeholder={`Enter ${field.label}`}
                    value={basicInformation[field.key]}
                    onChange={(e) => handleFieldChange(field.key, e.target.value)}
                  />
                )
              ) : (
                <><span className="basic-info-label">{field.label}</span>
                  <span className="basic-info-value">{basicInformation[field.key]} {loading && <LoadingSkeleton width="40%" height="10px" />}</span></>
              )}
            </div>
          ))}

        </div>
        {error && <div className="list-error-msg">{error} </div>}

      </div>

      {/* Client Contacts Section */}
      <div className="tasksScreen-task-table-wrapper" style={{ marginTop: 17 }}>
        <h3 className='basic-info-header'>Client Contacts</h3>
        <div className="basic-info-outer-card">
          <table className="tasksScreen-task-table">
            <thead>
              <tr>
                <th style={{ width: "25%" }}>Name</th>
                <th style={{ width: "15%", }}>Phone No.</th>
                <th style={{ width: "35%", }}>Email ID</th>
                <th style={{ width: "15%" }}>Role</th>
                <th style={{ width: "10%", cursor: 'pointer' }} onClick={handleAddContactClick}> <span style={{ fontSize: '15px' }}>+</span> New Contact</th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: 'white' }}>
              {clientContactList?.map((contact, index) => (
                <tr
                  key={index}
                  className="tasksScreen-main-task-row"
                  style={{
                    borderBottom: "1px solid #dee2e6", // Horizontal line between rows
                  }}
                >
                  <td>
                    {contact?.contact_name}{" "}
                    {contact?.is_primary_contact === 1 && (
                      <span style={{ color: "#609966", marginLeft: "5px" }}>★</span>
                    )}
                  </td>
                  <td>{contact?.contact_phone_code ? '(' + contact?.contact_phone_code +')' : ''} {contact?.contact_phone_no}</td>
                  <td style={styles.tableValue}>{contact?.contact_email}
                  {deleteError[contact?.contact_id] && 
                  <div style={{...styles.deleteErrorMsg, paddingTop: 10}} >{deleteError[contact?.contact_id]}</div>}
                  </td>
                  <td>{contact?.contact_role}</td>
                  <td>
                  <div style={{ display: "flex", gap: "2px" }}>
                    <button className="tasksScreen-edit-button" onClick={() => {handleEditClientContact(contact)}}>
                      <img
                        src={editIcon}
                        alt="Edit"
                        style={{ width: "16px", height: "16px" }}
                      />
                    </button>
                    <button className="tasksScreen-delete-button" style={{opacity: deleteLoading[contact?.contact_id] ? 0.3 : 1}} 
                    onClick={() => {handleDeleteClientContact(contact)}} disabled={deleteLoading[contact?.contact_id]}>
                      <img
                        src={deleteIcon}
                        alt="Delete"
                        style={{ width: "16px", height: "16px" }}
                      />
                    </button>
                    {deleteLoading[contact?.contact_id] &&
                      <span><Loader loading={deleteLoading[contact?.contact_id]} color={'var(--primary-color)'} size='small' /></span> 
                    }
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {clientContactListLoading && <Loader loading={clientContactListLoading} color={'var(--primary-color)'} />}
          {clientContactListError && <div className="list-error-msg">{clientContactListError} </div>}
        </div>
        <AddNewContactModal isOpen={isModalOpen} onClose={handleCloseModal} clientId={clientId} editData={contactInfoRef.current} onValueChange={onAddUpdateSuccess}/>
      </div>
      {/* <h3 className='basic-info-header' style={{paddingTop:20}}>Availed Services</h3> */}
      {/* <div className='basic-info-card' style={{ paddingBottom: "50px" }}> Ensures card padding at the bottom */}
      {/* <div style={styles.servicesGrid}>
            {services?.map((service, index) => (
            <label key={index} style={styles.serviceItem}>
                <input
                type="checkbox"
                checked={selectedServices.includes(service)}
                onChange={() => toggleService(service)}
                style={styles.checkbox}
                />
                {service}
            </label>
            ))}
        </div> */}
      {/* </div> */}
      <div style={{paddingTop:10}}></div>
      <ClientAvailedServicesScreen />
      {isEditable && (
        <div className="button-container">
          <button className="add-client-button" style={{ padding: '0px 30px' }} onClick={onUpdate}>
            Update Client
          </button>
          <button style={styles.cancelButton} onClick={onCancel}>
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    margin: "20px",
    //   fontFamily: "Arial, sans-serif",
    backgroundColor: '#fff',
    marginTop: '-18px'
  },
  header: {
    fontSize: "18px",
    color: "#609966",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: 'Roboto Slab'
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    padding: "20px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    borderBottom: "2px solid black",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  },
  item: {
    flex: "1 1 calc(25% - 20px)",
    minWidth: "200px",
  },
  label: {
    fontWeight: 400,
    color: "#767676",
    display: "block",
    marginBottom: "5px",
    fontSize: '12px',
    fontFamily: 'Roboto'
  },
  value: {
    fontSize: "14px",
    color: '#060606',
    fontWeight: 400,
    fontFamily: 'Roboto'
  },
  tableHeaderRow: {
    display: "flex",
    borderBottom: "2px solid #ddd",
    padding: "15px 0",
    fontWeight: "bold",
    color: "#333",
    backgroundColor: "#f5f5f5",
  },
  tableHeaderCell: {
    padding: "0 10px",
    fontSize: "14px",
  },
  tableRow: {
    display: "flex",
    borderBottom: "1px solid #ddd",
    padding: "10px 0",
    alignItems: "center",
  },
  tableCell: {
    padding: "0 10px",
    fontSize: "14px",
    color: "#555",
  },
  primaryIcon: {
    color: "#2b542c",
    marginLeft: "5px",
  },
  actionButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "#2b542c",
    fontSize: "16px",
    marginLeft: "10px",
  },
  newContactButton: {
    backgroundColor: "#2b542c",
    color: "#fff",
    border: "none",
    borderRadius: "3px",
    padding: "5px 10px",
    cursor: "pointer",
  },
  servicesGrid: {
    display: "flex",
    flexWrap: "wrap",
    // Reduced gap between items for better alignment
    rowGap: "10px", // Adds consistent spacing between rows
  },
  serviceItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    color: "#343C44",
    gap: "5px",
    paddingBottom: "5px",
    marginRight: '80px',// Adds spacing at the bottom of each service item
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  checkbox: {
    marginRight: "3px",
  },
  cancelButton: {
    backgroundColor: "#fff",
    color: "#2b542c",
    border: "1px solid #2b542c",
    borderRadius: "25px",
    padding: "10px 30px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Roboto",
    marginLeft: '10px'
  },
  tableValue: {
    color: "#192A3E",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "12px",
  },
  deleteErrorMsg: {
    color: 'red',
  }
};

export default PrimaryInformationScreen;