import React from 'react';
import CreateTaskController from '../../Controllers/AdminScreens/CreateTaskController';
import Input from '../../../Components/Input';
import Dropdown from '../../../Components/Dropdown';
import CheckBox from '../../../Components/CheckBox';
import uploadIcon from '../../../Assets/Icons/upload.png';
import search from '../../../Assets/Icons/search.png';
import TextInput from '../../../Components/TextInput';
import DateInput from '../../../Components/DateInput';
import Loader from '../../../Components/Loader';

const CreateTaskScreen = () => {
  const { formData,fileName, handleFileChange, handleSubmit, handleChange,
    serviceList,categoryList,groupList,clientList,yearList,periodList, error, loading } = CreateTaskController();

  return (
    <div>
       <div className='title-container' style={{paddingBottom:10,maxWidth:'96%',marginLeft:'auto'}}>
                <h2 className='title'>Tasks</h2>
            </div>
            <div className="create-task-container">
      <h2>Create Task</h2>
      <form onSubmit={handleSubmit}>
        {/* Task Information Section */}
        <div className="task-information">
          <h3 style={styles.heading}>Task Information</h3>
          <div className="task-fields">
            {/* <div className="filter-field">
              <label>Client Name</label>
              <TextInput
                type="text"
                placeholder="Client Name"
                value={formData.clientName || ''}
                onChange={(e) => handleChange('clientName', e.target.value)}
                right={<img src={search} alt="Search" className="search-icon" />}
              />
            </div> */}
            <Dropdown
              label="Client Name"
              options={clientList}
              value={formData.clientName?.label}
              onChange={(value) => handleChange('clientName', value)}
            />

            <Dropdown
              label="Services"
              options={serviceList}
              value={formData.services?.label}
              onChange={(value) => handleChange('services', value)}
            />

            <Dropdown
              label="Task Category"
              options={categoryList}
              value={formData.taskCategory?.label}
              onChange={(value) => handleChange('taskCategory', value)}
            />

            <Dropdown
              label="Group"
              options={groupList}
              value={formData.group.label}
              onChange={(value) => handleChange('group', value)}
            />

            <Dropdown
              label="Primary Assigned User"
              options={['User 1', 'User 2', 'User 3']}
              value={formData.primaryAssignedUser}
              onChange={(value) => handleChange('primaryAssignedUser', value)}
            />

            <Dropdown
              label="Task Leader"
              options={['Leader 1', 'Leader 2', 'Leader 3']}
              value={formData.taskLeader}
              onChange={(value) => handleChange('taskLeader', value)}
            />

            <Dropdown
              label="Task Manager"
              options={['Manager 1', 'Manager 2', 'Manager 3']}
              value={formData.taskManager}
              onChange={(value) => handleChange('taskManager', value)}
            />

            <Dropdown
              label="Financial Year"
              options={yearList}
              value={formData.financialYear.label}
              onChange={(value) => handleChange('financialYear', value)}
            />

            <Dropdown
              label="Period"
              options={periodList}
              value={formData.period.label}
              onChange={(value) => handleChange('period', value)}
            />

            {/* <Input
              label="Period"
              placeholder="Enter Period"
              value={formData.period}
              onChange={(e) => handleChange('period', e.target.value)}
            /> */}
              <div className="filter-field">
                  <label>Start Date</label>
                  <DateInput
                      value={formData.startDate || ""}
                      onChange={(e) => handleChange('startDate', e.target.value)}
                  />
              </div>
              <div className="filter-field">
                  <label>Due Date</label>
                  <DateInput
                      value={formData.dueDate || ""}
                      onChange={(e) => handleChange('dueDate', e.target.value)}
                  />
              </div>

           

            <Dropdown
              label="Task Priority"
              options={[{label:'Not Priority',value:0}, {label:'Priority',value:1}]}
              value={formData.taskPriority.label}
              onChange={(value) => handleChange('taskPriority', value)}
            />

            <Input
              label="Bill Amount"
              placeholder="Enter Bill Amount"
              value={formData.billAmount}
              onChange={(e) => handleChange('billAmount', e.target.value)}
            />

            <Input
              label="Agreed Bill Amount"
              placeholder="Enter Agreed Bill Amount"
              value={formData.agreedBillAmount}
              onChange={(e) => handleChange('agreedBillAmount', e.target.value)}
            />

            <Input
              label="File No."
              placeholder="Enter File Number"
              value={formData.fileNo}
              onChange={(e) => handleChange('fileNo', e.target.value)}
            />

            <Dropdown
              label="To-Do List"
              options={['To-Do 1', 'To-Do 2', 'To-Do 3']}
              value={formData.toDoList}
              onChange={(value) => handleChange('toDoList', value)}
            />

            <Dropdown
              label="Completed By"
              options={['User A', 'User B', 'User C']}
              value={formData.completedBy}
              onChange={(value) => handleChange('completedBy', value)}
            />
            <Input
              label="Task Description"
              placeholder="Enter Task Description"
              value={formData.taskDescription}
              onChange={(e) => handleChange('taskDescription', e.target.value)}
            />

            <div>
              {/* <label style={styles.checboxFlexSty}>
                <input
                  type="checkbox"
                  checked={formData.allowTimePosting || false}
                  onChange={(e) => handleChange('allowTimePosting', e.target.checked)}
                />
               <span style={{color:'#343C44',fontSize:12}}>Allow Time Posting even after Invoice Generation? </span> 
               
              </label> */}
              <CheckBox
                        label="Allow Time Posting even after Invoice Generation?"
                        checked={formData.allowTimePosting || false}
                        onChange={(isChecked) => handleChange('allowTimePosting',isChecked)}
                    />
            </div>
          </div>
        </div>

        {/* Upload Documents Section */}
        <div style={styles.uploadDocumentsContainer}>
          <h3 style={styles.heading}>Upload Documents</h3>
          <div style={styles.uploadBox}>
            <div style={styles.uploadIconWrapper}>
              <span style={styles.plusIcon}>+</span>
            </div>
            <p style={styles.uploadText}>Add Document (Max size : 2MB, Document format supported: PDF, Excel, JPEGs, PNG, Docx)</p>
            <img src={uploadIcon} alt="Upload" style={styles.uploadIcon} />
            <input
              type="file"
              style={styles.hiddenFileInput}
              onChange={handleFileChange}
              accept=".pdf, .doc, .docx, .xlsx, .png, .jpg"
            />
            {/* Display the selected file name */}
       {fileName && <p style={styles.fileName}>{fileName}</p>}
          </div>
        </div>

        {error && <div className='errorBox'>{error}</div>}

        {/* Buttons */}
        <div className="button-container">
          <button type="submit" className="add-client-button" style={{ padding: '0px 30px' }} disabled={loading}>
          <span style={{paddingRight:loading?10:0}}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>
            Create Task
          </button>
          {/* <button className="add-client-button" onClick={handleAddClient} disabled={loading}><span style={{paddingRight:loading?10:0}}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Add Client</button> */}
          <button type="button" style={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </form>
    </div>
    </div>
   
  );
};

const styles = {
  uploadDocumentsContainer: {
    width: "97%",
    // padding: "20px",
  },
  heading: {
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Roboto Slab",
    color: "#609966",
    marginBottom: "10px",
  },
  uploadBox: {
    width: "100%",
    height: "150px",
    border: "1px dashed #609966",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
    backgroundColor: "#ffffff",
  },
  uploadIconWrapper: {
    width: "80px",
    height: "80px",
    backgroundColor: "#f0f0f0",
    borderRadius: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
  },
  plusIcon: {
    fontSize: "20px",
    color: "#343C44",
    fontWeight: "500",
  },
  uploadText: {
    fontSize: "14px",
    color: "#343C44",
    fontFamily: "Roboto",
    fontWeight: "400",
    marginTop: "10px",
  },
  uploadIcon: {
    position: "absolute",
    top: "15px",
    right: "15px",
    width: "20px",
    height: "20px",
  },
  hiddenFileInput: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0,
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "#fff",
    color: "#2b542c",
    border: "1px solid #2b542c",
    borderRadius: "25px",
    padding: "10px 30px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Roboto",
    marginLeft:'10px'
  },
  checboxFlexSty:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    gap:5,
  },
};

export default CreateTaskScreen;


