import React ,{ useEffect,useState, useRef } from "react";
import { ORG_GET_SERVICE_LIST, ORG_DELETE_SERVICE } from "../../../../ApiServices/BaseURL";
import { postData,getData } from "../../../../ApiServices/ApiService";

const useTaskCategoryController = (onRefresh) => {
   
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('');
  const [categorieInfo,setCategorieInfo] = useState({})

  const categoryInfoRef = useRef(null);
  const [deleteLoading, setDeleteLoading] = useState({});
  const [deleteError, setDeleteError] = useState({});

  useEffect(()=>{
    // fetchTaskCategorieList(); 
  },[])

  const fetchTaskCategorieList =async ()=>{
    try {
        setLoading(true);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const response = await getData(`${ORG_GET_SERVICE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                setCategories(responseData?.dataJ);
              } else {
                setError(responseData?.info || 'Data Not Found');
              }
            } else {
              setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch Task Categorie data. Status code error.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setError(`Failed to fetch Task Categorie data: ${String(error)}`);
        }
      } finally {
        setLoading(false);
      }
  }

  const handleAddCategory = () => {
    categoryInfoRef.current = null;
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getStatusStyle = (status) => {
    switch (status) {
    //   case "2":
    //     return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" };
      case 0:
        //Active
        return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" };
      case 1:
        //Disabled
        return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" };
      default:
        return {};
    }
  };

  const handleEditService =(service)=>{
    setCategorieInfo(service);
    categoryInfoRef.current = service;
    setIsModalOpen(true);

  }

  const OnAddUpdateSucess = () => {
    // fetchTaskCategorieList();
    if(onRefresh){
      console.log('on refresh')
      onRefresh()
    }
  }

  const handleDeleteTaskCategory = (service) => {
    // console.log('handleDeleteTaskCategory service @', service);
    showConfirmation(service);
  }

  const showConfirmation = (service) => {
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      callDeleteTaskCategory(service);
    } else {
      console.log("Delete canceled");
    }
  }

  const callDeleteTaskCategory = async (service) => {
    try {
      setDeleteError((prev) => ({ ...prev, [service?.service_id]: ''}));
      setDeleteLoading((prev) => ({ ...prev, [service?.service_id]: true }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "service_id" : service?.service_id
      }
  
      console.log('callDeleteTaskCategory payload',payLoad);
  
      // Make the API call
      const response = await postData(ORG_DELETE_SERVICE,payLoad);
      const responseData = await response.json();
      console.log('callDeleteTaskCategory response Data',responseData);
  
      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            // setError(responseData?.info);
            setDeleteError((prev) => ({ ...prev, [service?.service_id]: responseData?.info}));
          } else {
            setDeleteError((prev) => ({ ...prev, [service?.service_id]: responseData?.info || 'Failed to delete service. Please try again.'}));
            // setError(responseData?.info || 'Failed to delete service. Please try again.');
          }
        } else {
          setDeleteError((prev) => ({ ...prev, [service?.service_id]: responseData?.info || 'Failed to delete service. Please try again...'}));
          // setError(responseData?.info || 'Failed to delete service. Please try again...');
        }
      } 
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          // setError('Unauthorized access. Your session may have expired. Please log in again.');
          setDeleteError((prev) => ({ ...prev, [service?.service_id]: 'Unauthorized access. Your session may have expired. Please log in again.'}));
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          // setError(responseData?.info || 'Bad request. Please check the request parameters.');
          setDeleteError((prev) => ({ ...prev, [service?.service_id]: responseData?.info || 'Bad request. Please check the request parameters.'}));
        }
      } 
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setDeleteError((prev) => ({ ...prev, [service?.service_id]: responseData?.info || 'Internal Server Error. Please try again later.'}));
        // setError(responseData?.info || 'Internal Server Error. Please try again later.');
      } 
      // Unexpected or network-related errors
      else {
        setDeleteError((prev) => ({ ...prev, [service?.service_id]: 'Unexpected error occurred. Please try again later.'}));
        // setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        // setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete service. Please try again.'}`);
        setDeleteError((prev) => ({ ...prev, [service?.service_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete service. Please try again.'}`}));
      } else if (error.request) {
        // Request was made but no response was received
        // setError('No response received from the server. Please check your network connection and try again.');
        setDeleteError((prev) => ({ ...prev, [service?.service_id]: 'No response received from the server. Please check your network connection and try again.'}));
      } else {
        // An error occurred in setting up the request
        // setError(`Failed to delete service data: ${String(error)}`);
        setDeleteError((prev) => ({ ...prev, [service?.service_id]: `Failed to delete service data: ${String(error)}` }));
      }
    } finally {
        setDeleteLoading((prev) => ({ ...prev, [service?.service_id]: false })); 
    }
  }

  return {
    categories,
    isModalOpen,
    handleAddCategory,
    handleCloseModal,
    getStatusStyle,
    handleEditService,
    categorieInfo,
    categoryInfoRef,
    OnAddUpdateSucess,
    handleDeleteTaskCategory,
    loading,
    deleteLoading,
    deleteError,
    error
  };
};

export default useTaskCategoryController;
