import React, { useState } from "react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import TextInput from "../TextInput";
import DateInput from "../DateInput";
import closeIcon from "../../Assets/Icons/close.png";
import search from "../../Assets/Icons/search.png";

const AddToDoModal = ({ isOpen, onClose, onValueChange }) => {
    const [type, setType] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [clientName, setClientName] = useState("");
    const [fileNo, setFileNo] = useState("");
    const [description, setDescription] = useState("");

    const typeOptions = ["Follow Up", "Meeting", "Task"];

    const handleAddSchedule = () => {
        return
        const scheduleData = {
            type,
            dateTime,
            clientName,
            fileNo,
            description,
        };
        onValueChange(scheduleData);
        onClose();
    };

    const resetForm = () => {
        setType("");
        setDateTime("");
        setClientName("");
        setFileNo("");
        setDescription("");
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" style={styles.iconImage} />
                </button>
                <h2 className="modal-title">Add Schedule/Reminder</h2>
                <hr className="title-divider" />

                <div className="form-grid">
                    <Dropdown
                        label="Select Type"
                        options={typeOptions}
                        value={type}
                        onChange={setType}
                    />
                    <div>
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Due By</label>
                        <DateInput
                            label="Published Date"
                            value={dateTime}
                            onChange={(date) => setDateTime(date)}
                            width={'250px'}
                        />
                    </div>
                    <div>
                        <div className="filter-field">
                            <label>Client</label>
                            <TextInput
                                type="text"
                                placeholder="Client"
                                value={clientName}
                                onChange={(e) => setClientName(e.target.value)}
                                right={<img src={search} alt="Search" className="search-icon" />}
                            />
                        </div>
                    </div>

                    <div>
                        <div className="filter-field">
                            <label>File No.</label>
                            <TextInput
                                type="text"
                                placeholder="File No."
                                value={fileNo}
                                onChange={(e) => setFileNo(e.target.value)}
                                right={<img src={search} alt="Search" className="search-icon" />}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <label style={styles.label}>Description</label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Enter Description"
                        style={styles.textarea}
                    ></textarea>
                </div>

                <hr className="footer-divider" />

                <div className="button-container">
                    <button className="add-client-button" onClick={handleAddSchedule}>Add</button>
                    <button className="cancel-button" onClick={() => { resetForm(); onClose(); }}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    iconImage: {
        width: '15px',
        height: '15px',
    },
    exisitingHeading: {
        color: '#343C44',
        fontSize: '10px',
        fontWeight: 500,
        fontFamily: 'Roboto'
    },
    label: {
        fontSize: "12px",
        color: "#343c44",
        fontWeight: "500",
    },
    textarea: {
        width: "96%",
        padding: "10px",
        border: "1px solid #D6E4EC",
        borderRadius: "5px",
        fontSize: "14px",
        minHeight: "80px",
        resize: "none",
    },
};

export default AddToDoModal;
