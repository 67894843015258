import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ORG_GET_TEAM_INFO, ORG_GET_TEAM_MEMBERS,ORG_UPDATE_TEAM_STATUS } from "../../../../ApiServices/BaseURL";
import { getData, postData } from "../../../../ApiServices/ApiService";

const ManageTeamDetailsController = () => {
    const location = useLocation();
    const isTeamInfoEdit = useRef(location.state?.isEdit);
    const [teamID, setTeamID] = useState(location.state?.selTeamInfo?.team_id || null);
    const [statusLoading,setStatusLoading]= useState(false);
    const [teamStatusError,setTeamStatusError]=useState('');
    
    const [primaryData, setPrimaryData] = useState({        
        team_id: null,
        team_name: "",
        team_size: 0,
        team_status: 0,
        team_description: "",
        emp_id_team_lead_name: "",
        emp_id_team_manager_name: "",
        create_by_name: "",
        create_at:''
    });

    const fieldConfigs = [
        { label: "Team Name", key: "team_name", type: "text" },
        { label: "Team Manager", key: "emp_id_team_manager_name", type: "dropdown", options: ["Group A", "Group B"] },
        { label: "Team Leader", key: "emp_id_team_lead_name", type: "dropdown", options: ["Team X", "Team Y"] },
        { label: "Created By", key: "create_by_name", type: "dropdown", options: ["Team X", "Team Y"] },
        { label: "Created On", key: "create_at", type: "date" },
    ];
    const teamMembers1 = [
      { name: "Adams Baker", phone: "98900 98900", email: "adams.baker@example.com" },
      { name: "Clark Davis", phone: "98900 98900", email: "clark.davis@example.com" },
      { name: "Evans Frank", phone: "98900 98900", email: "evans.frank@example.com" },
  ];

    const navigate = useNavigate();
    const [userToggleState, setUserToggleState] = useState(true);
    const [teamMemberModalOpen, setTeamMemberModalOpen] = useState(false);
    const [teamInfo, setTeamInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(''); 
    const [teamMembers, setTeamMembers]  = useState([]);
    const [teamMembersError, setTeamMemebrsError] = useState(''); 

    useEffect(()=>{
        if(!location.state?.isEdit){
            if(location.state?.selTeamInfo?.team_id){
              console.log("Use State")
            fetchTeamInfo(location.state?.selTeamInfo?.team_id);
            }
        }else{
            isTeamInfoEdit.current = location.state?.isEdit
            setPrimaryData(location.state?.selTeamInfo);

        }
        fetchTeamMembers(location.state?.selTeamInfo?.team_id)

    },[location.state?.isEdit])
    // console.log("@#$Team Info", location.state?.selTeamInfo)

    const fetchTeamInfo =async (teamId)=>{
        try {
            setLoading(true);
            setError('');
            // setTeamInfo({});
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_TEAM_INFO}?team_id=${teamId}&emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    setPrimaryData(responseData?.dataJ[0]);
                    // selTeamInfo.current = responseData?.dataJ[0];
                  } else {
                    setError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setError(responseData?.info || 'Failed to fetch Team Information. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch Team Information. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Team Information. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch Team Information: ${String(error)}`);
            }
          } finally {
            setLoading(false);
          }
      }

      const fetchTeamMembers =async (teamId)=>{
        try {
            setLoading(true);
            setTeamMemebrsError('');
            setTeamMembers([]);
            // setTeamInfo({});


            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_TEAM_MEMBERS}?emp_id_auth=${storedUserInfo?.emp_id}&team_id=${teamId}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  console.log("Fetch Member success")
                  if (responseData?.dataJ?.length > 0) {
                    setTeamMembers(responseData?.dataJ);
                    // selTeamInfo.current = responseData?.dataJ[0]
                  } else {
                    setTeamMemebrsError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setTeamMemebrsError(responseData?.info || 'Failed to fetch Team Members. Please try again.');
                }
              } else {
                setTeamMemebrsError(responseData?.info || 'Failed to fetch Team Members. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setTeamMemebrsError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setTeamMemebrsError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setTeamMemebrsError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setTeamMemebrsError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setTeamMemebrsError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Team Members. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setTeamMemebrsError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setTeamMemebrsError(`Failed to fetch Team Members: ${String(error)}`);
            }
          } finally {
            setLoading(false);
          }
      }

    const handleBack = () => {
        console.log('handleBack team')
        // navigate('/teams-userlist');
        navigate('/teams-userlist', { state: { selectedTab: location.state?.selectedTab || 0 } });
    }

    const handleUserToggleChange = async (newState,teamId) => {
      console.log('newState',newState)
      try {
        setStatusLoading(true);


        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const payLoad={
          "emp_id_auth": storedUserInfo?.emp_id,
          "team_status": newState === true ? 1:0,
          "team_id": teamId
        }
        console.log('team status:---',payLoad);
        const response = await postData(ORG_UPDATE_TEAM_STATUS,payLoad);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              console.log("Team Status success");
              setTeamStatusError(responseData?.info)
              
            } else {
              setTeamStatusError(responseData?.info || 'Failed to update team status. Please try again.');
            }
          } else {
            setTeamStatusError(responseData?.info || 'Failed to update team status. Status code error.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setTeamStatusError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setTeamStatusError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setTeamStatusError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setTeamStatusError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setTeamStatusError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update team status. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setTeamStatusError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setTeamStatusError(`Failed to update team status: ${String(error)}`);
        }
      } finally {
        setStatusLoading(false);
        setTimeout(() => {
          setTeamStatusError('')
        }, 5000);
      }

    }

    const handleEditTeamMember = (teamMember) => {
        console.log('handleEditTeamMember @@',teamMember)

    }

    const handleDeleteTeamMember = (teamMember) => {
        console.log('handleDeleteTeamMember @@',teamMember)
    }

    const handleUpdateTeam = () => {

    }

    const handleDeleteTeam = () => {
        
    }

    const handleCancelTeam = () => {
        
    }

    const handleAddTeamMembers = () => {
        console.log('handleAddTeamMembers')
        setTeamMemberModalOpen(true);
    }

    const handleCloseTeamMemberModal = () => {
        setTeamMemberModalOpen(false);
    }

    const onValueChangeTeamMember = () => {
      console.log('onValueChangeTeamMember')
      fetchTeamMembers(location.state?.selTeamInfo?.team_id);
    }


    const handleInputChange = (key, value) => {
        setPrimaryData((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    return {
        handleBack,
        userToggleState,
        handleUserToggleChange,
        handleEditTeamMember,
        handleDeleteTeamMember,
        handleUpdateTeam,
        handleDeleteTeam,
        handleCancelTeam,
        handleAddTeamMembers,
        teamMemberModalOpen,
        handleCloseTeamMemberModal,
        onValueChangeTeamMember,
        teamInfo,
        loading,
        error,
        isTeamInfoEdit,
        primaryData,
        fieldConfigs,
        handleInputChange,
        teamMembers,
        setTeamMembers,
        teamMembersError,
        teamID,
        statusLoading,
        teamStatusError
    }

}

export default ManageTeamDetailsController;