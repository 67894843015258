import React, { useState, useEffect,useRef } from 'react';
import Input from '../Input';
import Dropdown from '../Dropdown';
import closeIcon from '../../Assets/Icons/close.png';
import pluseIcon from '../../Assets/Icons/Pluse.png';
import uploadIcon from '../../Assets/Icons/upload.png';
import TextInput from '../TextInput';
import search from '../../Assets/Icons/search.png'

const ConfigurationAddTemplateModal = ({ isOpen, onClose, onValueChange }) => {
    const [cientName, setCientName] = useState('');
    const [templateName, setTemplateName] = useState('');
    const [channel, setChannel] = useState('');
    const [listName, setListName] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [opuOut, setOptOut] = useState('');
    const [messageContent, setMessageContent] = useState('');
    const [showUpload, setShowUpload] = useState(false);
  
    const fileInputRef = useRef(null);
  
    // useEffect(() => {
    //   if (clientName || clientType || identificationType || identificationNumber || groupClient || mobileNumber) {
    //     setShowUpload(true);
    //   } else {
    //     setShowUpload(false);
    //   }
    // }, [clientName, clientType, identificationType, identificationNumber, groupClient, mobileNumber]);
  
    // Reset form fields to initial values
    const resetForm = () => {
        setChannel('');
        setCientName('');
        setTemplateName('');
        setPhoneNo('');
        setOptOut('');
        setListName('')
    };
  
    // Run resetForm when modal closes
    useEffect(() => {
      if (!isOpen) {
        resetForm();
      }
    }, [isOpen]);
  
    const handleAddClient = () => {
      return
      const clientData = {
        channel,
        cientName,
        opuOut,
        listName,
        phoneNo,
        templateName
      };
  
      onValueChange(clientData); // Send data to Dashboard
      onClose(); // Close modal
    };
  
    // Handle file input change
    const handleFileChange = (event) => {
      const files = event.target.files;
      if (files && files.length > 0) {
        console.log("Uploaded file:", files[0]);
        // Handle file upload here (e.g., upload to server or process locally)
      }
    };
  
    // Trigger file input click
    const handleUploadClick = () => {
      fileInputRef.current.click();
    };

    const groupClientOptions = ["Active", "In Active"];
    const [groupClient, setGroupClient] = useState('');
  
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
          </button>
          <h2 className="modal-title">Add Template</h2>
          <hr className="title-divider" />
        <div className="form-grid">
          <Dropdown
              label="Channel"
              options={groupClientOptions}
              value={channel}
              onChange={setChannel}
            />
            <Input
                label="Template Name"
                placeholder="Template Name"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                />
          </div>
          <div style={{ width: "95%", display: "flex", marginTop:'15px' }}>
            <div style={{ width: "90%" }}>
                <label style={{ ...styles.labaleStyle, display: "block" }}>
                Message Content
                </label>
                <textarea
                placeholder="Message Content"
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
                style={{ ...styles.textarea, width: "100%" }}
                rows={15}
                />
            </div>
            </div>

          
          <hr className="footer-divider" />
  
          <div className="button-container">
            <button className="add-client-button" onClick={handleAddClient} style={{paddingLeft:30, paddingRight:30}}>Add</button>
            <button className="cancel-button" onClick={() => { resetForm(); onClose(); }}>Cancel</button>
          </div>
        </div>
      </div>
    );
  };
  
  const styles = {
      uploadContainer: {
          marginTop: '20px',
          width: '97%',
        },
        uploadBox: {
          width: '100%',
          border: '1px dashed #609966',
          borderRadius: '10px',
          padding: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          cursor: 'pointer',
          boxSizing: 'border-box',
          position: 'relative', // Position relative to contain the hidden input
        },
        uploadCircle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          backgroundColor: '#f0f0f0',
        },
        plusIcon: {
          fontSize: '16px',
          color: '#333',
        },
        uploadText: {
          flex: 1,
          color: '#262626',
          fontSize: '12px',
          fontFamily:'Roboto',
          fontWeight:400
        },
        uploadArrow: {
          fontSize: '18px',
          color: '#555',
        },
        fileInput: {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0,
          cursor: 'pointer',
        },
        iconImage: {
          width: '11px',
          height: '11px',
      },
      uploadIconImage: {
        width: '25px',
        height: '25px',
        marginRight:'5px'
    },
    exisitingHeading:{
      color:'#343C44',
      fontSize:'10px',
      fontWeight:500,
      fontFamily:'Roboto'
    },
    formGrid: {
        display: "grid",
        gridTemplateColumns: "1fr", // Single-column layout by default
        gap: "20px", // Space between rows
        marginBottom: "15px",
      },
      halfWidth: {
        gridColumn: "span 1",
        width: "48%", // Half width
        marginRight: "auto", // Aligns left
        marginBottom:'17px'
      },
      fullWidth: {
        gridColumn: "span 2",
        width: "97.5%", // Full width
      },
      row: {
        display: "flex",
        // justifyContent: "space-between",
        columnGap: "80px", // Space between dropdowns
        marginBottom: "15px",
        marginRight: "auto",
        width: "100%"
      },
      labaleStyle: {
        fontSize: '12px',
        marginBottom: '5px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        color: 'rgb(52, 60, 68)'
      },
      textarea: {
        border: "1px solid #d1e3ed",
        borderRadius: "5px",
        fontSize: "14px",
        padding: "10px",
        width: "100%",
        resize: "none", // Prevents resizing for consistent appearance
    },
  }
  
  export default ConfigurationAddTemplateModal;