import React, { useState } from "react";
import CheckBox from "../CheckBox";
import closeIcon from "../../Assets/Icons/close.png";

const ClientManageServicesModal = ({ isOpen, onClose }) => {
    const [selectedSpecializations, setSelectedSpecializations] = useState([]);

    const specializations = [
        "GSTR 1",
        "GSTR 3B",
        "GSTR 4",
        "GSTR 8",
        "GSTR 9",
        "GSTR 9C",
        "GSTR 10",
        "GSTR Registration",
        "GSTR LUT",
        "GSTR Refund",
        "GSTR Appeal/Notices",
        "GSTR Cancellation/Surrender",
        "Challan Generation",
        "GSTR Others",
        "Others",
    ];

    const handleCheckboxChange = (specialization) => {
        setSelectedSpecializations((prev) =>
            prev.includes(specialization)
                ? prev.filter((item) => item !== specialization)
                : [...prev, specialization]
        );
    };

    const handleUpdate = () => {
        console.log("Selected Specializations:", selectedSpecializations);
        onClose(); // Close the modal
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content" style={{ width: '664px' }}>
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" style={styles.closeIcon} />
                </button>
                <h2 className="modal-title">Manage Services</h2>
                <hr className="title-divider" />

                <div style={{ marginBottom: "20px" }}>
                    <label style={styles.label}>Service Category</label>
                    <div style={styles.categoryValue}>GST</div>
                </div>

                <div>
                    <label style={styles.label}>Select Specialization</label>
                    <div style={styles.specializationsGrid}>
                        {specializations.map((specialization, index) => (
                            <div key={index} style={styles.specializationItem}>
                                {/* <label style={styles.labelName}>
                                    <input
                                        type="checkbox"
                                        checked={selectedSpecializations.includes(specialization)}
                                        onChange={() => handleCheckboxChange(specialization)}
                                        style={styles.checkbox}
                                    />
                                    <span>{specialization}</span>
                                </label> */}
                                <CheckBox
                                    label={specialization}
                                    checked={selectedSpecializations.includes(specialization)}
                                    onChange={(isChecked) =>
                                        handleCheckboxChange(specialization, isChecked)
                                    }
                                    style={styles.checkbox}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <hr className="footer-divider" />

                <div className="button-container">
                    <button className="add-client-button" onClick={handleUpdate}>
                        Update
                    </button>
                    <button className="cancel-button" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    closeIcon: {
        width: "16px",
        height: "16px",
    },
    label: {
        display: "block",
        fontWeight: "400",
        marginBottom: "10px",
        color: "#767676",
        fontSize: "12px",
    },
    categoryValue: {
        color: "#060606",
        fontSize: "14px",
        fontWeight: "400",
    },
    specializationsGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(143px, 1fr))",
        gap: "10px",
    },
    specializationItem: {
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        color: "#343C44",
    },
    checkbox: {
        marginRight: "10px",
        color: "#343C44",
    },
    labelName: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

export default ClientManageServicesModal;
