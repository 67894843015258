import React from "react";
import InvoiceAccountClientInformationController from "../../../Controllers/AdminScreens/Invoices/InvoiceAccountClientInformationController";
import { useNavigate } from "react-router-dom";

const InvoiceAccountClientInformationScreen = ()=>{

  const navigator = useNavigate()

    const {
        invoiceInformation,
        setInvoiceInformation,
        summaryData,
        setSummaryData,
        invoiceData,
        setInvoiceData,
        otherInformationData,
        setOtherInformationData,
        receiptsData,
        setReceiptsData,
        estimatesData,
        setEstimatesData
    }= InvoiceAccountClientInformationController();

    // const handleEstimateNavigate = ()=>{
    //   navigator('')
    // }
    // const handleInvoiceNavigate = ()=>{
    //   navigator('')
    // }
    // const handleReceiptNavigate = ()=>{
    //   navigator('')
    // }

    return(
        <>
        <div style={{ width: '93%', margin: 'auto' }}>
        <h3 style={styles.header}>
          <div style={styles.headerContainer} >
            {/* <img
              src={backIcon}
              alt="Back"
              style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
              onClick={handleBack}
            /> */}
            <span>Accounts</span>
          </div>
        </h3>
      </div>
        <div style={styles.container}>
            <h3 style={{ ...styles.sectionTitle, marginBottom: "12px" }}>Invoice Information</h3>
        <div style={styles.row}>
          <div style={styles.staticField}>
            <label style={styles.label}>Client Name</label>
            <span style={styles.staticValue}>{invoiceInformation?.clientName}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Client Type</label>
            <span style={styles.staticValue}>{invoiceInformation?.clientType}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Date of Invoice</label>
            <span style={styles.staticValue}>{invoiceInformation?.dateOfInvoice}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Invoice Number</label>
            <span style={{...styles.staticValue, textDecoration:'underline', color:'#5A55D2'}}>{invoiceInformation?.invoiceNumber}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Combined Bill</label>
            <span style={styles.staticValue}>{invoiceInformation?.combinedBill}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Group Invoice</label>
            <span style={styles.staticValue}>{invoiceInformation?.groupInvoice}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Team Leader</label>
            <span style={styles.staticValue}>{invoiceInformation?.teamLeader}</span>
          </div>
          <div style={styles.staticField}>
            <label style={styles.label}>Service Agent</label>
            <span style={styles.staticValue}>{invoiceInformation?.serviceAgent}</span>
          </div>
        </div>
        </div>
        <div style={{display:'flex', flexWrap:'wrap', maxWidth:'93%', margin:'auto', columnGap:'15px'}}>
        <div style={styles.card} className="card-invoice-summary">
      <h2 style={styles.heading}>Summary</h2>
      <div style={{...styles.item, backgroundColor:'#F7F8FA', padding:'25px 16px'}}>
        <span style={styles.cardLable}>Invoiced Amount</span>
        <span style={styles.cardAmount}>{summaryData.invoicedAmount.toFixed(2)}</span>
      </div>
      <div style={{...styles.item,  padding:'25px 16px'}}>
        <span style={styles.cardLable}>Amount Paid</span>
        <span style={styles.cardAmount}>{summaryData.amountPaid.toFixed(2)}</span>
      </div>
      <div style={{...styles.item, backgroundColor:'#F7F8FA', padding:'25px 16px'}}>
        <span style={styles.cardLable}>Pending Payment</span>
        <span style={styles.cardAmount}>{summaryData.pendingPayment.toFixed(2)}</span>
      </div>
    </div>
    <div style={styles.card}  className="card-invoice-summary">
      <h2 style={styles.heading}>Invoice</h2>
      <div style={{...styles.item, backgroundColor:'#F7F8FA', padding:'25px 16px'}}>
        <span style={styles.cardLable}>Generated</span>
        <span style={{...styles.cardAmount, textDecoration:'underline', color:'#5A55D2'}}>{invoiceData.generated}</span>
      </div>
      <div style={{...styles.item,  padding:'25px 16px'}}>
        <span style={styles.cardLable}>Upcoming</span>
        <span style={{...styles.cardAmount, textDecoration:'underline', color:'#5A55D2'}}>{invoiceData.upcoming}</span>
      </div>
      <div style={{...styles.item, backgroundColor:'#F7F8FA', padding:'25px 16px'}}>
        <span style={styles.cardLable}>Deleted</span>
        <span style={{...styles.cardAmount, textDecoration:'underline', color:'#5A55D2'}}>{invoiceData.dleted}</span>
      </div>
    </div>
    <div style={styles.card}  className="card-invoice-summary">
      <h2 style={styles.heading}>Other Information</h2>
      <div style={{...styles.item, backgroundColor:'#F7F8FA', padding:'25px 16px'}}>
        <span style={styles.cardLable}>Client Taxable</span>
        <span style={styles.cardAmount}>{otherInformationData.clientTaxable}</span>
      </div>
      <div style={{...styles.item,  padding:'25px 16px'}}>
        <span style={styles.cardLable}>Advance Payment</span>
        <span style={styles.cardAmount}>{otherInformationData.advancePayment}</span>
      </div>
      <div style={{...styles.item, backgroundColor:'#F7F8FA', padding:'25px 16px'}}>
        <span style={styles.cardLable}>Pending Issues</span>
        <span style={styles.cardAmount}>{otherInformationData.pendingIssues}</span>
      </div>
    </div>
    <div style={styles.card}  className="card-invoice-summary">
      <h2 style={styles.heading}>Receipts</h2>
      <div style={{...styles.item, backgroundColor:'#F7F8FA', padding:'25px 16px'}}>
        <span style={styles.cardLable}>Generated</span>
        <span style={{...styles.cardAmount, textDecoration:'underline', color:'#5A55D2'}}>{receiptsData.generated}</span>
      </div>
      <div style={{...styles.item,  padding:'25px 16px'}}>
        <span style={styles.cardLable}>Pending</span>
        <span style={{...styles.cardAmount, textDecoration:'underline', color:'#5A55D2'}}>{receiptsData.pending}</span>
      </div>
      <div style={{...styles.item, backgroundColor:'#F7F8FA', padding:'25px 16px'}}>
        <span style={styles.cardLable}>Overdue</span>
        <span style={{...styles.cardAmount, textDecoration:'underline', color:'#5A55D2'}}>{receiptsData.overdue}</span>
      </div>
    </div>
    <div style={styles.card}  className="card-invoice-summary">
      <h2 style={styles.heading}>Estimates</h2>
      <div style={{...styles.item, backgroundColor:'#F7F8FA', padding:'25px 16px'}}>
        <span style={styles.cardLable}>Generated</span>
        <span style={{...styles.cardAmount, textDecoration:'underline', color:'#5A55D2'}}>{estimatesData.generated}</span>
      </div>
      <div style={{...styles.item,  padding:'25px 16px'}}>
        <span style={styles.cardLable}>Converted</span>
        <span style={{...styles.cardAmount, textDecoration:'underline', color:'#5A55D2'}}>{estimatesData.converted}</span>
      </div>
      <div style={{...styles.item, backgroundColor:'#F7F8FA', padding:'25px 16px'}}>
        <span style={styles.cardLable}>Deleted</span>
        <span style={{...styles.cardAmount, textDecoration:'underline', color:'#5A55D2'}}>{estimatesData.deleted}</span>
      </div>
    </div>
    </div>
        </>
    )

}

const styles = {
    container: {
      padding: "20px",
      maxWidth: "90%",
      margin: "auto",
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    title: {
      fontSize: "28px",
      marginBottom: "23px",
      fontWeight: "500",
      color: "#1F384C",
      fontFamily: "Roboto Slab"
    },
    section: {
      marginBottom: "21px",
    },
    sectionTitle: {
      fontSize: "18px",
      fontWeight: "500",
      color: "#609966",
      fontFamily: 'Roboto Slab'
    },
    
    cancelButton: {
      backgroundColor: "transparent",
      border: "1px solid #ccc",
      borderRadius: "5px",
      padding: "10px 20px",
      cursor: "pointer",
    },
    
    row: {
      display: 'flex',
      flexWrap: 'wrap', // Allow wrapping to the next row
      columnGap: '70px', // Gap between items (both horizontal and vertical)
      rowGap: '15px',
      padding: '16px 11px',
      border: '1px #EFF0F4 solid',
      borderBottom: '2px #060606 solid',
      justifyContent: 'space-between', // Distribute items evenly
    },
  
    staticField: {
      flex: '1 1 calc(25% - 70px)', // Adjusts for 4 items per row with gaps
      minWidth: '200px', // Minimum width of each item
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    label: {
      fontSize: "12px",
      marginBottom: "2px",
      color: "#767676",
      fontFamily: 'Roboto',
      fontWeight: '400'
    },
    staticValue: {
      fontSize: "14px",
      fontWeight: "400",
      color: '#060606',
      fontFamily: 'Roboto'
    },
    
    labaleStyle: {
      fontSize: '12px',
      marginBottom: '5px',
      fontWeight: '400',
      fontFamily: 'Roboto',
      color: 'rgb(52, 60, 68)'
    },
    card: {
        minWidth:'25%',
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '45px',
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    //   maxWidth: "90%",
    //   margin: "auto",
      marginTop:'25px',
      paddingBottom:'30px',
      paddingTop:'20px'
      },
      heading: {
        fontSize: '18px',
        fontWeight: 'bold',
        paddingBottom: '20px',
        // paddingTop: '19px',
      },
      item: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
      },
    cardLable:{
        color:'#505470',
        fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize:'14px'
    },
    cardAmount:{
        color:'#060606',
        fontFamily: 'Roboto',
      fontWeight: '700',
      fontSize:'16px'
    },

    headerContainer: {
      display: 'flex',
      alignItems: 'center', // Vertically align items
    },
    header: {
      fontSize: "22px",
      color: "#060606",
      marginBottom: "10px",
      fontWeight: 500,
      // fontFamily: 'Montserrat',
      //   marginLeft:'20px'
    },
  };

export default InvoiceAccountClientInformationScreen