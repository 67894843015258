import React, { useState } from 'react';
import dropDownIcon from '../../../../Assets/Icons/chevron-down.png';

const CommentsScreen = () => {

    const comments = [
        {
            submittedBy: "John Doe",
            comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut felis metus. Pellentesque quis...",
            commentDate: "11/11/2024 1:30 PM",
        },
        {
            submittedBy: "John Doe",
            comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut felis metus. Pellentesque quis...",
            commentDate: "11/11/2024 1:30 PM",
        },
        {
            submittedBy: "John Doe",
            comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut felis metus. Pellentesque quis...",
            commentDate: "11/11/2024 1:30 PM",
        },
        {
            submittedBy: "John Doe",
            comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut felis metus. Pellentesque quis...",
            commentDate: "11/11/2024 1:30 PM",
        },
        {
            submittedBy: "John Doe",
            comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut felis metus. Pellentesque quis...",
            commentDate: "11/11/2024 1:30 PM",
        },
    ];

    return (
        <div className="tasksScreen-task-table-wrapper">
            <h3 className='basic-info-header'>Comments</h3>
            <table className="tasksScreen-task-table">
                <thead>
                    <tr>
                        <th style={{ width: "5%" }}></th>
                        <th style={{ width: "20%" }}>Submitted By</th>
                        <th style={{ width: "60%" }}>Comment</th>
                        <th style={{ width: "15%" }}>Comment Date</th>
                    </tr>
                </thead>
                <tbody style={{backgroundColor:'white', borderBottom:'2px solid black'}}>
                    {comments?.map((comment, index) => (
                        <tr
                            key={index}
                            style={{
                                borderBottom: "1px solid #f8f9fa",
                            }}
                        >
                            <td>
                                <img
                                    src={dropDownIcon}
                                    alt="Dropdown"
                                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                                />
                            </td>
                            <td style={styles.tableValue}>{comment.submittedBy}</td>
                            <td style={styles.tableValue}>{comment.comment}</td>
                            <td style={styles.tableValue}>{comment.commentDate}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

};

const styles = {
    header: {
      fontSize: "18px",
      color: "#609966",
      marginBottom: "10px",
      fontWeight:500,
      fontFamily:'Roboto Slab'
    },
    tableValue:{
        colour:'#192A3E',
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px'
    },
    tableLink:{
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px',
        textDecoration:'underline',
        color: '#5A55D2'
    }
}

export default CommentsScreen;