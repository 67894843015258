import React, { useRef, useState } from "react";
import TextInput from "../../../../Components/TextInput";
import pluseIcon from "../../../../Assets/Icons/Pluse.png";
import search from "../../../../Assets/Icons/search.png";
import TaxDashboardController from "../../../Controllers/AdminScreens/AddOns/IncomeTaxPortalController";

const IncomeTaxPortalScreen = () => {

    const {
        organizationDetails,
        itrClientStats,
    } = TaxDashboardController();

    const [clientName, setClientName] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            console.log("Uploaded file:", files[0]);
            // Handle file upload here (e.g., upload to server or process locally)
        }
    };

    // Trigger file input click
    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div style={styles.container}>
                <h3 style={{ color: '#1F384C', fontSize: '28px', fontWeight: '500', lineHeight: '29.68px', wordWrap: 'break-word' }}>Income Tax Dashboard</h3>
                <div style={styles.row}>
                    <div style={styles.staticField}>
                        <label style={styles.label}>Organization Name</label>
                        <span style={styles.staticValue}>{organizationDetails.organizationName}</span>
                    </div>
                    <div style={styles.staticField}>
                        <label style={styles.label}>License No.</label>
                        <span style={styles.staticValue}>{organizationDetails.licenseNumber}</span>
                    </div>
                    <div style={styles.staticField}>
                        <label style={styles.label}>User Name</label>
                        <span style={styles.staticValue}>{organizationDetails.username}</span>
                    </div>
                    <div style={styles.staticField}>
                        <label style={styles.label}>Password</label>
                        <span
                            style={{ ...styles.staticValue, color: '#5A55D2', cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={handleShowPassword}
                        >
                            {showPassword ? organizationDetails.password : "View"}
                        </span>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '93%', margin: 'auto', columnGap: '34px' }}>
                <div style={styles.card}>
                    <h2 style={styles.heading}>All ITR Client</h2>
                    <div style={{ ...styles.item, backgroundColor: '#F7F8FA', padding: '25px 16px' }}>
                        <span style={styles.cardLable}>Filed</span>
                        <span style={styles.cardValue}>{itrClientStats.filed}</span>
                    </div>
                    <div style={{ ...styles.item, padding: '25px 16px' }}>
                        <span style={styles.cardLable}>In Progress</span>
                        <span style={styles.cardValue}>{itrClientStats.inProgress}</span>
                    </div>
                    <div style={{ ...styles.item, backgroundColor: '#F7F8FA', padding: '25px 16px' }}>
                        <span style={styles.cardLable}>Pending</span>
                        <span style={styles.cardValue}>{itrClientStats.pending}</span>
                    </div>
                </div>
                <div style={styles.card}>
                    <h2 style={styles.heading}>Import Clients</h2>
                    <div style={{ ...styles.item, backgroundColor: '#F7F8FA', padding: '25px 16px' }}>
                        <span style={styles.cardLable}>Upload instructions will be written here</span>
                    </div>
                    <div style={styles.uploadContainer} onClick={handleUploadClick} onDrop={(e) => e.preventDefault()}>
                        <div style={styles.uploadBox} onDragOver={(e) => e.preventDefault()}>
                            <span style={styles.uploadCircle}>
                                <img src={pluseIcon} alt="Plus Icon" style={styles.iconImage} />
                            </span>
                            <p style={styles.uploadText}>Upload document to import clients</p>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={styles.fileInput}
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>
                    <div style={{ justifySelf: 'end', paddingTop: '10px' }}>
                        <img src={require("../../../../Assets/Icons/right_arrow_button.png")} alt="Go" style={{ cursor: 'pointer' }} />
                    </div>
                </div>
                <div style={styles.card}>
                    <h2 style={styles.heading}>Search by PAN No.</h2>
                    <div style={{ ...styles.item, backgroundColor: '#F7F8FA', padding: '25px 16px' }}>
                        <span style={styles.cardLable}>Search instructions will be written here</span>
                    </div>
                    <div style={{ ...styles.item, padding: '5px 16px' }}>
                        <TextInput
                            type="text"
                            placeholder="Search"
                            value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                            right={<img src={search} alt="Search" className="search-icon" />}
                        />
                    </div>
                    <div style={{ justifySelf: 'end' }}>
                        <img src={require("../../../../Assets/Icons/right_arrow_button.png")} alt="Go" style={{ cursor: 'pointer' }} />
                    </div>
                </div>
            </div>
        </>
    )

}

const styles = {
    container: {
        padding: "20px",
        maxWidth: "90%",
        margin: "auto",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    title: {
        fontSize: "28px",
        marginBottom: "23px",
        fontWeight: "500",
        color: "#1F384C",
        fontFamily: "Roboto Slab"
    },
    section: {
        marginBottom: "21px",
    },
    sectionTitle: {
        fontSize: "18px",
        fontWeight: "500",
        color: "#609966",
        fontFamily: 'Roboto Slab'
    },

    cancelButton: {
        backgroundColor: "transparent",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "10px 20px",
        cursor: "pointer",
    },

    row: {
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: '15px',
        padding: '16px 11px',
        border: '1px #EFF0F4 solid',
        borderBottom: '1px #060606 solid',
        justifyContent: 'space-between', // Distribute items evenly
    },

    staticField: {
        flex: '1 1 calc(25% - 70px)', // Adjusts for 4 items per row with gaps
        minWidth: '200px', // Minimum width of each item
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    label: {
        fontSize: "12px",
        marginBottom: "2px",
        color: "#767676",
        fontFamily: 'Roboto',
        fontWeight: '400'
    },
    staticValue: {
        fontSize: "14px",
        fontWeight: "400",
        color: '#060606',
        fontFamily: 'Roboto'
    },

    labaleStyle: {
        fontSize: '12px',
        marginBottom: '5px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        color: 'rgb(52, 60, 68)'
    },
    card: {
        minWidth: '24%',
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '35px',
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        //   maxWidth: "90%",
        //   margin: "auto",
        marginTop: '25px',
        paddingBottom: '30px',
        paddingTop: '20px'
    },
    heading: {
        fontSize: '18px',
        fontWeight: 'bold',
        paddingBottom: '20px',
        // paddingTop: '19px',
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    cardLable: {
        color: '#505470',
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '12px'
    },
    cardAmount: {
        color: '#060606',
        fontFamily: 'Roboto',
        fontWeight: '700',
        fontSize: '16px'
    },

    "@media (max-width: 768px)": {
        card: {
            minWidth: '90%', // Reduce the gap for smaller screens
        },
    },
    uploadContainer: {
        marginTop: '20px',
        width: '97%',
    },
    uploadBox: {
        width: '100%',
        border: '1px dashed #609966',
        borderRadius: '10px',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
        boxSizing: 'border-box',
        position: 'relative', // Position relative to contain the hidden input
    },
    uploadCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
    },
    plusIcon: {
        fontSize: '16px',
        color: '#333',
    },
    uploadText: {
        flex: 1,
        color: '#262626',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    uploadArrow: {
        fontSize: '18px',
        color: '#555',
    },
    fileInput: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        cursor: 'pointer',
    },
    iconImage: {
        width: '15px',
        height: '15px',
        filter: 'brightness(0)',
    },
    iconButton: {
        border: "none",
        borderRadius: "50%",
        height: '10px',
        width: '10px',
        cursor: "pointer",
        marginTop: '10px',
    },
    cardValue: {
        fontSize: "16px",
        fontWeight: "bold",
        color: '#5A55D2',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
};

export default IncomeTaxPortalScreen;