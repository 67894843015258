import React, { useState, useRef, useEffect } from "react";
import Input from "../Input";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import search from '../../Assets/Icons/search.png';
import closeIcon from "../../Assets/Icons/close.png";
import uploadIcon from "../../Assets/Icons/upload.png";
import pluseIcon from "../../Assets/Icons/Pluse.png"

const CreateEntryModal = ({ isOpen, onClose }) => {
    const [clientName, setClientName] = useState("");
    const [documentName, setDocumentName] = useState("");
    const [documentNumber, setDocumentNumber] = useState("");
    const [source, setSource] = useState("");
    const [documentType, setDocumentType] = useState("");
    const [deliveryType, setDeliveryType] = useState("");
    const fileInputRef = useRef(null);

    const sourceOptions = ["Sent by Client", "Sent by Staff"];
    const documentTypeOptions = ["PDF", "Excel"];
    const deliveryTypeOptions = ["Physical", "Email", "WhatsApp"];

    const handleFileUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log("Uploaded file:", file.name);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            resetForm();
        }
    }, [isOpen]);

    const resetForm = () => {
        setClientName("");
        setDocumentName("");
        setDocumentNumber("");
        setSource("");
        setDeliveryType("");
        setDocumentType("");
    };

    const handleAddEntry = () => {
        const newEntry = {
            clientName,
            documentName,
            documentNumber,
            source,
            deliveryType,
        };
        console.log("New Entry:", newEntry);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" style={styles.closeIcon} />
                </button>
                <h2 className="modal-title">Add Document</h2>
                <hr className="title-divider" />
                {/* <div className="filter-field" style={{ paddingBottom: '10px' }}>
                    <label>Client Name</label>
                    <TextInput
                        type="text"
                        placeholder="Enter client name"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        right={<img src={search} alt="Search" className="search-icon" />}
                    />
                </div> */}
                <div className="form-grid">
                    <div>
                        <div className="filter-field">
                            <label>Client Name</label>
                            <TextInput
                                type="text"
                                placeholder="Enter client name"
                                value={clientName}
                                onChange={(e) => setClientName(e.target.value)}
                                right={<img src={search} alt="Search" className="search-icon" />}
                            />
                        </div>
                    </div>
                    <Dropdown
                        label="Document Tyoe"
                        options={documentTypeOptions}
                        value={documentType}
                        onChange={setDocumentType}
                    />

                    <Input
                        label="Document Name"
                        placeholder="Enter document name"
                        value={documentName}
                        onChange={(e) => setDocumentName(e.target.value)}
                    />

                    <Input
                        label="Document No."
                        placeholder="Enter document number"
                        value={documentNumber}
                        onChange={(e) => setDocumentNumber(e.target.value)}
                    />
                    <Dropdown
                        label="Source"
                        options={sourceOptions}
                        value={source}
                        onChange={setSource}
                    />
                    <Dropdown
                        label="Delivery Type"
                        options={deliveryTypeOptions}
                        value={deliveryType}
                        onChange={setDeliveryType}
                    />
                </div>
                <div style={styles.uploadContainer}>
                    <div style={styles.uploadBox} onClick={handleFileUploadClick}>
                        <span style={styles.uploadText}><div style={styles.resourceImage}><img src={pluseIcon} alt="Plus Icon" style={styles.iconImage} /></div>Upload Document</span>
                        <img src={uploadIcon} alt="Upload" style={styles.uploadIcon} />
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={styles.fileInput}
                        onChange={handleFileChange}
                    />
                </div>
                <hr className="footer-divider" />
                <div className="button-container">
                    <button className="add-client-button" onClick={handleAddEntry}>
                        Add
                    </button>
                    <button className="cancel-button" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    closeIcon: {
        width: "16px",
        height: "16px",
    },
    uploadContainer: {
        marginTop: "20px",
    },
    uploadBox: {
        display: "flex",
        alignItems: "center",
        border: "1px dashed #609966",
        borderRadius: "10px",
        padding: "10px",
        cursor: "pointer",
        justifyContent: 'space-between'
    },
    uploadIcon: {
        width: "20px",
        height: "20px",
        marginRight: "10px",
    },
    uploadText: {
        display: 'flex',
        color: "#343C44",
        fontSize: "14px",
        fontWeight: 500,
        gap: "10px",
        alignItems: 'center',
    },
    fileInput: {
        display: "none",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "10px",
        marginTop: "20px",
    },
    resourceImage: {
        width: "25px",
        height: "25px",
        backgroundColor: "#D9D9D9",
        borderRadius: "50%",
    },
    iconImage: {
        width: '20px',
        height: '20px',
        padding: '2px'
    },
};

export default CreateEntryModal;
