import React, { useState } from "react";
import ResourceDetailsController from "../../Controllers/AdminScreens/ResourceDetailsController";
import AddResourceModal from "../../../Components/AddResourceModal";
import phoneIcon from "../../../Assets/Icons/Phone.png";
import backIcon from "../../../Assets/Icons/back.png";

const ResourceDetailsScreen = () => {
    const { resource, navigateBack, navigateNext } = ResourceDetailsController();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAddUserClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="tasksScreen-main-content">
            <div style={styles.headerContainer}>
                <h2 style={styles.headerTitle}>Resources</h2>
                <button
                    className="add-client-button"
                    style={{ marginBottom: 10 }}
                    onClick={handleAddUserClick}
                >
                    Add Resources
                </button>
            </div>
            <div style={styles.container}>
                {/* Header Section */}
                <div style={styles.header}>
                    <div onClick={navigateBack}>
                        <img
                            src={backIcon}
                            alt="back"
                            style={styles.backIcon}
                        />
                    </div>
                    <div style={styles.title}>{resource.title || "No Title Available"}</div>
                    <button style={styles.helpButton}><img
                        src={phoneIcon}
                        alt="Phone"
                        style={styles.searchIcon}
                    /> <span>Need Help</span> </button>
                </div>

                {/* Sub Header Section */}
                <div style={styles.subHeader}>
                    <p style={styles.subHeaderText}>
                        {resource.shortDescription || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut felis metus. Pellentesque quis metus turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam lacus justo, faucibus in rutrum eu, vestibulum sed lectus."}
                    </p>
                </div>

                {/* Main Content Section */}
                <div style={styles.content}>
                    {
                        typeof resource.fullDescription === "string"
                            ? resource.fullDescription
                                .split("\n") // Split text by line breaks
                                .filter((paragraph) => paragraph.trim() !== "") // Remove empty lines
                                .map((paragraph, index) => (
                                    <p key={index} style={styles.paragraph}>
                                        {paragraph.trim()} {/* Trim leading/trailing whitespace */}
                                    </p>
                                ))
                            : (
                                <div>
                                    <p style={styles.paragraph}>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut felis metus. Pellentesque quis metus turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam lacus justo, faucibus in rutrum eu, vestibulum sed lectus. Proin molestie vitae nulla a pretium. Vestibulum cursus rutrum quam, eu lacinia ipsum semper at. Morbi sagittis nunc et urna dapibus, at feugiat felis iaculis. Sed cursus elit eu tincidunt pellentesque. Maecenas dictum rutrum feugiat. Nam porttitor ex sit amet purus congue ultrices. Proin egestas finibus est vitae rutrum. Aenean iaculis augue ligula, vel mollis lorem mattis vitae. Duis a eros at elit ullamcorper facilisis eu non diam. In fermentum ornare sem, in convallis est rutrum a.
                                    </p>
                                    <p style={styles.paragraph}>
                                        Vivamus odio sem, dictum ac diam eu, lobortis blandit tellus. Fusce hendrerit eu leo id vestibulum. Nunc interdum, dui vel placerat interdum, dolor sem vestibulum felis, sit amet hendrerit nulla nulla et enim. Etiam elementum condimentum cursus. Curabitur vitae varius nulla, id accumsan ipsum. Donec rhoncus a ex et pharetra. Ut ac efficitur nibh. Etiam sed dapibus libero, id ornare mauris. Sed rutrum faucibus facilisis. Duis a commodo elit, sit amet bibendum nisl. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Duis non ullamcorper libero. Cras auctor convallis elit sed facilisis. Nulla semper lacus ante, sit amet lobortis elit dignissim elementum. Nulla placerat tristique ex, at commodo nibh aliquam sed. Integer pulvinar, dolor nec tempus pellentesque, nisl eros gravida felis, vehicula rhoncus dui arcu hendrerit orci.
                                    </p>
                                    <p style={styles.paragraph}>
                                        Nunc eu convallis nisl, et lobortis tortor. Sed vitae placerat felis. Nam vehicula libero et ante blandit feugiat. Pellentesque eleifend venenatis blandit. Proin laoreet cursus vestibulum. Pellentesque hendrerit sodales nulla in imperdiet. Aliquam gravida ac velit ac feugiat. Duis dignissim efficitur ipsum. Pellentesque posuere sem id dui pretium, ut placerat nulla maximus. Vestibulum cursus dolor nec lacus dapibus, ut porttitor lectus rutrum. Donec maximus lacus a nibh rutrum sodales.
                                    </p>
                                    <p style={styles.paragraph}>
                                        Quisque turpis diam, pretium imperdiet enim vitae, egestas vestibulum magna. Suspendisse at elit mi. Maecenas rutrum hendrerit nulla. Curabitur facilisis leo odio, et efficitur tellus tincidunt in. In hac habitasse platea dictumst. Fusce aliquam faucibus est a consectetur. Nullam ultricies porttitor sodales. Sed commodo nec est at posuere. Sed nec venenatis est. In et lacus scelerisque, pharetra urna non, vulputate tortor. Nam aliquam urna quis lectus suscipit, at varius massa blandit. Curabitur metus erat, interdum ut accumsan consequat, tincidunt id quam.
                                    </p>
                                </div>
                            )
                    }

                </div>

                {/* Footer Section */}
                <div style={styles.footer}>
                    <button style={styles.nextButton} onClick={navigateNext}>
                        Next Article →
                    </button>
                </div>
                <AddResourceModal isOpen={isModalOpen} onClose={handleCloseModal} />
            </div>
        </div>
    );
};

const styles = {
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "5px",
    },
    headerTitle: {
        fontSize: "22px",
        fontWeight: "500",
        color: "#060606",
    },
    container: {
        padding: "20px",
        borderRadius: "10px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: '20px'
    },
    backButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        color: "#2e5c48",
        border: "1px solid #2e5c48",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        fontSize: "14px",
        cursor: "pointer",
    },
    title: {
        fontSize: "28px",
        fontWeight: "500",
        color: "#1F384C",
        flex: 1,
        textAlign: "center",
    },
    helpButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        color: "#2e5c48",
        border: "1px solid #C5C5C5",
        borderRadius: "20px",
        padding: "5px 15px",
        cursor: "pointer",
        fontSize: "14px",
        color: "#000000"
    },
    subHeader: {
        padding: "20px",
        backgroundColor: "#F2F2F2",
        borderRadius: "5px",
        marginBottom: "20px",
        border: "1px solid #bbb",
    },
    subHeaderText: {
        fontSize: "12px",
        color: "#767676",
    },
    content: {
        lineHeight: "19.60px",
        fontSize: "14px",
        color: "#060606",
        marginBottom: "20px",
    },
    paragraph: {
        fontSize: '16px',
        lineHeight: '1.6',
        marginBottom: '15px', // Spacing between paragraphs
        color: '#333', // Text color
        fontFamily: 'Arial, sans-serif', // Font family
        textAlign: 'justify', // Align text to justify for a cleaner look
      },
    footer: {
        marginTop: "20px",
    },
    nextButton: {
        backgroundColor: "#40513B",
        color: "#FFFFFF",
        padding: "20px 50px",
        border: "none",
        borderRadius: "50px",
        cursor: "pointer",
        fontSize: "16px",
    },
    searchIcon: {
        padding: '0px 10px 0px 0px'
    },
};

export default ResourceDetailsScreen;