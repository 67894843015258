import React, { useState } from "react";
import dropDownIcon from "../Assets/Icons/chevron-down.png";
import Loader from "./Loader";

const Dropdown = ({ label, options, width = 200, value, onChange, isMulti = false, style = {} ,loading}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    if (isMulti) {
      // Handle multiselect logic
      const updatedValue = Array.isArray(value)
        ? value.some((v) => v.value === option.value)
          ? value.filter((item) => item.value !== option.value) // Remove if already selected
          : [...value, option] // Add if not selected
        : [option]; // Initialize as array if not set
      onChange(updatedValue); // Notify parent of the updated value
    } else {
      // Handle single-select logic
      onChange(option); // Notify parent of the selected value
      setIsOpen(false); // Close dropdown for single-select
    }
  };

  const getDisplayValue = () => {
    if (isMulti) {
      // Ensure value is an array for multiselect
      return Array.isArray(value) && value?.length > 0
        ? value?.map((item) => item?.label).join(", ") // Join selected labels
        : "Select options"; // Default placeholder
    }
    return value?.label || value || "Select"; // For single select
  };

  return (
    <div style={styles.container}>
      <label style={styles.label}>{label}</label>
      {loading ? 
      <div style={{...styles.dropdown,width:width}} >
        <span style={styles.selectedOption}>Loading..</span>
        <Loader loading={loading} color={'#000'} size='small' />
      </div>:
      <div style={{ ...styles.dropdown, width: width, ...style }} onClick={toggleDropdown}>
      <span style={styles.selectedOption}>{getDisplayValue()}</span>
      <img src={dropDownIcon} alt="Down Arrow" style={styles.arrow} />
    </div>}

      
      {isOpen && (
        <div style={{ ...styles.dropdownMenu, width: width,maxHeight:250,overflowY:'auto' }}>
          {options.map((option, index) => (
            <div
              key={index}
              style={{
                ...styles.option,
                backgroundColor:
                  isMulti &&
                    Array.isArray(value) &&
                    value.some((item) => item.value === option.value)
                    ? "#d1e7dd"
                    : "#fff", // Highlight selected options
                borderBottom:
                  index !== options.length - 1 ? "1px solid #d1e3ed" : "none",
              }}
              onClick={() => handleOptionClick(option)}
            >
              {isMulti && (
                <input
                  type="checkbox"
                  checked={
                    Array.isArray(value) &&
                    value.some((item) => item.value === option.value)
                  }
                  onChange={() => handleOptionClick(option)}
                  style={styles.checkbox}
                />
              )}
              {option?.label ? option?.label : option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  label: {
    fontSize: "12px",
    marginBottom: "5px",
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#343C44",
  },
  dropdown: {
    // width: "200px",
    height: "25px",
    border: "1px solid #d1e3ed",
    borderRadius: "5px",
    padding: "10px 10px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  selectedOption: {
    color: "#555",
  },
  arrow: {
    fontSize: "12px",
    width: 20,
    height: 20,
    marginLeft: 4,
    verticalAlign: "middle",
  },
  dropdownMenu: {
    position: "absolute",
    top: "100%",
    // minWidth: "100%",
    // maxWidth: "300px",
    border: "1px solid #d1e3ed",
    borderRadius: "5px",
    backgroundColor: "#fff",
    zIndex: 1000,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "10px 0",
    Hight:'200px',
    OverflowY:'auto'
  },
  option: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "10px",
    cursor: "pointer",
  },
  checkbox: {
    margin: 0,
  },
};

export default Dropdown;
