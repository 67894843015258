import React, { useState } from 'react';
import profileImg from '../../../Assets/Images/temp-img.png';
import rightArrowIcon from '../../../Assets/Icons/arrow-right.png';
import arrowUpIcon from '../../../Assets/Icons/arrow-up.png';
import arrowDownIcon from '../../../Assets/Icons/arrow-down.png';
import editIcon from '../../../Assets/Icons/edit.png';
import deleteIcon from '../../../Assets/Icons/delete.png';
import leftArrowIcon from '../../../Assets/Icons/chevron-down.png';
import leftArrowBtsIcon from '../../../Assets/Icons/left-btn.png';
import plusIcon from '../../../Assets/Icons/Pluse.png';
import messageIcon from '../../../Assets/Icons/message.png';
import useDashboardController from '../../Controllers/AdminScreens/DashboardController';
import { useTheme } from '../../../GlobalContext/GlobalContext';
import { useNavigate } from 'react-router-dom';
import AddCommentModal from '../../../Components/Modals/AddCommentModal';
import AddToDoModal from '../../../Components/Modals/AddToDoModal';


const DashboardScreen = () => {
    const { theme } = useTheme();
    console.log('dashboard theme:', theme);
    const navigate = useNavigate();
    const {
        handleCreateTask,
        loading
    } = useDashboardController();
    const sampleTasks = [
        {
            id: 1,
            type: "Upcoming appointment",
            title: "Phani",
            date: "Monday, Oct 21",
            time: "3:30 to 4:30"
        },
        {
            id: 2,
            type: "Scheduled Followup",
            title: "Phani",
            date: "Monday, Oct 21",
            time: "3:30 to 4:30"
        },
        {
            id: 3,
            type: "To do Item",
            title: "Complete eFilling for HPCL by Oct 17th."
        },
        {
            id: 4,
            type: "Pending Task",
            title: "Complete GST for SRCL by Oct 17th."
        },
        {
            id: 5,
            type: "Add Expense",
            title: "File Expenses for the week Oct 1st - Oct 6th."
        },
        {
            id: 6,
            type: "Timesheet Reminder",
            title: "Submit timesheet for the week ending Oct 20th."
        },
        {
            id: 7,
            type: "Followup Remainder",
            title: "Phani",
            date: "Monday, Oct 21",
            time: "3:30 to 4:30"
        },
    ];
    const sampleNotifications = [
        { message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { message: "Curabitur accumsan turpis pharetra augue tincidunt." },
        { message: "Pellentesque habitant morbi tristique senectus et netus." },
        { message: "Suspendisse potenti. Sed porttitor lectus nibh." }
    ];
    const [currentIndex, setCurrentIndex] = useState(0);

    // Function to navigate to the next notification
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % sampleNotifications.length);
    };

    // Function to navigate to the previous notification
    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            (prevIndex - 1 + sampleNotifications.length) % sampleNotifications.length
        );
    };

    // Function to truncate notification if it exceeds 100 characters
    const getTruncatedNotification = (notification) => {
        return notification.message.length > 100 ? `${notification.message.substring(0, 100)}...` : notification.message;
    };
    const handleSheduleAndReminder=()=>{
        navigate('/schedule-reminder')
    }


    const sampleTaskOverview = [
        { task_status: "Completed", count: 1203, trend: 'up' },
        { task_status: "Pending", count: 21, trend: 'down' },
        { task_status: "Priority", count: 4, trend: 'up' },
        { task_status: "Not Started", count: 34, trend: 'up' }
    ];
    const pendingTasksData = [
        {
            client: "Sierra Ferguson",
            phone: "+91 (99) 436-46-15",
            task: "GST Filling, E-Tax File",
            dueDate: "Nov 25, 2024",
        },
        {
            client: "Sierra Ferguson1",
            phone: "+91 (99) 436-46-15",
            task: "GST Filling, E-Tax File",
            dueDate: "Nov 25, 2024",
        },
        {
            client: "Sierra Ferguson2",
            phone: "+91 (99) 436-46-15",
            task: "GST Filling, E-Tax File",
            dueDate: "Nov 25, 2024",
        },
        {
            client: "Sierra Ferguson3",
            phone: "+91 (99) 436-46-15",
            task: "GST Filling, E-Tax File",
            dueDate: "Nov 25, 2024",
        },
        {
            client: "Sierra Ferguson4",
            phone: "+91 (99) 436-46-15",
            task: "GST Filling, E-Tax File",
            dueDate: "Nov 25, 2024",
        },
        {
            client: "Sierra Ferguson5",
            phone: "+91 (99) 436-46-15",
            task: "GST Filling, E-Tax File",
            dueDate: "Nov 25, 2024",
        },
        {
            client: "Sierra Ferguson6",
            phone: "+91 (99) 436-46-15",
            task: "GST Filling, E-Tax File",
            dueDate: "Nov 25, 2024",
        },
        {
            client: "Sierra Ferguson7",
            phone: "+91 (99) 436-46-15",
            task: "GST Filling, E-Tax File",
            dueDate: "Nov 25, 2024",
        },
        {
            client: "Sierra Ferguson8",
            phone: "+91 (99) 436-46-15",
            task: "GST Filling, E-Tax File",
            dueDate: "Nov 25, 2024",
        },
        {
            client: "Sierra Ferguson9",
            phone: "+91 (99) 436-46-15",
            task: "GST Filling, E-Tax File",
            dueDate: "Nov 25, 2024",
        },
        // Add more sample data as needed
    ];

    const todosData = [
        {
            description: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...",
            dueDate: "Nov 25, 2024",
        },
        {
            description: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...",
            dueDate: "Nov 25, 2024",
        },
        {
            description: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...",
            dueDate: "Nov 25, 2024",
        },
        {
            description: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...",
            dueDate: "Nov 25, 2024",
        },
        {
            description: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...",
            dueDate: "Nov 25, 2024",
        },
        // Add more sample data as needed
    ];
    const ItemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(pendingTasksData.length / ItemsPerPage);

    // Function to handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Calculate the index of the first and last items on the current page
    const indexOfLastItem = currentPage * ItemsPerPage;
    const indexOfFirstItem = indexOfLastItem - ItemsPerPage;

    // Slice the data for the current page
    const currentTasks = pendingTasksData.slice(indexOfFirstItem, indexOfLastItem);

    const quickActions = [
        { label: 'Post Time Sheet' },
        { label: 'Leave/Attendance' },
        { label: 'Add Expense' },
        { label: 'Add Notes' },
        { label: 'Create Task', isActive: true },
    ];
    const appointments = [
        {
            id: 1,
            name_of_person: 'Amitabh Kanth',
            company: 'Nelsa Web LLC',
            date_of_schedule: 'Monday, Oct 14th, 6:30 PM',
            status: 'In Progress',
            statusColor: '#D1FAE5',
            textColor: '#059669'
        },
        {
            id: 2,
            name_of_person: 'Srikanth Reddy Venna',
            company: 'Datascale Pvt. Ltd.',
            date_of_schedule: 'Monday, Oct 14th, 6:30 PM',
            status: 'Start in 1 hour',
            statusColor: '#FEF3C7',
            textColor: '#D97706'
        },
        {
            id: 3,
            name_of_person: 'Srinivas Arutla',
            company: 'Media Channel Ltd.',
            date_of_schedule: 'Monday, Oct 14th, 6:30 PM',
            status: 'Overdue',
            statusColor: '#FECACA',
            textColor: '#DC2626'
        },
        {
            id: 4,
            name_of_person: 'Manish Singh',
            company: 'Corlax Company Ltd.',
            date_of_schedule: 'Monday, Oct 14th, 6:30 PM',
            status: 'Reschedule',
            statusColor: '#E0E7FF',
            textColor: '#4F46E5'
        },
    ];
    const [selectedOption, setSelectedOption] = useState('Follow ups'); // Default selected option
    const options = ['Follow ups', 'Appointments', 'Meeting'];
    const dateOptions = ['Last 7 days', 'Last Month', 'Last 6 Months', 'Last Year'];
    const [clientData, setClientData] = useState({
        clientName: '',
        clientType: '',
        identificationType: '',
        identificationNumber: '',
        groupClient: '',
        mobileNumber: '',
        existingClient: false,
    });

    // Function to handle all values from AddClientModal
    const handleAddClient = (data) => {
        setClientData(data); // Update the state with all data at once
        console.log('Client Data:', data); // For debugging
    };
    const [selecteTabledOption, setSelectedTableOption] = useState("ToDo's");
    const handleAppointments =(appointment)=>{
        navigate('/schedule-reminder')
    }

    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
    const [isAddToDoModalOpen, setIsAddToDoModalOpen] = useState(false);

    const handleAddCommentModal = () => {
        setIsCommentModalOpen(true); // Open the modal
      };
    
      const handleCloseCommentModal = () => {
        setIsCommentModalOpen(false); // Close the modal
      };

      const handleAddToDoModal = () => {
        setIsAddToDoModalOpen(true); // Open the modal
      };
    
      const handleCloseToDoModal = () => {
        setIsAddToDoModalOpen(false); // Close the modal
      };

    return (
        <div className='dashboard-container'>
            <div className="dashboard-screen">
                <div className="main-content">
                    <div className="main-bachground">
                        {/* Add your other dashboard components here */}   
                        <div className="good-morning-section wrap-div">
                            <div className='clientInfo-sty'>
                                <h2 style={{ fontSize: '48px', color: '#1F384C', fontFamily: "Roboto Slab", fontWeight: '500', fontStyle: 'normal', lineHeight: '106%' }}>Good Morning, Thirrpathe!</h2>
                                <p style={{ fontSize: '17px', color: '#060606', fontFamily: 'Montserrat', fontWeight: '500' }}>Plan your workday here, add tasks, activities, notes, or other things to keep track of throughout the day.</p>
                            </div>
                            {/* <div style={{display:'flex'}}> */}
                                {/* Add Task card placed separately from scrollable task container */}
                                <div className="add-task-card" onClick={handleCreateTask}>
                                    <span className="plus-sign">+</span>
                                </div>

                                {/* Scrollable Task Cards */}
                                <div className="task-container-wrapper"style={{paddingBottom:0}}>
                                    <div className="task-container">
                                        {sampleTasks?.map((task, index) => (
                                            <div
                                                className="task-card"
                                                key={task.id}
                                                style={{ backgroundColor: index % 2 === 0 ? 'var(--primary-color)' : 'var(--secondary-color)' }}
                                            >
                                                <h4 className="task-type">{task.type}</h4>
                                                <p className="task-title">{task.title}</p>
                                                {task.date && <p className="task-date">{task.date}</p>}
                                                {task.time && <p className="task-time">{task.time}</p>}
                                                <button className="task-details-button"><img src={rightArrowIcon} alt="Right Arrow" /></button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            {/* </div> */}


                        </div>
                        <div className="notifications-taskoverview-wrapper">
                            <div className="notifications-section">
                                <div className="notifications-header">
                                    <h3 className='notifications-heading'>Notifications</h3>
                                    <p className="notification-message">
                                        {getTruncatedNotification(sampleNotifications[currentIndex])}
                                    </p>
                                    <div className="notification-navigation">
                                        <button className="nav-button" onClick={handlePrevious}>
                                            <img src={leftArrowBtsIcon} alt="Previous" />
                                        </button>

                                        <button className="nav-button" onClick={handleNext}>
                                            <img src={leftArrowBtsIcon} alt="Next" className="rotate-icon" />
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className="task-overview-section">
                                <div className="overview-content">
                                    <h3 className="task-overview-title">Tasks<br />Overview</h3>
                                    {sampleTaskOverview?.map((item, index) => (
                                        <div className="task-overview-item" key={index}>
                                            <h4 className="task-count">{item.count}</h4>
                                            <div className="task-label-container">
                                                <p className="task-label">{item.task_status}</p>
                                                <span className={`trend-icon ${item.trend}`}>
                                                    {item.trend === 'up' ? (
                                                        <img src={arrowUpIcon} alt="Up Arrow" className="arrow-icon" />
                                                    ) : (
                                                        <img src={arrowDownIcon} alt="Down Arrow" className="arrow-icon" />
                                                    )}
                                                </span>

                                            </div>
                                        </div>
                                    ))}
                                    {/* <button className="add-button">+</button> */}
                                </div>
                            </div>

                        </div>
                        <div className="tables-container">
                            {/* Pending Tasks Table */}
                            <div className="table-section">
                                <h3 className="table-title">Pending Tasks</h3>
                                <div className="table-header-card">
                                    <div className="table-header">
                                        <div className="table-cell client-info"><span className="header-text">Client</span></div>
                                        <div className="table-cell tasks">Tasks</div>
                                        <div className="table-cell due-date">Due Date</div>
                                        <div className="table-cell actions-header"></div>
                                    </div>
                                </div>
                                <div className="table-content">
                                    {currentTasks?.map((task, index) => (
                                        <div className="table-row" key={index}>
                                            <div className="table-cell client-info">
                                                <img src={messageIcon} alt="Message Icon" className="message-icon" style={{ cursor: 'pointer'}} onClick={handleAddCommentModal}/>
                                                <div>
                                                    <p className="client-name">{task.client}</p>
                                                    <p className="client-phone">{task.phone}</p>
                                                </div>
                                            </div>
                                            <div className="table-cell tasks">{task.task}</div>
                                            <div className="table-cell due-date">{task.dueDate}</div>
                                            <div className="table-cell actions">
                                                <button className="action-button">
                                                    <img src={editIcon} alt="Edit" className="icon-image" />
                                                </button>
                                                <button className="action-button">
                                                    <img src={deleteIcon} alt="Delete" className="icon-image" />
                                                </button>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                                {/* Pagination and Total */}
                                <hr className="divider" />
                                <div className="table-footer">
                                    <div className="total-count">Total: {pendingTasksData.length}</div>
                                    <div className="pagination">
                                        <div className="pagination-info">
                                            {`${currentPage}-${totalPages} of items`}
                                        </div>
                                        <button
                                            className="pagination-button-arrow"
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            <img src={leftArrowIcon} alt="Previous" className="arrow-left icon-image" />
                                        </button>
                                        {[...Array(totalPages)].map((_, index) => (
                                            <button
                                                key={index}
                                                className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                                                onClick={() => handlePageChange(index + 1)}
                                            >
                                                {index + 1}
                                            </button>
                                        ))}
                                        <button
                                            className="pagination-button-arrow"
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                        >
                                            <img src={leftArrowIcon} alt="Next" className="arrow-right icon-image" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* To Dos and Notes Table */}
                            <div className="table-section" style={{minWidth:220}}>
                                <div className="header-container">
                                    {/* <h3 className="table-title">To Dos and Notes</h3> */}
                                    <h3 className="table-title">To Dos</h3>
                                    <div className="toggle-container">
                                        <span
                                            className={`toggle-option ${selecteTabledOption === "ToDo's" ? 'selected' : 'not-selected'}`}
                                            onClick={() => setSelectedTableOption("ToDo's")}
                                        >
                                            ToDo's
                                        </span>
                                        {/* <span
                                            className={`toggle-option ${selecteTabledOption === "Notes" ? 'selected' : 'not-selected'}`}
                                            onClick={() => setSelectedTableOption("Notes")}
                                        >
                                            Notes
                                        </span> */}
                                    </div>
                                </div>
                                <div className="table-header-card">
                                    <div className="table-header">
                                        <div className="table-cell description padding-left-des">Description</div>
                                        <div className="table-cell due-dates" style={{paddingRight:'90px'}}>Due Date</div>
                                        <div></div>
                                    </div>
                                </div>
                                <div className="table-content">
                                    {todosData?.map((todo, index) => (
                                        <div className="table-row" key={index}>
                                            <div className="table-cell description">
                                                {/* Add the radio button in front of the description */}
                                                <label className="description-radio">
                                                    <input
                                                        type="radio"
                                                        name="todoItem"
                                                        value={todo.description}
                                                        className="large-radio"
                                                    />
                                                    <span className='todo-description'>{todo.description}</span>
                                                </label>
                                            </div>
                                            <div className="table-cell due-dates">{todo.dueDate}</div>
                                            <div style={{paddingRight:'20px'}}><button className="tasksScreen-edit-button">
                                            <img src={editIcon} alt="Edit" onClick={handleAddToDoModal}/>
                                        </button>
                                        <button className="tasksScreen-delete-button">
                                            <img src={deleteIcon} alt="Delete" />
                                        </button></div>
                                        </div>
                                    ))}
                                </div>
                                <hr className="divider" />
                                <div>
                                    <button onClick={handleSheduleAndReminder} className='add-popup-buttons'>
                                       + Add To-Dos and Notes
                                    </button>
                                </div>
                            </div>

                        </div>

                        {/* <div className="quick-actions-container">
                            <h3 className="quick-actions-title">Quick <br />Actions</h3>
                            <div className="quick-actions-cards">
                                {quickActions?.map((action, index) => (
                                    <div
                                        key={index}
                                        className={`quick-action-card ${action.isActive ? 'active' : ''}`}
                                    >
                                        <div className="quick-action-content">
                                            <p className="quick-action-label">{action.label}</p>
                                            <div className="quick-action-icon">
                                                <img src={plusIcon} alt="Plus" className="plus-icon" />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div> */}


                    </div>
                    <div className="dashboard-container-Upcoming-Appointments">
                        <div className="left-section">
                            <div className="activity-header">
                                <h4 className="activity-title">Activity</h4>
                                <select className="dropdown-select">
                                    {dateOptions.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="right-section">
                            <div className="appointments-header">
                                <h4 className='Upcoming-Appointments-header'>Upcoming Appointments</h4>
                                <div className="filter-options">
                                    {options.map((option) => (
                                        <span
                                            key={option}
                                            className={`filter-option ${selectedOption === option ? 'selected' : 'not-selected'}`}
                                            onClick={() => setSelectedOption(option)}
                                        >
                                            {option}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className="appointments-list">
                                {appointments?.map((appointment) => (
                                    <div className="appointment-item" key={appointment.id} onClick={()=>handleAppointments(appointment)}>
                                        <div className="appointment-info">
                                            <img src={profileImg} alt="Appointment" className="appointment-image" />
                                            <div>
                                                <p className="appointment-name">{appointment.name_of_person}</p>
                                                <p className="appointment-company">
                                                    {appointment.company} - {appointment.date_of_schedule}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="appointment-status">
                                            <span
                                                className="status-badge"
                                                style={{
                                                    backgroundColor: appointment.statusColor,
                                                    color: appointment.textColor,
                                                }}
                                            >
                                                {appointment.status}
                                            </span>
                                            <input type="radio" name="appointment-select" />
                                        </div>
                                    </div>
                                ))}
                                <div style={{paddingTop:10}}>
                                    <button onClick={handleSheduleAndReminder}  className='add-popup-buttons'>
                                       + Add Schedule and Reminders
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <AddCommentModal isOpen={isCommentModalOpen} onClose={handleCloseCommentModal} />
            <AddToDoModal isOpen={isAddToDoModalOpen} onClose={handleCloseToDoModal} />
        </div>

    );
};

export default DashboardScreen;
