import React, { useState, useEffect, useRef } from 'react';
import Input from '../Input';
import Dropdown from '../Dropdown';
import closeIcon from '../../Assets/Icons/close.png';
import TextInput from '../TextInput';
import search from '../../Assets/Icons/search.png';
import { postData } from '../../ApiServices/ApiService';
import { ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, ORG_ADD_CLIENT_TO_GROUP } from '../../ApiServices/BaseURL';
import Loader from '../Loader';

const AddGroupMemberModal = ({ isOpen, onClose, onValueChange,groupID }) => {

    console.log('groupmembermodal groupID : ',groupID)
    const [loading, setLoading] = useState(false)
    const [addGroupMemberLoading, setAddGroupMemberLoading] = useState(false)
    const [error, setError] = useState('');
    const [clientList, setClientList] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [success, setSuccess] = useState('');

    const [searchUser, setSearchUser] = useState('');

    const handleAddGroupMember = async () => {
        try {
            setError(''); 
            setSuccess('')          
            setAddGroupMemberLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {  emp_id_auth: storedUserInfo?.emp_id,  group_id: groupID?.current || groupID,client_id:selectedClient?.client_id }
        
            console.log('addclientto_group payload',payLoad);
            // Make the API call
            const response = await postData(ORG_ADD_CLIENT_TO_GROUP,payLoad);
            const responseData = await response.json();
            console.log('response Data',responseData);
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                    setSuccess(responseData?.info);
                  setTimeout(() => {
                    handleClose();
                    onValueChange();
                  }, 3000);
                } else {
                  setError(responseData?.info || 'Failed to add group member. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to add group member. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to add group member. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch Task Categorie data: ${String(error)}`);
            }
          } finally {
            setAddGroupMemberLoading(false);
          }

        // onValueChange(teamData);
        // onClose();
    }

    const handleClose=()=>{
        setError('');
        onClose();
        setSuccess('');
        setSearchUser('');
        setClientList([]);
        setSelectedClient(null)
      }

    const handleSearchClient = async () => {
   
        try {
            setError('');           
            setLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {  emp_id_auth: storedUserInfo?.emp_id,  search_type: "GENERIC", search_word: searchUser}
        
            console.log('team Data',payLoad);
            // Make the API call
            const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER,payLoad);
            const responseData = await response.json();
            console.log('response Data',responseData);
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                    setClientList(responseData?.dataJ || []);
                //   setError(responseData?.info);
                  setTimeout(() => {
                    // handleClose();
                    // onValueChange();
                  }, 2000);
                } else {
                  setError(responseData?.info || 'Failed to fetch Client. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch Client. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Client. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch Task Categorie data: ${String(error)}`);
            }
          } finally {
            setLoading(false);
          }

        // onValueChange(teamData);
        // onClose();
    };

    const handleSelectClient = (client) => {
        setSelectedClient(client); // Set selected client data
        setSearchUser(client.client_name); // Update TextInput with the selected client's name
        setClientList([]); // Clear the dropdown
      };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={handleClose}>
                    <img src={closeIcon} alt="Close" style={styles.iconImage} />
                </button>
                <h2 className="modal-title">Add Group Member</h2>
                <hr className="title-divider" />

                <div>
                    <div className="filter-field" style={{ marginBottom: '15px' }}>
                        <label
                            style={{
                                ...styles.labaleStyle,
                                // marginBottom: '8px',
                                display: 'block',
                            }}
                        >
                            Search Client
                        </label>
                        <TextInput
                            type="text"
                            placeholder="Search Client"
                            value={searchUser}
                            onChange={(e) => setSearchUser(e.target.value)}
                            onRightClick={handleSearchClient}
                            right={
                                loading ? (
                                    <Loader loading={loading} color={'var(--primary-color)'} size='small'/> // Show loader when loading
                                  ) : (
                                    <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                                  )
                                }
                            style={{ padding: '20px 10px' }}
                        />
                                                {clientList.length > 0 && (
                            <ul style={styles.dropdownList}>
                                {clientList.map((client, index) => (
                                <li
                                    key={client?.client_id}
                                    onClick={() => handleSelectClient(client)}
                                    style={{
                                    ...styles.dropdownItem,
                                    borderBottom: index !== clientList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                                    }}
                                >
                                    {client?.client_name}
                                </li>
                                ))}
                            </ul>
                            )}

                    </div>

                    <div className='basic-info-row'>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Client Name</span>
                        <span className='basic-info-value'>{selectedClient?.client_name || '--'}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Client Type</span>
                        <span className='basic-info-value'>{selectedClient?.client_name || '--'}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Email ID</span>
                        <span className='basic-info-value'>{selectedClient?.client_email || '--'}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Mobile No.</span>
                        <span className='basic-info-value'>{selectedClient?.client_mobile_no || '--'}</span>
                    </div>
                    </div>
                </div>


                <hr className="footer-divider" />
                <div className="button-container">
                    <button className="add-client-button" onClick={handleAddGroupMember} style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                    <span>Add</span>
                    {addGroupMemberLoading && (
                        <Loader loading={addGroupMemberLoading} color={'var(--background-color)'} size="small" />
                    )}
                    </button>
                    <button className="cancel-button" onClick={() => { handleClose(); }}>Cancel</button>
                </div>
                {error && <div className="list-error-msg">{error} </div>}
                {success && <div className="list-success-msg">{success} </div>}

            </div>

        </div>
    )

}

const styles = {
    uploadContainer: {
        marginTop: '20px',
        width: '97%',
    },
    uploadBox: {
        width: '100%',
        border: '1px dashed #8FC7F2',
        borderRadius: '10px',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
        boxSizing: 'border-box',
        position: 'relative', // Position relative to contain the hidden input
    },
    uploadCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
    },
    plusIcon: {
        fontSize: '16px',
        color: '#333',
    },
    uploadText: {
        flex: 1,
        color: '#262626',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    uploadArrow: {
        fontSize: '18px',
        color: '#555',
    },
    fileInput: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        cursor: 'pointer',
    },
    iconImage: {
        width: '15px',
        height: '15px',
    },
    uploadIconImage: {
        width: '25px',
        height: '25px',
        marginRight: '5px'
    },
    exisitingHeading: {
        color: '#343C44',
        fontSize: '10px',
        fontWeight: 500,
        fontFamily: 'Roboto'
    },
    labaleStyle: {
        fontSize: '12px',
        // marginBottom: '5px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        color: 'rgb(52, 60, 68)',
    },
    dropdownList: {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      maxHeight: '200px',
      overflowY: 'auto',
      zIndex: 1000,
      padding: 0, // Remove default padding
      margin: 0, // Remove default margin
      listStyleType: 'none', // Remove the dots
    },
    dropdownItem: {
      padding: '10px',
      cursor: 'pointer',
      borderBottom: '1px solid #eee', // Default for all items
      backgroundColor: '#fff',
      hover: {
        backgroundColor: '#f0f0f0',
      },
    },
}

export default AddGroupMemberModal;