import { useState } from "react";

const UserSpecializationController = () => {

    const userSpecializationServices = [
        { category: "GST", type: "GSTR 3B, GSTR 1" },
        { category: "Income Tax", type: "ITR Filing" },
        { category: "TDS", type: "TDS Returns" },
    ];

    const [selUserSpecializationService, setSelUserSpecializationService] = useState(null);
    const [isUserSpecializationModalOpen, setIsUserSpecializationModalOpen] = useState(false);

    const handleCloseUserSpecializationModal = () => {
        setIsUserSpecializationModalOpen(false);
        setSelUserSpecializationService(null); 
      };

    const handleEditSpecialization = (service) => {
        setSelUserSpecializationService(service); 
        setIsUserSpecializationModalOpen(true);
    }

    const handleToggleChange = (newState) => {
        console.log('Toggle is now:', newState);
    }

    return {
        userSpecializationServices,
        handleEditSpecialization,
        handleToggleChange,
        selUserSpecializationService,
        isUserSpecializationModalOpen,
        handleCloseUserSpecializationModal
    };

}

export default UserSpecializationController;