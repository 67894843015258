import { useState, useEffect } from 'react';

const ResourcesListController = () => {
    const [resources, setResources] = useState([]);
    const [filteredResources, setFilteredResources] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    useEffect(() => {
        // Sample data to match the UI
        const data = Array(10).fill({
            title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            description: "Phasellus ut felis metus. Pellentesque quis metus turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.",
        });
        setResources(data);
        setFilteredResources(data);
    }, []);

    useEffect(() => {
        const filtered = resources.filter((resource) =>
            resource.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredResources(filtered);
        setCurrentPage(1); // Reset to first page on search
    }, [searchTerm, resources]);

    const totalPages = Math.ceil(filteredResources.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentResources = filteredResources.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return {
        currentResources,
        currentPage,
        totalPages,
        handlePageChange,
        setSearchTerm,
    };
};

export default ResourcesListController;
