import React, { useState } from 'react';
import ClientListController from '../../../Controllers/AdminScreens/Clients/ClientListController';
import downArrow from '../../../../Assets/Icons/chevron-down.png';
import filter from '../../../../Assets/Icons/filter.png';
import Dropdown from '../../../../Components/Dropdown';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../Components/Loader';

const ClientListScreen = () => {
    const navigate = useNavigate();
    const {
        tasks,
        currentPage,
        totalPages,
        handlePageChange,
        loading,
        error
    } = ClientListController();
    console.log('get client screen')

    const [showFilters, setShowFilters] = useState(false); // Control the visibility of filters
    const [filters, setFilters] = useState({
        clientName: '',
        clientType: '',
        gstin: '',
        city: '',
        assignedTo: '',
        groupName: '',
        pendingTask: '',
    });

    const handleFilterChange = (name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const applyFilters = () => {
        console.log('Applying filters:', filters);
    };

    const resetFilters = () => {
        setFilters({
            clientName: '',
            clientType: '',
            gstin: '',
            city: '',
            assignedTo: '',
            groupName: '',
            pendingTask: '',
        });
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleNav = (pageName, componentName,clintId)=>{
        console.log('page',pageName);
        navigate(`/${pageName}`,{ state: { task:componentName,clientId:clintId } })
    
      }

    return (
        <>
        <div style={{ width: '93%', margin: 'auto' }}>
            <h3 style={styles.header}>
                <div style={styles.headerContainer}>
                    <span>Clients</span>
                </div>
            </h3>
        </div>
        <div className="tasksScreen-main-content">
            {/* Filters Section */}
            <div className="tasksScreen-tasks-container">
                <div className="tasksScreen-tasks-header">
                    <h2 className="tasksScreen-tasks-title">Search Clients</h2>
                </div>
                <div className="tasksScreen-filters-row">
                    <div className="tasksScreen-filters-button-wrapper">
                        <button className="tasksScreen-filters-button" onClick={toggleFilters}>
                            <img src={filter} alt="Filter"/>  <span>
                            {showFilters ? 'Filters' : 'Filters'}
                            </span>
                            
                        </button>
                    </div>
                </div>

                {showFilters && (
                    <div className={`tasksScreen-filters-container ${showFilters ? 'tasksScreen-open-filters' : ''}`}>
                        <div className="tasksScreen-filters">
                            {/* Replace each filter select with Dropdown component */}
                            <Dropdown
                                label="Client Name"
                                options={['Type 1', 'Type 2']}
                                value={filters.taskType}
                                onChange={(value) => handleFilterChange('taskType', value)}
                            />

                            <Dropdown
                                label="Clent Type"
                                options={['Type 1', 'Type 2']}
                                value={filters.taskType}
                                onChange={(value) => handleFilterChange('taskType', value)}
                            />

                            <Dropdown
                                label="GSTIN NO."
                                options={['2023-24', '2024-25']}
                                value={filters.financialYear}
                                onChange={(value) => handleFilterChange('financialYear', value)}
                            />

                            <Dropdown
                                label="Pending Task"
                                options={['Q1', 'Q2', 'Q3', 'Q4']}
                                value={filters.period}
                                onChange={(value) => handleFilterChange('period', value)}
                            />

                            <Dropdown
                                label="City"
                                options={['In Progress', 'Not Started', 'Priority', 'Delayed']}
                                value={filters.status}
                                onChange={(value) => handleFilterChange('status', value)}
                            />

                            <Dropdown
                                label="Assigned To"
                                options={['Type 1', 'Type 2']}
                                value={filters.taskType}
                                onChange={(value) => handleFilterChange('taskType', value)}
                            />

                            <Dropdown
                                label="Group Name"
                                options={['Type 1', 'Type 2']}
                                value={filters.taskType}
                                onChange={(value) => handleFilterChange('taskType', value)}
                            />

                            <Dropdown
                                label="Services"
                                options={['Type 1', 'Type 2']}
                                value={filters.taskType}
                                onChange={(value) => handleFilterChange('taskType', value)}
                            />
                        </div>

                        <div className="tasksScreen-filter-buttons">
                            <button onClick={applyFilters} className="tasksScreen-apply-button">Apply</button>
                            <button onClick={toggleFilters} className="tasksScreen-cancel-button">Cancel</button>
                            <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
                        </div>
                    </div>
                )}
            </div>

            {/* Table Section */}
            <div className="tasksScreen-tasks-container" style={{ marginTop: 20 }}>
                <div className="tasksScreen-task-table-wrapper" >
                    <table className="tasksScreen-task-table">
                        <thead>
                            <tr>
                                <th>Client Name</th>
                                <th>Client Type</th>
                                {/* <th>Services</th> */}
                                <th>GSTIN No.</th>
                                <th>City</th>
                                <th>Group Name</th>
                                <th>Assigned To</th>
                                {/* <th>Pending Task</th> */}
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: '#fff' }}>
                            {tasks.map((task, index) => (
                                <React.Fragment key={index}>
                                    <tr
                                        className={`tasksScreen-main-task-row ${task.showDetails ? 'tasksScreen-show-border' : ''}`}
                                    >
                                        <td style={{ color: '#5A55D2', textDecoration: 'underline',cursor:'pointer' }} onClick={()=>{handleNav('manage-clients','PrimaryInformation',task?.client_id )}}>{task?.client_name}</td>
                                        <td>{task?.client_type_name}</td>
                                        {/* <td>{task?.services}</td> */}
                                        <td>{task?.client_gstin}</td>
                                        <td>{task?.client_city}</td>
                                        <td>{task?.group_name}</td>
                                        <td>{task?.emp_name_incharge}</td>
                                        {/* <td>
                                            <span
                                                className={`tasksScreen-status-badge ${task.status.toLowerCase().replace(' ', '-')}`}
                                            >
                                                {task.status}
                                            </span>
                                        </td> */}
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                    {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
                    {error && <div className="list-error-msg">{error} </div>}
                </div>

                {/* Pagination */}
                <div className="tasksScreen-table-footer" >
                    <span className="tasksScreen-pagination-info">
                        {`${currentPage}-${totalPages} of items`}
                    </span>
                    <div className="tasksScreen-pagination">
                        <button
                            className="tasksScreen-pagination-button"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            <img
                                src={downArrow}
                                alt="Previous"
                                className="tasksScreen-arrow-left tasksScreen-icon-image"
                            />
                        </button>
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            className="tasksScreen-pagination-button"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            <img
                                src={downArrow}
                                alt="Next"
                                className="tasksScreen-arrow-right tasksScreen-icon-image"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};
const styles = {
    header: {
      fontSize: "22px",
      color: "#060606",
      marginBottom: "10px",
      fontWeight:500,
      fontFamily:'Montserrat',
    //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader:{
        marginTop:'-5px'
    }
  };

export default ClientListScreen;