

export const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  
  
  export const validatePassword = (password) => {
    // Password should be at least 8 characters long and include a number and a special character
    const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(password);
  };
  
  export const validateTempPassword = (tempPassword) => {
    const re = /^[A-Za-z0-9!@#$%^&*()_+-=]{6,12}$/;
    return re.test(tempPassword);
  };


  export const validateTime = (operatingHours) => {
    const re = /^([0-9]|[01]\d|2[0-3]):([0-5]\d)-([0-9]|[01]\d|2[0-3]):([0-5]\d)$/;
    return re.test(operatingHours)
  
  };

  export const validateMobileNumber = (mobileNumber) => {
    const re = /^[0-9]{10}$/; // Exactly 10 digits
    return re.test(mobileNumber);
  };

  export const validateText = (text) => {
    const re = /^[A-Za-z]+$/; // Only alphabets
    return re.test(text);
  };

  export const validateNumber = (number) => {
    const re = /^[0-9]+$/; // Only digits
    return re.test(number);
  };



  